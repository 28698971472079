import { db, storage } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import request from "superagent";
import {
  GET_PRODUCTS,
  GET_COUPON,
  GET_ERROR,
  GET_SUCCESS,
  GET_CATEGORIES,
  GET_SUBCATEGORIES,
  GET_CURRENCY,
  GET_LOCATION,
  GET_ORDER,
  GET_SLOT,
  GET_STORE,
  GET_SINGLE_ORDER,
  GET_COMMON_SLOT,
  GET_STORE_DETAIL,
  GET_SINGLE_PROMO,
  Dev_URL,
  GET_ALL_PROMO,
  GET_STORE_ALL,
} from "../actionTypes";
import moment from "moment";
import _ from "lodash";
import random from "random-string-generator";
import Papa from "papaparse";
const referencevalue = require("../env");
const cryptLib = require("@skavinvarnan/cryptlib");

let bucket_baseurl =
  "https://storage.googleapis.com/shopezy-a4bc4.appspot.com/";

const newMetadata = {
  cacheControl: "public,max-age=0",
};

export function saveAsset(prod, fileList, fileList1, username) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var temp = prod.Product_Search_Key;
      var Product_Search_Key = temp.join("+");
      var globalarray = [];
      fileList.forEach((f) => {
        storage
          .ref(
            prod.Ref_Store_Id +
              "/Product_Images/Manual/" +
              prod.Product_Id +
              "_" +
              f.name
          )
          .put(f, newMetadata)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              globalarray.push(snapshot.ref.fullPath);

              if (fileList.length === globalarray.length) {
                //foreach work done console.log(globalarray.length);
                fileList1.forEach((thumb) => {
                  storage
                    .ref(
                      prod.Ref_Store_Id +
                        "/Product_Images/thumb/" +
                        prod.Product_Id +
                        "_" +
                        thumb.name
                    )
                    .put(thumb, newMetadata)
                    .then(function (snapshot) {
                      snapshot.ref
                        .getDownloadURL()
                        .then(function (downloadURL) {
                          var thumbImage = snapshot.ref.fullPath;
                          // Add a new document with a generated id.
                          prod.Ref_Store_Id.forEach((Ref_Store_Id) => {
                            db.collection("Product_Details1")
                              .add({
                                Product_Search_Key: Product_Search_Key,
                                Product_Add_Limit: parseInt(
                                  prod.Product_Add_Limit
                                ),
                                Product_Id: prod.Product_Id,
                                Ref_Store_Id: Ref_Store_Id,
                                Product_Name_Lowercase:
                                  prod.Product_Name.toLowerCase(),
                                Is_Marked_Up_Price_Enabled:
                                  prod.Is_Marked_Up_Price_Enabled === "true",
                                Marked_Up_Price:
                                  prod.Marked_Up_Price === undefined
                                    ? 0
                                    : parseFloat(prod.Marked_Up_Price),
                                Marked_Up_Quantity:
                                  prod.Marked_Up_Quantity === undefined
                                    ? 0
                                    : parseInt(prod.Marked_Up_Quantity),
                                Product_Name: prod.Product_Name,
                                Is_Home_Page: prod.Is_Home_Page === "true",
                                Product_Display_Order: parseInt(
                                  prod.Product_Display_Order
                                ),
                                Product_Description: prod.Product_Description,
                                Ref_Product_Brand_Id: prod.Ref_Product_Brand_Id,
                                Ref_Sub_Category_Id: prod.Ref_Sub_Category_Id,
                                Ref_Category_Id: prod.Ref_Category_Id,
                                Product_Measuring_Quantity: "",
                                Product_No_of_Units: "",
                                Is_Active: true,
                                Is_Stock_Available: true,
                                Ref_Currency_Id: prod.Ref_Currency_Id,
                                Display_Name: "",
                                Is_Premier_Promo: false,
                                Promo_Product_Details: [],
                                Product_Actual_Price: parseFloat(
                                  prod.Product_Actual_Price
                                ),
                                Product_Is_Discounted:
                                  prod.Product_Is_Discounted === "true",
                                Product_Discounted_Price:
                                  prod.Product_Discounted_Price === undefined
                                    ? 0
                                    : parseFloat(prod.Product_Discounted_Price),
                                Ref_Product_Offer_Type:
                                  prod.Ref_Product_Offer_Type === undefined
                                    ? 0
                                    : parseInt(prod.Ref_Product_Offer_Type),
                                Ref_Product_Offer_Value:
                                  prod.Ref_Product_Offer_Value === undefined
                                    ? 0
                                    : parseFloat(prod.Ref_Product_Offer_Value),
                                Product_Offer_Shared_By:
                                  prod.Product_Offer_Shared_By === undefined
                                    ? ""
                                    : prod.Product_Offer_Shared_By,
                                Product_Tax_Applicable:
                                  prod.Product_Tax_Applicable === "true",
                                Ref_Product_Tax_Slab:
                                  prod.Ref_Product_Tax_Slab === undefined
                                    ? ""
                                    : prod.Ref_Product_Tax_Slab,
                                Ref_Product_Commision_Slab:
                                  prod.Ref_Product_Commision_Slab === undefined
                                    ? ""
                                    : prod.Ref_Product_Commision_Slab,
                                Product_Disclaimer:
                                  prod.Product_Disclaimer === undefined
                                    ? ""
                                    : prod.Product_Disclaimer,
                                Product_Rating: prod.Product_Rating,
                                Created_At:
                                  firebase.firestore.FieldValue.serverTimestamp(),
                                Updated_At:
                                  firebase.firestore.FieldValue.serverTimestamp(),
                                Product_Thumb_Image_Url: thumbImage,
                                Product_Images: globalarray,
                                Updated_By:
                                  username === undefined ? "" : username,
                              })
                              .then((res) => {
                                dispatch({ type: GET_SUCCESS, payload: res });
                                resolve(res);
                              })
                              .catch((error) => {
                                dispatch({ type: GET_ERROR, payload: error });
                                reject(error);
                              });
                          });
                        });
                    });
                });
              }
            });
          });
      });
    });
  };
}

export function updateAsset(prodid, prod, fileList, fileList1, username) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var temp = prod.Product_Search_Key;
      var Product_Search_Key = temp.join("+");
      _.remove(fileList, function (n) {
        return n.status === "done";
      });
      var filelist1_check = Array.isArray(fileList1);
      var globalarray = [];
      if (fileList.length !== 0 && filelist1_check === true) {
        fileList.forEach((f) => {
          // console.log(f);
          storage
            .ref(
              prod.Ref_Store_Id +
                "/Product_Images/Manual/" +
                prod.Product_Id +
                "_" +
                f.name
            )
            .put(f, newMetadata)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                globalarray.push(snapshot.ref.fullPath);

                if (fileList.length === globalarray.length) {
                  //foreach work done console.log(globalarray.length);
                  fileList1.forEach((thumb) => {
                    storage
                      .ref(
                        prod.Ref_Store_Id +
                          "/Product_Images/thumb/" +
                          prod.Product_Id +
                          "_" +
                          thumb.name
                      )
                      .put(thumb, newMetadata)
                      .then(function (snapshot) {
                        snapshot.ref
                          .getDownloadURL()
                          .then(function (downloadURL) {
                            var thumbImage = snapshot.ref.fullPath;
                            // Add a new document with a generated id.
                            db.collection("Product_Details1")
                              .doc(prodid)
                              .update({
                                Product_Search_Key: Product_Search_Key,
                                Product_Add_Limit: parseInt(
                                  prod.Product_Add_Limit
                                ),
                                Product_Id: prod.Product_Id,
                                Is_Home_Page: prod.Is_Home_Page === "true",
                                Ref_Store_Id: prod.Ref_Store_Id,
                                Product_Display_Order: parseInt(
                                  prod.Product_Display_Order
                                ),
                                Product_Name_Lowercase:
                                  prod.Product_Name.toLowerCase(),
                                Is_Marked_Up_Price_Enabled:
                                  prod.Is_Marked_Up_Price_Enabled === "true",
                                Marked_Up_Price:
                                  prod.Marked_Up_Price === undefined
                                    ? 0
                                    : parseFloat(prod.Marked_Up_Price),
                                Marked_Up_Quantity:
                                  prod.Marked_Up_Quantity === undefined
                                    ? 0
                                    : parseInt(prod.Marked_Up_Quantity),
                                Product_Name: prod.Product_Name,
                                Product_Description: prod.Product_Description,
                                Ref_Product_Brand_Id: prod.Ref_Product_Brand_Id,
                                Ref_Sub_Category_Id: prod.Ref_Sub_Category_Id,
                                Ref_Category_Id: prod.Ref_Category_Id,
                                Is_Active: prod.Is_Active,
                                Ref_Currency_Id: prod.Ref_Currency_Id,
                                Product_Actual_Price: parseFloat(
                                  prod.Product_Actual_Price
                                ),
                                Product_Is_Discounted:
                                  prod.Product_Is_Discounted === "true",
                                Product_Discounted_Price:
                                  prod.Product_Discounted_Price === undefined
                                    ? 0
                                    : parseFloat(prod.Product_Discounted_Price),
                                Ref_Product_Offer_Type:
                                  prod.Ref_Product_Offer_Type === undefined
                                    ? 0
                                    : parseInt(prod.Ref_Product_Offer_Type),
                                Ref_Product_Offer_Value:
                                  prod.Ref_Product_Offer_Value === undefined
                                    ? 0
                                    : parseFloat(prod.Ref_Product_Offer_Value),
                                Product_Offer_Shared_By:
                                  prod.Product_Offer_Shared_By === undefined
                                    ? ""
                                    : prod.Product_Offer_Shared_By,
                                Product_Tax_Applicable:
                                  prod.Product_Tax_Applicable === "true",
                                Ref_Product_Tax_Slab:
                                  prod.Ref_Product_Tax_Slab === undefined
                                    ? ""
                                    : prod.Ref_Product_Tax_Slab,
                                Ref_Product_Commision_Slab:
                                  prod.Ref_Product_Commision_Slab === undefined
                                    ? ""
                                    : prod.Ref_Product_Commision_Slab,
                                Product_Disclaimer:
                                  prod.Product_Disclaimer === undefined
                                    ? ""
                                    : prod.Product_Disclaimer,
                                Product_Rating: prod.Product_Rating,

                                Updated_At:
                                  firebase.firestore.FieldValue.serverTimestamp(),
                                Product_Thumb_Image_Url: thumbImage,
                                Product_Images: globalarray,
                                Updated_By:
                                  username === undefined ? "" : username,
                              })
                              .then((res) => {
                                dispatch({ type: GET_SUCCESS, payload: res });
                                resolve(res);
                              })
                              .catch((error) => {
                                dispatch({ type: GET_ERROR, payload: error });
                                reject(error);
                              });
                          });
                      });
                  });
                }
              });
            });
        });
      } else if (filelist1_check === true) {
        fileList1.forEach((thumb) => {
          storage
            .ref(
              prod.Ref_Store_Id +
                "/Product_Images/thumb/" +
                prod.Product_Id +
                "_" +
                thumb.name
            )
            .put(thumb, newMetadata)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                var thumbImage = snapshot.ref.fullPath;
                // Add a new document with a generated id.
                db.collection("Product_Details1")
                  .doc(prodid)
                  .update({
                    Product_Search_Key: Product_Search_Key,
                    Product_Id: prod.Product_Id,
                    Product_Add_Limit: parseInt(prod.Product_Add_Limit),
                    Is_Home_Page: prod.Is_Home_Page === "true",
                    Ref_Store_Id: prod.Ref_Store_Id,
                    Product_Display_Order: parseInt(prod.Product_Display_Order),
                    Product_Name_Lowercase: prod.Product_Name.toLowerCase(),
                    Is_Marked_Up_Price_Enabled:
                      prod.Is_Marked_Up_Price_Enabled === "true",
                    Marked_Up_Price:
                      prod.Marked_Up_Price === undefined
                        ? 0
                        : parseFloat(prod.Marked_Up_Price),
                    Marked_Up_Quantity:
                      prod.Marked_Up_Quantity === undefined
                        ? 0
                        : parseInt(prod.Marked_Up_Quantity),
                    Product_Name: prod.Product_Name,
                    Product_Description: prod.Product_Description,
                    Ref_Product_Brand_Id: prod.Ref_Product_Brand_Id,
                    Ref_Sub_Category_Id: prod.Ref_Sub_Category_Id,
                    Ref_Category_Id: prod.Ref_Category_Id,
                    Is_Active: prod.Is_Active,
                    Ref_Currency_Id: prod.Ref_Currency_Id,
                    Product_Actual_Price: parseFloat(prod.Product_Actual_Price),
                    Product_Is_Discounted:
                      prod.Product_Is_Discounted === "true",
                    Product_Discounted_Price:
                      prod.Product_Discounted_Price === undefined
                        ? 0
                        : parseFloat(prod.Product_Discounted_Price),
                    Ref_Product_Offer_Type:
                      prod.Ref_Product_Offer_Type === undefined
                        ? 0
                        : parseInt(prod.Ref_Product_Offer_Type),
                    Ref_Product_Offer_Value:
                      prod.Ref_Product_Offer_Value === undefined
                        ? 0
                        : parseFloat(prod.Ref_Product_Offer_Value),
                    Product_Offer_Shared_By:
                      prod.Product_Offer_Shared_By === undefined
                        ? ""
                        : prod.Product_Offer_Shared_By,
                    Product_Tax_Applicable:
                      prod.Product_Tax_Applicable === "true",
                    Ref_Product_Tax_Slab:
                      prod.Ref_Product_Tax_Slab === undefined
                        ? ""
                        : prod.Ref_Product_Tax_Slab,
                    Ref_Product_Commision_Slab:
                      prod.Ref_Product_Commision_Slab === undefined
                        ? ""
                        : prod.Ref_Product_Commision_Slab,
                    Product_Disclaimer:
                      prod.Product_Disclaimer === undefined
                        ? ""
                        : prod.Product_Disclaimer,
                    Product_Rating: prod.Product_Rating,

                    Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                    Product_Thumb_Image_Url: thumbImage,
                    Updated_By: username === undefined ? "" : username,
                  })
                  .then((res) => {
                    dispatch({ type: GET_SUCCESS, payload: res });
                    resolve(res);
                  })
                  .catch((error) => {
                    dispatch({ type: GET_ERROR, payload: error });
                    reject(error);
                  });
              });
            });
        });
      } else {
        db.collection("Product_Details1")
          .doc(prodid)
          .update({
            Product_Search_Key: Product_Search_Key,
            Product_Id: prod.Product_Id,
            Product_Add_Limit: parseInt(prod.Product_Add_Limit),
            Is_Home_Page: prod.Is_Home_Page === "true",
            Ref_Store_Id: prod.Ref_Store_Id,
            Product_Display_Order: parseInt(prod.Product_Display_Order),
            Product_Name_Lowercase: prod.Product_Name.toLowerCase(),
            Is_Marked_Up_Price_Enabled:
              prod.Is_Marked_Up_Price_Enabled === "true",
            Marked_Up_Price:
              prod.Marked_Up_Price === undefined
                ? 0
                : parseFloat(prod.Marked_Up_Price),
            Marked_Up_Quantity:
              prod.Marked_Up_Quantity === undefined
                ? 0
                : parseInt(prod.Marked_Up_Quantity),
            Product_Name: prod.Product_Name,
            Product_Description: prod.Product_Description,
            Ref_Product_Brand_Id: prod.Ref_Product_Brand_Id,
            Ref_Sub_Category_Id: prod.Ref_Sub_Category_Id,
            Ref_Category_Id: prod.Ref_Category_Id,
            Is_Active: prod.Is_Active,
            Ref_Currency_Id: prod.Ref_Currency_Id,
            Product_Actual_Price: parseFloat(prod.Product_Actual_Price),
            Product_Is_Discounted: prod.Product_Is_Discounted === "true",
            Product_Discounted_Price:
              prod.Product_Discounted_Price === undefined
                ? 0
                : parseFloat(prod.Product_Discounted_Price),
            Ref_Product_Offer_Type:
              prod.Ref_Product_Offer_Type === undefined
                ? 0
                : parseInt(prod.Ref_Product_Offer_Type),
            Ref_Product_Offer_Value:
              prod.Ref_Product_Offer_Value === undefined
                ? 0
                : parseFloat(prod.Ref_Product_Offer_Value),
            Product_Offer_Shared_By:
              prod.Product_Offer_Shared_By === undefined
                ? ""
                : prod.Product_Offer_Shared_By,
            Product_Tax_Applicable: prod.Product_Tax_Applicable === "true",
            Ref_Product_Tax_Slab:
              prod.Ref_Product_Tax_Slab === undefined
                ? ""
                : prod.Ref_Product_Tax_Slab,
            Ref_Product_Commision_Slab:
              prod.Ref_Product_Commision_Slab === undefined
                ? ""
                : prod.Ref_Product_Commision_Slab,
            Product_Disclaimer:
              prod.Product_Disclaimer === undefined
                ? ""
                : prod.Product_Disclaimer,
            Product_Rating: prod.Product_Rating,

            Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
            Updated_By: username === undefined ? "" : username,
          })
          .then((res) => {
            dispatch({ type: GET_SUCCESS, payload: res });
            resolve(res);
          })
          .catch((error) => {
            dispatch({ type: GET_ERROR, payload: error });
            reject(error);
          });
      }
    });
  };
}

export function productList(store_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const productList = [];
      var catlist = [];
      var subcatlist = [];
      var currencylist = [];
      var itemsProcessed = 0;

      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
            console.log(bucket_baseurl);
          });
        });

      //===============================================
      db.collection("Categories")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var catid = op_doc_status.id;
            var catname = op_doc_status.data().Category_Name;
            var obj_order_status = {
              catid: catid,
              catname: catname,
            };
            catlist.push(obj_order_status);
          });
          //  console.log(catlist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      //===============================================
      db.collection("Sub_Categories")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var subcatid = op_doc_status.id;
            var subcatname = op_doc_status.data().Sub_Category_Name;
            var obj_order_status = {
              subcatid: subcatid,
              subcatname: subcatname,
            };
            subcatlist.push(obj_order_status);
          });
          // console.log(subcatlist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      //===============================================
      db.collection("MD_Currency")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var currencyid = op_doc_status.id;
            var Currency_Symbol = op_doc_status.data().Currency_Symbol;
            var obj_order_status = {
              currency_id: currencyid,
              Currency_Symbol: Currency_Symbol,
            };
            currencylist.push(obj_order_status);
          });
          // console.log(currencylist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================

      db.collection("Product_Details1")
        .where("Is_Premier_Promo", "==", false)
        .where("Ref_Store_Id", "==", store_id)
        .orderBy("Is_Active", "desc")
        .orderBy("Updated_At", "desc")
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.size !== 0) {
            querySnapshot.forEach(function (doc) {
              var catid = doc.data().Ref_Category_Id;
              var subcatid = doc.data().Ref_Sub_Category_Id;
              var did = doc.id;
              var pid = doc.data().Product_Id;
              var Product_Discounted_Price = doc
                .data()
                .Product_Discounted_Price.toFixed(2);
              var Product_Price = doc.data().Product_Actual_Price.toFixed(2);
              var pname = doc.data().Product_Name;
              var currency_id = doc.data().Ref_Currency_Id;
              var imgUrl = bucket_baseurl + doc.data().Product_Thumb_Image_Url;
              var Status = doc.data().Is_Active.toString();
              var Product_Display_Order = doc.data().Product_Display_Order;
              var updated_at = moment(doc.data().Updated_At.toDate()).format(
                "LLL"
              );
              var Is_Marked_Up_Price_Enabled =
                doc.data().Is_Marked_Up_Price_Enabled;
              var Marked_Up_Price = doc.data().Marked_Up_Price;
              var Marked_Up_Quantity = doc.data().Marked_Up_Quantity;
              var updated_by =
                doc.data().Updated_By === undefined
                  ? "-"
                  : doc.data().Updated_By;
              var isstock = doc.data().Is_Stock_Available;
              itemsProcessed++;

              //Currency symbol
              if (currency_id) {
                var currency_status =
                  currencylist[
                    currencylist
                      .map(function (item1) {
                        return item1.currency_id;
                      })
                      .indexOf(currency_id)
                  ];
                //console.log(selectedorder_status, ' - ', Promo_Status);
                var symbol = currency_status.Currency_Symbol;
              } else {
                symbol = "-";
              }

              //Category Name
              if (catid) {
                var cat_status =
                  catlist[
                    catlist
                      .map(function (item1) {
                        return item1.catid;
                      })
                      .indexOf(catid)
                  ];
                //console.log(selectedorder_status, ' - ', Promo_Status);
                if (cat_status !== undefined) {
                  var catname = cat_status.catname;
                } else {
                  catname = "-";
                }
              } else {
                catname = "-";
              }

              //Sub Category Name
              if (subcatid) {
                var subcat_status =
                  subcatlist[
                    subcatlist
                      .map(function (item1) {
                        return item1.subcatid;
                      })
                      .indexOf(subcatid)
                  ];
                //console.log(selectedorder_status, ' - ', Promo_Status);
                if (subcat_status !== undefined) {
                  var subcatname = subcat_status.subcatname;
                } else {
                  subcatname = "-";
                }
                // var subcatname = subcat_status.subcatname;
              } else {
                subcatname = "-";
              }
              if (Is_Marked_Up_Price_Enabled === true) {
                var markedup_price =
                  Marked_Up_Quantity +
                  " For " +
                  symbol +
                  " " +
                  Marked_Up_Price.toFixed(2);
              } else {
                markedup_price = "-";
              }
              productList.push({
                key: did,
                pid: pid,
                pname: pname,
                Product_Discounted_Price:
                  symbol + " " + Product_Discounted_Price,
                Product_Price: symbol + " " + Product_Price,
                imgUrl: imgUrl,
                catname: catname,
                subcatname: subcatname,
                Status: Status,
                updated_at: updated_at,
                Product_Display_Order: Product_Display_Order,
                isstock: isstock,
                markedup_price: markedup_price,
                updated_by,
              });
            });
            if (itemsProcessed === productList.length) {
              dispatch({
                type: GET_PRODUCTS,
                payload: productList,
              });
              resolve(productList);
              // console.log("Current data: ", productList);
            }
          } else {
            reject("No Data");
          }
        });
    });
  };
}

export function productList1(store_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const productList = [];
      var catlist = [];
      var subcatlist = [];
      var currencylist = [];
      var itemsProcessed = 0;

      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
            console.log(bucket_baseurl);
          });
        });

      //===============================================
      db.collection("Categories")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var catid = op_doc_status.id;
            var catname = op_doc_status.data().Category_Name;
            var obj_order_status = {
              catid: catid,
              catname: catname,
            };
            catlist.push(obj_order_status);
          });
          //  console.log(catlist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      //===============================================
      db.collection("Sub_Categories")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var subcatid = op_doc_status.id;
            var subcatname = op_doc_status.data().Sub_Category_Name;
            var obj_order_status = {
              subcatid: subcatid,
              subcatname: subcatname,
            };
            subcatlist.push(obj_order_status);
          });
          // console.log(subcatlist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      //===============================================
      db.collection("MD_Currency")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var currencyid = op_doc_status.id;
            var Currency_Symbol = op_doc_status.data().Currency_Symbol;
            var obj_order_status = {
              currency_id: currencyid,
              Currency_Symbol: Currency_Symbol,
            };
            currencylist.push(obj_order_status);
          });
          // console.log(currencylist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================

      db.collection("Product_Details1")
        .where("Is_Premier_Promo", "==", true)
        .where("Ref_Store_Id", "==", store_id)
        .orderBy("Is_Active", "desc")
        .orderBy("Updated_At", "desc")
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.size !== 0) {
            querySnapshot.forEach(function (doc) {
              var catid = doc.data().Ref_Category_Id;
              var subcatid = doc.data().Ref_Sub_Category_Id;
              var did = doc.id;
              var pid = doc.data().Product_Id;
              var Product_Discounted_Price = doc
                .data()
                .Product_Discounted_Price.toFixed(2);
              var Product_Price = doc.data().Product_Actual_Price.toFixed(2);
              var pname = doc.data().Product_Name;
              var currency_id = doc.data().Ref_Currency_Id;
              var imgUrl = bucket_baseurl + doc.data().Product_Thumb_Image_Url;
              var Status = doc.data().Is_Active.toString();
              var updated_at = moment(doc.data().Updated_At.toDate()).format(
                "LLL"
              );
              var Is_Marked_Up_Price_Enabled =
                doc.data().Is_Marked_Up_Price_Enabled;
              var Marked_Up_Price = doc.data().Marked_Up_Price;
              var Marked_Up_Quantity = doc.data().Marked_Up_Quantity;
              var Product_Display_Order = doc.data().Product_Display_Order;
              var isstock = doc.data().Is_Stock_Available;
              itemsProcessed++;

              //Currency symbol
              if (currency_id) {
                var currency_status =
                  currencylist[
                    currencylist
                      .map(function (item1) {
                        return item1.currency_id;
                      })
                      .indexOf(currency_id)
                  ];
                //console.log(selectedorder_status, ' - ', Promo_Status);
                var symbol = currency_status.Currency_Symbol;
              } else {
                symbol = "-";
              }

              //Category Name
              if (catid) {
                var cat_status =
                  catlist[
                    catlist
                      .map(function (item1) {
                        return item1.catid;
                      })
                      .indexOf(catid)
                  ];
                //console.log(selectedorder_status, ' - ', Promo_Status);
                var catname = cat_status.catname;
              } else {
                catname = "-";
              }

              //Sub Category Name
              if (subcatid) {
                var subcat_status =
                  subcatlist[
                    subcatlist
                      .map(function (item1) {
                        return item1.subcatid;
                      })
                      .indexOf(subcatid)
                  ];
                //console.log(selectedorder_status, ' - ', Promo_Status);
                var subcatname = subcat_status.subcatname;
              } else {
                subcatname = "-";
              }
              if (Is_Marked_Up_Price_Enabled === true) {
                var markedup_price =
                  Marked_Up_Quantity +
                  " For " +
                  symbol +
                  " " +
                  Marked_Up_Price.toFixed(2);
              } else {
                markedup_price = "-";
              }
              productList.push({
                key: did,
                pid: pid,
                pname: pname,
                Product_Discounted_Price:
                  symbol + " " + Product_Discounted_Price,
                Product_Price: symbol + " " + Product_Price,
                imgUrl: imgUrl,
                catname: catname,
                subcatname: subcatname,
                Status: Status,
                updated_at: updated_at,
                Product_Display_Order: Product_Display_Order,
                isstock: isstock,
                markedup_price: markedup_price,
              });
            });
            if (itemsProcessed === productList.length) {
              dispatch({
                type: GET_PRODUCTS,
                payload: productList,
              });
              resolve(productList);
              // console.log("Current data: ", productList);
            }
          } else {
            reject("No Data");
          }
        });
    });
  };
}

export function productpromo(store_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var arr_promo_status = [];
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });
      db.collection("MD_Promotion_Status")
        .get()
        .then((order_status) => {
          order_status.forEach((doc_order_status) => {
            var statuskey = doc_order_status.data().Promotion_Status;
            var statusid = doc_order_status.data().Promotion_Value;
            var obj_promo_status = {
              promo_statusId: statusid,
              promo_statuskey: statuskey,
            };
            arr_promo_status.push(obj_promo_status);
          });

          //console.log(arr_promo_status);

          get_allpromo(arr_promo_status);
        })
        .catch((err) => {
          console.log("Error getting documents", err);
          reject(err);
        });
      function get_allpromo(arr_promo_status) {
        var arr_order = [];

        db.collection("Promotion_Product_Details1")
          .where("Ref_Store_Id", "==", store_id)
          .orderBy("Updated_At", "desc")
          .get()
          .then((snap_order) => {
            var snap_size = snap_order.size;
            if (snap_size !== 0) {
              snap_order.forEach((doc_order) => {
                var promo_doc_id = doc_order.id;
                var Banner_image =
                  bucket_baseurl + doc_order.data().Banner_image;
                var Buy_Promo_Qty = doc_order.data().Buy_Promo_Qty;
                var Free_Promo_Qty = doc_order.data().Free_Promo_Qty;
                var Promo_Images =
                  bucket_baseurl + doc_order.data().Promo_Images;
                var Promo_Price = doc_order.data().Promo_Price;
                var Promo_Status = doc_order.data().Promo_Status;
                var Promo_Title = doc_order.data().Promo_Title;
                var Ref_Promo_Type_Id = doc_order.data().Ref_Promo_Type_Id;
                var From_Date = doc_order.data().From_Date.toString();
                var ActivationdateString = moment.unix(From_Date).format("ll");
                var To_Date = doc_order.data().To_Date.toString();
                var ExpiredateString = moment.unix(To_Date).format("ll");
                var Promo_Display_Order = doc_order.data().Promo_Display_Order;
                var Created_At = moment(
                  doc_order.data().Created_At.toDate()
                ).format("MMMM Do YYYY, h:mm:ss a");
                var Updated_At = moment(
                  doc_order.data().Updated_At.toDate()
                ).format("lll");

                //arr_order_status
                if (Promo_Status) {
                  var selectedorder_status =
                    arr_promo_status[
                      arr_promo_status
                        .map(function (item1) {
                          return item1.promo_statusId;
                        })
                        .indexOf(Promo_Status)
                    ];
                  //console.log(selectedorder_status, ' - ', Promo_Status);
                  var dir_promo_status = selectedorder_status.promo_statuskey;
                  // console.log(dir_promo_status);
                } else {
                  dir_promo_status = "N/A";
                }

                var obj_arr = {
                  key: promo_doc_id,
                  Banner_image: Banner_image,
                  Buy_Promo_Qty: Buy_Promo_Qty,
                  Free_Promo_Qty: Free_Promo_Qty,
                  Promo_Images: Promo_Images,
                  Promo_Price:
                    Ref_Promo_Type_Id === 501
                      ? "---"
                      : "£ " + Promo_Price.toFixed(2),
                  Promo_Title: Promo_Title,
                  Ref_Promo_Type_Id: Ref_Promo_Type_Id,
                  From_Date: ActivationdateString,
                  To_Date: ExpiredateString,
                  Created_At: Created_At,
                  Updated_At: Updated_At,
                  Promo_Status: dir_promo_status,
                  Promo_Status_Id: Promo_Status,
                  Promo_Display_Order: Promo_Display_Order,
                };
                arr_order.push(obj_arr);
                var arr_length = arr_order.length;
                if (arr_length === snap_size) {
                  // console.log('response', arr_length + ' ' + snap_size) console.log(arr_order)
                  dispatch({ type: GET_ALL_PROMO, payload: arr_order });
                  resolve(arr_order);
                }
              });
            } else {
              reject("No Data");
              dispatch({ type: GET_ALL_PROMO, payload: arr_order });
            }
          });
      }
    });
  };
}

export function filterproductpromo(value, store_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var arr_promo_status = [];
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });
      db.collection("MD_Promotion_Status")
        .get()
        .then((order_status) => {
          order_status.forEach((doc_order_status) => {
            var statuskey = doc_order_status.data().Promotion_Status;
            var statusid = doc_order_status.data().Promotion_Value;
            var obj_promo_status = {
              promo_statusId: statusid,
              promo_statuskey: statuskey,
            };
            arr_promo_status.push(obj_promo_status);
          });

          console.log(store_id);

          get_allpromo(arr_promo_status);
        })
        .catch((err) => {
          console.log("Error getting documents", err);
          reject(err);
        });
      function get_allpromo(arr_promo_status) {
        var arr_order = [];

        db.collection("Promotion_Product_Details1")
          .where("Ref_Promo_Type_Id", "==", value)
          .where("Ref_Store_Id", "==", store_id)
          .orderBy("Updated_At", "desc")
          .get()
          .then((snap_order) => {
            var snap_size = snap_order.size;
            if (snap_size !== 0) {
              snap_order.forEach((doc_order) => {
                var promo_doc_id = doc_order.id;
                var Banner_image =
                  bucket_baseurl + doc_order.data().Banner_image;
                var Buy_Promo_Qty = doc_order.data().Buy_Promo_Qty;
                var Free_Promo_Qty = doc_order.data().Free_Promo_Qty;
                var Promo_Images =
                  bucket_baseurl + doc_order.data().Promo_Images;
                var Promo_Price = doc_order.data().Promo_Price;
                var Promo_Status = doc_order.data().Promo_Status;
                var Promo_Title = doc_order.data().Promo_Title;
                var Ref_Promo_Type_Id = doc_order.data().Ref_Promo_Type_Id;
                var From_Date = doc_order.data().From_Date.toString();
                var ActivationdateString = moment.unix(From_Date).format("ll");
                var To_Date = doc_order.data().To_Date.toString();
                var ExpiredateString = moment.unix(To_Date).format("ll");
                var Created_At = moment(
                  doc_order.data().Created_At.toDate()
                ).format("MMMM Do YYYY, h:mm:ss a");
                var Updated_At = moment(
                  doc_order.data().Updated_At.toDate()
                ).format("lll");

                //arr_order_status
                if (Promo_Status) {
                  var selectedorder_status =
                    arr_promo_status[
                      arr_promo_status
                        .map(function (item1) {
                          return item1.promo_statusId;
                        })
                        .indexOf(Promo_Status)
                    ];
                  console.log(selectedorder_status, " - ", Promo_Status);
                  var dir_promo_status = selectedorder_status.promo_statuskey;
                  console.log(dir_promo_status);
                } else {
                  dir_promo_status = "N/A";
                }

                var obj_arr = {
                  key: promo_doc_id,
                  Banner_image: Banner_image,
                  Buy_Promo_Qty: Buy_Promo_Qty,
                  Free_Promo_Qty: Free_Promo_Qty,
                  Promo_Images: Promo_Images,
                  Promo_Price: "£ " + Promo_Price.toFixed(2),
                  Promo_Title: Promo_Title,
                  Ref_Promo_Type_Id: Ref_Promo_Type_Id,
                  From_Date: ActivationdateString,
                  To_Date: ExpiredateString,
                  Created_At: Created_At,
                  Updated_At: Updated_At,
                  Promo_Status: dir_promo_status,
                  Promo_Status_Id: Promo_Status,
                };
                arr_order.push(obj_arr);
                var arr_length = arr_order.length;
                if (arr_length === snap_size) {
                  // console.log('response', arr_length + ' ' + snap_size)
                  console.log(arr_order);
                  dispatch({ type: GET_ALL_PROMO, payload: arr_order });
                  resolve(arr_order);
                }
              });
            } else {
              reject("No Data");
            }
          });
      }
    });
  };
}

export function Singlepromo(promoid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });
      test(promoid);
      async function test(promoid) {
        try {
          await request
            .post(Dev_URL + "/getpromotionprodctinfonew")
            .send({ Promo_ID: promoid })
            .then((res) => {
              console.log(res.body);
              var promoinfo = res.body.Promoinfo;
              var arr_product = [];
              var arr_img = [];
              var id = -1;
              if (res.body.ReturnCode === 200) {
                promoinfo.Promo_Images.forEach((proimg) => {
                  console.log(proimg);

                  var img_arr = {
                    uid: id,
                    name: "promo_images" + id + ".png",
                    status: "done",
                    url: bucket_baseurl + proimg,
                    thumbUrl: bucket_baseurl + proimg,
                  };
                  id--;
                  arr_img.push(img_arr);
                });
                promoinfo.Product_Group.forEach((doc_order) => {
                  var obj_arr = {};
                  // console.log(doc_order);
                  doc_order.Product_Detail.forEach((pro_order) => {
                    foo(pro_order);
                    async function foo() {
                      console.log("start");

                      try {
                        await db
                          .collection("Product_Details1")
                          .doc(pro_order.Product_Id)
                          .get()
                          .then((doc) => {
                            //console.log(doc.data());
                            obj_arr = {
                              Key: pro_order.Key,
                              Product_Actual_Price:
                                pro_order.Product_Actual_Price,
                              Product_Discounted_Price:
                                pro_order.Product_Discounted_Price,
                              Product_Id: pro_order.Product_Id,
                              Ref_Products_Display_Name:
                                pro_order.Ref_Products_Display_Name,
                              Ref_Products_Image_Url:
                                pro_order.Ref_Products_Image_Url,
                              Is_Stock_Available: doc.data().Is_Stock_Available,
                              Is_Premier_Promo: doc.data().Is_Premier_Promo,
                              Display_Name: doc.data().Display_Name,
                              Is_Marked_Up_Price_Enabled:
                                doc.data().Is_Marked_Up_Price_Enabled,
                              Marked_Up_Price: doc.data().Marked_Up_Price,
                              Marked_Up_Quantity: doc.data().Marked_Up_Quantity,
                              Product_Is_Discounted:
                                doc.data().Product_Is_Discounted,
                            };
                            console.log(obj_arr);
                            arr_product.push(obj_arr);
                            if (
                              doc_order.Product_Detail.length ===
                              arr_product.length
                            ) {
                              Currency_Symbol();
                            }
                          })
                          .catch((error) => {
                            console.log("Error getting document:", error);
                          });
                        console.log("end");
                      } catch (err) {
                        console.log("Error getting documents", err);
                      }
                    }
                  });
                });
                function Currency_Symbol() {
                  db.collection("MD_Currency")
                    .doc(promoinfo.Ref_Currency_Id)
                    .onSnapshot((doc) => {
                      console.log(doc.data().Currency_Symbol);

                      var symbol = doc.data().Currency_Symbol;

                      obj_arr_form(symbol);
                    });
                }

                function obj_arr_form(symbol) {
                  var ActivationdateString = moment
                    .unix(promoinfo.From_Date.toString())
                    .format("YYYY-MM-DD HH:mm:ss");
                  var actmomentObj = moment(
                    ActivationdateString,
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  var ExpdateString = moment
                    .unix(promoinfo.To_Date.toString())
                    .format("YYYY-MM-DD HH:mm:ss");
                  var expmomentObj = moment(
                    ExpdateString,
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  var obj_arr = {
                    Banner_image: promoinfo.Banner_image,
                    Buy_Promo_Qty: promoinfo.Buy_Promo_Qty.toString(),
                    Free_Promo_Qty: promoinfo.Free_Promo_Qty,
                    From_Date: actmomentObj,
                    Product_Group: promoinfo.Product_Group,
                    Promo_Images: arr_img,
                    Promo_Price: promoinfo.Promo_Price.toFixed(2),
                    Ref_Promo_Type_Id: promoinfo.Ref_Promo_Type_Id.toString(),
                    Promo_Title: promoinfo.Promo_Title,
                    To_Date: expmomentObj,
                    promo_group: arr_product,
                    Ref_Store_Id: promoinfo.Ref_Store_Id,
                    symbol: symbol,
                    Ref_Currency_Id: promoinfo.Ref_Currency_Id,
                    Promo_Description: promoinfo.Promo_Description,
                    Promo_Dup: promoinfo.Promo_Images,
                    Group_Id: promoinfo.Group_Id,
                    Promo_Status: promoinfo.Promo_Status,
                    Promo_Display_Order: promoinfo.Promo_Display_Order,
                  };
                  dispatch_value(obj_arr);
                }
                function dispatch_value(obj_arr) {
                  dispatch({ type: GET_SINGLE_PROMO, payload: obj_arr });
                  resolve(obj_arr);
                }
              } else {
                reject(res.body.message);
              }
            });
        } catch (e) {
          console.log("We have the error", e);
        }
      }
    });
  };
}

export function SingleProduct(pid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var arr_img = [];
      var id = -1;
      var docRef = db.collection("Product_Details1").doc(pid);
      docRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            // console.log("Document data:", doc.data());
            var prod = doc.data();
            prod.Product_Images.forEach((proimg) => {
              var img_arr = {
                uid: id,
                name: "Product_Images" + id + ".png",
                status: "done",
                url: bucket_baseurl + proimg,
                thumbUrl: bucket_baseurl + proimg,
              };
              id--;
              arr_img.push(img_arr);
            });
            var str = prod.Product_Search_Key;
            var Product_Search_Key = str.split("+");
            const results = Product_Search_Key.map((row) => ({ option: row }));
            var obj = {
              Is_Active: prod.Is_Active,
              Product_Add_Limit:
                prod.Product_Add_Limit === undefined
                  ? 0
                  : parseInt(prod.Product_Add_Limit),
              Is_Home_Page: prod.Is_Home_Page.toString(),
              Ref_Store_Id: prod.Ref_Store_Id,
              Is_Marked_Up_Price_Enabled:
                prod.Is_Marked_Up_Price_Enabled.toString(),
              Is_Premier_Promo: prod.Is_Premier_Promo,
              Marked_Up_Price: prod.Marked_Up_Price.toFixed(2),
              Marked_Up_Quantity: prod.Marked_Up_Quantity,
              Product_Actual_Price: prod.Product_Actual_Price.toFixed(2),
              Product_Description: prod.Product_Description,
              Product_Disclaimer: prod.Product_Disclaimer,
              Product_Discounted_Price:
                prod.Product_Discounted_Price.toString(),
              Product_Display_Order: parseInt(prod.Product_Display_Order),
              Product_Id: prod.Product_Id,
              Product_Search_Key: results,
              Product_Search_Option: Product_Search_Key,
              Product_Images: arr_img,
              Product_Is_Discounted: prod.Product_Is_Discounted.toString(),
              Product_Measuring_Quantity: prod.Product_Measuring_Quantity,
              Product_Name: prod.Product_Name,
              Product_No_of_Units: prod.Product_No_of_Units,
              Product_Offer_Shared_By: prod.Product_Offer_Shared_By,
              Product_Rating: prod.Product_Rating,
              Product_Tax_Applicable: prod.Product_Tax_Applicable.toString(),
              Product_Thumb_Image_Url:
                bucket_baseurl + prod.Product_Thumb_Image_Url,
              Ref_Category_Id: prod.Ref_Category_Id,
              Ref_Currency_Id: prod.Ref_Currency_Id,
              Ref_Product_Brand_Id: prod.Ref_Product_Brand_Id,
              Ref_Product_Commision_Slab: prod.Ref_Product_Commision_Slab,
              Ref_Product_Offer_Type: prod.Ref_Product_Offer_Type,
              Ref_Product_Offer_Value: prod.Ref_Product_Offer_Value,
              Ref_Product_Tax_Slab: prod.Ref_Product_Tax_Slab.toString(),
              Ref_Sub_Category_Id: prod.Ref_Sub_Category_Id,
            };
            dispatch({ type: GET_PRODUCTS, payload: obj });
            resolve(obj);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            reject("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          reject(error);
        });
    });
  };
}
export function filterProduct(cat, subCat, storeid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(cat, subCat, storeid);
      const filterproductList = [];
      var catlist = [];
      var subcatlist = [];
      var currencylist = [];
      var itemsProcessed = 0;

      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
            console.log(bucket_baseurl);
          });
        });

      //===============================================
      db.collection("Categories")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var catid = op_doc_status.id;
            var catname = op_doc_status.data().Category_Name;
            var obj_order_status = {
              catid: catid,
              catname: catname,
            };
            catlist.push(obj_order_status);
          });
          //  console.log(catlist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      //===============================================
      db.collection("Sub_Categories")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var subcatid = op_doc_status.id;
            var subcatname = op_doc_status.data().Sub_Category_Name;
            var obj_order_status = {
              subcatid: subcatid,
              subcatname: subcatname,
            };
            subcatlist.push(obj_order_status);
          });
          // console.log(subcatlist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      //===============================================
      db.collection("MD_Currency")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var currencyid = op_doc_status.id;
            var Currency_Symbol = op_doc_status.data().Currency_Symbol;
            var obj_order_status = {
              currency_id: currencyid,
              Currency_Symbol: Currency_Symbol,
            };
            currencylist.push(obj_order_status);
          });
          // console.log(currencylist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      db.collection("Product_Details1")
        .where("Is_Premier_Promo", "==", false)
        .where("Ref_Category_Id", "==", cat)
        .where("Ref_Sub_Category_Id", "==", subCat)
        .where("Ref_Store_Id", "==", storeid)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            console.log("No matching documents.");
            dispatch({ type: GET_PRODUCTS, payload: filterproductList });
            resolve(filterproductList);
          }

          snapshot.forEach((doc) => {
            var catid = doc.data().Ref_Category_Id;
            var subcatid = doc.data().Ref_Sub_Category_Id;
            var did = doc.id;
            var pid = doc.data().Product_Id;
            var Product_Discounted_Price = doc
              .data()
              .Product_Discounted_Price.toFixed(2);
            var Product_Price = doc.data().Product_Actual_Price.toFixed(2);
            var pname = doc.data().Product_Name;
            var currency_id = doc.data().Ref_Currency_Id;
            var imgUrl = bucket_baseurl + doc.data().Product_Thumb_Image_Url;
            var Status = doc.data().Is_Active.toString();
            var updated_at = moment(doc.data().Updated_At.toDate()).format(
              "LLL"
            );
            var Product_Display_Order = doc.data().Product_Display_Order;
            var isstock = doc.data().Is_Stock_Available;
            itemsProcessed++;

            //Currency symbol
            if (currency_id) {
              var currency_status =
                currencylist[
                  currencylist
                    .map(function (item1) {
                      return item1.currency_id;
                    })
                    .indexOf(currency_id)
                ];
              //console.log(selectedorder_status, ' - ', Promo_Status);
              var symbol = currency_status.Currency_Symbol;
            } else {
              symbol = "-";
            }

            //Category Name
            if (catid) {
              var cat_status =
                catlist[
                  catlist
                    .map(function (item1) {
                      return item1.catid;
                    })
                    .indexOf(catid)
                ];
              //console.log(selectedorder_status, ' - ', Promo_Status);
              var catname = cat_status.catname;
            } else {
              catname = "-";
            }

            //Sub Category Name
            if (subcatid) {
              var subcat_status =
                subcatlist[
                  subcatlist
                    .map(function (item1) {
                      return item1.subcatid;
                    })
                    .indexOf(subcatid)
                ];
              //console.log(selectedorder_status, ' - ', Promo_Status);
              var subcatname = subcat_status.subcatname;
            } else {
              subcatname = "-";
            }

            filterproductList.push({
              key: did,
              pid: pid,
              pname: pname,
              Product_Discounted_Price: symbol + " " + Product_Discounted_Price,
              Product_Price: symbol + " " + Product_Price,
              imgUrl: imgUrl,
              catname: catname,
              subcatname: subcatname,
              Status: Status,
              updated_at: updated_at,
              Product_Display_Order: Product_Display_Order,
              isstock: isstock,
            });
          });
          if (itemsProcessed === filterproductList.length) {
            dispatch({
              type: GET_PRODUCTS,
              payload: filterproductList,
            });
            resolve(filterproductList);
            // console.log("Current data: ", productList);
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    });
  };
}

export function filterProduct1(cat, subCat, storeid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const filterproductList = [];
      var catlist = [];
      var subcatlist = [];
      var currencylist = [];
      var itemsProcessed = 0;

      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
            console.log(bucket_baseurl);
          });
        });

      //===============================================
      db.collection("Categories")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var catid = op_doc_status.id;
            var catname = op_doc_status.data().Category_Name;
            var obj_order_status = {
              catid: catid,
              catname: catname,
            };
            catlist.push(obj_order_status);
          });
          //  console.log(catlist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      //===============================================
      db.collection("Sub_Categories")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var subcatid = op_doc_status.id;
            var subcatname = op_doc_status.data().Sub_Category_Name;
            var obj_order_status = {
              subcatid: subcatid,
              subcatname: subcatname,
            };
            subcatlist.push(obj_order_status);
          });
          // console.log(subcatlist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      //===============================================
      db.collection("MD_Currency")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var currencyid = op_doc_status.id;
            var Currency_Symbol = op_doc_status.data().Currency_Symbol;
            var obj_order_status = {
              currency_id: currencyid,
              Currency_Symbol: Currency_Symbol,
            };
            currencylist.push(obj_order_status);
          });
          // console.log(currencylist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      db.collection("Product_Details1")
        .where("Is_Premier_Promo", "==", true)
        .where("Ref_Category_Id", "==", cat)
        .where("Ref_Sub_Category_Id", "==", subCat)
        .where("Ref_Store_Id", "==", storeid)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            console.log("No matching documents.");
            dispatch({ type: GET_PRODUCTS, payload: filterproductList });
            resolve(filterproductList);
          }

          snapshot.forEach((doc) => {
            var catid = doc.data().Ref_Category_Id;
            var subcatid = doc.data().Ref_Sub_Category_Id;
            var did = doc.id;
            var pid = doc.data().Product_Id;
            var Product_Discounted_Price = doc
              .data()
              .Product_Discounted_Price.toFixed(2);
            var Product_Price = doc.data().Product_Actual_Price.toFixed(2);
            var pname = doc.data().Product_Name;
            var currency_id = doc.data().Ref_Currency_Id;
            var imgUrl = bucket_baseurl + doc.data().Product_Thumb_Image_Url;
            var Status = doc.data().Is_Active.toString();
            var updated_at = moment(doc.data().Updated_At.toDate()).format(
              "LLL"
            );
            var Product_Display_Order = doc.data().Product_Display_Order;
            var isstock = doc.data().Is_Stock_Available;
            itemsProcessed++;

            //Currency symbol
            if (currency_id) {
              var currency_status =
                currencylist[
                  currencylist
                    .map(function (item1) {
                      return item1.currency_id;
                    })
                    .indexOf(currency_id)
                ];
              //console.log(selectedorder_status, ' - ', Promo_Status);
              var symbol = currency_status.Currency_Symbol;
            } else {
              symbol = "-";
            }

            //Category Name
            if (catid) {
              var cat_status =
                catlist[
                  catlist
                    .map(function (item1) {
                      return item1.catid;
                    })
                    .indexOf(catid)
                ];
              //console.log(selectedorder_status, ' - ', Promo_Status);
              var catname = cat_status.catname;
            } else {
              catname = "-";
            }

            //Sub Category Name
            if (subcatid) {
              var subcat_status =
                subcatlist[
                  subcatlist
                    .map(function (item1) {
                      return item1.subcatid;
                    })
                    .indexOf(subcatid)
                ];
              //console.log(selectedorder_status, ' - ', Promo_Status);
              var subcatname = subcat_status.subcatname;
            } else {
              subcatname = "-";
            }

            filterproductList.push({
              key: did,
              pid: pid,
              pname: pname,
              Product_Discounted_Price: symbol + " " + Product_Discounted_Price,
              Product_Price: symbol + " " + Product_Price,
              imgUrl: imgUrl,
              catname: catname,
              subcatname: subcatname,
              Status: Status,
              updated_at: updated_at,
              Product_Display_Order: Product_Display_Order,
              isstock: isstock,
            });
          });
          if (itemsProcessed === filterproductList.length) {
            dispatch({
              type: GET_PRODUCTS,
              payload: filterproductList,
            });
            resolve(filterproductList);
            // console.log("Current data: ", productList);
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    });
  };
}

export function filtersubCat(cat) {
  return (dispatch) => {
    db.collection("Constants")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          bucket_baseurl = doc.data().Bucket_Base_Url;
        });
      });
    db.collection("Sub_Categories")
      .where("Ref_Category_Id", "==", cat)
      .onSnapshot(function (doc) {
        const filtersubCat = [];
        doc.forEach(function (doc) {
          filtersubCat.push({
            id: doc.id,
            sid: doc.data().Sub_Category_Id,
            sname: doc.data().Sub_Category_Name,
            imgUrl: bucket_baseurl + doc.data().Sub_Category_Image_Url,
          });
        });
        // console.log("Current data: ", filtersubCat);
        dispatch({ type: GET_SUBCATEGORIES, payload: filtersubCat });
      });
  };
}

export function catList(store_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });
      db.collection("Categories")
        .where("Category_Status", "==", true)
        .where("Ref_Store_Id", "==", store_id)
        .onSnapshot(function (doc) {
          const catList = [];
          doc.forEach(function (doc1) {
            // console.log(doc)
            catList.push({
              id: doc1.id,
              cid: doc1.data().Category_Id,
              cname: doc1.data().Category_Name,
              imgUrl: bucket_baseurl + doc1.data().Category_Image_Url,
            });
          });
          resolve(catList);
          dispatch({ type: GET_CATEGORIES, payload: catList });
        });
    });
  };
}

export function subcatList(store_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });
      db.collection("Sub_Categories")
        .where("Sub_Category_Status", "==", true)
        .where("Ref_Store_Id", "==", store_id)
        .onSnapshot(function (doc) {
          const subcatList = [];
          doc.forEach(function (doc1) {
            console.log(doc);
            subcatList.push({
              id: doc1.id,
              sid: doc1.data().Sub_Category_Id,
              sname: doc1.data().Sub_Category_Name,
              imgUrl: bucket_baseurl + doc1.data().Sub_Category_Image_Url,
            });
          });
          resolve(subcatList);
          dispatch({ type: GET_SUBCATEGORIES, payload: subcatList });
        });
    });
  };
}
export function catList1() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });
      console.log("catlist1");
      db.collection("Categories").onSnapshot(function (doc) {
        const catList = [];
        doc.forEach(function (doc1) {
          //console.log(doc)
          catList.push({
            id: doc1.id,
            cid: doc1.data().Category_Id,
            cname: doc1.data().Category_Name,
            imgUrl: bucket_baseurl + doc1.data().Category_Image_Url,
          });
        });
        resolve(catList);
        dispatch({ type: GET_CATEGORIES, payload: catList });
      });
    });
  };
}

export function subcatList1() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });
      db.collection("Sub_Categories")
        .where("Sub_Category_Status", "==", true)
        .onSnapshot(function (doc) {
          const subcatList = [];
          doc.forEach(function (doc1) {
            // console.log(doc)
            subcatList.push({
              id: doc1.id,
              sid: doc1.data().Sub_Category_Id,
              sname: doc1.data().Sub_Category_Name,
              imgUrl: bucket_baseurl + doc1.data().Sub_Category_Image_Url,
            });
          });
          resolve(subcatList);
          dispatch({ type: GET_SUBCATEGORIES, payload: subcatList });
        });
    });
  };
}

export function promoList() {
  return (dispatch) => {
    db.collection("MD_Promotion_Type")
      .where("Is_Active", "==", true)
      .onSnapshot(function (doc) {
        const subcatList = [];
        doc.forEach(function (doc) {
          subcatList.push({
            id: doc.id,
            Promotion_Id: doc.data().Promotion_Id,
            Promotion_Image_Url: doc.data().Promotion_Image_Url,
            Promotion_Type: doc.data().Promotion_Type,
          });
        });
        // console.log("Current data: ", catList);
        dispatch({ type: GET_COMMON_SLOT, payload: subcatList });
      });
  };
}

export function currencyList() {
  return (dispatch) => {
    db.collection("MD_Currency").onSnapshot(function (doc) {
      const currencyList = [];
      doc.forEach(function (doc) {
        //console.log("Current data: ", doc.data());
        currencyList.push({
          id: doc.id,
          code: doc.data().Currency_Code,
          name: doc.data().Currency_Name,
          symbol: doc.data().Currency_Symbol,
        });
      });
      // console.log("Current data: ", currencyList);
      dispatch({ type: GET_CURRENCY, payload: currencyList });
    });
  };
}

export function couponList() {
  return (dispatch) => {
    db.collection("MD_Coupon").onSnapshot(function (doc) {
      const couponList = [];
      doc.forEach(function (doc) {
        //console.log("Current data: ", doc.data());
        couponList.push({
          id: doc.id,
          Coupon_Status: doc.data().Coupon_Status,
          Coupon_Type: doc.data().Coupon_Type,
          Coupon_Value: doc.data().Coupon_Value,
        });
      });
      // console.log("Current data: ", currencyList);
      dispatch({ type: GET_COUPON, payload: couponList });
    });
  };
}
export function couponList1() {
  return (dispatch) => {
    db.collection("MD_Coupon")
      .where("Coupon_Type", "==", "Discount")
      .onSnapshot(function (doc) {
        const couponList = [];
        doc.forEach(function (doc) {
          //console.log("Current data: ", doc.data());
          couponList.push({
            id: doc.id,
            Coupon_Status: doc.data().Coupon_Status,
            Coupon_Type: doc.data().Coupon_Type,
            Coupon_Value: doc.data().Coupon_Value,
          });
        });
        // console.log("Current data: ", currencyList);
        dispatch({ type: GET_COUPON, payload: couponList });
      });
  };
}

export function addCat(cat, fobj) {
  console.log(cat);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fobj.forEach((f) => {
        storage
          .ref(
            "Category_Images/" +
              cat.Category_Name +
              "_" +
              cat.Category_Id +
              f.name
          )
          .put(f)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              // console.log( snapshot.ref.fullPath)
              var thumbImage = snapshot.ref.fullPath;
              db.collection("Categories")
                .add({
                  Category_Id: cat.Category_Id,
                  Category_Name: cat.Category_Name,
                  Category_Display_Order: parseInt(cat.Category_Display_Order),
                  Category_Description: cat.Category_Description,
                  Category_Status: cat.Category_Status === "true",
                  Category_Image_Url: thumbImage,
                  Ref_Store_Id_List: cat.Ref_Store_Id_List,
                  Created_At: firebase.firestore.FieldValue.serverTimestamp(),
                  Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then((docRef) => {
                  // console.log(docRef.id)
                  dispatch({ type: GET_SUCCESS, payload: docRef.id });
                  resolve(docRef.id);
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          });
      });
    });
  };
}

export function UpdateCat(cat, fobj, catid) {
  console.log(catid);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (fobj === "") {
        db.collection("Categories")
          .doc(catid)
          .update({
            Category_Id: cat.Category_Id,
            Category_Name: cat.Category_Name,
            Category_Display_Order: parseInt(cat.Category_Display_Order),
            Category_Description: cat.Category_Description,
            Category_Status: cat.Category_Status === "true",
            Ref_Store_Id_List: cat.Ref_Store_Id_List,
            Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then((docRef) => {
            // console.log(docRef.id)
            resolve("completed");
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        fobj.forEach((f) => {
          storage
            .ref(
              "Category_Images/" +
                cat.Category_Name +
                "_" +
                cat.Category_Id +
                f.name
            )
            .put(f)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                var thumbImage = snapshot.ref.fullPath;
                db.collection("Categories")
                  .doc(catid)
                  .update({
                    Category_Id: cat.Category_Id,
                    Category_Name: cat.Category_Name,
                    Category_Display_Order: parseInt(
                      cat.Category_Display_Order
                    ),
                    Category_Description: cat.Category_Description,
                    Category_Status: cat.Category_Status === "true",
                    Ref_Store_Id_List: cat.Ref_Store_Id_List,
                    Category_Image_Url: thumbImage,
                    // Created_At: firebase.firestore.FieldValue.serverTimestamp(),
                    Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then((docRef) => {
                    // console.log(docRef.id) dispatch({     type: GET_SUCCESS,     payload:
                    // docRef.id })
                    resolve("completed");
                  })
                  .catch((err) => {
                    console.log(err);
                    reject(err);
                  });
              });
            });
        });
      }
    });
  };
}

export function UpdateSubCat(subcat, fobj, catid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (fobj === "") {
        db.collection("Sub_Categories")
          .doc(catid)
          .update({
            Ref_Category_Id: subcat.Ref_Category_Id,
            Sub_Category_Id: subcat.Sub_Category_Id,
            Sub_Category_Name: subcat.Sub_Category_Name,
            Sub_Category_DisplayOrder: parseInt(
              subcat.Sub_Category_DisplayOrder
            ),
            Sub_Category_Description: subcat.Sub_Category_Description,
            Sub_Category_Status: subcat.Sub_Category_Status === "true",
            Ref_Store_Id_List: subcat.Ref_Store_Id_List,
            Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then((docRef) => {
            // console.log(docRef.id)
            resolve("completed");
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        fobj.forEach((f) => {
          storage
            .ref(
              "SubCategory_Images/" +
                subcat.Sub_Category_Name +
                "_" +
                subcat.Sub_Category_Id +
                f.name
            )
            .put(f)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                var thumbImage = snapshot.ref.fullPath;
                db.collection("Sub_Categories")
                  .doc(catid)
                  .update({
                    Ref_Category_Id: subcat.Ref_Category_Id,
                    Sub_Category_Id: subcat.Sub_Category_Id,
                    Sub_Category_Name: subcat.Sub_Category_Name,
                    Sub_Category_DisplayOrder: parseInt(
                      subcat.Sub_Category_DisplayOrder
                    ),
                    Sub_Category_Description: subcat.Sub_Category_Description,
                    Sub_Category_Status: subcat.Sub_Category_Status === "true",
                    Sub_Category_Image_Url: thumbImage,
                    Ref_Store_Id_List: subcat.Ref_Store_Id_List,
                    Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then((docRef) => {
                    // console.log(docRef.id)
                    resolve("completed");
                  })
                  .catch((err) => {
                    console.log(err);
                    reject(err);
                  });
              });
            });
        });
      }
    });
  };
}

export function checkcat(value) {
  return (dispatch) => {
    //console.log(value);

    return new Promise((resolve, reject) => {
      db.collection("Categories")
        .where("Category_Id", "==", value)
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.size === 0) {
            console.log("No such document!");
            resolve("NO");
            dispatch({ type: GET_SUCCESS, payload: "No such document!" });
          } else {
            querySnapshot.forEach(function (doc) {
              console.log(doc);
              reject(doc);
            });
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          reject(error);
        });
    });
  };
}

export function checksubcat(value) {
  return (dispatch) => {
    //console.log(value);

    return new Promise((resolve, reject) => {
      db.collection("Sub_Categories")
        .where("Sub_Category_Id", "==", value)
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.size === 0) {
            console.log("No such document!");
            resolve("NO");
            dispatch({ type: GET_SUCCESS, payload: "No such document!" });
          } else {
            querySnapshot.forEach(function (doc) {
              console.log(doc);
              reject(doc);
            });
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          reject(error);
        });
    });
  };
}

export function subCat(subcat, fobj) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fobj.forEach((f) => {
        storage
          .ref(
            "SubCategory_Images/" +
              subcat.Sub_Category_Name +
              "_" +
              subcat.Sub_Category_Id +
              f.name
          )
          .put(f)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              var thumbImage = snapshot.ref.fullPath;
              db.collection("Sub_Categories")
                .add({
                  Ref_Category_Id: subcat.Ref_Category_Id,
                  Sub_Category_Id: subcat.Sub_Category_Id,
                  Sub_Category_Name: subcat.Sub_Category_Name,
                  Sub_Category_Description: subcat.Sub_Category_Description,
                  Sub_Category_Status: subcat.Sub_Category_Status === "true",
                  Sub_Category_Image_Url: thumbImage,
                  Sub_Category_DisplayOrder: parseInt(
                    subcat.Sub_Category_DisplayOrder
                  ),
                  Ref_Store_Id_List: subcat.Ref_Store_Id_List,
                  Created_At: firebase.firestore.FieldValue.serverTimestamp(),
                  Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then((docRef) => {
                  // console.log(docRef.id)
                  resolve(docRef.id);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          });
      });
    });
  };
}

export function addDis(dis) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(dis);
      db.collection("Coupons_Master")
        .add({
          Coupon_Activation_Date: parseInt(
            moment(dis.activation_date).format("X")
          ),
          Coupon_Ceiling_Limit_Applicable: dis.ceiling_limit === "true",
          Coupon_Ceiling_Limit_Value:
            dis.ceiling_limit_value === undefined ? 0 : dis.ceiling_limit_value,
          Coupon_Code: Array.isArray(dis.coupon_code)
            ? dis.coupon_code[0].toString()
            : dis.coupon_code,
          Coupon_Description: dis.coupon_description,
          Coupon_Discount_Type: parseInt(dis.discount_type),
          Coupon_Discount_Value: parseInt(dis.discount_value),
          Coupon_Expiration_Date: parseInt(
            moment(dis.expiration_date).format("X")
          ),
          Coupon_No_Of_Usage: 0,
          Coupon_Per_User_Limit: parseInt(dis.Coupon_Per_User_Limit),
          Coupon_Status: parseInt(dis.coupon_status),
          Coupon_Title: dis.coupon_Title,
          Coupon_Usage_Limit_Applicable: dis.usage_limit === "true",
          Coupon_Usage_Limit_Value: dis.usage_limit_value,
          Ref_Operating_City_Id: dis.operating_city,
          Coupon_Type: parseInt(dis.coupon_type),
          Ref_Store_Id: dis.store_name,
          Created_At: firebase.firestore.FieldValue.serverTimestamp(),
          Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function (docRef) {
          console.log("Document written with ID: ", docRef.id);
          resolve(docRef.id);
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
          reject(error);
        });
    });
  };
}

export function getLivelocation(orderid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("RD_Enroute_To_Customer")
        .doc(orderid)
        .onSnapshot(function (doc) {
          //   console.log("Current data: ", doc.data());
          var driver_loc = {
            lat: doc.data().Latitude,
            lng: doc.data().Longitude,
          };
          dispatch({ type: GET_LOCATION, payload: driver_loc });
          resolve(driver_loc);
        });
    });
  };
}

// =================================== Category Table & Sub Category Table
// ============================================//

export function catTable() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });
      db.collection("Categories")
        .orderBy("Created_At", "desc")
        .get()
        .then((snapshot) => {
          const catList = [];
          snapshot.forEach(function (doc) {
            catList.push({
              key: doc.id,
              cid: doc.data().Category_Id,
              cname: doc.data().Category_Name,
              imgUrl: bucket_baseurl + doc.data().Category_Image_Url,
              description: doc.data().Category_Description,
              Created_At: moment(doc.data().Created_At.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              ),
              tags: doc.data().Category_Status.toString(),
            });
          });
          // console.log("Current data: ", catList);
          dispatch({ type: GET_CATEGORIES, payload: catList });
          resolve(catList);
        });
    });
  };
}

export function SinglecatTable(catid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });
      db.collection("Categories")
        .doc(catid)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            console.log("No such document!");
            reject("No such document!");
          } else {
            console.log("Document data:", doc.data());

            var catList = {
              cid: doc.data().Category_Id,
              cname: doc.data().Category_Name,
              imgUrl: bucket_baseurl + doc.data().Category_Image_Url,
              description: doc.data().Category_Description,
              tags: doc.data().Category_Status.toString(),
              Category_Display_Order: doc.data().Category_Display_Order,
              Ref_Store_Id_List: doc.data().Ref_Store_Id_List,
            };

            // console.log("Current data: ", catList);
            dispatch({ type: GET_COMMON_SLOT, payload: catList });
            resolve(catList);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function SinglesubcatTable(catid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });
      db.collection("Sub_Categories")
        .doc(catid)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            console.log("No such document!");
            reject("No such document!");
          } else {
            console.log("Document data:", doc.data());

            var catList = {
              Category_Id: doc.data().Ref_Category_Id,
              sid: doc.data().Sub_Category_Id,
              cname: doc.data().Sub_Category_Name,
              imgUrl: bucket_baseurl + doc.data().Sub_Category_Image_Url,
              description: doc.data().Sub_Category_Description,
              tags: doc.data().Sub_Category_Status.toString(),
              Ref_Store_Id_List: doc.data().Ref_Store_Id_List,
              Sub_Category_DisplayOrder: doc
                .data()
                .Sub_Category_DisplayOrder.toString(),
            };

            console.log("Current data: ", catList);
            dispatch({ type: GET_COMMON_SLOT, payload: catList });
            resolve(catList);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function subcatTable() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
          });
        });

      db.collection("Sub_Categories")
        .orderBy("Created_At", "desc")
        .get()
        .then((snapshot) => {
          const subcatList = [];
          // console.log(snapshot.exists)
          if (snapshot.size > 0) {
            //console.log(snapshot);
            var itemsProcessed = 0;
            snapshot.forEach(function (doc) {
              var catid = doc.data().Ref_Category_Id;
              itemsProcessed++;
              db.collection("Categories")
                .doc(catid)
                .get()
                .then((catdoc) => {
                  subcatList.push({
                    key: doc.id,
                    sid: doc.data().Sub_Category_Id,
                    sname: doc.data().Sub_Category_Name,
                    imgUrl: bucket_baseurl + doc.data().Sub_Category_Image_Url,
                    category: catdoc.data().Category_Name,
                    description: doc.data().Sub_Category_Description,
                    Created_At: moment(doc.data().Created_At.toDate()).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    ),
                    tags: doc.data().Sub_Category_Status.toString(),
                  });
                  if (itemsProcessed === subcatList.length) {
                    dispatch({ type: GET_SUBCATEGORIES, payload: subcatList });
                    resolve(subcatList);
                  } else {
                    // message.error("Data fetch error!");
                  }
                })
                .catch((err) => {
                  console.log("Error getting document", err);
                  reject(err);
                });
            });
          } else {
            console.log(snapshot.docs, subcatList);
            reject("No Data");
          }
        })
        .catch((err) => {
          console.log("Error getting document", err);
          reject(err);
        });
    });
  };
}

// ====================================== Add Store
// =========================================

export function AddStore(store, fobj, faddress, reqid) {
  console.log(fobj);

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var file = fobj;
      storage
        .ref(
          "Store_Images/" +
            store.Store_Name +
            "/" +
            store.Store_Name +
            "_" +
            file.name
        )
        .put(file)
        .then(function (snapshot) {
          snapshot.ref.getDownloadURL().then(function (downloadURL) {
            var thumbImage = snapshot.ref.fullPath;
            db.collection("Stores_Master")
              .add({
                Store_Name: store.Store_Name,
                Store_Short_Code: store.Store_Short_Code,
                Store_Display_Id: 1,
                Configuration_Message_One:
                  store.Configuration_Message_One === undefined
                    ? ""
                    : store.Configuration_Message_One,
                Configuration_Message_Two:
                  store.Configuration_Message_Two === undefined
                    ? ""
                    : store.Configuration_Message_Two,
                Store_Address: faddress.address,
                Store_Location_Latitude: faddress.lat,
                Store_Location_Longitude: faddress.lng,
                Store_Availability_Status: false,
                IsActive: false,
                Stores_Master_Commission_Applicable:
                  store.Stores_Master_Commission_Applicable === "true",
                Stores_Commission_Percentage:
                  store.Stores_Commission_Percentage === undefined
                    ? 0
                    : store.Stores_Commission_Percentage,
                Store_Logo: thumbImage,
                Maximum_Replaceable_Amount: store.replaceable_amount,
                Ref_Slot_Time: parseInt(store.ref_slot),
                Ref_Operating_City_Id: store.ref_city,
                Category_18_List: store.Category_18_List,
                Exclude_Min_Order_Category_List:
                  store.Exclude_Min_Order_Category_List,
                Min_Order_Amount: parseFloat(store.Min_Order_Amount),
                Delivery_Fee: parseFloat(store.Delivery_Fee),
                Bag_Fee: parseFloat(store.Bag_Fee),
                Is_Happy_Hour_Enabled_Today: false,
                Is_Offer_Opened: false,
                Is_Offer_Closed: false,
                Happy_Hour_Unix_Start_Time: 0,
                Happy_Hour_Unix_End_Time: 0,
                Happy_Hour_Id: "",
                Static_KM: parseFloat(store.Static_KM),
                Static_Milli_KM: parseFloat(store.Static_Milli_KM),
                Blocked_Postal_code:
                  store.Blocked_Postal_code === undefined
                    ? []
                    : store.Blocked_Postal_code,
                Store_Number: store.Phone_number,
                Delivery_Distance: store.Delivery_Distance,
                Store_Serviceable_Status: false,
                Store_Alert_Message: "",
                Order_Start_Time: parseInt(store.Order_Start_Time),
                Order_Time: store.Order_Time,
                Cash_Back_Fee: parseFloat(store.Cash_Back_Fee),
                Cash_Back_Category: store.Cash_Back_Category,
                Is_Static_Delivery_Price_Enabled:
                  store.Is_Static_Delivery_Price_Enabled === "true",
                Is_Static_Min_Order_Amount_Enabled:
                  store.Is_Static_Min_Order_Amount_Enabled === "true",
                Service_Type: parseInt(store.Service_Type),
                Created_At: firebase.firestore.FieldValue.serverTimestamp(),
                Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
              })
              .then((docRef) => {
                var store_id = docRef.id;
                db.collection("Stores_Master")
                  .doc(store_id)
                  .update({ Store_Id: store_id })
                  .then((result) => {
                    console.log();

                    addstoreuser(store_id, store);
                  })
                  .catch((err) => {});
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      //function start
      function addstoreuser(store_id, store) {
        //console.log(store_id , store) Add a new document with a generated id.
        db.collection("Store_Manager")
          .add({
            Ref_Store_Id: store_id,
            Manager_Name: store.Manager_Name,
            Manager_Id: "",
            Profile_Image: "",
            Manager_Mobile_Number: parseInt(store.phone),
            Manager_Email_Address: store.Email_ID,
            IsActive: false,
            Created_At: firebase.firestore.FieldValue.serverTimestamp(),
            Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(function (docRef) {
            console.log("Document written with ID: ", docRef.id);
            processcomplete();
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      }

      function processcomplete() {
        db.collection("Store_Request")
          .doc(reqid)
          .update({ Ref_MD_Store_Request_Status_Id: 4 })
          .then((result) => {
            dispatch({ type: GET_SUCCESS, payload: "Success" });
            resolve("Success");
          })
          .catch((err) => {});
      }
    });
  };
}
//====================== Update Store ===================================//
export function updatestore(
  store,
  fobj,
  faddress,
  reqid,
  happpyid,
  Dynamic_Min_Order_Amount,
  Dynamic_Delivery_Fee,
  Blocked_Postal_code
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(
        store,
        fobj,
        faddress,
        reqid,
        happpyid,
        Dynamic_Min_Order_Amount,
        Dynamic_Delivery_Fee,
        Blocked_Postal_code
      );
      if (fobj === undefined) {
        if (_.isEmpty(faddress)) {
          db.collection("Stores_Master")
            .doc(reqid)
            .update({
              Store_Name: store.Store_Name,
              Store_Short_Code: store.Store_Short_Code,
              Configuration_Message_One:
                store.Configuration_Message_One === undefined
                  ? ""
                  : store.Configuration_Message_One,
              Configuration_Message_Two:
                store.Configuration_Message_Two === undefined
                  ? ""
                  : store.Configuration_Message_Two,
              IsActive: store.Store_Status === "true",
              Maximum_Replaceable_Amount: store.replaceable_amount,
              Ref_Slot_Time: parseInt(store.ref_slot),
              Ref_Operating_City_Id: store.ref_city,
              Category_18_List: store.Category_18_List,
              Min_Order_Amount: parseFloat(store.Min_Order_Amount),
              Delivery_Fee: parseFloat(store.Delivery_Fee),
              Bag_Fee: parseFloat(store.Bag_Fee),
              Exclude_Min_Order_Category_List:
                store.Exclude_Min_Order_Category_List,
              Is_Happy_Hour_Enabled_Today:
                store.Is_Happy_Hour_Enabled_Today === "true",
              Is_Offer_Opened:
                store.Is_Happy_Hour_Enabled_Today === "false" ? false : false,
              Is_Offer_Closed:
                store.Is_Happy_Hour_Enabled_Today === "false" ? false : false,
              Happy_Hour_Unix_Start_Time:
                store.Happy_Hour_Unix_Start_Time === undefined
                  ? 0
                  : parseInt(moment(store.Happy_Hour_Unix_Start_Time).unix()),
              Happy_Hour_Unix_End_Time:
                store.Happy_Hour_Unix_End_Time === undefined
                  ? 0
                  : parseInt(moment(store.Happy_Hour_Unix_End_Time).unix()),
              Happy_Hour_Id: happpyid,
              Static_KM: parseFloat(store.Static_KM),
              Static_Milli_KM: parseFloat(store.Static_Milli_KM),
              Blocked_Postal_code:
                Blocked_Postal_code === undefined ? [] : Blocked_Postal_code,
              Store_Number: store.Phone_number,
              Delivery_Distance: store.Delivery_Distance,
              Store_Serviceable_Status:
                store.Store_Serviceable_Status === "true",
              Store_Alert_Message: store.Store_Alert_Message,

              Order_Start_Time: parseInt(store.Order_Start_Time),
              Order_Time: store.Order_Time,
              Is_Static_Min_Order_Amount_Enabled:
                store.Is_Static_Min_Order_Amount_Enabled === "true",
              Static_Min_Order_Amount:
                store.Is_Static_Min_Order_Amount_Enabled === "true"
                  ? store.Static_Min_Order_Amount
                  : 0,
              Dynamic_Min_Order_Amount: Dynamic_Min_Order_Amount,

              Is_Static_Delivery_Price_Enabled:
                store.Is_Static_Delivery_Price_Enabled === "true",
              Static_Delivery_Price:
                store.Is_Static_Delivery_Price_Enabled === "true"
                  ? parseFloat(store.Static_Delivery_Price)
                  : 0,
              Dynamic_Delivery_Price: Dynamic_Delivery_Fee,

              Service_Type: parseInt(store.Service_Type),
              Service_Value: parseInt(store.Service_Value),
              Cash_Back_Fee: parseFloat(store.Cash_Back_Fee),
              Cash_Back_Category: store.Cash_Back_Category,
              Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((result) => {
              dispatch({ type: GET_SUCCESS, payload: "Success" });
              resolve("Success");
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          db.collection("Stores_Master")
            .doc(reqid)
            .update({
              Store_Name: store.Store_Name,
              Store_Short_Code: store.Store_Short_Code,
              Configuration_Message_One:
                store.Configuration_Message_One === undefined
                  ? ""
                  : store.Configuration_Message_One,
              Configuration_Message_Two:
                store.Configuration_Message_Two === undefined
                  ? ""
                  : store.Configuration_Message_Two,
              Store_Address: faddress.address,
              Store_Location_Latitude: faddress.lat,
              Store_Location_Longitude: faddress.lng,
              IsActive: store.Store_Status === "true",
              Maximum_Replaceable_Amount: store.replaceable_amount,
              Ref_Slot_Time: parseInt(store.ref_slot),
              Ref_Operating_City_Id: store.ref_city,
              Category_18_List: store.Category_18_List,
              Exclude_Min_Order_Category_List:
                store.Exclude_Min_Order_Category_List,
              Min_Order_Amount: parseFloat(store.Min_Order_Amount),
              Delivery_Fee: parseFloat(store.Delivery_Fee),
              Is_Happy_Hour_Enabled_Today:
                store.Is_Happy_Hour_Enabled_Today === "true",
              Is_Offer_Opened:
                store.Is_Happy_Hour_Enabled_Today === "false" ? false : false,
              Is_Offer_Closed:
                store.Is_Happy_Hour_Enabled_Today === "false" ? false : false,
              Happy_Hour_Unix_Start_Time:
                store.Happy_Hour_Unix_Start_Time === undefined
                  ? 0
                  : parseInt(moment(store.Happy_Hour_Unix_Start_Time).unix()),
              Happy_Hour_Unix_End_Time:
                store.Happy_Hour_Unix_End_Time === undefined
                  ? 0
                  : parseInt(moment(store.Happy_Hour_Unix_End_Time).unix()),
              Happy_Hour_Id: happpyid,
              Static_KM: parseFloat(store.Static_KM),
              Static_Milli_KM: parseFloat(store.Static_Milli_KM),
              Blocked_Postal_code:
                store.Blocked_Postal_code === undefined
                  ? []
                  : store.Blocked_Postal_code,
              Store_Number: store.Phone_number,
              Delivery_Distance: store.Delivery_Distance,
              Store_Serviceable_Status:
                store.Store_Serviceable_Status === "true",
              Store_Alert_Message: store.Store_Alert_Message,

              Order_Start_Time: parseInt(store.Order_Start_Time),
              Order_Time: store.Order_Time,
              Is_Static_Min_Order_Amount_Enabled:
                store.Is_Static_Min_Order_Amount_Enabled === "true",
              Static_Min_Order_Amount:
                store.Is_Static_Min_Order_Amount_Enabled === "true"
                  ? store.Static_Min_Order_Amount
                  : 0,
              Dynamic_Min_Order_Amount: Dynamic_Min_Order_Amount,

              Is_Static_Delivery_Price_Enabled:
                store.Is_Static_Delivery_Price_Enabled === "true",
              Static_Delivery_Price:
                store.Is_Static_Delivery_Price_Enabled === "true"
                  ? parseFloat(store.Static_Delivery_Price)
                  : 0,
              Dynamic_Delivery_Price: Dynamic_Delivery_Fee,

              Service_Type: parseInt(store.Service_Type),
              Service_Value: parseInt(store.Service_Value),
              Cash_Back_Fee: parseFloat(store.Cash_Back_Fee),
              Cash_Back_Category: store.Cash_Back_Category,
              Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((result) => {
              dispatch({ type: GET_SUCCESS, payload: "Success" });
              resolve("Success");
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        }
      } else {
        var file = fobj;
        storage
          .ref(
            "Store_Images/" +
              store.Store_Name +
              "/" +
              store.Store_Name +
              "_" +
              file.name
          )
          .put(file)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              var thumbImage = snapshot.ref.fullPath;
              if (_.isEmpty(faddress)) {
                db.collection("Stores_Master")
                  .doc(reqid)
                  .update({
                    Store_Name: store.Store_Name,
                    Store_Short_Code: store.Store_Short_Code,
                    Configuration_Message_One:
                      store.Configuration_Message_One === undefined
                        ? ""
                        : store.Configuration_Message_One,
                    Configuration_Message_Two:
                      store.Configuration_Message_Two === undefined
                        ? ""
                        : store.Configuration_Message_Two,

                    IsActive: store.Store_Status === "true",
                    Store_Logo: thumbImage,
                    Maximum_Replaceable_Amount: store.replaceable_amount,
                    Ref_Slot_Time: parseInt(store.ref_slot),
                    Ref_Operating_City_Id: store.ref_city,
                    Category_18_List: store.Category_18_List,
                    Exclude_Min_Order_Category_List:
                      store.Exclude_Min_Order_Category_List,
                    Min_Order_Amount: parseFloat(store.Min_Order_Amount),
                    Delivery_Fee: parseFloat(store.Delivery_Fee),
                    Is_Happy_Hour_Enabled_Today:
                      store.Is_Happy_Hour_Enabled_Today === "true",
                    Is_Offer_Opened:
                      store.Is_Happy_Hour_Enabled_Today === "false"
                        ? false
                        : false,
                    Is_Offer_Closed:
                      store.Is_Happy_Hour_Enabled_Today === "false"
                        ? false
                        : false,
                    Happy_Hour_Unix_Start_Time:
                      store.Happy_Hour_Unix_Start_Time === undefined
                        ? 0
                        : parseInt(
                            moment(store.Happy_Hour_Unix_Start_Time).unix()
                          ),
                    Happy_Hour_Unix_End_Time:
                      store.Happy_Hour_Unix_End_Time === undefined
                        ? 0
                        : parseInt(
                            moment(store.Happy_Hour_Unix_End_Time).unix()
                          ),
                    Happy_Hour_Id: happpyid,
                    Static_KM: parseFloat(store.Static_KM),
                    Static_Milli_KM: parseFloat(store.Static_Milli_KM),
                    Blocked_Postal_code:
                      store.Blocked_Postal_code === undefined
                        ? []
                        : store.Blocked_Postal_code,
                    Store_Number: store.Phone_number,
                    Delivery_Distance: store.Delivery_Distance,
                    Store_Serviceable_Status:
                      store.Store_Serviceable_Status === "true",
                    Store_Alert_Message: store.Store_Alert_Message,

                    Order_Start_Time: parseInt(store.Order_Start_Time),
                    Order_Time: store.Order_Time,
                    Is_Static_Min_Order_Amount_Enabled:
                      store.Is_Static_Min_Order_Amount_Enabled === "true",
                    Static_Min_Order_Amount:
                      store.Is_Static_Min_Order_Amount_Enabled === "true"
                        ? store.Static_Min_Order_Amount
                        : 0,
                    Dynamic_Min_Order_Amount: Dynamic_Min_Order_Amount,

                    Is_Static_Delivery_Price_Enabled:
                      store.Is_Static_Delivery_Price_Enabled === "true",
                    Static_Delivery_Price:
                      store.Is_Static_Delivery_Price_Enabled === "true"
                        ? parseFloat(store.Static_Delivery_Price)
                        : 0,
                    Dynamic_Delivery_Price: Dynamic_Delivery_Fee,

                    Service_Type: parseInt(store.Service_Type),
                    Service_Value: parseInt(store.Service_Value),
                    Cash_Back_Fee: parseFloat(store.Cash_Back_Fee),
                    Cash_Back_Category: store.Cash_Back_Category,
                    Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then((result) => {
                    dispatch({ type: GET_SUCCESS, payload: "Success" });
                    resolve("Success");
                  })
                  .catch((err) => {
                    console.log(err);
                    reject(err);
                  });
              } else {
                db.collection("Stores_Master")
                  .doc(reqid)
                  .update({
                    Store_Name: store.Store_Name,
                    Store_Short_Code: store.Store_Short_Code,
                    Configuration_Message_One:
                      store.Configuration_Message_One === undefined
                        ? ""
                        : store.Configuration_Message_One,
                    Configuration_Message_Two:
                      store.Configuration_Message_Two === undefined
                        ? ""
                        : store.Configuration_Message_Two,
                    Store_Address: faddress.address,
                    Store_Location_Latitude: faddress.lat,
                    Store_Location_Longitude: faddress.lng,
                    IsActive: store.Store_Status === "true",
                    Store_Logo: thumbImage,
                    Maximum_Replaceable_Amount: store.replaceable_amount,
                    Ref_Slot_Time: parseInt(store.ref_slot),
                    Ref_Operating_City_Id: store.ref_city,
                    Category_18_List: store.Category_18_List,
                    Exclude_Min_Order_Category_List:
                      store.Exclude_Min_Order_Category_List,
                    Min_Order_Amount: parseFloat(store.Min_Order_Amount),
                    Delivery_Fee: parseFloat(store.Delivery_Fee),
                    Is_Happy_Hour_Enabled_Today:
                      store.Is_Happy_Hour_Enabled_Today === "true",
                    Is_Offer_Opened:
                      store.Is_Happy_Hour_Enabled_Today === "false"
                        ? false
                        : false,
                    Is_Offer_Closed:
                      store.Is_Happy_Hour_Enabled_Today === "false"
                        ? false
                        : false,
                    Happy_Hour_Unix_Start_Time:
                      store.Happy_Hour_Unix_Start_Time === undefined
                        ? 0
                        : parseInt(
                            moment(store.Happy_Hour_Unix_Start_Time).unix()
                          ),
                    Happy_Hour_Unix_End_Time:
                      store.Happy_Hour_Unix_End_Time === undefined
                        ? 0
                        : parseInt(
                            moment(store.Happy_Hour_Unix_End_Time).unix()
                          ),
                    Happy_Hour_Id: happpyid,
                    Static_KM: parseFloat(store.Static_KM),
                    Static_Milli_KM: parseFloat(store.Static_Milli_KM),
                    Blocked_Postal_code:
                      store.Blocked_Postal_code === undefined
                        ? []
                        : store.Blocked_Postal_code,
                    Store_Number: store.Phone_number,
                    Delivery_Distance: store.Delivery_Distance,
                    Store_Serviceable_Status:
                      store.Store_Serviceable_Status === "true",
                    Store_Alert_Message: store.Store_Alert_Message,

                    Order_Start_Time: parseInt(store.Order_Start_Time),
                    Order_Time: store.Order_Time,
                    Is_Static_Min_Order_Amount_Enabled:
                      store.Is_Static_Min_Order_Amount_Enabled === "true",
                    Static_Min_Order_Amount:
                      store.Is_Static_Min_Order_Amount_Enabled === "true"
                        ? store.Static_Min_Order_Amount
                        : 0,
                    Dynamic_Min_Order_Amount: Dynamic_Min_Order_Amount,

                    Is_Static_Delivery_Price_Enabled:
                      store.Is_Static_Delivery_Price_Enabled === "true",
                    Static_Delivery_Price:
                      store.Is_Static_Delivery_Price_Enabled === "true"
                        ? parseFloat(store.Static_Delivery_Price)
                        : 0,
                    Dynamic_Delivery_Price: Dynamic_Delivery_Fee,

                    Service_Type: parseInt(store.Service_Type),
                    Service_Value: parseInt(store.Service_Value),
                    Cash_Back_Fee: parseFloat(store.Cash_Back_Fee),
                    Cash_Back_Category: store.Cash_Back_Category,
                    Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then((result) => {
                    dispatch({ type: GET_SUCCESS, payload: "Success" });
                    resolve("Success");
                  })
                  .catch((err) => {
                    console.log(err);
                    reject(err);
                  });
              }
            });
          });
      }
    });
  };
}
// ==================================== Order ==================================
// //

export function allorder() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var arr_order_status = [];
      var arr_cutomer_info = [];
      var arr_store_info = [];
      var arr_driver_info = [];
      var arr_city_info = [];
      var arr_order = [];
      var Total_Price;
      //===============================================
      db.collection("Operating_City_Master")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var Delivery_Fee = op_doc_status.data().Delivery_Fee;
            var cityid = op_doc_status.id;
            var obj_order_status = {
              cityid: cityid,
              Delivery_Fee: Delivery_Fee.toFixed(2),
            };
            arr_city_info.push(obj_order_status);
          });
          //console.log(arr_city_info)
          city_info(arr_city_info);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      function city_info(arr_city_info) {
        db.collection("MD_Order_Status")
          .get()
          .then((order_status) => {
            order_status.forEach((doc_order_status) => {
              var statuskey = doc_order_status.data().Order_Status;
              var statusid = doc_order_status.data().Order_Status_Id;
              var obj_order_status = {
                order_statusId: statusid,
                order_statuskey: statuskey,
              };
              arr_order_status.push(obj_order_status);
            });
            customer_info(arr_city_info, arr_order_status);
          })

          .catch((err) => {
            console.log("Error getting documents", err);
            // reject(err);
          });
      }
      function customer_info(arr_city_info, arr_order_status) {
        db.collection("Customer_Shared_Information")
          .get()
          .then((customerdoc) => {
            customerdoc.forEach((doc_customer_status) => {
              var cus_id = doc_customer_status.id;
              var cus_fname = doc_customer_status.data().Customer_First_Name;
              var cus_lname = doc_customer_status.data().Customer_Last_Name;
              var cus_mobile_num =
                doc_customer_status.data().Customer_Mobile_Number;
              var Customer_Number_Of_Trips =
                doc_customer_status.data().Customer_Number_Of_Trips;
              var cus_name = cus_fname + " " + cus_lname;
              var obj_order_status = {
                cus_name: cus_name,
                cus_mobile_num: cus_mobile_num,
                cus_id: cus_id,
                Customer_Number_Of_Trips: Customer_Number_Of_Trips,
              };
              arr_cutomer_info.push(obj_order_status);
            });

            store_info(arr_city_info, arr_order_status, arr_cutomer_info);
          })

          .catch((err) => {
            console.log("Error getting documents", err);
            // reject(err);
          });
      }
      function store_info(arr_city_info, arr_order_status, arr_cutomer_info) {
        db.collection("Stores_Master")
          .get()
          .then((storedoc) => {
            storedoc.forEach((doc_store_status) => {
              var store_id = doc_store_status.id;
              var store_name = doc_store_status.data().Store_Name;
              var obj_order_status = {
                store_id: store_id,
                store_name: store_name,
              };
              arr_store_info.push(obj_order_status);
            });
            Driver_info(
              arr_city_info,
              arr_order_status,
              arr_cutomer_info,
              arr_store_info
            );
          })

          .catch((err) => {
            console.log("Error getting documents", err);
            // reject(err);
          });
      }
      function Driver_info(
        arr_city_info,
        arr_order_status,
        arr_cutomer_info,
        arr_store_info
      ) {
        db.collection("Driver_Master")
          .get()
          .then((storedoc) => {
            storedoc.forEach((doc_store_status) => {
              var driver_id = doc_store_status.id;
              var Driver_Email_Address =
                doc_store_status.data().Driver_Email_Address;
              db.collection("Driver_Shared_Information")
                .doc(driver_id)
                .get()
                .then(function (doc) {
                  if (doc.exists) {
                    var Driver_First_Name = doc.data().Driver_First_Name;
                    var Driver_Last_Name = doc.data().Driver_Last_Name;
                    var obj_order_status = {
                      driver_id: driver_id,
                      Driver_Email_Address: Driver_Email_Address,
                      Driver_name: Driver_First_Name + " " + Driver_Last_Name,
                    };
                    arr_driver_info.push(obj_order_status);
                  }
                })
                .catch((err) => {
                  console.log("Error getting documents", err);
                  // reject(err);
                });
            });
            order_master(
              arr_city_info,
              arr_order_status,
              arr_cutomer_info,
              arr_store_info,
              arr_driver_info
            );
          })

          .catch((err) => {
            console.log("Error getting documents", err);
            // reject(err);
          });
      }

      function order_master(
        arr_city_info,
        arr_order_status,
        arr_cutomer_info,
        arr_store_info,
        arr_driver_info
      ) {
        // console.log(arr_city_info,arr_order_status,arr_cutomer_info,arr_store_info,arr_driver_info)
        db.collection("Order")
          .orderBy("Created_At", "desc")
          .get()
          .then((snap_order) => {
            //var snap_size = snap_order.size;
            snap_order.forEach((doc_order) => {
              // console.log(doc_order.data())
              var order_doc_id = doc_order.id;
              var order_id = doc_order.data().Display_Order_Id;
              var customer_id = doc_order.data().Ref_Customer_Id;
              var orderstatus_id = doc_order.data().Ref_Order_Status_Id;
              var address = doc_order.data().Order_Delivery_Address;
              var store_id = doc_order.data().Ref_Order_Store_Id;
              var order_date = doc_order.data().Created_At;
              var dateString = order_date.toDate();
              var dateconvert = moment(dateString).format("LLL");
              var Price = doc_order.data().Total_Price.toFixed(2);
              var Order_Modify_Fare = doc_order.data().Order_Modify_Fare;
              var App_version =
                doc_order.data().App_Version === undefined
                  ? "-"
                  : doc_order.data().App_Version;
              console.log(doc_order.data().Delivered_Time, order_doc_id);
              // var Delivered_Time = (doc_order.data().Delivered_Time === 0 ? "-" : moment(doc_order.data().Delivered_Time.toDate()).format('LLL'));
              var Order_Discount_Fare = doc_order.data().Order_Discount_Fare;
              var Ref_Driver_Id = doc_order.data().Ref_Driver_Id;
              const cipherText = doc_order.data().Order_OTP;
              var Ref_Geo_Id = doc_order.data().Ref_Geo_Id;
              var Order_Request_Device = doc_order.data().Order_Request_Device;
              if (cipherText !== "") {
                const key = "bbC2H19lkVbQDfakxcrtNMQdd0FloLyw";
                var OTP_Num = cryptLib.decryptCipherTextWithRandomIV(
                  cipherText,
                  key
                );
              } else {
                OTP_Num = "-";
              }

              //console.log(Ref_Driver_Id);
              if (Order_Modify_Fare > 0) {
                var Total_Price1 = Order_Modify_Fare;
              } else {
                Total_Price1 = Price;
              }

              var Order_Currency_Symbol =
                doc_order.data().Order_Currency_Symbol;

              //arr_city_info
              if (Ref_Geo_Id) {
                var selectedcity_status =
                  arr_city_info[
                    arr_city_info
                      .map(function (item1) {
                        return item1.cityid;
                      })
                      .indexOf(Ref_Geo_Id)
                  ];
                //console.log(selectedcity_status, ' - ', Ref_Geo_Id);
                var Delivery_Fee = parseFloat(selectedcity_status.Delivery_Fee);
                // console.log(dir_order_status);
              } else {
                Delivery_Fee = 0.0;
              }

              //arr_order_status
              if (orderstatus_id) {
                var selectedorder_status =
                  arr_order_status[
                    arr_order_status
                      .map(function (item1) {
                        return item1.order_statusId;
                      })
                      .indexOf(orderstatus_id)
                  ];
                //console.log(selectedorder_status, ' - ', orderstatus_id);
                var dir_order_status = selectedorder_status.order_statuskey;
                // console.log(dir_order_status);
              } else {
                dir_order_status = "N/A";
              }

              //arr_cutomer_info
              if (customer_id) {
                var selectedcustomer_status =
                  arr_cutomer_info[
                    arr_cutomer_info
                      .map(function (item1) {
                        return item1.cus_id;
                      })
                      .indexOf(customer_id)
                  ];
                // console.log(selectedcustomer_status, ' - ', customer_id);
                var customer_name = selectedcustomer_status.cus_name;
                var customer_phone = selectedcustomer_status.cus_mobile_num;
                var orderno = selectedcustomer_status.Customer_Number_Of_Trips;
                // console.log(dir_order_status);
              } else {
                customer_name = "N/A";
                customer_phone = "";
                orderno = "";
              }

              //arr_store_info
              if (store_id) {
                var selectedstore_status =
                  arr_store_info[
                    arr_store_info
                      .map(function (item1) {
                        return item1.store_id;
                      })
                      .indexOf(store_id)
                  ];
                // console.log(selectedstore_status, ' - ', store_id);
                var store_name = selectedstore_status.store_name;
              } else {
                store_name = "N/A";
              }

              //arr_Driver_info
              if (Ref_Driver_Id) {
                var selecteddriver_status =
                  arr_driver_info[
                    arr_driver_info
                      .map(function (item1) {
                        return item1.driver_id;
                      })
                      .indexOf(Ref_Driver_Id)
                  ];
                // console.log(selectedstore_status, ' - ', store_id);
                var driver_name = selecteddriver_status.Driver_name;
                var driver_email = selecteddriver_status.Driver_Email_Address;
                var driver_status = true;
              } else {
                driver_name = "-";
                driver_email = "-";
                driver_status = false;
              }

              Total_Price = Total_Price1 + Delivery_Fee - Order_Discount_Fare;
              //console.log(Delivered_Time);
              var obj_arr = {
                key: order_doc_id,
                order_id: order_id,
                customer_name: customer_name,
                customer_phone: customer_phone,
                delivery_address: address,
                store_name: store_name,
                order_status: dir_order_status,
                orderstatus_id: orderstatus_id,
                order_date: dateconvert,
                Total_Price:
                  Order_Currency_Symbol + " " + Total_Price.toFixed(2),
                App_version: App_version,
                driver_name: driver_name,
                driver_email: driver_email,
                OTP_Num: OTP_Num,
                orderno: orderno,
                driver_status: driver_status,
                //Del_Time:Delivered_Time,
                Order_Request_Device: Order_Request_Device,
              };
              // console.log(obj_arr)
              arr_order.push(obj_arr);
            });
            dispatch({ type: GET_ORDER, payload: arr_order });
            resolve("Success");
            // var arr_length = arr_order.length;
            // if (arr_length === snap_size) {
            //    // console.log('response', arr_length + ' ' + snap_size)
            //     //console.log(arr_order)
            //     dispatch({type: GET_ORDER, payload: arr_order});
            //     resolve(arr_order);

            // }
          })

          .catch(function (error) {
            console.log("Error getting documents of trip_Master: ", error);
            reject(error);
          });
      }
    });
  };
}
export function customeallorder(sdate, edate) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var arr_order_status = [];
      var arr_store_info = [];
      var arr_order = [];
      var arr_payment_type = [];
      var Total_Price;
      //===============================================
      db.collection("MD_PaymentType")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var Payment_Type = op_doc_status.data().Payment_Type;
            var Payment_Type_Id = op_doc_status.data().Payment_Type_Id;
            var obj_order_status = {
              Payment_Type_Id: Payment_Type_Id,
              Payment_Type: Payment_Type,
            };
            arr_payment_type.push(obj_order_status);
          });
          //console.log(arr_city_info)
          city_info();
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });

      //========================================
      function city_info() {
        db.collection("MD_Order_Status")
          .get()
          .then((order_status) => {
            order_status.forEach((doc_order_status) => {
              var statuskey = doc_order_status.data().Order_Status;
              var statusid = doc_order_status.data().Order_Status_Id;
              var obj_order_status = {
                order_statusId: statusid,
                order_statuskey: statuskey,
              };
              arr_order_status.push(obj_order_status);
            });
            //console.log(arr_order_status);

            store_info();
          })

          .catch((err) => {
            console.log("Error getting documents", err);
            // reject(err);
          });
      }

      function store_info() {
        db.collection("Stores_Master")
          .get()
          .then((storedoc) => {
            storedoc.forEach((doc_store_status) => {
              var store_id = doc_store_status.id;
              var store_name = doc_store_status.data().Store_Name;
              var obj_order_status = {
                store_id: store_id,
                store_name: store_name,
              };
              arr_store_info.push(obj_order_status);
            });
            order_master();
          })

          .catch((err) => {
            console.log("Error getting documents", err);
            // reject(err);
          });
      }
      function order_master() {
        const fdate = moment(sdate);
        const tdate = moment(edate);

        const from_date = fdate.startOf("day").format();
        const to_date = tdate.endOf("day").format();
        const start = new Date(from_date);
        const end = new Date(to_date);
        // console.log(start, end);
        // console.log(arr_city_info,arr_order_status,arr_cutomer_info,arr_store_info,arr_driver_info)
        db.collection("Order")
          .where("Created_At", ">", start)
          .where("Created_At", "<", end)
          .orderBy("Created_At", "desc")
          .get()
          .then((snap_order) => {
            //var snap_size = snap_order.size;
            snap_order.forEach((doc_order) => {
              // console.log(doc_order.data().Display_Order_Id);
              var order_doc_id = doc_order.id;
              if (doc_order.data().Ref_Service_Fee !== undefined) {
                var Ref_Service_Fee = doc_order.data().Ref_Service_Fee;
              } else {
                Ref_Service_Fee = 0;
              }

              if (doc_order.data().Ref_Delivery_Fee !== undefined) {
                var Delivery_Fee = doc_order.data().Ref_Delivery_Fee;
              } else {
                Delivery_Fee = 0;
              }
              if (doc_order.data().Ref_Bag_Fee !== undefined) {
                var Bag_Fee = doc_order.data().Ref_Bag_Fee;
              } else {
                Bag_Fee = 0;
              }

              var order_id =
                doc_order.data().Display_Order_Id === undefined
                  ? "-"
                  : doc_order.data().Display_Order_Id;
              var customer_id = doc_order.data().Ref_Customer_Id;
              var customer_phonenumber =
                doc_order.data().Ref_Customer_Mobile_No === undefined
                  ? "-"
                  : doc_order.data().Ref_Customer_Mobile_No;
              var customer_name = doc_order.data().Ref_Customer_Name;
              var Ref_Total_No_of_Order =
                doc_order.data().Ref_Total_No_of_Order;
              var orderstatus_id = doc_order.data().Ref_Order_Status_Id;
              var address = doc_order.data().Order_Delivery_Address;
              var store_id = doc_order.data().Ref_Order_Store_Id;
              var order_date = doc_order.data().Created_At;
              var dateString = order_date.toDate();
              var dateconvert = moment(dateString).format("LLL");
              var orderdifftime = moment(dateString).fromNow();
              var Ref_Delivery_Preferences =
                doc_order.data().Ref_Delivery_Preferences;
              if (doc_order.data().Ref_Order_Transaction_Payment_Id === "") {
                var Ref_Order_Transaction_Payment_Id = 0;
              } else {
                Ref_Order_Transaction_Payment_Id = 1;
              }

              const actualdeliverytime = moment(
                dateString,
                "ddd MMM DD YYYY HH:mm:ss GMT Z"
              ).diff(Date.now(), "hours", true);

              //console.log(actualdeliverytime);
              var Ref_Cash_Back_Amount =
                doc_order.data().Ref_Cash_Back_Amount === undefined
                  ? 0
                  : doc_order.data().Ref_Cash_Back_Amount;
              var Price = doc_order.data().Total_Price;
              var Order_Modify_Fare = doc_order.data().Order_Modify_Fare;
              var Order_Coupon_Applicable =
                doc_order.data().Order_Coupon_Applicable;
              var App_version =
                doc_order.data().App_Version === undefined
                  ? "-"
                  : doc_order.data().App_Version;

              var Order_Discount_Fare = doc_order.data().Order_Discount_Fare;
              var Ref_Driver_Name = doc_order.data().Ref_Driver_Name;
              var updated_by =
                doc_order.data().Updated_By === undefined
                  ? "-"
                  : doc_order.data().Updated_By;
              if (doc_order.data().Order_OTP !== "") {
                var cipherText = doc_order.data().Order_OTP;
              } else {
                cipherText = undefined;
              }
              if (doc_order.data().Cash_Back_Code !== "") {
                var cashText = doc_order.data().Cash_Back_Code;
              } else {
                cashText = undefined;
              }

              if (doc_order.data().Delivery_Preferences_Code !== "") {
                var deliveryText = doc_order.data().Delivery_Preferences_Code;
                if (deliveryText === 0) {
                  deliveryText = undefined;
                }
                //console.log(deliveryText);
              } else {
                deliveryText = undefined;
                // console.log(deliveryText);
              }

              //console.log(deliveryText);
              //var Ref_Geo_Id = doc_order.data().Ref_Geo_Id;
              var Ref_Payment_Type_Id =
                doc_order.data().Ref_Payment_Type_Id === undefined
                  ? "-"
                  : doc_order.data().Ref_Payment_Type_Id;

              var Order_Request_Device = doc_order.data().Order_Request_Device;

              if (cipherText !== undefined) {
                const key = "bbC2H19lkVbQDfakxcrtNMQdd0FloLyw";
                var OTP_Num = cryptLib.decryptCipherTextWithRandomIV(
                  cipherText,
                  key
                );
              } else {
                OTP_Num = "-";
              }

              if (cashText !== undefined) {
                const key1 = "bbC2H19lkVbQDfakxcrtNMQdd0FloLyw";
                var cashback_otp = cryptLib.decryptCipherTextWithRandomIV(
                  cashText,
                  key1
                );
              } else {
                cashback_otp = "-";
              }

              if (deliveryText !== undefined) {
                const key2 = "bbC2H19lkVbQDfakxcrtNMQdd0FloLyw";
                var delivery_otp = cryptLib.decryptCipherTextWithRandomIV(
                  deliveryText,
                  key2
                );
              } else {
                delivery_otp = "-";
              }

              //console.log(Ref_Driver_Id);
              if (Order_Modify_Fare > 0) {
                var Total_Price1 = Order_Modify_Fare;
              } else {
                Total_Price1 = Price;
              }

              var Order_Currency_Symbol =
                doc_order.data().Order_Currency_Symbol;

              //arr_payment_info
              if (Ref_Payment_Type_Id) {
                var payment_status =
                  arr_payment_type[
                    arr_payment_type
                      .map(function (item1) {
                        return item1.Payment_Type_Id;
                      })
                      .indexOf(Ref_Payment_Type_Id)
                  ];
                if (payment_status !== undefined) {
                  var Payment_Type = payment_status.Payment_Type;
                } else {
                  Payment_Type = "-";
                }

                // console.log(dir_order_status);
              } else {
                Payment_Type = "-";
              }

              //arr_order_status
              if (orderstatus_id) {
                var selectedorder_status =
                  arr_order_status[
                    arr_order_status
                      .map(function (item1) {
                        return item1.order_statusId;
                      })
                      .indexOf(orderstatus_id)
                  ];
                //console.log(selectedorder_status, ' - ', orderstatus_id);
                var dir_order_status = selectedorder_status.order_statuskey;
                // console.log(dir_order_status);
              } else {
                dir_order_status = "N/A";
              }

              //arr_store_info
              if (store_id) {
                var selectedstore_status =
                  arr_store_info[
                    arr_store_info
                      .map(function (item1) {
                        return item1.store_id;
                      })
                      .indexOf(store_id)
                  ];
                // console.log(selectedstore_status, ' - ', store_id);
                var store_name = selectedstore_status.store_name;
              } else {
                store_name = "N/A";
              }

              //console.log(Total_Price1,Delivery_Fee,Bag_Fee,Order_Discount_Fare)
              var total_amount =
                Total_Price1 + Delivery_Fee + Bag_Fee + Ref_Service_Fee;
              // console.log(total_amount)
              Total_Price = total_amount - Order_Discount_Fare;
              //  console.log(Total_Price);

              var customerdetail = "";
              customerdetail += order_id + "\n";
              customerdetail += customer_name + "\n";
              customerdetail += customer_phonenumber + "\n";
              customerdetail += address + "\n";
              customerdetail += store_name + "\n";
              customerdetail += Payment_Type + "\n";
              customerdetail += Order_Currency_Symbol + Total_Price.toFixed(2);
              var obj_arr = {
                key: order_doc_id,
                order_id: order_id,
                customer_name: customer_name,
                customer_phone: customer_phonenumber.toString(),
                cust_id: customer_id,
                delivery_address: address,
                store_name: store_name,
                order_status: dir_order_status,
                orderstatus_id: orderstatus_id,
                order_date: dateconvert,
                orderdifftime: orderdifftime,
                actualdeliverytime: actualdeliverytime,
                Total_Price:
                  Order_Currency_Symbol + " " + Total_Price.toFixed(2),
                App_version: App_version,
                driver_name: Ref_Driver_Name,
                OTP_Num: OTP_Num,
                cashback_otp: cashback_otp,
                orderno: Ref_Total_No_of_Order.toString(),
                Payment_Type: Payment_Type,
                Order_Request_Device: Order_Request_Device,
                Order_Coupon_Applicable: Order_Coupon_Applicable,
                store_id,
                customerdetail,
                Ref_Order_Transaction_Payment_Id,
                Ref_Delivery_Preferences,
                delivery_otp,
                Delivery_Fee,
                Ref_Service_Fee,
                Ref_Cash_Back_Amount:
                  Order_Currency_Symbol + " " + Ref_Cash_Back_Amount.toFixed(2),
                updated_by,
              };
              //console.log(obj_arr)
              arr_order.push(obj_arr);
            });
            dispatch({ type: GET_ORDER, payload: arr_order });
            resolve("Success");
          })

          .catch(function (error) {
            console.log("Error getting documents of trip_Master: ", error);
            reject(error);
          });
      }
    });
  };
}

export function storeallorder(store, sdate, edate) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var arr_order_status = [];
      var arr_store_info = [];
      var arr_order = [];
      var arr_payment_type = [];
      var Total_Price;
      //===============================================
      db.collection("MD_PaymentType")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var Payment_Type = op_doc_status.data().Payment_Type;
            var Payment_Type_Id = op_doc_status.data().Payment_Type_Id;
            var obj_order_status = {
              Payment_Type_Id: Payment_Type_Id,
              Payment_Type: Payment_Type,
            };
            arr_payment_type.push(obj_order_status);
          });
          //console.log(arr_city_info)
          city_info();
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });

      //========================================
      function city_info() {
        db.collection("MD_Order_Status")
          .get()
          .then((order_status) => {
            order_status.forEach((doc_order_status) => {
              var statuskey = doc_order_status.data().Order_Status;
              var statusid = doc_order_status.data().Order_Status_Id;
              var obj_order_status = {
                order_statusId: statusid,
                order_statuskey: statuskey,
              };
              arr_order_status.push(obj_order_status);
            });
            console.log(arr_order_status);

            store_info();
          })

          .catch((err) => {
            console.log("Error getting documents", err);
            // reject(err);
          });
      }

      function store_info() {
        db.collection("Stores_Master")
          .get()
          .then((storedoc) => {
            storedoc.forEach((doc_store_status) => {
              var store_id = doc_store_status.id;
              var store_name = doc_store_status.data().Store_Name;
              var obj_order_status = {
                store_id: store_id,
                store_name: store_name,
              };
              arr_store_info.push(obj_order_status);
            });
            order_master();
          })

          .catch((err) => {
            console.log("Error getting documents", err);
            // reject(err);
          });
      }
      function order_master() {
        const fdate = moment(sdate);
        const tdate = moment(edate);

        const from_date = fdate.startOf("day").format();
        const to_date = tdate.endOf("day").format();
        const start = new Date(from_date);
        const end = new Date(to_date);
        console.log(start, end);
        // console.log(arr_city_info,arr_order_status,arr_cutomer_info,arr_store_info,arr_driver_info)
        db.collection("Order")
          .where("Ref_Order_Store_Id", "==", store)
          .where("Created_At", ">", start)
          .where("Created_At", "<", end)
          .orderBy("Created_At", "desc")
          .get()
          .then((snap_order) => {
            //var snap_size = snap_order.size;
            snap_order.forEach((doc_order) => {
              // console.log(doc_order.data().Display_Order_Id);
              var order_doc_id = doc_order.id;
              if (doc_order.data().Ref_Delivery_Fee !== undefined) {
                var Delivery_Fee = doc_order.data().Ref_Delivery_Fee;
              } else {
                Delivery_Fee = 0;
              }
              if (doc_order.data().Ref_Bag_Fee !== undefined) {
                var Bag_Fee = doc_order.data().Ref_Bag_Fee;
              } else {
                Bag_Fee = 0;
              }
              if (doc_order.data().Ref_Cash_Back_Fee !== undefined) {
                var cash_back_fee = doc_order.data().Ref_Cash_Back_Fee;
              } else {
                cash_back_fee = 0;
              }
              var order_id =
                doc_order.data().Display_Order_Id === undefined
                  ? "-"
                  : doc_order.data().Display_Order_Id;
              console.log(order_id);
              var customer_id = doc_order.data().Ref_Customer_Id;
              var customer_phonenumber =
                doc_order.data().Ref_Customer_Mobile_No === undefined
                  ? "-"
                  : doc_order.data().Ref_Customer_Mobile_No;
              var customer_name = doc_order.data().Ref_Customer_Name;
              var Ref_Total_No_of_Order =
                doc_order.data().Ref_Total_No_of_Order === undefined
                  ? "-"
                  : doc_order.data().Ref_Total_No_of_Order;
              var orderstatus_id = doc_order.data().Ref_Order_Status_Id;
              var address = doc_order.data().Order_Delivery_Address;
              var store_id = doc_order.data().Ref_Order_Store_Id;
              var order_date = doc_order.data().Created_At;
              var dateString = order_date.toDate();
              var dateconvert = moment(dateString).format("LLL");
              var orderdifftime = moment(dateString).fromNow();
              var Ref_Delivery_Preferences =
                doc_order.data().Ref_Delivery_Preferences;
              if (doc_order.data().Ref_Order_Transaction_Payment_Id === "") {
                var Ref_Order_Transaction_Payment_Id = 0;
              } else {
                Ref_Order_Transaction_Payment_Id = 1;
              }

              const actualdeliverytime = moment(
                dateString,
                "ddd MMM DD YYYY HH:mm:ss GMT Z"
              ).diff(Date.now(), "hours", true);

              //console.log(actualdeliverytime);
              var Ref_Cash_Back_Amount =
                doc_order.data().Ref_Cash_Back_Amount === undefined
                  ? 0
                  : doc_order.data().Ref_Cash_Back_Amount;
              // console.log(Ref_Cash_Back_Amount);
              var Price = doc_order.data().Total_Price;
              var Order_Modify_Fare = doc_order.data().Order_Modify_Fare;
              var Order_Coupon_Applicable =
                doc_order.data().Order_Coupon_Applicable;
              var App_version =
                doc_order.data().App_Version === undefined
                  ? "-"
                  : doc_order.data().App_Version;

              var Order_Discount_Fare = doc_order.data().Order_Discount_Fare;
              var Ref_Driver_Name = doc_order.data().Ref_Driver_Name;
              var Order_Postal_Code = doc_order.data().Order_Postal_Code;

              var zero_check_start_time = doc_order.data().Start_Time;
              var zero_check_del_time = doc_order.data().Delivered_Time;

              if (zero_check_start_time !== 0) {
                var Start_Time = doc_order.data().Start_Time;
                var cal_start_time = moment.unix(Start_Time).format("LLL");
              } else {
                cal_start_time = "-";
              }
              if (zero_check_del_time !== 0) {
                var Created_At = moment(
                  doc_order.data().Delivered_Time.toDate()
                ).format("MMMM Do YYYY, h:mm:ss a");
              } else {
                Created_At = "-";
              }

              // var Delivered_Time = doc_order.data().Delivered_Time;
              // var cal_delivered_time = Delivered_Time.toDate();
              console.log(cal_start_time, Created_At);

              if (doc_order.data().Order_OTP !== "") {
                var cipherText = doc_order.data().Order_OTP;
              } else {
                cipherText = undefined;
              }
              if (doc_order.data().Cash_Back_Code !== "") {
                var cashText = doc_order.data().Cash_Back_Code;
              } else {
                cashText = undefined;
              }

              //var Ref_Geo_Id = doc_order.data().Ref_Geo_Id;
              var Ref_Payment_Type_Id =
                doc_order.data().Ref_Payment_Type_Id === undefined
                  ? "-"
                  : doc_order.data().Ref_Payment_Type_Id;

              var Order_Request_Device = doc_order.data().Order_Request_Device;

              if (cipherText !== undefined) {
                const key = "bbC2H19lkVbQDfakxcrtNMQdd0FloLyw";
                var OTP_Num = cryptLib.decryptCipherTextWithRandomIV(
                  cipherText,
                  key
                );
              } else {
                OTP_Num = "-";
              }

              if (cashText !== undefined) {
                const key1 = "bbC2H19lkVbQDfakxcrtNMQdd0FloLyw";
                var cashback_otp = cryptLib.decryptCipherTextWithRandomIV(
                  cashText,
                  key1
                );
              } else {
                cashback_otp = "-";
              }

              //console.log(Ref_Driver_Id);
              if (Order_Modify_Fare > 0) {
                var Total_Price1 = Order_Modify_Fare;
              } else {
                Total_Price1 = Price;
              }

              var Order_Currency_Symbol =
                doc_order.data().Order_Currency_Symbol;

              //arr_payment_info
              if (Ref_Payment_Type_Id) {
                var payment_status =
                  arr_payment_type[
                    arr_payment_type
                      .map(function (item1) {
                        return item1.Payment_Type_Id;
                      })
                      .indexOf(Ref_Payment_Type_Id)
                  ];
                if (payment_status !== undefined) {
                  var Payment_Type = payment_status.Payment_Type;
                } else {
                  Payment_Type = "-";
                }

                // console.log(dir_order_status);
              } else {
                Payment_Type = "-";
              }

              //arr_order_status
              if (orderstatus_id) {
                var selectedorder_status =
                  arr_order_status[
                    arr_order_status
                      .map(function (item1) {
                        return item1.order_statusId;
                      })
                      .indexOf(orderstatus_id)
                  ];
                //console.log(selectedorder_status, ' - ', orderstatus_id);
                var dir_order_status = selectedorder_status.order_statuskey;
                // console.log(dir_order_status);
              } else {
                dir_order_status = "N/A";
              }

              //arr_store_info
              if (store_id) {
                var selectedstore_status =
                  arr_store_info[
                    arr_store_info
                      .map(function (item1) {
                        return item1.store_id;
                      })
                      .indexOf(store_id)
                  ];
                // console.log(selectedstore_status, ' - ', store_id);
                var store_name = selectedstore_status.store_name;
              } else {
                store_name = "N/A";
              }

              //console.log(Total_Price1,Delivery_Fee,Bag_Fee,Order_Discount_Fare)
              var total_amount = Total_Price1 + Delivery_Fee + Bag_Fee;
              // console.log(total_amount)
              Total_Price = total_amount - Order_Discount_Fare;
              //  console.log(Total_Price);

              var customerdetail = "";
              customerdetail += order_id + "\n";
              customerdetail += customer_name + "\n";
              customerdetail += customer_phonenumber + "\n";
              customerdetail += address + "\n";
              customerdetail += store_name + "\n";
              customerdetail += Payment_Type + "\n";
              customerdetail += Order_Currency_Symbol + Total_Price.toFixed(2);
              var obj_arr = {
                key: order_doc_id,
                order_id: order_id,
                customer_name: customer_name,
                customer_phone: customer_phonenumber.toString(),
                cust_id: customer_id,
                delivery_address: address,
                store_name: store_name,
                order_status: dir_order_status,
                orderstatus_id: orderstatus_id,
                order_date: dateconvert,
                orderdifftime: orderdifftime,
                actualdeliverytime: actualdeliverytime,
                Total_Price:
                  Order_Currency_Symbol + " " + Total_Price.toFixed(2),
                App_version: App_version,
                driver_name: Ref_Driver_Name,
                OTP_Num: OTP_Num,
                cashback_otp: cashback_otp,
                orderno: Ref_Total_No_of_Order.toString(),
                Payment_Type: Payment_Type,
                Order_Request_Device: Order_Request_Device,
                Order_Coupon_Applicable: Order_Coupon_Applicable,
                store_id,
                customerdetail,
                Ref_Order_Transaction_Payment_Id,
                Ref_Delivery_Preferences,
                Delivery_Fee:
                  Order_Currency_Symbol + " " + Delivery_Fee.toFixed(2),
                Bag_Fee: Order_Currency_Symbol + " " + Bag_Fee.toFixed(2),
                cash_back_fee:
                  Order_Currency_Symbol + " " + cash_back_fee.toFixed(2),
                Ref_Cash_Back_Amount:
                  Order_Currency_Symbol + " " + Ref_Cash_Back_Amount.toFixed(2),
                Order_Postal_Code,
                Order_Time:
                  Ref_Delivery_Preferences === 2 ? Created_At : cal_start_time,
              };
              //console.log(obj_arr)
              arr_order.push(obj_arr);
            });
            dispatch({ type: GET_ORDER, payload: arr_order });
            resolve("Success");
          })

          .catch(function (error) {
            console.log("Error getting documents of trip_Master: ", error);
            reject(error);
          });
      }
    });
  };
}
//======================= Order Details =====================================//
export function OrderDetail(orderid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Order")
        .doc(orderid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            //console.log("Document data:", doc.data());
            var order_id = doc.data().Display_Order_Id;
            var customer_id = doc.data().Ref_Customer_Id;
            var address = doc.data().Order_Delivery_Address;
            var order_date = moment(doc.data().Created_At.toDate()).format(
              "MM/DD/YYYY hh:mm:ss A"
            );
            var driver_id = doc.data().Ref_Driver_Id;
            var store_id = doc.data().Ref_Order_Store_Id;
            var del_loc = {
              lat: doc.data().Order_Delivery_Latitude,
              lng: doc.data().Order_Delivery_Longitude,
            };

            // var dateString = order_date.toDate()
            // var dateconvert = moment(dateString).format('MMMM Do YYYY, h:mm:ss a');

            db.collection("Stores_Master")
              .doc(store_id)
              .get()
              .then(function (doc) {
                if (doc.exists) {
                  var store_loc = {
                    lat: doc.data().Store_Location_Latitude,
                    lng: doc.data().Store_Location_Longitude,
                  };
                  get_customerinfo(
                    customer_id,
                    order_id,
                    address,
                    driver_id,
                    del_loc,
                    order_date,
                    store_loc
                  );
                } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
                }
              })
              .catch(function (error) {
                console.log("Error getting document:", error);
              });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });

      function get_customerinfo(
        customer_id,
        order_id,
        address,
        driver_id,
        del_loc,
        order_date,
        store_loc
      ) {
        db.collection("Customer_Shared_Information")
          .doc(customer_id)
          .get()
          .then(function (customerdoc) {
            if (customerdoc.exists) {
              //console.log("Document data:", doc.data());
              var cus_fname = customerdoc.data().Customer_First_Name;
              var cus_lname = customerdoc.data().Customer_Last_Name;
              var cus_mobile_num = customerdoc.data().Customer_Mobile_Number;
              var cus_name = cus_fname + " " + cus_lname;
              var cus_email = customerdoc.data().Customer_Email_Address;

              making_object(
                order_id,
                address,
                driver_id,
                del_loc,
                order_date,
                store_loc,
                cus_name,
                cus_mobile_num,
                cus_email
              );
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });
      }

      let making_object = (
        order_id,
        address,
        driver_id,
        del_loc,
        order_date,
        store_loc,
        cus_name,
        cus_mobile_num,
        cus_email
      ) => {
        var arr_order = {
          order_id: order_id,
          customer_name: cus_name,
          cus_phone: cus_mobile_num,
          cus_email: cus_email,
          del_address: address,
          del_loc: del_loc,
          driver_id: driver_id,
          order_date: order_date,
          store_loc: store_loc,
        };

        dispatchevent(arr_order);
      };

      let dispatchevent = (arr_order) => {
        dispatch({ type: GET_SINGLE_ORDER, payload: arr_order });
        resolve(arr_order);
      };
    });
  };
}
//========================================================================

export function OrderDetailNew(orderid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(orderid);
      db.collection("Order")
        .doc(orderid)
        .get()
        .then(function (doc) {
          const data = doc.data();
          console.log(data.Ref_Order_Status_Id);
          // console.log(data.Ref_Order_Status_Id)
          if (data.Ref_Bag_Fee !== undefined) {
            var Bag_Fee = data.Ref_Bag_Fee;
          } else {
            Bag_Fee = 0;
          }
          if (data.Ref_Cash_Back_Fee !== undefined) {
            var Ref_Cash_Back_Fee = data.Ref_Cash_Back_Fee;
          } else {
            Ref_Cash_Back_Fee = 0;
          }
          var Ref_Service_Fee = data.Ref_Service_Fee;
          var Delivery_Fee = data.Ref_Delivery_Fee;
          var Display_Order_Id = data.Display_Order_Id;
          var Ref_Customer_Id = data.Ref_Customer_Id;
          var Ref_Order_Manager_Id = data.Ref_Order_Manager_Id;
          var Ref_Order_Store_Id = data.Ref_Order_Store_Id;
          var TimeZone_Offset = data.TimeZone_Offset;
          var Order_Delivery_Time = moment
            .unix(data.Order_Delivery_Time + TimeZone_Offset)
            .local()
            .format("MM/DD/YYYY hh:mm:ss A");
          var Created_At = moment(data.Created_At.toDate()).format(
            "MM/DD/YYYY hh:mm:ss A"
          );
          var Saving_Price = data.Saving_Price;
          var Order_Currency_Symbol = data.Order_Currency_Symbol;
          var Modified_Fare = data.Order_Modify_Fare;
          var Order_Modify_Fare = data.Order_Modify_Fare;
          var Total_Total_Price = data.Total_Price;
          var Ref_Payment_Type_Id = data.Ref_Payment_Type_Id;
          var refunddata = "";
          if (
            Ref_Payment_Type_Id === referencevalue.Card ||
            Ref_Payment_Type_Id === referencevalue.Cardold
          ) {
            var Payment_Type = "Card";
          } else if (Ref_Payment_Type_Id === referencevalue.Cash) {
            Payment_Type = "Cash";
          } else {
            Payment_Type = "ApplePay";
          }
          if (Modified_Fare === 0) {
            var Total_Price = data.Total_Price;
          } else {
            Total_Price = Modified_Fare;
          }
          if (Order_Modify_Fare !== 0) {
            if (parseInt(Total_Total_Price) < parseInt(Order_Modify_Fare)) {
              refunddata = "";
            } else {
              var actualpricediff =
                parseFloat(Total_Total_Price) - parseFloat(Order_Modify_Fare);
              //console.log("actualpricediff", actualpricediff);
              if (actualpricediff === 0 || actualpricediff === 0.0) {
                refunddata = "";
              } else {
                refunddata =
                  "Your Order has been Modified by the Manager, " +
                  Order_Currency_Symbol +
                  " " +
                  actualpricediff.toFixed(2) +
                  " will be processed in 4-5 business days";
              }
            }
          } else {
            refunddata = "";
          }
          var Order_Delivery_Address = data.Order_Delivery_Address;
          var Order_Discount_Fare = data.Order_Discount_Fare;
          var Ref_Geo_Id = data.Ref_Geo_Id;
          city_master(
            data,
            Order_Currency_Symbol,
            Display_Order_Id,
            Ref_Customer_Id,
            Ref_Order_Manager_Id,
            Ref_Order_Store_Id,
            Order_Delivery_Time,
            Created_At,
            Saving_Price,
            Total_Price,
            actualpricediff,
            refunddata,
            Order_Delivery_Address,
            Order_Discount_Fare,
            Ref_Geo_Id,
            Payment_Type,
            Delivery_Fee,
            Bag_Fee,
            Ref_Cash_Back_Fee,
            Ref_Service_Fee
          );
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
      // ================  Operating_City_Master Collection ==================================
      function city_master(
        data,
        Order_Currency_Symbol,
        Display_Order_Id,
        Ref_Customer_Id,
        Ref_Order_Manager_Id,
        Ref_Order_Store_Id,
        Order_Delivery_Time,
        Created_At,
        Saving_Price,
        Total_Price,
        actualpricediff,
        refunddata,
        Order_Delivery_Address,
        Order_Discount_Fare,
        Ref_Geo_Id,
        Payment_Type,
        Delivery_Fee,
        Bag_Fee,
        Ref_Cash_Back_Fee,
        Ref_Service_Fee
      ) {
        db.collection("Operating_City_Master")
          .doc(Ref_Geo_Id)
          .get()
          .then((op_doc) => {
            // if (op_doc.exists) {
            //   var Delivery_Fee = op_doc.data().Delivery_Fee;
            // }
            store_manager(
              data,
              Order_Currency_Symbol,
              Display_Order_Id,
              Ref_Customer_Id,
              Ref_Order_Manager_Id,
              Ref_Order_Store_Id,
              Order_Delivery_Time,
              Created_At,
              Saving_Price,
              Total_Price,
              actualpricediff,
              refunddata,
              Order_Delivery_Address,
              Order_Discount_Fare,
              Ref_Geo_Id,
              Payment_Type,
              Delivery_Fee,
              Bag_Fee,
              Ref_Cash_Back_Fee,
              Ref_Service_Fee
            );
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });
      }

      // ================ Store_Manager Collection ==================================

      function store_manager(
        data,
        Order_Currency_Symbol,
        Display_Order_Id,
        Ref_Customer_Id,
        Ref_Order_Manager_Id,
        Ref_Order_Store_Id,
        Order_Delivery_Time,
        Created_At,
        Saving_Price,
        Total_Price,
        actualpricediff,
        refunddata,
        Order_Delivery_Address,
        Order_Discount_Fare,
        Ref_Geo_Id,
        Payment_Type,
        Delivery_Fee,
        Bag_Fee,
        Ref_Cash_Back_Fee,
        Ref_Service_Fee
      ) {
        var Managername;
        var Manager_Email_Address;
        var Manager_Mobile_Number;
        db.collection("Store_Manager")
          .where("Manager_Id", "==", Ref_Order_Manager_Id)
          .get()
          .then((ordsnap) => {
            if (ordsnap.size !== 0) {
              ordsnap.forEach((managerdetaildoc) => {
                Managername = managerdetaildoc.data().Manager_Name;
                Manager_Email_Address =
                  managerdetaildoc.data().Manager_Email_Address;
                Manager_Mobile_Number =
                  managerdetaildoc.data().Manager_Mobile_Number;
              });
            } else {
              Managername = "-";
              Manager_Email_Address = "-";
              Manager_Mobile_Number = "-";
            }
            stores_master(
              data,
              Order_Currency_Symbol,
              Display_Order_Id,
              Ref_Customer_Id,
              Ref_Order_Manager_Id,
              Ref_Order_Store_Id,
              Order_Delivery_Time,
              Created_At,
              Saving_Price,
              Total_Price,
              actualpricediff,
              refunddata,
              Order_Delivery_Address,
              Order_Discount_Fare,
              Ref_Geo_Id,
              Payment_Type,
              Delivery_Fee,
              Bag_Fee,
              Ref_Cash_Back_Fee,
              Ref_Service_Fee,
              Managername,
              Manager_Email_Address,
              Manager_Mobile_Number
            );
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });
      }
      // ================  Store Master Collection ==================================
      function stores_master(
        data,
        Order_Currency_Symbol,
        Display_Order_Id,
        Ref_Customer_Id,
        Ref_Order_Manager_Id,
        Ref_Order_Store_Id,
        Order_Delivery_Time,
        Created_At,
        Saving_Price,
        Total_Price,
        actualpricediff,
        refunddata,
        Order_Delivery_Address,
        Order_Discount_Fare,
        Ref_Geo_Id,
        Payment_Type,
        Delivery_Fee,
        Bag_Fee,
        Ref_Cash_Back_Fee,
        Ref_Service_Fee,
        Managername,
        Manager_Email_Address,
        Manager_Mobile_Number
      ) {
        db.collection("Stores_Master")
          .doc(Ref_Order_Store_Id)
          .get()
          .then((storedoc) => {
            if (storedoc.exists) {
              var Store_Address = storedoc.data().Store_Address;
              var Store_Name = storedoc.data().Store_Name;

              customer_info(
                data,
                Order_Currency_Symbol,
                Display_Order_Id,
                Ref_Customer_Id,
                Ref_Order_Manager_Id,
                Ref_Order_Store_Id,
                Order_Delivery_Time,
                Created_At,
                Saving_Price,
                Total_Price,
                actualpricediff,
                refunddata,
                Order_Delivery_Address,
                Order_Discount_Fare,
                Ref_Geo_Id,
                Payment_Type,
                Delivery_Fee,
                Bag_Fee,
                Ref_Cash_Back_Fee,
                Ref_Service_Fee,
                Managername,
                Manager_Email_Address,
                Manager_Mobile_Number,
                Store_Address,
                Store_Name
              );
            }
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });
      }
      // ================  Customer_Shared_Information  ==================================

      function customer_info(
        data,
        Order_Currency_Symbol,
        Display_Order_Id,
        Ref_Customer_Id,
        Ref_Order_Manager_Id,
        Ref_Order_Store_Id,
        Order_Delivery_Time,
        Created_At,
        Saving_Price,
        Total_Price,
        actualpricediff,
        refunddata,
        Order_Delivery_Address,
        Order_Discount_Fare,
        Ref_Geo_Id,
        Payment_Type,
        Delivery_Fee,
        Bag_Fee,
        Ref_Cash_Back_Fee,
        Ref_Service_Fee,
        Managername,
        Manager_Email_Address,
        Manager_Mobile_Number,
        Store_Address,
        Store_Name
      ) {
        db.collection("Customer_Shared_Information")
          .doc(Ref_Customer_Id)
          .get()
          .then((customerdoc) => {
            if (customerdoc.exists) {
              var Customer_Email_Address =
                customerdoc.data().Customer_Email_Address;
              var Customer_First_Name = customerdoc.data().Customer_First_Name;
              var Customer_Last_Name = customerdoc.data().Customer_Last_Name;
              order_info(
                data,
                Order_Currency_Symbol,
                Display_Order_Id,
                Ref_Customer_Id,
                Ref_Order_Manager_Id,
                Ref_Order_Store_Id,
                Order_Delivery_Time,
                Created_At,
                Saving_Price,
                Total_Price,
                actualpricediff,
                refunddata,
                Order_Delivery_Address,
                Order_Discount_Fare,
                Ref_Geo_Id,
                Payment_Type,
                Delivery_Fee,
                Bag_Fee,
                Ref_Cash_Back_Fee,
                Ref_Service_Fee,
                Managername,
                Manager_Email_Address,
                Manager_Mobile_Number,
                Store_Address,
                Store_Name,
                Customer_Email_Address,
                Customer_First_Name,
                Customer_Last_Name
              );
            }
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });
      }
      // ================  Order_Details  ===================================================
      function order_info(
        data,
        Order_Currency_Symbol,
        Display_Order_Id,
        Ref_Customer_Id,
        Ref_Order_Manager_Id,
        Ref_Order_Store_Id,
        Order_Delivery_Time,
        Created_At,
        Saving_Price,
        Total_Price,
        actualpricediff,
        refunddata,
        Order_Delivery_Address,
        Order_Discount_Fare,
        Ref_Geo_Id,
        Payment_Type,
        Delivery_Fee,
        Bag_Fee,
        Ref_Cash_Back_Fee,
        Ref_Service_Fee,
        Managername,
        Manager_Email_Address,
        Manager_Mobile_Number,
        Store_Address,
        Store_Name,
        Customer_Email_Address,
        Customer_First_Name,
        Customer_Last_Name
      ) {
        var finalarray = [];
        var order_table_data = "";
        var order_table_data_htm = "";
        var order_size = 0;
        //var Ref_Promotion_Type_Id = "";
        var totalproductcount = 0;
        var promo_display_name = "";
        var totaldiscountprice = 0;
        var Ref_Products_Actual_Fare = "";
        var No_Of_Order = "";
        var Ref_Products_Display_Name = "";
        var Ref_Products_Is_Discounted = "";
        var Ref_Order_Detail_Status_Id = "";
        // var Ref_Products_Actual_Fare= ""
        db.collection("Order_Details")
          .where("Ref_Order_Id", "==", orderid)
          .get()
          .then((ordsnap) => {
            ordsnap.forEach((orderdetaildoc) => {
              var orderdetail = orderdetaildoc.data();
              finalarray.push(orderdetail);
            });
            finalarray.sort(
              (a, b) =>
                Number(a.Display_Order_Detail_Id) -
                Number(b.Display_Order_Detail_Id)
            );
            console.log("ascending finalarray", finalarray);
            for (var l = 0; l < finalarray.length; l++) {
              Ref_Order_Detail_Status_Id =
                finalarray[l]["Ref_Order_Detail_Status_Id"];

              if (
                Ref_Order_Detail_Status_Id ===
                  parseInt(referencevalue.ProductEdited) ||
                Ref_Order_Detail_Status_Id ===
                  parseInt(referencevalue.ProductReplaced) ||
                Ref_Order_Detail_Status_Id ===
                  parseInt(referencevalue.ProductCancelled)
              ) {
                // totalproductcount = totalproductcount + No_Of_Order
                console.log("edited");
                No_Of_Order = finalarray[l]["No_Of_Order"];
                Ref_Products_Display_Name =
                  finalarray[l]["Ref_Products_Display_Name"];
                Ref_Products_Is_Discounted =
                  finalarray[l]["Ref_Products_Is_Discounted"];
                var Ref_Promotion_Type_Id =
                  finalarray[l]["Ref_Promotion_Type_Id"];
                if (Ref_Promotion_Type_Id === 101) {
                  var color = "blue";
                } else if (Ref_Promotion_Type_Id === 201) {
                  color = "red";
                } else if (Ref_Promotion_Type_Id === 301) {
                  color = "green";
                } else {
                  color = "orange";
                }
                // if (Ref_Products_Is_Discounted === true) {
                //     Ref_Products_Actual_Fare = finalarray[l]['Ref_Products_Discounted_Price * No_Of_Order;
                // } else {
                //     Ref_Products_Actual_Fare = finalarray[l]['Ref_Products_Actual_Fare * No_Of_Order;
                // }
                totaldiscountprice = Total_Price;
                Ref_Products_Actual_Fare =
                  finalarray[l]["Ref_Order_Total_Fare"];
                promo_display_name = "";
                if (finalarray[l]["Premier_Promo_Applied"] === true) {
                  promo_display_name = finalarray[l]["Promo_Display_Name"];
                } else {
                  promo_display_name = "";
                }
                //  totaldiscountprice = totaldiscountprice + Ref_Products_Actual_Fare
                if (order_size === 0) {
                  order_table_data =
                    '<tr><td><span style="text-decoration:line-through;">' +
                    No_Of_Order +
                    'x</span></td><td><br/><span style="text-decoration:line-through;">' +
                    Ref_Products_Display_Name +
                    '</span><br><span style="text-decoration:line-through;color:' +
                    color +
                    '">' +
                    promo_display_name +
                    '</span></td><td style="text-align:right;"><span style="text-decoration:line-through;">' +
                    Order_Currency_Symbol +
                    " " +
                    Ref_Products_Actual_Fare.toFixed(2) +
                    "</span></td></tr>";
                } else {
                  order_table_data +=
                    '<tr><td><span style="text-decoration:line-through;">' +
                    No_Of_Order +
                    'x</span></td><td><br/><span style="text-decoration:line-through;">' +
                    Ref_Products_Display_Name +
                    '</span><br><span style="text-decoration:line-through;color:' +
                    color +
                    '">' +
                    promo_display_name +
                    '</span></td><td style="text-align:right;"><span style="text-decoration:line-through;">' +
                    Order_Currency_Symbol +
                    " " +
                    Ref_Products_Actual_Fare.toFixed(2) +
                    "</span></td></tr>";
                }
              } else if (
                Ref_Order_Detail_Status_Id ===
                  parseInt(referencevalue.ProductPlaced) ||
                Ref_Order_Detail_Status_Id ===
                  parseInt(referencevalue.ProductChanged) ||
                Ref_Order_Detail_Status_Id ===
                  parseInt(referencevalue.ProductAdded)
              ) {
                console.log("added");
                No_Of_Order = finalarray[l]["No_Of_Order"];
                totalproductcount =
                  parseInt(totalproductcount) + parseInt(No_Of_Order);
                Ref_Products_Display_Name =
                  finalarray[l]["Ref_Products_Display_Name"];
                Ref_Products_Is_Discounted =
                  finalarray[l]["Ref_Products_Is_Discounted"];
                // if (Ref_Products_Is_Discounted === true) {
                //     Ref_Products_Actual_Fare = finalarray[l]['Ref_Products_Discounted_Price * No_Of_Order;
                // } else {
                //     Ref_Products_Actual_Fare = finalarray[l]['Ref_Products_Actual_Fare * No_Of_Order;
                // }
                Ref_Promotion_Type_Id = finalarray[l]["Ref_Promotion_Type_Id"];
                if (Ref_Promotion_Type_Id === 101) {
                  color = "#4f81c7";
                } else if (Ref_Promotion_Type_Id === 201) {
                  color = "#D0021B";
                } else if (Ref_Promotion_Type_Id === 301) {
                  color = "#7ED321";
                } else {
                  color = "#F39C12";
                }
                //  totalproductcount = totalproductcount + No_Of_Order
                promo_display_name = "";
                if (finalarray[l]["Premier_Promo_Applied"] === true) {
                  promo_display_name = finalarray[l]["Promo_Display_Name"];
                } else {
                  promo_display_name = "";
                }
                Ref_Products_Actual_Fare =
                  finalarray[l]["Ref_Order_Total_Fare"];
                totaldiscountprice = Total_Price;
                if (order_size === 0) {
                  order_table_data_htm =
                    '<tr><td style="padding: 5px;">' +
                    No_Of_Order +
                    'x</td><td style="padding: 5px;">' +
                    Ref_Products_Display_Name +
                    '<br><span style="color:' +
                    color +
                    '">' +
                    promo_display_name +
                    '</span></td><td style="text-align: right;padding: 5px;width:100px;">' +
                    Order_Currency_Symbol +
                    " " +
                    Ref_Products_Actual_Fare.toFixed(2) +
                    "</td></tr>";
                  order_table_data =
                    "<tr><td>" +
                    No_Of_Order +
                    "x</td><td>" +
                    Ref_Products_Display_Name +
                    '<br><span style="color:' +
                    color +
                    '">' +
                    promo_display_name +
                    '</span></td><td style="text-align: right;">' +
                    Order_Currency_Symbol +
                    " " +
                    Ref_Products_Actual_Fare.toFixed(2) +
                    "</td></tr>";
                } else {
                  order_table_data_htm +=
                    '<tr><td style="padding: 5px;">' +
                    No_Of_Order +
                    'x</td><td style="padding: 5px;">' +
                    Ref_Products_Display_Name +
                    '<br><span style="color:' +
                    color +
                    '">' +
                    promo_display_name +
                    '</span></td><td style="text-align: right;padding: 5px;width:100px;">' +
                    Order_Currency_Symbol +
                    " " +
                    Ref_Products_Actual_Fare.toFixed(2) +
                    "</td></tr>";

                  order_table_data +=
                    "<tr><td>" +
                    No_Of_Order +
                    "x</td><td>" +
                    Ref_Products_Display_Name +
                    '<br><span style="color:' +
                    color +
                    '">' +
                    promo_display_name +
                    '</span></td><td style="text-align: right;">' +
                    Order_Currency_Symbol +
                    " " +
                    Ref_Products_Actual_Fare.toFixed(2) +
                    "</td></tr>";
                }
              }
              order_size++;
            }

            Total_Price =
              Total_Price +
              Delivery_Fee +
              Bag_Fee +
              Ref_Service_Fee +
              Ref_Cash_Back_Fee -
              Order_Discount_Fare;
            if (Order_Discount_Fare > 0) {
              Order_Discount_Fare = "-" + Order_Discount_Fare.toFixed(2);
            } else {
              Order_Discount_Fare = "0.00";
            }

            var obj_arr = {
              Store_Name: Store_Name,
              Payment_Type: Payment_Type,
              order_table_data_htm: order_table_data_htm,
              finalarray: finalarray,
              Ref_Order_Detail_Status_Id: Ref_Order_Detail_Status_Id,
              Saving_Price: Saving_Price.toFixed(2),
              Store_Mobile_Number: "+44" + Manager_Mobile_Number,
              Customer_Name: Customer_First_Name,
              Manager_Name: Managername,
              RefundAmount: refunddata,
              Order_Delivery_Time: Order_Delivery_Time,
              Display_Order_Id: Display_Order_Id,
              Created_At: Created_At,
              Order_Currency_Symbol: Order_Currency_Symbol,
              Total_Price: Total_Price.toFixed(2),
              Order_Delivery_Address: Order_Delivery_Address,
              Order_Details: order_table_data,
              TotalOrderPrice: totaldiscountprice.toFixed(2),
              Order_Discount_Fare: Order_Discount_Fare,
              Delivery_Fee: Delivery_Fee.toFixed(2),
              Bag_Fee: Bag_Fee.toFixed(2),
              Ref_Cash_Back_Fee: Ref_Cash_Back_Fee.toFixed(2),
              Ref_Service_Fee: Ref_Service_Fee.toFixed(2),
              Last4DigitNumber: "XXXX",
              TotalOrderCount: totalproductcount,
              Store_Address: Store_Address,
              Ref_Promotion_Type_Id: Ref_Promotion_Type_Id,
              promo_display_name: promo_display_name,
              Manager_Email_Address: Manager_Email_Address,
              Ref_Products_Is_Discounted: Ref_Products_Is_Discounted,
              Ref_Products_Display_Name: Ref_Products_Display_Name,
              No_Of_Order: No_Of_Order,
              Ref_Products_Actual_Fare: Ref_Products_Actual_Fare,
              orderid,
              Customer_Email_Address,
              data,
            };
            console.log(obj_arr);
            dispatch({
              type: GET_COMMON_SLOT,
              payload: obj_arr,
            });

            resolve(obj_arr);
            // return true
          })
          .catch((err) => {});
      }
    });
  };
}
//============ Slot ==========================================================//
export function slottable() {
  return (dispatch) => {
    db.collection("Slot_Master").onSnapshot(function (doc) {
      const catList = [];
      doc.forEach(function (doc) {
        catList.push({
          id: doc.id,
          sname: doc.data().Slot_Set_Name,
        });
      });
      // console.log("Current data: ", catList);
      dispatch({ type: GET_SLOT, payload: catList });
    });
  };
}

//============ City ==========================================================//

export function citytable() {
  return (dispatch) => {
    db.collection("Operating_City_Master").onSnapshot(function (doc) {
      const catList = [];
      doc.forEach(function (doc) {
        catList.push({
          id: doc.id,
          cname: doc.data().City_Name,
        });
      });
      // console.log("Current data: ", catList);
      dispatch({ type: GET_COMMON_SLOT, payload: catList });
    });
  };
}

export function promotable() {
  return (dispatch) => {
    db.collection("MD_Promotion_Type")
      .where("Is_Active", "==", true)
      .onSnapshot(function (doc) {
        const catList = [];
        doc.forEach(function (doc) {
          catList.push({
            key: doc.id,
            Promotion_Id: doc.data().Promotion_Id.toString(),
            promoname: doc.data().Promotion_Type,
          });
        });
        // console.log("Current data: ", catList);
        dispatch({ type: GET_COMMON_SLOT, payload: catList });
      });
  };
}

export function StoreAll() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var arr_driver_commission = [];
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
            console.log(bucket_baseurl);
          });
        });
      var outstandingdriverdocRef = db.collection(
        "Store_Outstanding_Commission_Master"
      );
      outstandingdriverdocRef
        .get()
        .then(function (outstandingdriverdoc) {
          if (outstandingdriverdoc.size !== 0) {
            outstandingdriverdoc.forEach(function (outstandingdriverdocs) {
              var Storeid = outstandingdriverdocs.id;

              var Store_Outstanding_Balance =
                outstandingdriverdocs.data().Store_Outstanding_Balance;
              var Manager_Currency_Symbol =
                outstandingdriverdocs.data().Manager_Currency_Symbol;

              arr_driver_commission.push({
                Storeid: Storeid,
                Store_Outstanding_Balance: Store_Outstanding_Balance,
                Manager_Currency_Symbol: Manager_Currency_Symbol,
              });
            });
          } else {
            //reject("error");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          reject(error);
        });

      db.collection("Stores_Master")
        .orderBy("Created_At", "desc")
        .get()
        .then((doc) => {
          const storeList = [];
          var snap_size = doc.size;
          if (snap_size !== 0) {
            doc.forEach(function (doc1) {
              var Storeid = doc1.data().Store_Id;
              // Driver Commission Status Check
              var Outstanding_Amount;
              var commission_status =
                arr_driver_commission[
                  arr_driver_commission
                    .map(function (item2) {
                      return item2.Storeid;
                    })
                    .indexOf(Storeid)
                ];
              console.log(commission_status);
              if (commission_status === undefined) {
                Outstanding_Amount = "--";
              } else {
                Outstanding_Amount =
                  commission_status.Manager_Currency_Symbol +
                  " " +
                  commission_status.Store_Outstanding_Balance;
              }

              var obj_arr = {
                key: doc1.id,
                store_name: doc1.data().Store_Name,
                address: doc1.data().Store_Address,
                messageone: doc1.data().Configuration_Message_One,
                messagetwo: doc1.data().Configuration_Message_Two,
                logo: bucket_baseurl + doc1.data().Store_Logo,
                status: doc1.data().IsActive.toString(),
                store_available_status: doc1
                  .data()
                  .Store_Availability_Status.toString(),
                create_time: doc1.data().Created_At,
                Outstanding_Amount: Outstanding_Amount,
              };

              storeList.push(obj_arr);
              var arr_length = storeList.length;
              console.log(arr_length);
              if (arr_length === snap_size) {
                dispatch({ type: GET_STORE, payload: storeList });
                resolve(storeList);
              }
            });
          } else {
            reject("No Data");
          }
        });
    });
  };
}

export function checkemail(value) {
  return (dispatch) => {
    console.log(value);

    return new Promise((resolve, reject) => {
      db.collection("Store_Manager")
        .where("email", "==", value)
        .get()
        .then(function (querySnapshot) {
          console.log(querySnapshot.empty);
          if (querySnapshot.empty === true) {
            resolve("NO");
            dispatch({ type: GET_SUCCESS, payload: "No such document!" });

            console.log("No such document!");
          } else {
            console.log("such document!");
            resolve("YES");
            dispatch({ type: GET_SUCCESS, payload: "Document Available!" });
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          reject(error);
        });
    });
  };
}

export function StoreAllRequest() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var arr_request_status = [];
      db.collection("MD_Store_Request_Status")
        .get()
        .then((order_status) => {
          order_status.forEach((doc_order_status) => {
            var statuskey = doc_order_status.data().Req_Type;
            var statusid = doc_order_status.data().Req_ID;
            var obj_order_status = {
              request_statusId: statusid,
              request_statuskey: statuskey,
            };
            arr_request_status.push(obj_order_status);
          });

          console.log(arr_request_status);

          get_allrequest(arr_request_status);
        })
        .catch((err) => {
          console.log("Error getting documents", err);
          reject(err);
        });
      function get_allrequest(arr_request_status) {
        var arr_order = [];

        db.collection("Store_Request")
          .orderBy("Created_At", "desc")
          .get()
          .then((snap_order) => {
            var snap_size = snap_order.size;
            if (snap_size !== 0) {
              snap_order.forEach((doc_order) => {
                var request_doc_id = doc_order.id;
                var request_status_id =
                  doc_order.data().Ref_MD_Store_Request_Status_Id;
                var manager_name = doc_order.data().Manager_Name;
                var manager_email = doc_order.data().Manager_Email_Address;
                var mobile = doc_order.data().Manager_Mobile_Number;
                var sname = doc_order.data().Manager_Store_Name;
                var address = doc_order.data().Manager_Store_Address;
                var create_time = moment(
                  doc_order.data().Created_At.toDate()
                ).format("MMMM Do YYYY, h:mm:ss a");

                //arr_order_status
                if (request_status_id) {
                  var selectedorder_status =
                    arr_request_status[
                      arr_request_status
                        .map(function (item1) {
                          return item1.request_statusId;
                        })
                        .indexOf(request_status_id)
                    ];
                  console.log(selectedorder_status, " - ", request_status_id);
                  var dir_order_status = selectedorder_status.request_statuskey;
                  console.log(dir_order_status);
                } else {
                  dir_order_status = "N/A";
                }

                var obj_arr = {
                  key: request_doc_id,
                  manager_name: manager_name,
                  manager_phone: mobile,
                  manager_email: manager_email,
                  address: address,
                  store_name: sname,
                  request_status: dir_order_status,
                  requeststatus_id: request_status_id,
                  request_date: create_time,
                };
                arr_order.push(obj_arr);
                var arr_length = arr_order.length;
                if (arr_length === snap_size) {
                  // console.log('response', arr_length + ' ' + snap_size)
                  console.log(arr_order);
                  dispatch({ type: GET_STORE, payload: arr_order });
                  resolve(arr_order);
                }
              });
            } else {
              reject("No Data");
            }
          });
      }
    });
  };
}

export function StoreDetail(reqid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Store_Request")
        .doc(reqid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            var obj_arr = {
              manager_name: doc.data().Manager_Name,
              manager_phone: doc.data().Manager_Mobile_Number,
              manager_email: doc.data().Manager_Email_Address,
              store_name: doc.data().Manager_Store_Name,
              Manager_Store_Address: doc.data().Manager_Store_Address,
            };
            dispatch({ type: GET_STORE_DETAIL, payload: obj_arr });
            resolve(obj_arr);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
    });
  };
}

export function StoreEdit(reqid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Stores_Master")
        .doc(reqid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            var Configuration_Message_One =
              doc.data().Configuration_Message_One;
            var Configuration_Message_Two =
              doc.data().Configuration_Message_Two;
            var Maximum_Replaceable_Amount =
              doc.data().Maximum_Replaceable_Amount;
            var Ref_Operating_City_Id = doc.data().Ref_Operating_City_Id;
            var Ref_Slot_Time = doc.data().Ref_Slot_Time.toString();
            var Store_Address = doc.data().Store_Address;
            var Store_Availability_Status = doc
              .data()
              .Store_Availability_Status.toString();
            var Status = doc.data().IsActive.toString();
            var Store_Name = doc.data().Store_Name;
            var Store_Support_Email = doc.data().Store_Support_Email;
            var Store_Id = doc.data().Store_Id;
            var Store_Logo = doc.data().Store_Logo;
            var Category_18_List = doc.data().Category_18_List;
            var Exclude_Min_Order_Category_List =
              doc.data().Exclude_Min_Order_Category_List;
            var Manager_Mobile_Number;
            var Manager_Email_Address;
            var Manager_Name;
            var Delivery_Fee = doc.data().Delivery_Fee.toFixed(2);
            var Bag_Fee = doc.data().Bag_Fee.toFixed(2);
            var Min_Order_Amount = doc.data().Min_Order_Amount.toFixed(2);
            var Stores_Master_Commission_Applicable = doc
              .data()
              .Stores_Master_Commission_Applicable.toString();
            var Stores_Commission_Percentage =
              doc.data().Stores_Commission_Percentage;
            var Store_Short_Code = doc.data().Store_Short_Code;
            var Blocked_Postal_code = doc.data().Blocked_Postal_code;
            var Happy_Hour_Id = doc.data().Happy_Hour_Id;
            // var Activation_Date = doc
            //   .data()
            //   .Happy_Hour_Unix_Start_Time.toString();
            // var Expiration_Date = doc
            //   .data()
            //   .Happy_Hour_Unix_End_Time.toString();
            var ActivationdateString = moment().format("YYYY-MM-DD HH:mm:ss");
            var actmomentObj = moment(
              ActivationdateString,
              "YYYY-MM-DD HH:mm:ss"
            );
            var ExpirationdateString = moment().format("YYYY-MM-DD HH:mm:ss");
            var expmomentObj = moment(
              ExpirationdateString,
              "YYYY-MM-DD HH:mm:ss"
            );
            var Is_Offer_Opened = doc.data().Is_Offer_Opened.toString();
            var Is_Offer_Closed = doc.data().Is_Offer_Closed.toString();
            var Is_Happy_Hour_Enabled_Today = doc
              .data()
              .Is_Happy_Hour_Enabled_Today.toString();
            var Static_KM = doc.data().Static_KM;
            var Static_Milli_KM = doc.data().Static_Milli_KM;
            var Delivery_Distance = doc.data().Delivery_Distance;
            var Phone_number = doc.data().Store_Number;
            var Store_Serviceable_Status = doc
              .data()
              .Store_Serviceable_Status.toString();
            var Store_Alert_Message = doc.data().Store_Alert_Message;
            var Order_Time = doc.data().Order_Time;
            var Order_Start_Time = doc.data().Order_Start_Time.toString();
            var Is_Static_Delivery_Price_Enabled = doc
              .data()
              .Is_Static_Delivery_Price_Enabled.toString();
            var Is_Static_Min_Order_Amount_Enabled = doc
              .data()
              .Is_Static_Min_Order_Amount_Enabled.toString();
            var Service_Type = doc.data().Service_Type.toString();
            var Service_Value = doc.data().Service_Value;
            var Static_Min_Order_Amount = doc.data().Static_Min_Order_Amount;
            var Static_Delivery_Price = doc.data().Static_Delivery_Price;
            var Dynamic_Min_Order_Amount = doc.data().Dynamic_Min_Order_Amount;
            var Dynamic_Delivery_Price = doc.data().Dynamic_Delivery_Price;
            var Cash_Back_Fee = doc.data().Cash_Back_Fee;
            var Cash_Back_Category = doc.data().Cash_Back_Category;
            db.collection("Store_Manager")
              .where("Ref_Store_Id", "==", Store_Id)
              .get()
              .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc1) {
                  // doc.data() is never undefined for query doc snapshots console.log(doc1.id, "
                  // => ", doc1.data());
                  Manager_Mobile_Number = doc1.data().Manager_Mobile_Number;
                  Manager_Email_Address = doc1.data().Manager_Email_Address;
                  Manager_Name = doc1.data().Manager_Name;
                });
                var obj_arr = {
                  Dynamic_Min_Order_Amount,
                  Dynamic_Delivery_Price,
                  Is_Static_Delivery_Price_Enabled,
                  Is_Static_Min_Order_Amount_Enabled,
                  Service_Type,
                  Service_Value,
                  Static_Min_Order_Amount,
                  Static_Delivery_Price,
                  Configuration_Message_One: Configuration_Message_One,
                  Configuration_Message_Two: Configuration_Message_Two,
                  Maximum_Replaceable_Amount: Maximum_Replaceable_Amount,
                  Ref_Operating_City_Id: Ref_Operating_City_Id,
                  Ref_Slot_Time: Ref_Slot_Time,
                  Store_Address: Store_Address,
                  Store_Availability_Status: Store_Availability_Status,
                  Status: Status,
                  Store_Name: Store_Name,
                  Store_Support_Email: Store_Support_Email,
                  Manager_Mobile_Number: Manager_Mobile_Number,
                  Manager_Email_Address: Manager_Email_Address,
                  Manager_Name: Manager_Name,
                  Store_Logo: bucket_baseurl + Store_Logo,
                  Stores_Master_Commission_Applicable:
                    Stores_Master_Commission_Applicable,
                  Stores_Commission_Percentage: Stores_Commission_Percentage,
                  Category_18_List: Category_18_List,
                  Exclude_Min_Order_Category_List:
                    Exclude_Min_Order_Category_List,
                  Delivery_Fee,
                  Min_Order_Amount,
                  Bag_Fee,
                  Store_Short_Code,
                  Blocked_Postal_code,
                  Happy_Hour_Id,
                  Happy_Hour_Unix_End_Time: expmomentObj,
                  Happy_Hour_Unix_Start_Time: actmomentObj,
                  Is_Happy_Hour_Enabled_Today,
                  Static_KM,
                  Static_Milli_KM,
                  Delivery_Distance,
                  Phone_number,
                  Is_Offer_Opened,
                  Is_Offer_Closed,
                  Store_Serviceable_Status,
                  Store_Alert_Message,
                  Order_Time,
                  Order_Start_Time,
                  Cash_Back_Category,
                  Cash_Back_Fee,
                };
                dispatch({ type: GET_STORE_DETAIL, payload: obj_arr });
                resolve(obj_arr);
              })
              .catch(function (error) {
                console.log("Error getting documents: ", error);
              });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    });
  };
}

export function SingleManager(pid) {
  return (dispatch) => {
    var docRef = db.collection("Store_Manager").doc(pid);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          // console.log("Document data:", doc.data());
          dispatch({
            type: GET_COMMON_SLOT,
            payload: doc.data(),
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };
}

export function AuthFailure(pid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = db.collection("Store_Manager").doc(pid);
      docRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            // console.log("Document data:", doc.data());
            var Manager_Email_Address = doc.data().Manager_Email_Address;
            var Ref_Store_Id = doc.data().Ref_Store_Id;

            db.collection("Store_Manager")
              .doc(pid)
              .delete()
              .then(function () {
                console.log("Document successfully deleted!");

                db.collection("Stores_Master")
                  .doc(Ref_Store_Id)
                  .delete()
                  .then(function () {
                    console.log("Document successfully deleted!");

                    db.collection("Store_Request")
                      .where(
                        "Manager_Email_Address",
                        "==",
                        Manager_Email_Address
                      )
                      .get()
                      .then(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                          // doc.data() is never undefined for query doc snapshots
                          console.log(doc.id, " => ", doc.data());
                          db.collection("Store_Request")
                            .doc(doc.id)
                            .update({ Ref_MD_Store_Request_Status_Id: 3 })
                            .then((result) => {
                              resolve(result);
                            })
                            .catch((err) => {
                              reject(err);
                            });
                        });
                      })
                      .catch(function (error) {
                        console.log("Error getting documents: ", error);
                        reject(error);
                      });
                  })
                  .catch(function (error) {
                    console.error("Error removing document: ", error);
                    reject(error);
                  });
              })
              .catch(function (error) {
                console.error("Error removing document: ", error);
                reject(error);
              });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          reject(error);
        });
    });
  };
}

export function cityCheck(value) {
  return (dispatch) => {
    console.log("Citycheck", value);

    return new Promise((resolve, reject) => {
      if (value === undefined) {
        console.log("Empty Close");
      } else {
        db.collection("Operating_City_Master")
          .where("City_Name", "==", value)
          .get()
          .then(function (querySnapshot) {
            console.log(querySnapshot.empty);
            if (querySnapshot.empty === true) {
              reject("NO");
              dispatch({ type: GET_SUCCESS, payload: "No such document!" });

              // console.log("No such document!");
            } else {
              querySnapshot.forEach(function (doc) {
                // console.log(doc.id, ' => ', doc.data()); console.log("such document!");
                resolve(doc.id);
                dispatch({ type: GET_SUCCESS, payload: "Document Available!" });
              });
            }
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
            reject(error);
          });
      }
    });
  };
}

//============================= Get all notification ========================= //

export function Allnotification() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var store_arr = [];
      db.collection("Stores_Master")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var obj_arr = {
              key: doc.id,
              store_name: doc.data().Store_Name,
            };
            store_arr.push(obj_arr);
          });
          get_allrequest();
        });

      function get_allrequest() {
        var arr_order = [];

        db.collection("Send_Push_Notification")
          .orderBy("Created_At", "desc")
          .limit(40)
          .get()
          .then((snap_order) => {
            var snap_size = snap_order.size;
            if (snap_size !== 0) {
              snap_order.forEach((doc_order) => {
                var coupon_doc_id = doc_order.id;
                var description = doc_order.data().description;
                var image = doc_order.data().image;
                var type = doc_order.data().type;
                var topic = doc_order.data().topic;
                var create_time = moment(
                  doc_order.data().Created_At.toDate()
                ).format("MMMM Do YYYY, h:mm:ss a");
                //arr_order_status
                if (topic) {
                  var selectedorder_status =
                    store_arr[
                      store_arr
                        .map(function (item1) {
                          return item1.key;
                        })
                        .indexOf(topic)
                    ];
                  console.log(selectedorder_status, " - ", topic);
                  var dir_order_status = selectedorder_status.store_name;
                  console.log(dir_order_status);
                } else {
                  dir_order_status = "-";
                }

                var obj_arr = {
                  key: coupon_doc_id,
                  description: description,
                  image: image,
                  type: type,
                  topic: dir_order_status,
                  create_time: create_time,
                };
                arr_order.push(obj_arr);
                var arr_length = arr_order.length;
                if (arr_length === snap_size) {
                  // console.log('response', arr_length + ' ' + snap_size)
                  console.log(arr_order);
                  dispatch({ type: GET_COMMON_SLOT, payload: arr_order });
                  resolve(arr_order);
                }
              });
            } else {
              reject("No Data");
            }
          });
      }
    });
  };
}
//============================= Get all coupon code ========================= //

export function Allcoupon() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var arr_request_status = [];
      var store_arr = [];
      db.collection("Stores_Master")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var obj_arr = {
              key: doc.id,
              store_name: doc.data().Store_Name,
            };
            store_arr.push(obj_arr);
          });
          get_coupon_md();
        });

      function get_coupon_md() {
        db.collection("MD_Coupon")
          .get()
          .then((order_status) => {
            order_status.forEach((doc_order_status) => {
              var statuskey = doc_order_status.data().Coupon_Status;
              var statusid = doc_order_status.data().Coupon_Value;
              var obj_order_status = {
                order_statusId: statusid,
                order_statuskey: statuskey,
              };
              arr_request_status.push(obj_order_status);
            });

            console.log(arr_request_status);

            get_allrequest(arr_request_status);
          })
          .catch((err) => {
            console.log("Error getting documents", err);
            reject(err);
          });
      }

      function get_allrequest(arr_request_status) {
        var arr_order = [];

        db.collection("Coupons_Master")
          .orderBy("Created_At", "desc")
          .get()
          .then((snap_order) => {
            var snap_size = snap_order.size;
            if (snap_size !== 0) {
              snap_order.forEach((doc_order) => {
                var coupon_doc_id = doc_order.id;
                var coupon_code = doc_order.data().Coupon_Code;
                var discount_value = doc_order.data().Coupon_Discount_Value;
                var activation_date = moment
                  .unix(doc_order.data().Coupon_Activation_Date)
                  .format("MM/DD/YYYY, h:mm:ss a");
                var expired_date = moment
                  .unix(doc_order.data().Coupon_Expiration_Date)
                  .format("MM/DD/YYYY, h:mm:ss a");
                var usage_limit = doc_order.data().Coupon_Per_User_Limit;
                var Coupon_Discount_Type =
                  doc_order.data().Coupon_Discount_Type;
                var coupon_status = doc_order.data().Coupon_Status;
                var Ref_Store_Id = doc_order.data().Ref_Store_Id;
                console.log(Ref_Store_Id);

                //arr_order_status
                if (Ref_Store_Id) {
                  var selectedorder_status1 =
                    store_arr[
                      store_arr
                        .map(function (item1) {
                          return item1.key;
                        })
                        .indexOf(Ref_Store_Id)
                    ];
                  console.log(selectedorder_status1, " - ", Ref_Store_Id);
                  var dir_order_status1 = selectedorder_status1.store_name;
                  console.log(dir_order_status1);
                } else {
                  dir_order_status1 = "-";
                }

                //arr_order_status
                if (Coupon_Discount_Type) {
                  var selectedorder_status =
                    arr_request_status[
                      arr_request_status
                        .map(function (item1) {
                          return item1.order_statusId;
                        })
                        .indexOf(Coupon_Discount_Type)
                    ];
                  console.log(
                    selectedorder_status,
                    " - ",
                    Coupon_Discount_Type
                  );
                  var dir_order_status = selectedorder_status.order_statuskey;
                  console.log(dir_order_status);
                } else {
                  dir_order_status = "N/A";
                }

                // Coupon Status
                if (coupon_status) {
                  var selectedcoupn_status =
                    arr_request_status[
                      arr_request_status
                        .map(function (item1) {
                          return item1.order_statusId;
                        })
                        .indexOf(coupon_status)
                    ];
                  console.log(selectedcoupn_status, " - ", coupon_status);
                  var coupon_status_new = selectedcoupn_status.order_statuskey;
                  console.log(coupon_status_new);
                } else {
                  coupon_status = "N/A";
                }

                var obj_arr = {
                  key: coupon_doc_id,
                  Ref_Store_Id:
                    dir_order_status1 === undefined ? "" : dir_order_status1,
                  coupon_code: coupon_code,
                  discount_value: discount_value,
                  activation_date: activation_date,
                  expired_date: expired_date,
                  usage_limit: usage_limit,
                  discount_type: dir_order_status,
                  coupon_status: coupon_status_new,
                };
                arr_order.push(obj_arr);
                var arr_length = arr_order.length;
                if (arr_length === snap_size) {
                  // console.log('response', arr_length + ' ' + snap_size)
                  console.log(arr_order);
                  dispatch({ type: GET_COMMON_SLOT, payload: arr_order });
                  resolve(arr_order);
                }
              });
            } else {
              reject("No Data");
            }
          });
      }
    });
  };
}

//============================== Edit Coupon ================================ //
export function SingleCoupon(pid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = db.collection("Coupons_Master").doc(pid);
      docRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            var Ref_Store_Id = doc.data().Ref_Store_Id;
            var Activation_Date = doc.data().Coupon_Activation_Date.toString();
            var Expiration_Date = doc.data().Coupon_Expiration_Date.toString();
            var ActivationdateString = moment
              .unix(Activation_Date)
              .format("YYYY-MM-DD HH:mm:ss");
            var actmomentObj = moment(
              ActivationdateString,
              "YYYY-MM-DD HH:mm:ss"
            );
            var ExpirationdateString = moment
              .unix(Expiration_Date)
              .format("YYYY-MM-DD HH:mm:ss");
            var expmomentObj = moment(
              ExpirationdateString,
              "YYYY-MM-DD HH:mm:ss"
            );

            var obj_arr = {
              Coupon_Activation_Date: actmomentObj,
              Coupon_Ceiling_Limit_Applicable: doc
                .data()
                .Coupon_Ceiling_Limit_Applicable.toString(),
              Coupon_Ceiling_Limit_Value: doc.data().Coupon_Ceiling_Limit_Value,
              Coupon_Code: doc.data().Coupon_Code,
              Coupon_Description: doc.data().Coupon_Description,
              Coupon_Discount_Type: doc.data().Coupon_Discount_Type.toString(),
              Coupon_Discount_Value: doc.data().Coupon_Discount_Value,
              Coupon_Expiration_Date: expmomentObj,
              Coupon_No_Of_Usage: doc.data().Coupon_No_Of_Usage,
              Coupon_Per_User_Limit: doc.data().Coupon_Per_User_Limit,
              Coupon_Status: doc.data().Coupon_Status.toString(),
              Coupon_Title: doc.data().Coupon_Title,
              Coupon_Type: doc.data().Coupon_Type.toString(),
              Coupon_Usage_Limit_Applicable: doc
                .data()
                .Coupon_Usage_Limit_Applicable.toString(),
              Coupon_Usage_Limit_Value: doc.data().Coupon_Usage_Limit_Value,
              Ref_Operating_City_Id: doc.data().Ref_Operating_City_Id,
              Ref_Store_Id: Ref_Store_Id,
            };
            dispatch({ type: GET_COUPON, payload: obj_arr });
            resolve(obj_arr);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            reject("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          reject(error);
        });
    });
  };
}

//================================= Update Discount =========================//

export function UpdateDis(couponid, dis) {
  return (dispatch) => {
    console.log(dis.ceiling_limit, dis.ceiling_limit_value);
    return new Promise((resolve, reject) => {
      var washingtonRef = db.collection("Coupons_Master").doc(couponid);

      // Set the "capital" field of the city 'DC'
      return washingtonRef
        .update({
          Coupon_Activation_Date: parseInt(
            moment(dis.activation_date).format("X")
          ),
          Coupon_Ceiling_Limit_Applicable: dis.ceiling_limit === "true",
          Coupon_Ceiling_Limit_Value:
            dis.ceiling_limit_value === undefined ? 0 : dis.ceiling_limit_value,
          Coupon_Code: dis.coupon_code.toString(),
          Coupon_Description: dis.coupon_description,
          Coupon_Discount_Type: parseInt(dis.discount_type),
          Coupon_Discount_Value: parseInt(dis.discount_value),
          Coupon_Expiration_Date: parseInt(
            moment(dis.expiration_date).format("X")
          ),
          Coupon_Per_User_Limit: parseInt(dis.Coupon_Per_User_Limit),
          Coupon_Status: parseInt(dis.coupon_status),
          Coupon_Title: dis.coupon_Title,
          Coupon_Usage_Limit_Applicable: dis.usage_limit === "true",
          Coupon_Usage_Limit_Value: dis.usage_limit_value,
          Ref_Operating_City_Id: dis.operating_city,
          Coupon_Type: parseInt(dis.coupon_type),
          Ref_Store_Id: dis.store_name,
          Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function () {
          console.log("Document successfully updated!");
          resolve("Document successfully updated!");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          reject(error);
        });
    });
  };
}

// ================= Store Settle Up
// =======================================================//

export function StoreSettleUp(drid, outamount, admin_user) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var Settledup = "SettledUp";
      var adminID = admin_user;
      var id = drid;
      var cretated_at = firebase.firestore.FieldValue.serverTimestamp();
      var OutstandingAmt = outamount;
      var OutstandingAmt_ary = OutstandingAmt.split(" ");
      var OutstandingAmount = parseFloat(OutstandingAmt_ary[1]);
      console.log(
        "OutstandingAmount",
        OutstandingAmount,
        "OutstandingAmt",
        OutstandingAmt
      );
      var Outstandings = 0;
      db.collection("Store_Order_Commission")
        .where("Ref_Order_Store_Id", "==", id)
        .where("Ref_Store_Settleup_Id", "==", "")
        .get()
        .then((snapshot) => {
          if (snapshot.size !== 0) {
            console.log("snapshot_size", snapshot.size);
            var length = 1;
            snapshot.forEach((doc) => {
              var Outstanding = parseFloat(doc.data().Outstanding);
              console.log("snapshot.size", snapshot.size);
              if (length === 1) {
                console.log("before size 1", Outstanding);
                Outstandings = Outstanding;
              } else {
                console.log("before", Outstanding);
                Outstandings += Outstanding;
              }
              console.log("after", Outstandings);
              length++;
            });
            // Outstanding = 0; OutstandingAmount = 0;
            if (Outstandings === OutstandingAmount) {
              console.log("Outstanding equals", Outstandings);
              var data_outstanding = {
                Created_At: cretated_at,
                Ref_Order_Store_Id: id,
                Ref_admin_Id: adminID,
                OutstandingAmount_settledup: OutstandingAmount,
                status: Settledup,
              };
              console.log(data_outstanding);
              db.collection("Store_Settleup_Logs")
                .add(data_outstanding)
                .then((ref_log) => {
                  var driver_log_autoid = ref_log.id;
                  db.collection("Store_Settleup_Logs")
                    .doc(driver_log_autoid)
                    .get()
                    .then(function (docRef_log) {
                      var ref_settledupdate = docRef_log.data().Created_At;
                      db.collection("Store_Outstanding_Commission_Master")
                        .doc(id)
                        .update({
                          Store_Outstanding_Balance: 0,
                          Updated_At: cretated_at,
                          Settledup_Date: ref_settledupdate,
                        })
                        .then(function (docRef) {
                          db.collection("Store_Order_Commission")
                            .where("Ref_Order_Store_Id", "==", id)
                            .where("Ref_Store_Settleup_Id", "==", "")
                            .get()
                            .then((snapshot1) => {
                              if (snapshot1.size !== 0) {
                                snapshot1.forEach((doc) => {
                                  var autoid_drivercommision = doc.id;
                                  db.collection("Store_Order_Commission")
                                    .doc(autoid_drivercommision)
                                    .update({
                                      Ref_Store_Settleup_Id: driver_log_autoid,
                                      Outstanding: 0,
                                    });
                                  console.log(
                                    "Store_Order_Commission for driverid: ",
                                    id,
                                    autoid_drivercommision
                                  );
                                });
                                resolve("Settledup successfully.");
                              } else {
                                //console.error("Error document: ", error);
                                reject(
                                  "No data in Store_Order_Commission collection"
                                );
                              }
                            })
                            .catch(function (error) {
                              console.error("Error document: ", error);
                              reject(error);
                            });
                        })
                        .catch(function (error) {
                          console.error("Error document: ", error);
                          reject(error);
                        });
                    })
                    .catch(function (error) {
                      console.error("Error document: ", error);
                      reject(error);
                    });
                })
                .catch(function (error) {
                  console.error("Error message: ", error);
                  reject(error);
                });
            } else {
              reject("Mismatch in outstanding amount.");
            }
          } else {
            reject("No Outstanding Balance Available For Driver..!");
          }
        })
        .catch(function (error) {
          console.error("Error message", error);
          reject(error);
        });
    });
  };
}

//====================== Add promo ======================================= //

export function addPromo(
  promo,
  fileList,
  fileList1,
  product,
  dealpro,
  username
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var Currency_Symbol;
      db.collection("MD_Currency")
        .doc(promo.Ref_Currency_Id)
        .onSnapshot((doc) => {
          //console.log(doc.data().Currency_Symbol);

          Currency_Symbol = doc.data().Currency_Symbol;
        });
      var globalarray = [];
      var promoname;
      let id2 = random("lowernumeric");
      if (promo.promo_type === "101") {
        promoname = "buyonegetone";
      } else if (promo.promo_type === "201") {
        promoname = "mixandmatch";
      } else if (promo.promo_type === "501") {
        promoname = "discountoffer";
      } else {
        promoname = "dealoffer";
      }
      fileList.forEach((f) => {
        // console.log(f);
        storage
          .ref(
            promo.Ref_Store_Id +
              "/Promo_Images/PromoAdditional/" +
              promoname +
              "_" +
              id2 +
              f.name
          )
          .put(f)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              globalarray.push(snapshot.ref.fullPath);
              if (fileList.length === globalarray.length) {
                fileList1.forEach((f) => {
                  // console.log(f);
                  storage
                    .ref(
                      promo.Ref_Store_Id +
                        "/Promo_Images/Banner/" +
                        promoname +
                        "_" +
                        id2 +
                        f.name
                    )
                    .put(f)
                    .then(function (snapshot) {
                      snapshot.ref
                        .getDownloadURL()
                        .then(function (downloadURL) {
                          const thumbImage = snapshot.ref.fullPath;
                          let dataobj;
                          if (promo.promo_type === "101") {
                            dataobj = {
                              Buy_Promo_Qty: 1,
                              Free_Promo_Qty: 1,
                              Promo_Price: parseFloat(promo.promo_price),
                              From_Date: parseInt(
                                moment(promo.activation_date).format("X")
                              ),
                              Promo_Status: 1,
                              Promo_Title: "Buy 1 Get 1",
                              Ref_Promo_Type_Id: parseInt(promo.promo_type),
                              To_Date: parseInt(
                                moment(promo.expiration_date).format("X")
                              ),
                              Banner_image: thumbImage,
                              Product_Detail: product,
                              ProductGroup: [],
                              Promo_Images: globalarray,
                              Display_Name: "Buy 1 Get 1",
                              Group_Name: "Buy 1 Get 1",
                              Max_Selection_Count: 1,
                              Min_Selection_Count: 1,
                              Selection_Count: 1,
                              Promo_Description: promo.Promo_Description,
                              Ref_Currency_Id: promo.Ref_Currency_Id,
                              StoreID: promo.Ref_Store_Id,
                              Promo_Display_Order: parseInt(
                                promo.Promo_Display_Order
                              ),
                              Updated_By:
                                username === undefined ? "" : username,
                            };
                          } else if (promo.promo_type === "201") {
                            dataobj = {
                              Buy_Promo_Qty: parseInt(promo.buy_quantity),
                              Free_Promo_Qty: 1,
                              Promo_Price: parseFloat(promo.promo_price),
                              From_Date: parseInt(
                                moment(promo.activation_date).format("X")
                              ),
                              Promo_Status: 1,
                              Promo_Title:
                                "Any " +
                                promo.buy_quantity +
                                " For " +
                                Currency_Symbol +
                                " " +
                                parseFloat(
                                  Math.round(promo.promo_price * 100) / 100
                                ).toFixed(2),
                              Ref_Promo_Type_Id: parseInt(promo.promo_type),
                              To_Date: parseInt(
                                moment(promo.expiration_date).format("X")
                              ),
                              Banner_image: thumbImage,
                              Product_Detail: product,
                              ProductGroup: [],
                              Promo_Images: globalarray,
                              Display_Name: "Mix and Match",
                              Group_Name:
                                "Any " +
                                promo.buy_quantity +
                                " For " +
                                Currency_Symbol +
                                " " +
                                parseFloat(
                                  Math.round(promo.promo_price * 100) / 100
                                ).toFixed(2),
                              Max_Selection_Count: 1,
                              Min_Selection_Count: 1,
                              Selection_Count: 1,
                              Promo_Description: promo.Promo_Description,
                              Ref_Currency_Id: promo.Ref_Currency_Id,
                              StoreID: promo.Ref_Store_Id,
                              Promo_Display_Order: parseInt(
                                promo.Promo_Display_Order
                              ),
                              Updated_By:
                                username === undefined ? "" : username,
                            };
                          } else if (promo.promo_type === "501") {
                            dataobj = {
                              Buy_Promo_Qty: 0,
                              Free_Promo_Qty: 0,
                              Promo_Price: 0,
                              From_Date: parseInt(
                                moment(promo.activation_date).format("X")
                              ),
                              Promo_Status: 1,
                              Promo_Title: promo.Offer_Name,
                              Ref_Promo_Type_Id: parseInt(promo.promo_type),
                              To_Date: parseInt(
                                moment(promo.expiration_date).format("X")
                              ),
                              Banner_image: thumbImage,
                              Product_Detail: product,
                              ProductGroup: [],
                              Promo_Images: globalarray,
                              Display_Name: promo.Offer_Name,
                              Group_Name: promo.Offer_Name,
                              Max_Selection_Count: 0,
                              Min_Selection_Count: 0,
                              Selection_Count: 0,
                              Promo_Description: promo.Promo_Description,
                              Ref_Currency_Id: promo.Ref_Currency_Id,
                              StoreID: promo.Ref_Store_Id,
                              Promo_Display_Order: parseInt(
                                promo.Promo_Display_Order
                              ),
                              Updated_By:
                                username === undefined ? "" : username,
                            };
                          } else {
                            dataobj = {
                              Buy_Promo_Qty: 1,
                              Free_Promo_Qty: 1,
                              Promo_Price: parseFloat(promo.promo_price),
                              From_Date: parseInt(
                                moment(promo.activation_date).format("X")
                              ),
                              Promo_Status: 1,
                              Promo_Title: promo.promo_title,
                              Ref_Promo_Type_Id: parseInt(promo.promo_type),
                              To_Date: parseInt(
                                moment(promo.expiration_date).format("X")
                              ),
                              Banner_image: thumbImage,
                              ProductGroup: dealpro,
                              Product_Detail: [],
                              Promo_Images: globalarray,
                              Display_Name: "",
                              Group_Name: "",
                              Max_Selection_Count: 1,
                              Min_Selection_Count: 1,
                              Selection_Count: 1,
                              Promo_Description: promo.Promo_Description,
                              Ref_Currency_Id: promo.Ref_Currency_Id,
                              StoreID: promo.Ref_Store_Id,
                              Promo_Display_Order: parseInt(
                                promo.Promo_Display_Order
                              ),
                              Updated_By:
                                username === undefined ? "" : username,
                            };
                          }

                          console.log(dataobj);

                          request
                            .post(Dev_URL + "/addpromonew")
                            .send(dataobj)
                            .then((res) => {
                              console.log(res.body);
                              if (res.body.ReturnCode === 200) {
                                resolve(res.body);
                              } else {
                                reject(res.body);
                              }
                            })
                            .catch((err) => {
                              reject(err);
                            });
                        });
                    });
                });
              }
            });
          });
      });
    });
  };
}

// =================================== Update promo
// ===================================
export function UpdatePromo(
  promo,
  fileList,
  fileList1,
  product,
  promodet,
  promoid,
  dealpro,
  username
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(promo);
      var promoname;
      let id2 = random("lowernumeric");
      if (promo.promo_type === "101") {
        promoname = "buyonegetone";
      } else if (promo.promo_type === "201") {
        promoname = "mixandmatch";
      } else {
        promoname = "dealoffer";
      }
      _.remove(fileList, function (n) {
        return n.status === "done";
      });
      var filelist1_check = Array.isArray(fileList1);
      var globalarray = [];
      console.log(filelist1_check, promoid);

      if (fileList.length !== 0 && filelist1_check === true) {
        console.log(fileList);
        console.log(fileList1);
        fileList.forEach((f) => {
          // console.log(f);
          storage
            .ref(
              promo.Ref_Store_Id +
                "/Promo_Images/PromoAdditional/" +
                promoname +
                "_" +
                id2 +
                f.name
            )
            .put(f)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                globalarray.push(snapshot.ref.fullPath);
                if (fileList.length === globalarray.length) {
                  fileList1.forEach((f) => {
                    // console.log(f);
                    storage
                      .ref(
                        promo.Ref_Store_Id +
                          "/Promo_Images/Banner/" +
                          promoname +
                          "_" +
                          id2 +
                          f.name
                      )
                      .put(f)
                      .then(function (snapshot) {
                        snapshot.ref
                          .getDownloadURL()
                          .then(function (downloadURL) {
                            const thumbImage = snapshot.ref.fullPath;
                            let dataobj;
                            if (promo.promo_type === "101") {
                              dataobj = {
                                PromoID: promoid,
                                Buy_Promo_Qty: 1,
                                Free_Promo_Qty: 1,
                                Promo_Price: parseFloat(promo.promo_price),
                                From_Date: parseInt(
                                  moment(promo.activation_date).format("X")
                                ),
                                Promo_Status: promodet.Promo_Status,
                                Promo_Title: "Buy 1 Get 1",
                                Ref_Promo_Type_Id: parseInt(promo.promo_type),
                                To_Date: parseInt(
                                  moment(promo.expiration_date).format("X")
                                ),
                                Banner_image: thumbImage,
                                Product_Detail: product,
                                Promo_Images: globalarray,
                                Display_Name: "Buy 1 Get 1",
                                Group_Name: "Buy 1 Get 1",
                                Max_Selection_Count: 1,
                                Min_Selection_Count: 1,
                                Selection_Count: 1,
                                Promo_Description: promo.Promo_Description,
                                Ref_Currency_Id: promo.Ref_Currency_Id,
                                Group_Id: promodet.Group_Id,
                                StoreID: promo.Ref_Store_Id,
                                Promo_Display_Order: parseInt(
                                  promo.Promo_Display_Order
                                ),
                                Updated_By:
                                  username === undefined ? "" : username,
                              };
                            } else if (promo.promo_type === "201") {
                              dataobj = {
                                PromoID: promoid,
                                Buy_Promo_Qty: parseInt(promo.buy_quantity),
                                Free_Promo_Qty: 1,
                                Promo_Price: parseFloat(promo.promo_price),
                                From_Date: parseInt(
                                  moment(promo.activation_date).format("X")
                                ),
                                Promo_Status: promodet.Promo_Status,
                                Promo_Title:
                                  "Any " +
                                  promo.buy_quantity +
                                  " For " +
                                  promodet.symbol +
                                  " " +
                                  parseFloat(
                                    Math.round(promo.promo_price * 100) / 100
                                  ).toFixed(2),
                                Ref_Promo_Type_Id: parseInt(promo.promo_type),
                                To_Date: parseInt(
                                  moment(promo.expiration_date).format("X")
                                ),
                                Banner_image: thumbImage,
                                Product_Detail: product,
                                ProductGroup: [],
                                Promo_Images: globalarray,
                                Display_Name: "Mix and Match",
                                Group_Name:
                                  "Any " +
                                  promo.buy_quantity +
                                  " For " +
                                  promodet.symbol +
                                  " " +
                                  parseFloat(
                                    Math.round(promo.promo_price * 100) / 100
                                  ).toFixed(2),
                                Max_Selection_Count: 1,
                                Min_Selection_Count: 1,
                                Selection_Count: 1,
                                Promo_Description: promo.Promo_Description,
                                Ref_Currency_Id: promo.Ref_Currency_Id,
                                Group_Id: promodet.Group_Id,
                                StoreID: promo.Ref_Store_Id,
                                Promo_Display_Order: parseInt(
                                  promo.Promo_Display_Order
                                ),
                                Updated_By:
                                  username === undefined ? "" : username,
                              };
                            } else if (promo.promo_type === "501") {
                              dataobj = {
                                PromoID: promoid,
                                Buy_Promo_Qty: 0,
                                Free_Promo_Qty: 0,
                                Promo_Price: 0,
                                From_Date: parseInt(
                                  moment(promo.activation_date).format("X")
                                ),
                                Promo_Status: promodet.Promo_Status,
                                Promo_Title: promo.promo_title,
                                Ref_Promo_Type_Id: parseInt(promo.promo_type),
                                To_Date: parseInt(
                                  moment(promo.expiration_date).format("X")
                                ),
                                Banner_image: thumbImage,
                                Product_Detail: product,
                                ProductGroup: [],
                                Promo_Images: globalarray,
                                Display_Name: promo.promo_title,
                                Group_Name: promo.promo_title,
                                Max_Selection_Count: 0,
                                Min_Selection_Count: 0,
                                Selection_Count: 0,
                                Promo_Description: promo.Promo_Description,
                                Ref_Currency_Id: promo.Ref_Currency_Id,
                                Group_Id: promodet.Group_Id,
                                StoreID: promo.Ref_Store_Id,
                                Promo_Display_Order: parseInt(
                                  promo.Promo_Display_Order
                                ),
                                Updated_By:
                                  username === undefined ? "" : username,
                              };
                            } else {
                              dataobj = {
                                PromoID: promoid,
                                Buy_Promo_Qty: 1,
                                Free_Promo_Qty: 1,
                                Promo_Price: parseFloat(promo.promo_price),
                                From_Date: parseInt(
                                  moment(promo.activation_date).format("X")
                                ),
                                Promo_Status: promodet.Promo_Status,
                                Promo_Title: promo.promo_title,
                                Ref_Promo_Type_Id: parseInt(promo.promo_type),
                                To_Date: parseInt(
                                  moment(promo.expiration_date).format("X")
                                ),
                                Banner_image: thumbImage,
                                ProductGroup: dealpro,
                                Product_Detail: [],
                                Promo_Images: globalarray,
                                Display_Name: promo.promo_title,
                                Group_Name: promo.promo_title,
                                Max_Selection_Count: 1,
                                Min_Selection_Count: 1,
                                Selection_Count: 1,
                                Promo_Description: promo.Promo_Description,
                                Ref_Currency_Id: promo.Ref_Currency_Id,
                                StoreID: promo.Ref_Store_Id,
                                Promo_Display_Order: parseInt(
                                  promo.Promo_Display_Order
                                ),
                                Updated_By:
                                  username === undefined ? "" : username,
                              };
                            }

                            console.log(dataobj);

                            request
                              .post(Dev_URL + "/addpromonew")
                              .send(dataobj)
                              .then((res) => {
                                console.log(res.body);
                                if (res.body.ReturnCode === 200) {
                                  resolve(res.body);
                                } else {
                                  reject(res.body);
                                }
                              })
                              .catch((err) => {
                                reject(err);
                              });
                          });
                      });
                  });
                }
              });
            });
        });
      } else if (filelist1_check === true) {
        fileList1.forEach((f) => {
          // console.log(f);
          storage
            .ref(
              promo.Ref_Store_Id +
                "/Promo_Images/Banner/" +
                promoname +
                "_" +
                id2 +
                f.name
            )
            .put(f)
            .then(function (snapshot) {
              snapshot.ref.getDownloadURL().then(function (downloadURL) {
                const thumbImage = snapshot.ref.fullPath;
                let dataobj;
                if (promo.promo_type === "101") {
                  dataobj = {
                    PromoID: promoid,
                    Buy_Promo_Qty: 1,
                    Free_Promo_Qty: 1,
                    Promo_Price: parseFloat(promo.promo_price),
                    From_Date: parseInt(
                      moment(promo.activation_date).format("X")
                    ),
                    Promo_Status: promodet.Promo_Status,
                    Promo_Title: "Buy 1 Get 1",
                    Ref_Promo_Type_Id: parseInt(promo.promo_type),
                    To_Date: parseInt(
                      moment(promo.expiration_date).format("X")
                    ),
                    Banner_image: thumbImage,
                    Product_Detail: product,
                    Promo_Images: promodet.Promo_Dup,
                    Display_Name: "Buy 1 Get 1",
                    Group_Name: "Buy 1 Get 1",
                    Max_Selection_Count: 1,
                    Min_Selection_Count: 1,
                    Selection_Count: 1,
                    Promo_Description: promo.Promo_Description,
                    Ref_Currency_Id: promo.Ref_Currency_Id,
                    Group_Id: promodet.Group_Id,
                    StoreID: promo.Ref_Store_Id,
                    Promo_Display_Order: parseInt(promo.Promo_Display_Order),
                    Updated_By: username === undefined ? "" : username,
                  };
                } else if (promo.promo_type === "201") {
                  dataobj = {
                    PromoID: promoid,
                    Buy_Promo_Qty: parseInt(promo.buy_quantity),
                    Free_Promo_Qty: 1,
                    Promo_Price: parseFloat(promo.promo_price),
                    From_Date: parseInt(
                      moment(promo.activation_date).format("X")
                    ),
                    Promo_Status: promodet.Promo_Status,
                    Promo_Title:
                      "Any " +
                      promo.buy_quantity +
                      " For " +
                      promodet.symbol +
                      " " +
                      parseFloat(
                        Math.round(promo.promo_price * 100) / 100
                      ).toFixed(2),
                    Ref_Promo_Type_Id: parseInt(promo.promo_type),
                    To_Date: parseInt(
                      moment(promo.expiration_date).format("X")
                    ),
                    Banner_image: thumbImage,
                    Product_Detail: product,
                    ProductGroup: [],
                    Promo_Images: promodet.Promo_Dup,
                    Display_Name: "Mix and Match",
                    Group_Name:
                      "Any " +
                      promo.buy_quantity +
                      " For " +
                      promodet.symbol +
                      " " +
                      parseFloat(
                        Math.round(promo.promo_price * 100) / 100
                      ).toFixed(2),
                    Max_Selection_Count: 1,
                    Min_Selection_Count: 1,
                    Selection_Count: 1,
                    Promo_Description: promo.Promo_Description,
                    Ref_Currency_Id: promo.Ref_Currency_Id,
                    Group_Id: promodet.Group_Id,
                    StoreID: promo.Ref_Store_Id,
                    Promo_Display_Order: parseInt(promo.Promo_Display_Order),
                    Updated_By: username === undefined ? "" : username,
                  };
                } else if (promo.promo_type === "501") {
                  dataobj = {
                    PromoID: promoid,
                    Buy_Promo_Qty: 0,
                    Free_Promo_Qty: 0,
                    Promo_Price: 0,
                    From_Date: parseInt(
                      moment(promo.activation_date).format("X")
                    ),
                    Promo_Status: promodet.Promo_Status,
                    Promo_Title: promo.promo_title,
                    Ref_Promo_Type_Id: parseInt(promo.promo_type),
                    To_Date: parseInt(
                      moment(promo.expiration_date).format("X")
                    ),
                    Banner_image: promodet.Banner_image,
                    Product_Detail: product,
                    ProductGroup: [],
                    Promo_Images: promodet.Promo_Dup,
                    Display_Name: promo.promo_title,
                    Group_Name: promo.promo_title,
                    Max_Selection_Count: 0,
                    Min_Selection_Count: 0,
                    Selection_Count: 0,
                    Promo_Description: promo.Promo_Description,
                    Ref_Currency_Id: promo.Ref_Currency_Id,
                    Group_Id: promodet.Group_Id,
                    StoreID: promo.Ref_Store_Id,
                    Promo_Display_Order: parseInt(promo.Promo_Display_Order),
                    Updated_By: username === undefined ? "" : username,
                  };
                } else {
                  dataobj = {
                    PromoID: promoid,
                    Buy_Promo_Qty: 1,
                    Free_Promo_Qty: 1,
                    Promo_Price: parseFloat(promo.promo_price),
                    From_Date: parseInt(
                      moment(promo.activation_date).format("X")
                    ),
                    Promo_Status: promodet.Promo_Status,
                    Promo_Title: promo.promo_title,
                    Ref_Promo_Type_Id: parseInt(promo.promo_type),
                    To_Date: parseInt(
                      moment(promo.expiration_date).format("X")
                    ),
                    Banner_image: thumbImage,
                    ProductGroup: dealpro,
                    Product_Detail: [],
                    Promo_Images: promodet.Promo_Dup,
                    Display_Name: promo.promo_title,
                    Group_Name: promo.promo_title,
                    Max_Selection_Count: 1,
                    Min_Selection_Count: 1,
                    Selection_Count: 1,
                    Promo_Description: promo.Promo_Description,
                    Ref_Currency_Id: promo.Ref_Currency_Id,
                    StoreID: promo.Ref_Store_Id,
                    Promo_Display_Order: parseInt(promo.Promo_Display_Order),
                    Updated_By: username === undefined ? "" : username,
                  };
                }

                console.log(dataobj);

                request
                  .post(Dev_URL + "/addpromonew")
                  .send(dataobj)
                  .then((res) => {
                    console.log(res.body);
                    if (res.body.ReturnCode === 200) {
                      resolve(res.body);
                    } else {
                      reject(res.body);
                    }
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            });
        });
      } else {
        let dataobj;
        if (promo.promo_type === "101") {
          dataobj = {
            PromoID: promoid,
            Buy_Promo_Qty: 1,
            Free_Promo_Qty: 1,
            Promo_Price: parseFloat(promo.promo_price),
            From_Date: parseInt(moment(promo.activation_date).format("X")),
            Promo_Status: promodet.Promo_Status,
            Promo_Title: "Buy 1 Get 1",
            Ref_Promo_Type_Id: parseInt(promo.promo_type),
            To_Date: parseInt(moment(promo.expiration_date).format("X")),
            Banner_image: promodet.Banner_image,
            Promo_Images: promodet.Promo_Dup,
            Product_Detail: product,
            Display_Name: "Buy 1 Get 1",
            Group_Name: "Buy 1 Get 1",
            Max_Selection_Count: 1,
            Min_Selection_Count: 1,
            Selection_Count: 1,
            Promo_Description: promo.Promo_Description,
            Ref_Currency_Id: promo.Ref_Currency_Id,
            Group_Id: promodet.Group_Id,
            StoreID: promo.Ref_Store_Id,
            Promo_Display_Order: parseInt(promo.Promo_Display_Order),
            Updated_By: username === undefined ? "" : username,
          };
        } else if (promo.promo_type === "201") {
          dataobj = {
            PromoID: promoid,
            Buy_Promo_Qty: parseInt(promo.buy_quantity),
            Free_Promo_Qty: 1,
            Promo_Price: parseFloat(promo.promo_price),
            From_Date: parseInt(moment(promo.activation_date).format("X")),
            Promo_Status: promodet.Promo_Status,
            Promo_Title:
              "Any " +
              promo.buy_quantity +
              " For " +
              promodet.symbol +
              " " +
              parseFloat(Math.round(promo.promo_price * 100) / 100).toFixed(2),
            Ref_Promo_Type_Id: parseInt(promo.promo_type),
            To_Date: parseInt(moment(promo.expiration_date).format("X")),
            Banner_image: promodet.Banner_image,
            Promo_Images: promodet.Promo_Dup,
            Product_Detail: product,
            ProductGroup: [],
            Display_Name: "Mix and Match",
            Group_Name:
              "Any " +
              promo.buy_quantity +
              " For " +
              promodet.symbol +
              " " +
              parseFloat(Math.round(promo.promo_price * 100) / 100).toFixed(2),
            Max_Selection_Count: 1,
            Min_Selection_Count: 1,
            Selection_Count: 1,
            Promo_Description: promo.Promo_Description,
            Ref_Currency_Id: promo.Ref_Currency_Id,
            Group_Id: promodet.Group_Id,
            StoreID: promo.Ref_Store_Id,
            Promo_Display_Order: parseInt(promo.Promo_Display_Order),
            Updated_By: username === undefined ? "" : username,
          };
        } else if (promo.promo_type === "501") {
          dataobj = {
            PromoID: promoid,
            Buy_Promo_Qty: 0,
            Free_Promo_Qty: 0,
            Promo_Price: 0,
            From_Date: parseInt(moment(promo.activation_date).format("X")),
            Promo_Status: promodet.Promo_Status,
            Promo_Title: promo.promo_title,
            Ref_Promo_Type_Id: parseInt(promo.promo_type),
            To_Date: parseInt(moment(promo.expiration_date).format("X")),
            Banner_image: promodet.Banner_image,
            Product_Detail: product,
            ProductGroup: [],
            Promo_Images: promodet.Promo_Dup,
            Display_Name: promo.promo_title,
            Group_Name: promo.promo_title,
            Max_Selection_Count: 0,
            Min_Selection_Count: 0,
            Selection_Count: 0,
            Promo_Description: promo.Promo_Description,
            Ref_Currency_Id: promo.Ref_Currency_Id,
            Group_Id: promodet.Group_Id,
            StoreID: promo.Ref_Store_Id,
            Promo_Display_Order: parseInt(promo.Promo_Display_Order),
            Updated_By: username === undefined ? "" : username,
          };
        } else {
          dataobj = {
            PromoID: promoid,
            Buy_Promo_Qty: 1,
            Free_Promo_Qty: 1,
            Promo_Price: parseFloat(promo.promo_price),
            From_Date: parseInt(moment(promo.activation_date).format("X")),
            Promo_Status: promodet.Promo_Status,
            Promo_Title: promo.promo_title,
            Ref_Promo_Type_Id: parseInt(promo.promo_type),
            To_Date: parseInt(moment(promo.expiration_date).format("X")),
            Banner_image: promodet.Banner_image,
            Promo_Images: promodet.Promo_Dup,
            ProductGroup: dealpro,
            Product_Detail: [],
            Display_Name: "",
            Group_Name: "",
            Max_Selection_Count: 1,
            Min_Selection_Count: 1,
            Selection_Count: 1,
            Promo_Description: promo.Promo_Description,
            Ref_Currency_Id: promo.Ref_Currency_Id,
            StoreID: promo.Ref_Store_Id,
            Promo_Display_Order: parseInt(promo.Promo_Display_Order),
            Updated_By: username === undefined ? "" : username,
          };
        }

        console.log(dataobj);

        request
          .post(Dev_URL + "/addpromonew")
          .send(dataobj)
          .then((res) => {
            console.log(res.body);
            if (res.body.ReturnCode === 200) {
              resolve(res.body);
            } else {
              reject(res.body);
            }
          })
          .catch((err) => {
            reject(err);
          });
      } // Else Block End
    });
  };
}

export function UploadProduct(baseurl, file) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(file[0]);
      var uploadedData = [];

      Papa.parse(file[0], {
        header: true,
        encoding: "UTF-8",
        skipEmptyLines: true,
        complete: function (results) {
          console.log(results.data);
          results.data.forEach(function (prod) {
            var arr = [];
            arr.push(baseurl + prod.Product_Images + ".jpg");
            var msgdata = {
              Product_Id: prod.Product_Id,
              Product_Name_Lowercase: prod.Product_Name.toLowerCase(),
              Is_Marked_Up_Price_Enabled:
                prod.Is_Marked_Up_Price_Enabled === "TRUE",
              Marked_Up_Price:
                prod.Marked_Up_Price === undefined
                  ? 0
                  : parseFloat(prod.Marked_Up_Price),
              Marked_Up_Quantity:
                prod.Marked_Up_Quantity === undefined
                  ? 0
                  : parseInt(prod.Marked_Up_Quantity),
              Product_Name: prod.Product_Name,
              Product_Display_Order: 0,
              Product_Description: prod.Product_Description,
              Ref_Product_Brand_Id: prod.Ref_Product_Brand_Id,
              Ref_Sub_Category_Id: prod.Ref_Sub_Category_Id,
              Ref_Category_Id: prod.Ref_Category_Id,
              Product_Measuring_Quantity: "",
              Product_No_of_Units: "",
              Is_Active: true,
              Ref_Currency_Id: prod.Ref_Currency_Id,
              Display_Name: "",
              Is_Premier_Promo: false,
              Promo_Product_Details: [],
              Product_Actual_Price: parseFloat(prod.Product_Actual_Price),
              Product_Is_Discounted: prod.Product_Is_Discounted === "TRUE",
              Product_Discounted_Price:
                prod.Product_Discounted_Price === undefined
                  ? 0
                  : parseFloat(prod.Product_Discounted_Price),
              Ref_Product_Offer_Type:
                prod.Ref_Product_Offer_Type === undefined
                  ? 0
                  : parseInt(prod.Ref_Product_Offer_Type),
              Ref_Product_Offer_Value:
                prod.Ref_Product_Offer_Value === undefined
                  ? 0
                  : parseFloat(prod.Ref_Product_Offer_Value),
              Product_Offer_Shared_By:
                prod.Product_Offer_Shared_By === undefined
                  ? ""
                  : prod.Product_Offer_Shared_By,
              Product_Tax_Applicable: prod.Product_Tax_Applicable === "TRUE",
              Ref_Product_Tax_Slab:
                prod.Ref_Product_Tax_Slab === undefined
                  ? ""
                  : prod.Ref_Product_Tax_Slab,
              Ref_Product_Commision_Slab:
                prod.Ref_Product_Commision_Slab === undefined
                  ? ""
                  : prod.Ref_Product_Commision_Slab,
              Product_Disclaimer:
                prod.Product_Disclaimer === undefined
                  ? ""
                  : prod.Product_Disclaimer,
              Product_Rating: parseInt(prod.Product_Rating),
              Created_At: firebase.firestore.FieldValue.serverTimestamp(),
              Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
              Product_Thumb_Image_Url:
                baseurl + prod.Product_Thumb_Image_Url + ".jpg",
              Product_Images: arr,
            };

            console.log(msgdata);
            db.collection("Product_Details1")
              .doc()
              .set(msgdata)
              .then(function (doc) {
                uploadedData.push({ id: doc });
                if (uploadedData.length === results.data.length) {
                  resolve(uploadedData);
                }
                // console.log("uploadedData" , uploadedData);
              });
          });
        },
      });
    });
  };
}

export function dailyorderreport(storeid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var arr_order = [];
      //console.log(ddate)
      db.collection("Daily_Order_Delivered_Report")
        .where("Ref_Store_Id", "==", storeid)
        .orderBy("Created_At", "desc")
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.size !== 0) {
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              var key = doc.id;
              var DeliveredDate = doc.data().DeliveredDate;
              var Total_Delivery_Amount =
                referencevalue.currencysymbol +
                " " +
                doc.data().Total_Delivery_Amount;
              var Total_Delivery_Count = doc.data().Total_Delivery_Count;
              var obj_arr = {
                key,
                DeliveredDate,
                Total_Delivery_Amount,
                Total_Delivery_Count,
              };
              arr_order.push(obj_arr);
            });
            dispatch({ type: GET_COMMON_SLOT, payload: arr_order });
            resolve("Success");
          } else {
            //console.log(obj_arr_order);
            dispatch({ type: GET_COMMON_SLOT, payload: arr_order });
            resolve("Success");
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    });
  };
}
export function pushmessage() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var message_arr = [];
      db.collection("Push_Notification_Template")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            message_arr.push(doc.data());
          });
          if (querySnapshot.size === message_arr.length) {
            dispatch({ type: GET_COMMON_SLOT, payload: message_arr });
            resolve(message_arr);
          }
        });
    });
  };
}

export function allstorename() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
            //console.log(bucket_baseurl);
          });
        });
      var message_arr = [];
      // db.collection("Stores_Master").where("IsActive", "==", true).get().then(function(querySnapshot) {
      db.collection("Stores_Master")
        .where("IsActive", "==", true)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var obj_arr = {
              key: doc.id,
              store_name: doc.data().Store_Name,
              imgUrl: bucket_baseurl + doc.data().Store_Logo,
            };
            message_arr.push(obj_arr);
          });
          dispatch({ type: GET_STORE_ALL, payload: message_arr });
          resolve(message_arr);
        });
    });
  };
}

export function MD_Order_Status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("MD_Order_Status").onSnapshot(function (doc) {
        const MD_Status = [];
        doc.forEach(function (doc) {
          //console.log("Current data: ", doc.data());
          MD_Status.push({
            Order_Status: doc.data().Order_Status,
            Order_Status_Id: doc.data().Order_Status_Id,
          });
        });
        // console.log("Current data: ", currencyList);
        resolve(MD_Status);
      });
    });
  };
}

export function vat_tax_report(storeid, smonth, emonth) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      //console.log(storeid, smonth)
      var m = moment(smonth);
      var m1 = m.startOf("day").format();
      var start = new Date(m1);
      var n = moment(emonth);
      var n1 = n.endOf("day").format();
      var end = new Date(n1);
      console.log(start);
      console.log(end);
      var arr_tax_slab = [];
      var arr_tax_slab_no = [];
      db.collection("Order")
        .where("Ref_Order_Store_Id", "==", storeid)
        .where("Created_At", ">", start)
        .where("Created_At", "<", end)
        .where("Ref_Order_Status_Id", "==", 12)
        .onSnapshot(function (querySnapshot) {
          console.log(querySnapshot.size);
          if (querySnapshot.size !== 0) {
            var index = 0;
            var totalcount = 0;
            querySnapshot.forEach((doc) => {
              // console.log(doc.id);

              db.collection("Order_Details")
                .where("Ref_Order_Id", "==", doc.id)
                .onSnapshot(function (querySnapshot1) {
                  totalcount =
                    parseInt(totalcount) + parseInt(querySnapshot1.size);
                  querySnapshot1.forEach((doc1) => {
                    //console.log(doc1.data());
                    var Ref_Product_Tax_Slab =
                      doc1.data().Ref_Product_Tax_Slab === ""
                        ? "20"
                        : doc1.data().Ref_Product_Tax_Slab;
                    var order_obj = {
                      Ref_Product_Tax_Slab: Ref_Product_Tax_Slab,
                      Ref_Order_Total_Fare: doc1.data().Ref_Order_Total_Fare,
                    };
                    arr_tax_slab.push(order_obj);
                    arr_tax_slab_no.push(Ref_Product_Tax_Slab);
                  });
                  index++;
                  if (querySnapshot.size === index) {
                    console.log(totalcount);
                    //console.log(order_detail_arr);
                    //resolve(order_detail_arr);
                    unique_count();
                  }
                });
            });
          }
        });

      function unique_count() {
        var slab_details = [];
        var uSet = new Set(arr_tax_slab_no);
        var uniquetax_slab = [...uSet];
        //console.log(uniquetax_slab);
        uniquetax_slab.forEach((modelName) => {
          var filteredArray = arr_tax_slab.filter(
            (modelItem) => modelItem.Ref_Product_Tax_Slab === modelName
          );

          console.log(filteredArray);
          var sum = filteredArray.reduce(
            (a, v) => (a = a + v.Ref_Order_Total_Fare),
            0
          );
          var obj_arr = {
            Slab_Name: modelName,
            Slab_Count: filteredArray.length,
            Slab_Total: sum.toFixed(2),
          };
          //console.log(obj_arr);
          slab_details.push(obj_arr);
        });
        console.log(slab_details);
        resolve(slab_details);
      }
    });
  };
}

export function MD_Promo_Type(storeid) {
  console.log(storeid);
  var arr_len = [];
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Promotion_Product_Details1")
        .where("Ref_Store_Id", "==", storeid)
        .where("Promo_Status", "==", 1)
        .where("Ref_Promo_Type_Id", "==", 501)
        .get()
        .then(function (querySnapshot) {
          console.log();
          if (querySnapshot.size === 0) {
            resolve(arr_len);
          } else {
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              var dataobj = doc.data();
              Object.assign(dataobj, { Ref_Promo_Id: doc.id });

              arr_len.push(dataobj);
              console.log(querySnapshot.size, arr_len.length);
              if (querySnapshot.size === arr_len.length) {
                resolve(arr_len);
                console.log(arr_len);
              }
            });
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          reject(error);
        });
    });
  };
}
export function Add_Promo_Type(values, products) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var obj_arr = [];
      var obj = {
        Offer_Name: values.Offer_Name,
        Banner_Display_Order: parseInt(values.Banner_Display_Order),
        Callout_Type:
          values.Callout_Type === "1"
            ? "Products"
            : values.Callout_Type === "2"
            ? "Detail"
            : values.Callout_Type === "3"
            ? "General"
            : "Referral",
        Coupon_Code: values.Offer_title === undefined ? "" : values.Offer_title,
        Coupon_End_Date: "",
        Coupon_Id: "",
        Coupon_Start_Date: "",
        Offer_Banner_Image:
          values.Offer_Banner_Image === undefined
            ? ""
            : values.Offer_Banner_Image,
        Offer_Description:
          values.Offer_Description === undefined
            ? ""
            : values.Offer_Description,
        Offer_Id: parseInt(values.Callout_Type),
        Products: values.Callout_Type === "1" ? products : [],
      };
      console.log(obj);
      db.collection("MD_Dynamic_Homescreen1")
        .where("Ref_Store_Id", "==", values.Ref_Store_Id_List)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            obj_arr = doc.data().Top_Banner_Offers;
            console.log("obj_arr before", obj_arr);
            obj_arr.push(obj);
            console.log(obj_arr);
            db.collection("MD_Dynamic_Homescreen1")
              .doc(doc.id)
              .update({
                Top_Banner_Offers: obj_arr,
              })
              .then(function () {
                resolve("success");
                console.log("Document successfully written!");
              })
              .catch(function (error) {
                console.log("Error writing document: ", error);
              });
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          reject(error);
        });
    });
  };
}

//======= Product id Duplicate ====================

export function product_id_check(value) {
  return (dispatch) => {
    //console.log(value);

    return new Promise((resolve, reject) => {
      db.collection("Product_Details1")
        .where("Product_Id", "==", value)
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.size === 0) {
            console.log("No such document!");
            resolve("NO");
            dispatch({ type: GET_SUCCESS, payload: "No such document!" });
          } else {
            querySnapshot.forEach(function (doc) {
              console.log(doc);
              reject(doc);
            });
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          reject(error);
        });
    });
  };
}

export function product_id_check_edit(value, Ref_Store_Id) {
  return (dispatch) => {
    console.log(value, Ref_Store_Id);

    return new Promise((resolve, reject) => {
      db.collection("Product_Details1")
        .where("Product_Id", "==", value)
        .where("Ref_Store_Id", "==", Ref_Store_Id)
        .get()
        .then(function (querySnapshot) {
          console.log(querySnapshot.size);
          if (querySnapshot.size === 1 || querySnapshot.size === 0) {
            console.log("No such document!");
            resolve("NO");
            dispatch({ type: GET_SUCCESS, payload: "No such document!" });
          } else {
            querySnapshot.forEach(function (doc) {
              console.log(doc);
              reject(doc);
            });
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          reject(error);
        });
    });
  };
}

export function SortProduct(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const productList = [];
      var catlist = [];
      var subcatlist = [];
      var currencylist = [];
      var itemsProcessed = 0;

      console.log(values);

      db.collection("Constants")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            bucket_baseurl = doc.data().Bucket_Base_Url;
            console.log(bucket_baseurl);
          });
        });

      //===============================================
      db.collection("Categories")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var catid = op_doc_status.id;
            var catname = op_doc_status.data().Category_Name;
            var obj_order_status = {
              catid: catid,
              catname: catname,
            };
            catlist.push(obj_order_status);
          });
          //  console.log(catlist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      //===============================================
      db.collection("Sub_Categories")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var subcatid = op_doc_status.id;
            var subcatname = op_doc_status.data().Sub_Category_Name;
            var obj_order_status = {
              subcatid: subcatid,
              subcatname: subcatname,
            };
            subcatlist.push(obj_order_status);
          });
          // console.log(subcatlist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================
      //===============================================
      db.collection("MD_Currency")
        .get()
        .then((op_doc) => {
          op_doc.forEach((op_doc_status) => {
            var currencyid = op_doc_status.id;
            var Currency_Symbol = op_doc_status.data().Currency_Symbol;
            var obj_order_status = {
              currency_id: currencyid,
              Currency_Symbol: Currency_Symbol,
            };
            currencylist.push(obj_order_status);
          });
          // console.log(currencylist);
        })
        .catch(function (error) {
          console.log("Error getting documents of city_Master: ", error);
          reject(error);
        });
      //========================================

      db.collection("Product_Details1")
        .where("Ref_Sub_Category_Id", "==", values.subcategory)
        .where("Ref_Store_Id", "==", values.store_name)
        .where("Is_Active", "==", true)
        .orderBy("Product_Display_Order", "desc")
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.size !== 0) {
            querySnapshot.forEach(function (doc) {
              var catid = doc.data().Ref_Category_Id;
              var subcatid = doc.data().Ref_Sub_Category_Id;
              var did = doc.id;
              var pid = doc.data().Product_Id;
              var Product_Discounted_Price = doc
                .data()
                .Product_Discounted_Price.toFixed(2);
              var Product_Price = doc.data().Product_Actual_Price.toFixed(2);
              var pname = doc.data().Product_Name;
              var currency_id = doc.data().Ref_Currency_Id;
              var imgUrl = bucket_baseurl + doc.data().Product_Thumb_Image_Url;
              var Status = doc.data().Is_Active.toString();
              var Product_Display_Order = doc.data().Product_Display_Order;
              var updated_at = moment(doc.data().Updated_At.toDate()).format(
                "LLL"
              );
              var Is_Marked_Up_Price_Enabled =
                doc.data().Is_Marked_Up_Price_Enabled;
              var Marked_Up_Price = doc.data().Marked_Up_Price;
              var Marked_Up_Quantity = doc.data().Marked_Up_Quantity;

              var isstock = doc.data().Is_Stock_Available;
              itemsProcessed++;

              //Currency symbol
              if (currency_id) {
                var currency_status =
                  currencylist[
                    currencylist
                      .map(function (item1) {
                        return item1.currency_id;
                      })
                      .indexOf(currency_id)
                  ];
                //console.log(selectedorder_status, ' - ', Promo_Status);
                var symbol = currency_status.Currency_Symbol;
              } else {
                symbol = "-";
              }

              //Category Name
              if (catid) {
                var cat_status =
                  catlist[
                    catlist
                      .map(function (item1) {
                        return item1.catid;
                      })
                      .indexOf(catid)
                  ];
                //console.log(selectedorder_status, ' - ', Promo_Status);
                if (cat_status !== undefined) {
                  var catname = cat_status.catname;
                } else {
                  catname = "-";
                }
              } else {
                catname = "-";
              }

              //Sub Category Name
              if (subcatid) {
                var subcat_status =
                  subcatlist[
                    subcatlist
                      .map(function (item1) {
                        return item1.subcatid;
                      })
                      .indexOf(subcatid)
                  ];
                //console.log(selectedorder_status, ' - ', Promo_Status);
                if (subcat_status !== undefined) {
                  var subcatname = subcat_status.subcatname;
                } else {
                  subcatname = "-";
                }
                // var subcatname = subcat_status.subcatname;
              } else {
                subcatname = "-";
              }
              if (Is_Marked_Up_Price_Enabled === true) {
                var markedup_price =
                  Marked_Up_Quantity +
                  " For " +
                  symbol +
                  " " +
                  Marked_Up_Price.toFixed(2);
              } else {
                markedup_price = "-";
              }
              productList.push({
                key: did,
                pid: pid,
                pname: pname,
                Product_Discounted_Price:
                  symbol + " " + Product_Discounted_Price,
                Product_Price: symbol + " " + Product_Price,
                imgUrl: imgUrl,
                catname: catname,
                subcatname: subcatname,
                Status: Status,
                updated_at: updated_at,
                Product_Display_Order: Product_Display_Order,
                isstock: isstock,
                markedup_price: markedup_price,
              });
            });
            if (itemsProcessed === productList.length) {
              var sortedarr = _.orderBy(
                productList,
                ["Product_Display_Order"],
                ["asc"]
              );
              dispatch({
                type: GET_PRODUCTS,
                payload: sortedarr,
              });
              resolve(productList);
              // console.log("Current data: ", productList);
            }
          } else {
            reject("No Data");
          }
        });
    });
  };
}

export function updateProductList(prodList) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var itemsProcessed = 0;
      var arr_length = prodList.length;
      prodList.forEach((prodList, index) => {
        db.collection("Product_Details1")
          .doc(prodList.key)
          .update({
            Product_Display_Order: index,
            Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            itemsProcessed++;
            console.log("Document successfully updated!", index);
            console.log(itemsProcessed, arr_length);
            if (itemsProcessed === arr_length) {
              resolve("Success");
              // console.log("Current data: ", productList);
            }
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      });
    });
  };
}

// ======= Order Log Details ====================

export function orderlog(value) {
  return (dispatch) => {
    //console.log(value);
    return new Promise((resolve, reject) => {
      var arr_order_status = [];
      var arr_order_log = [];
      db.collection("MD_Order_Status")
        .get()
        .then((order_status) => {
          order_status.forEach((doc_order_status) => {
            var statuskey = doc_order_status.data().Order_Status;
            var statusid = doc_order_status.data().Order_Status_Id;
            var obj_order_status = {
              order_statusId: statusid,
              order_statuskey: statuskey,
            };
            arr_order_status.push(obj_order_status);
          });
          order_info();
        })

        .catch((err) => {
          console.log("Error getting documents", err);
          // reject(err);
        });

      function order_info() {
        db.collection("Order_log")
          .doc(value)
          .get()
          .then((doc) => {
            if (doc.exists) {
              // console.log("Document data:", doc.data());
              let order_obj_arr = doc.data().Order_Detail;
              //console.log(order_obj_arr);
              order_obj_arr.forEach((obj) => {
                let Ref_Order_Status_Id = obj.Ref_Order_Status_Id;
                let Updated_at = moment(obj.Updated_At.toDate()).format("LLL");
                // console.log(Ref_Order_Status_Id, arr_order_status);
                //arr_order_status
                if (Ref_Order_Status_Id) {
                  var selectedorder_status =
                    arr_order_status[
                      arr_order_status
                        .map(function (item1) {
                          return item1.order_statusId;
                        })
                        .indexOf(Ref_Order_Status_Id)
                    ];

                  var dir_order_status = selectedorder_status.order_statuskey;
                } else {
                  dir_order_status = "N/A";
                }

                let obj_arr = { dir_order_status, Updated_at };
                // console.log(obj_arr);
                arr_order_log.push(obj_arr);
                if (order_obj_arr.length === arr_order_log.length) {
                  resolve(arr_order_log);
                }
              });
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              reject("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      }
    });
  };
}

// get - getDesignations
export function getDesignations() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var hubs = [];
      db.collection("DesignationMaster")
        .orderBy("desgId", "asc")
        .get()
        .then(function (querySnapshot) {
          // console.log(querySnapshot);
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            hubs.push({
              docid: doc.id,
              desgRole: doc.data().desgRole,
              desgId: doc.data().desgId,
              Description: doc.data().Description,
              IsActive: doc.data().IsActive,
              Permissions: doc.data().Permissions,
            });
            // console.log(key, querySnapshot.size, key);
            if (querySnapshot.size === hubs.length) {
              resolve(hubs);
            }
          });
        });
    });
  };
}

// Delete - Designations
export function deleteDesignations(docid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(docid);
      db.collection("DesignationMaster")
        .doc(docid)
        .delete()
        .then((res) => {
          resolve("Deleted Successfully!");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

//Get - getModules
export function getModules() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var modules = [];
      db.collection("ModulesMaster")
        .orderBy("id", "asc")
        .get()
        .then(function (doc) {
          // console.log(doc)
          if (doc.empty) {
            reject("No Modules found");
          } else {
            doc.forEach((m) => {
              // console.log(m)
              modules.push({
                key: m.id,
                Name: m.data().Name,
                id: m.data().id,
              });
              if (modules.length === doc.size) {
                resolve(modules);
              }
            });
          }
        });
    });
  };
}

// Add - Designations
export function createDesignations(values, ps) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values, ps);

      db.collection("DesignationMaster")
        .orderBy("desgId", "desc")
        .limit(1)
        .get()
        .then(function (querySnapshot) {
          // console.log(querySnapshot);
          querySnapshot.forEach(function (doc) {
            console.log(doc.data());
            db.collection("DesignationMaster")
              .doc()
              .set({
                desgId: doc.data().desgId + 1,
                desgRole: values.desgRole,
                Description: values.Description,
                IsActive: values.IsActive === "true" ? true : false,
                Permissions: ps,
              })
              .then(function (querySnapshot) {
                resolve("Updated");
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
    });
  };
}

// edit - Designations
export function editDesignations(values, ps, docid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values, ps, docid);
      db.collection("DesignationMaster")
        .doc(docid)
        .update({
          desgRole: values.desgRole,
          Description: values.Description,
          IsActive: values.IsActive === "true" ? true : false,
          Permissions: ps,
        })
        .then(function (querySnapshot) {
          resolve("Updated");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
