import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth } from "../firebase";
import _ from 'lodash';
import { emailLogin, logout } from '../actions/userAction';
import { catList1, subcatList1, filterProduct, filtersubCat } from '../actions/assetAction';
import { drivers,DriverSettleUp } from '../actions/driverAction';
import { db } from '../firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Icon, Select, Avatar, Button, Input, Table, Tag,Menu, Dropdown, message } from 'antd';
//import 'antd/dist/antd.css';
import '../styles/css/App.css';
import Highlighter from 'react-highlight-words';
import { TimeAgo } from "@n1ru4l/react-time-ago";



const Option = Select.Option;


class Drivers extends Component {

  constructor(props) {
    super(props);
    // state
    this.state = {
      // category: "",
      // subcategory: "",
      disabled: true,
      searchText: '',
      tdata: null,
      loading: true,
      date:'',
      admin_user:''
    };

  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => { this.searchInput = node; }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
            </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
            </Button>
        </div>
      ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text || ''}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
    console.log(this.state.searchText);
  }


  handleCategory = (value) => {
    this.setState({
      category: value,
      subcategory: "",
      disabled: false
    });
    this.props.filtersubCat(value);
  }

  handlesubCategory = (value) => {
    this.setState({
      subcategory: value,
      loading: true
    });
    var cat = this.state.category;
    var subcat = value;
    this.props.filterProduct(cat, subcat).then(res => {
      console.log(res);
      this.setState({
        tdata: this.props.products,
        loading: false
      });
    })
      .catch(error => {
        console.log(error);
      }
      )
  }

  handleResett = () => {
    //  componentWillMount
    
    const initialDate = new Date();
    
    this.setState({
      loading: true,
      
    });

    this.props.drivers().then(res => {
      console.log(res);
      this.setState({
        tdata: this.props.driver,
        loading: false,
        date:initialDate
      });
    })
      .catch(error => {
        console.log(error);
      }
      )


  }


  componentWillMount() {

    var user = auth.currentUser.uid;
    this.setState({
      admin_user:user
    });

    console.log(this.state.admin_user)
    const initialDate = new Date();
    

    this.props.drivers().then(res => {
      //  console.log(res);
        this.setState({
          tdata: this.props.driver,
          loading: false,
          date:initialDate
        });
      })
        .catch(error => {
          console.log(error);
          this.setState({
           
            loading: false,
            
          });
        }
        )
  
    this.props.catList1();
    this.props.subcatList1();

  }
  

  // ======================================================
  renderCategory() {
    return _.map(this.props.categories, (categories, key) => {
      return (
        <Option value={categories.id} key={key}>
          <Avatar src={categories.imgUrl} />
          {categories.cname}
          {/* ({categories.cid})  */}
        </Option>
      );
    })
  }
  renderSubCategory() {
    return _.map(this.props.subcategories, (subcategories, key) => {
      return (
        <Option value={subcategories.id} key={key}>
          <Avatar src={subcategories.imgUrl} />
          {subcategories.sname}
          {/* ({subcategories.sid})  */}
        </Option>
      );
    })
  }

  

  handleConfirm = async (key) => {

    this.setState({
      loading: true
    });


var washingtonRef = db.collection("Driver_Available_Status").doc(key);

// Set the "capital" field of the city 'DC'
    try {
      await washingtonRef.update({
        Driver_Status_Id: 2,
        Updated_At: firebase.firestore.FieldValue.serverTimestamp()
      });
      console.log("Document successfully updated!");
      this.props.drivers().then(res => {
        console.log(res);
        this.setState({
          tdata: this.props.driver,
          loading: false
        });
      })
        .catch(error => {
          console.log(error);
        });
    }
    catch (error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    }

  }


handleReject = async (key) => {

    this.setState({
      loading: true
    });


var washingtonRef = db.collection("Driver_Available_Status").doc(key);

// Set the "capital" field of the city 'DC'
    try {
      await washingtonRef.update({
        Driver_Status_Id: 3,
        Updated_At: firebase.firestore.FieldValue.serverTimestamp()
      });
      console.log("Document successfully updated!");
      this.props.drivers().then(res => {
        console.log(res);
        this.setState({
          tdata: this.props.driver,
          loading: false
        });
      })
        .catch(error => {
          console.log(error);
        });
    }
    catch (error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    }

  }

  handleOffline = async (key) => {

    console.log(key);

    this.setState({
      loading: true
    });


var washingtonRef = db.collection("Driver_Available_Status").doc(key);

// Set the "capital" field of the city 'DC'
    try {
      await washingtonRef.update({
        Driver_Status_Id: 4,
        Updated_At: firebase.firestore.FieldValue.serverTimestamp()
      });
      console.log("Document successfully updated!");
      this.props.drivers().then(res => {
        console.log(res);
        this.setState({
          tdata: this.props.driver,
          loading: false
        });
      })
        .catch(error => {
          console.log(error);
        });
    }
    catch (error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    }

  }

  handleSettleUp = (key,amount) => {

    console.log(key,amount);
    var admin_user = this.state.admin_user
    this.setState({
      loading: true
    });

    
     this.props.DriverSettleUp(key,amount,admin_user).then(res => {
     // console.log(res);
     message.success(res);
     this.props.drivers().then(res => {
      console.log(res);
      this.setState({
        tdata: this.props.driver,
        loading: false
      });
    })
      .catch(error => {
        console.log(error);
      });
     
    })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false
        });
        message.error(error);
      });
   



  }
  //===============================================

  render() {

    const menu = (key, amount) => (
      <Menu>
        <Menu.Item key="1">
        <Link to={{ pathname: '/editdriver',  driverid: key }}> Edit</Link>
        </Menu.Item>
        <Menu.Item key="7" onClick={() => this.handleOffline(key)}>
          
          In-Active
        </Menu.Item>
        <Menu.Item key="3" onClick={() => this.handleSettleUp(key,amount) }>
        
          Settle Up
        </Menu.Item>
      </Menu>
    );
    const menu1 = (key) => (
      <Menu>
        <Menu.Item key="2" onClick={() => this.handleConfirm(key)}>
          Approve
        </Menu.Item>
        <Menu.Item key="5" onClick={() => this.handleReject(key)}>
          Reject
        </Menu.Item>
        <Menu.Item key="3">
        <Link to={{ pathname: '/editdriver',  driverid: key }}> Edit</Link>
        </Menu.Item>
      </Menu>
    );
    const menu2 = (key) => (
      <Menu>
        <Menu.Item key="2" onClick={() => this.handleConfirm(key)}>
          Active
        </Menu.Item>
        <Menu.Item key="3">
        <Link to={{ pathname: '/editdriver',  driverid: key }}> Edit</Link>
        </Menu.Item>
      </Menu>
    );
    // console.log(this.props.products);
    const columns = [
      {
        title: 'Driver Id',
        dataIndex: 'key',
        key: 'key',
       
        ...this.getColumnSearchProps('key'),

      }, 
      {
        title: 'Driver Name',
        dataIndex: 'driver',
        key: 'driver',
       
        ...this.getColumnSearchProps('driver'),

      }, {
        title: 'Mobile Number',
        dataIndex: 'phone',
        key: 'phone',
       
        
      },
      {
        title: 'Email address',
        dataIndex: 'email',
        key: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Company Name',
        dataIndex: 'organization',
        key: 'organization',
        
      },
      {
        title: 'Outstanding Amount ',
        dataIndex: 'outstanding',
        key: 'outstanding',
        
      },
      {
        title: 'Delivery Status',
        dataIndex: 'trip',
        key: 'trip',
       
        render: (text, record) => (
          <span>
          {record.trip === "Offline" ?
          <Tag color="volcano">{record.trip}</Tag>
          :<Tag color="green">{record.trip}</Tag>
          }
         
        </span>
        ),
        
       
      },
      {
        title: 'Driver Status',
        dataIndex: 'drivestatus',
        key: 'drivestatus',
        render: (text, record) => (
          <span>
          {record.drivestatusid === 1 ?
          <Tag color="volcano">{record.drivestatus}</Tag>
          :<Tag color="green">{record.drivestatus}</Tag>
          }
         
        </span>
        ),
      },
      {
        title: 'Updated At',
        dataIndex: 'update_at',
        key: 'update_at',
        ...this.getColumnSearchProps('update_at'),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        width	: 180,
        render: (text, record) => (

          <span>
          {(() => {
            switch (record.drivestatusid) {
              case 2:
                return (
                  <Dropdown overlay={menu(record.key, record.outstanding)} trigger={['click']}>
      <Button type="dashed">
        Select Action <Icon type="down" />
      </Button>
    </Dropdown>
                );
                case 1:
                return (
                  <Dropdown overlay={menu1(record.key)} trigger={['click']}>
      <Button type="dashed">
        Select Action <Icon type="down" />
      </Button>
    </Dropdown>
                );
               

              default:
                return (
                  <Dropdown overlay={menu2(record.key)} trigger={['click']}>
      <Button type="dashed">
        Select Action <Icon type="down" />
      </Button>
    </Dropdown>
                );
            }
          })()}
        </span>

       
            
          ),

      }
    ];



    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h4>Driver</h4>
              </div>
           
            </div>

          </div>
        </div>
        <div className="container-fluid allproduct">
          <div className="text-right filterdiv">
            <span style={{ marginRight: 10 }}>Last Refreshed Time : <TimeAgo date={new Date()}>{({ value }) => <b><Icon type="clock-circle" theme="outlined" /> {value}</b>}</TimeAgo></span>
            {/* <Select
              showSearch
              placeholder="Trip Status"
              optionFilterProp="children"
              onChange={this.handleCategory}
              value={this.state.category}
              filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
              style={{ width: 150 }}
            >
              {this.renderCategory()}
            </Select>
            <Select
              showSearch
              placeholder="Driver Status"
              optionFilterProp="children"
              onChange={this.handlesubCategory}
              value={this.state.subcategory}
              disabled={this.state.disabled}
              filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
              style={{ width: 150, marginLeft: 10 }}
            >
              {this.renderSubCategory()}
            </Select> */}
            <Button type="primary" style={{ marginLeft: 15 }} onClick={this.handleResett}>
              Refresh
            </Button>

          </div>
          <Table columns={columns} dataSource={this.state.tdata} loading={this.state.loading}  bordered scroll={{ x: 1500  }} />
        </div>

      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    driver: state.driver,
    
  };
}


export default connect(mapStateToProps, { emailLogin, logout, DriverSettleUp, drivers, catList1, subcatList1, filterProduct, filtersubCat })(Drivers);
