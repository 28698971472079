import { auth, db } from "../firebase";
import {
  GET_USER,
  USER_STATUS,
  GET_DRIVER,
  GET_USERINFO,
} from "../actionTypes";
import moment from "moment";

export function getUser() {
  return (dispatch) => {
    // show loading status before getting user to true
    dispatch({ type: USER_STATUS, payload: true });
    auth.onAuthStateChanged((user) => {
      dispatch({ type: GET_USER, payload: user });

      if (user !== null) {
        db.collection("EmployeeMaster")
          .where("authId", "==", user.uid)
          .get()
          .then((querySnapshot) => {
            var userinfo = "";
            // console.log(querySnapshot.size, querySnapshot.empty);
            if (querySnapshot.empty) {
              dispatch({
                type: GET_USERINFO,
                payload: userinfo,
              });
            } else {
              querySnapshot.forEach(function (doc) {
                // console.log("Current data: ", doc.data());
                userinfo = doc.data();
                userinfo.id = doc.id;
                userinfo.Permission = [];

                db.collection("DesignationMaster")
                  .where("desgId", "==", doc.data().designation)
                  .get()
                  .then((ddoc) => {
                    //  console.log("User designation:", ddoc.empty);
                    if (ddoc.empty) {
                      dispatch({
                        type: GET_USERINFO,
                        payload: userinfo,
                      });
                    } else {
                      ddoc.forEach((s) => {
                        // console.log(s.data());
                        userinfo.Permission = s.data().Permissions;
                        //console.log(userinfo);
                        dispatch({
                          type: GET_USERINFO,
                          payload: userinfo,
                        });

                        // show loading status to false
                        dispatch({
                          type: USER_STATUS,
                          payload: false,
                        });
                      });
                    }
                  });
              });
            }
          });
      } else {
        dispatch({
          type: GET_USERINFO,
          payload: {},
        });
        dispatch({
          type: USER_STATUS,
          payload: false,
        });
      }
    });
  };
}

export function emailLogin(email, password) {
  return (dispatch) => auth.signInWithEmailAndPassword(email, password);
}

export function createuser(email, password, userid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then(function (user) {
          //console.log(user);
          const uid = user.user.uid;

          db.collection("Store_Manager")
            .doc(userid)
            .update({ Manager_Id: uid, IsActive: true })
            .then((result) => {
              //resolve(true);
              db.collection("Store_Manager")
                .doc(userid)
                .get()
                .then(function (doc) {
                  if (doc.exists) {
                    var storeid = doc.data().Ref_Store_Id;
                    var storemailid = doc.data().Manager_Email_Address;
                    //console.log(storeid);
                    db.collection("Stores_Master")
                      .doc(storeid)
                      .update({
                        IsActive: true,
                        Store_Availability_Status: true,
                      })
                      .then((result) => {
                        resolve(true);
                        processcomplete(storemailid);
                      })
                      .catch((err) => {
                        reject(err);
                      });

                    function processcomplete(storemailid) {
                      db.collection("Store_Request")
                        .where("Manager_Email_Address", "==", storemailid)
                        .get()
                        .then(function (querySnapshot) {
                          querySnapshot.forEach(function (doc) {
                            // doc.data() is never undefined for query doc snapshots
                            console.log(doc.id, " => ", doc.data());
                            db.collection("Store_Request")
                              .doc(doc.id)
                              .update({ Ref_MD_Store_Request_Status_Id: 2 })
                              .then((result) => {
                                resolve(result);
                              })
                              .catch((err) => {
                                reject(err);
                              });
                          });
                        })
                        .catch(function (error) {
                          console.log("Error getting documents: ", error);
                          reject(error);
                        });
                    }
                  } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                  }
                })
                .catch(function (error) {
                  console.log("Error getting document:", error);
                });
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch(function (error) {
          var errorCode = error.code;

          reject(errorCode);
        });
    });
  };
}

export function resetPassword(email) {
  return (dispatch) => auth.sendPasswordResetEmail(email);
}

export function updatePassword(email, password, newpassword) {
  console.log(newpassword, email);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const user = auth().currentUser;

      user
        .updatePassword(newpassword)
        .then(() => {
          // Update successful.
        })
        .catch((error) => {
          console.log(error);
          // An error ocurred
          // ...
        });
    });
  };
}

export function logout() {
  return (dispatch) => auth.signOut();
}

// export function allcustomer() {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       var customer_arr = [];
//       db.collection("Customer_Shared_Information")
//         .orderBy("Created_At", "desc")
//         .get()
//         .then((querySnapshot) => {
//           var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
//           console.log("last", lastVisible);

//           console.log(querySnapshot.size);
//           querySnapshot.forEach((doc) => {
//             // doc.data() is never undefined for query doc snapshots
//             var docRef = db.collection("Customer_Device_Details").doc(doc.id);
//             docRef
//               .get()
//               .then((customer_doc) => {
//                 console.log("Document data:", customer_doc.data());
//                 var obj_customer = {
//                   key: doc.id,
//                   Customer_Name:
//                     doc.data().Customer_First_Name +
//                     " " +
//                     doc.data().Customer_Last_Name,
//                   Customer_Email_Address: doc.data().Customer_Email_Address,
//                   Customer_Mobile_Number: doc
//                     .data()
//                     .Customer_Mobile_Number.toString(),
//                   App_Version:
//                     customer_doc.data() === undefined
//                       ? "-"
//                       : customer_doc.data().App_Version,
//                   Device_Make:
//                     customer_doc.data() === undefined
//                       ? "-"
//                       : customer_doc.data().Device_Make,
//                   Device_Model:
//                     customer_doc.data() === undefined
//                       ? "-"
//                       : customer_doc.data().Device_Model,
//                   Device_OS:
//                     customer_doc.data() === undefined
//                       ? "-"
//                       : customer_doc.data().Device_OS,
//                   create_at: moment(doc.data().Created_At.toDate()).format(
//                     "MMMM Do YYYY, h:mm:ss a"
//                   ),
//                   order_count: doc.data().Customer_Number_Of_Trips,
//                   Referral_Code: doc.data().Referral_Code,
//                 };

//                 customer_arr.push(obj_customer);
//                 if (querySnapshot.size === customer_arr.length) {
//                   dispatch({ type: GET_DRIVER, payload: customer_arr });
//                   resolve(customer_arr);
//                 }
//               })
//               .catch((error) => {
//                 console.log("Error getting document:", error);
//               });
//           });
//         });
//     });
//   };
// }

// export function allcustomer(sdate, edate) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       var arr_country_code = [];
//       var arr_device = [];
//       var arr_address = [];
//       db.collection("MD_Country_Code")
//         .get()
//         .then((snap_country) => {
//           snap_country.forEach((doc_country) => {
//             var country_autoId = doc_country.id,
//               country_code = doc_country.data().Country_Code;
//             var obj_country_code = {
//               country_autoId: country_autoId,
//               country_code: country_code,
//             };
//             arr_country_code.push(obj_country_code);
//           });
//           get_user_address(arr_country_code);
//         })
//         .catch((err) => {
//           console.log("Error getting country code", err);
//           reject(err);
//         });
//       function get_user_address(arr_country_code) {
//         db.collection("Customer_Address")
//           .get()
//           .then((snap_country) => {
//             snap_country.forEach((doc_country) => {
//               var Ref_Customer_Id = doc_country.data().Ref_Customer_Id;
//               var Full_Address = doc_country.data().Full_Address;
//               var Post_Code = doc_country.data().Post_Code;
//               var obj_country_code = {
//                 Ref_Customer_Id: Ref_Customer_Id,
//                 Full_Address: Full_Address,
//                 Post_Code: Post_Code,
//               };
//               arr_address.push(obj_country_code);
//             });
//             get_device_details(arr_address, arr_country_code);
//           })
//           .catch((err) => {
//             console.log("Error getting country code", err);
//             reject(err);
//           });
//       }

//       function get_device_details(arr_address, arr_country_code) {
//         db.collection("Customer_Device_Details")
//           .get()
//           .then((snap_country) => {
//             console.log(snap_country.size);
//             snap_country.forEach((doc_country) => {
//               var customer_id = doc_country.id;
//               var App_Version =
//                 doc_country.data().App_Version === undefined
//                   ? "-"
//                   : doc_country.data().App_Version;
//               var Device_Make = doc_country.data().Device_Make;
//               var Device_Model = doc_country.data().Device_Model;
//               var Device_OS = doc_country.data().Device_OS;
//               var obj_country_code = {
//                 customer_id: customer_id,
//                 App_Version: App_Version,
//                 Device_Make: Device_Make,
//                 Device_Model: Device_Model,
//                 Device_OS: Device_OS,
//               };
//               arr_device.push(obj_country_code);
//             });
//             //console.log(arr_device)
//             get_country_code(arr_address, arr_country_code, arr_device);
//           })
//           .catch((err) => {
//             console.log("Error getting country code", err);
//             reject(err);
//           });
//       }

//       function get_country_code(arr_address, arr_country_code, arr_device) {
//         const fdate = moment(sdate);
//         const tdate = moment(edate);
//         const from_date = fdate.startOf("day").format();
//         const to_date = tdate.endOf("day").format();
//         const start = new Date(from_date);
//         const end = new Date(to_date);

//         var arr_customer_status = [];
//         db.collection("Customer_Shared_Information")
//           .where("Created_At", ">", start)
//           .where("Created_At", "<", end)
//           .orderBy("Created_At", "desc")
//           .get()
//           .then((snap_customer_status) => {
//             var snap_size = snap_customer_status.size;
//             console.log(snap_size);
//             snap_customer_status.forEach((doc_driver_status) => {
//               var dri_doc_id = doc_driver_status.id;
//               // console.log(dri_doc_id);
//               var Customer_Email_Address =
//                 doc_driver_status.data().Customer_Email_Address;
//               var Customer_First_Name =
//                 doc_driver_status.data().Customer_First_Name;
//               var Customer_Last_Name =
//                 doc_driver_status.data().Customer_Last_Name;
//               var Customer_Mobile_Number =
//                 doc_driver_status.data().Customer_Mobile_Number;
//               var Created_At = moment(
//                 doc_driver_status.data().Created_At.toDate()
//               ).format("MMMM Do YYYY, h:mm:ss a");
//               var Ref_Customer_Country_Code_Id =
//                 doc_driver_status.data().Ref_Customer_Country_Code_Id;
//               var order_count =
//                 doc_driver_status.data().Customer_Number_Of_Trips;
//               var Referral_Code = doc_driver_status.data().Referral_Code;
//               // Customer Country Code
//               if (Ref_Customer_Country_Code_Id) {
//                 var selectecountry_code =
//                   arr_country_code[
//                     arr_country_code
//                       .map(function (item) {
//                         return item.country_autoId;
//                       })
//                       .indexOf(Ref_Customer_Country_Code_Id)
//                   ];
//                 var Country_code = selectecountry_code.country_code;
//               } else {
//                 Country_code = "";
//               }

//               //Customer Device Information
//               if (dri_doc_id) {
//                 // console.log(dri_doc_id)
//                 var selectedevice_code =
//                   arr_device[
//                     arr_device
//                       .map(function (item) {
//                         return item.customer_id;
//                       })
//                       .indexOf(dri_doc_id)
//                   ];
//                 if (selectedevice_code !== undefined) {
//                   var App_Version = selectedevice_code.App_Version;
//                   var Device_Make = selectedevice_code.Device_Make;
//                   var Device_Model = selectedevice_code.Device_Model;
//                   var Device_OS = selectedevice_code.Device_OS;
//                 } else {
//                   App_Version = "-";
//                   Device_Make = "-";
//                   Device_Model = "-";
//                   Device_OS = "-";
//                 }
//               }

//               if (dri_doc_id) {
//                 // console.log(dri_doc_id)
//                 var selected_address =
//                   arr_address[
//                     arr_address
//                       .map(function (item) {
//                         return item.Ref_Customer_Id;
//                       })
//                       .indexOf(dri_doc_id)
//                   ];
//                 if (selected_address !== undefined) {
//                   var Full_Address = selected_address.Full_Address;
//                   var Post_Code = selected_address.Post_Code;
//                 } else {
//                   Full_Address = "-";
//                   Post_Code = "-";
//                 }
//               }

//               var dri_phone_num = "+" + Country_code + Customer_Mobile_Number;
//               var obj_customer = {
//                 key: dri_doc_id,
//                 Customer_Name: Customer_First_Name + " " + Customer_Last_Name,
//                 Customer_Email_Address: Customer_Email_Address,
//                 Customer_Mobile_Number: dri_phone_num,
//                 App_Version: App_Version,
//                 Device_Make: Device_Make,
//                 Device_Model: Device_Model,
//                 Device_OS: Device_OS,
//                 create_at: Created_At,
//                 order_count: order_count,
//                 Full_Address: Full_Address,
//                 Post_Code: Post_Code,
//                 Referral_Code: Referral_Code,
//                 //Ref_Customer_Country_Code_Id: Ref_Customer_Country_Code_Id
//               };
//               arr_customer_status.push(obj_customer);
//             });
//             var arr_length = arr_customer_status.length;
//             if (arr_length === snap_size) {
//               dispatch({ type: GET_DRIVER, payload: arr_customer_status });
//               resolve(arr_customer_status);
//             }
//           })
//           .catch((err) => {
//             console.log("Error getting documents", err);
//             reject(err);
//           });
//         //country code
//       }
//     });
//   };
// }

export function allcustomer(sdate, edate) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const fdate = moment(sdate);
      const tdate = moment(edate);
      const from_date = fdate.startOf("day").format();
      const to_date = tdate.endOf("day").format();
      const start = new Date(from_date);
      const end = new Date(to_date);
      var arr_country_code = [];
      var arr_store_info = [];
      var arr_city_info = [];
      var arr_customer_address = [];
      var arr_customer_status = [];
      db.collection("MD_Country_Code")
        .get()
        .then((snap_country) => {
          snap_country.forEach((doc_country) => {
            var country_autoId = doc_country.id,
              country_code = doc_country.data().Country_Code;
            var obj_country_code = {
              country_autoId: country_autoId,
              country_code: country_code,
            };
            arr_country_code.push(obj_country_code);
          });
          store_info(arr_country_code);
        })
        .catch((err) => {
          console.log("Error getting country code", err);
          reject(err);
        });
      function store_info() {
        db.collection("Stores_Master")
          .get()
          .then((storedoc) => {
            storedoc.forEach((doc_store_status) => {
              var store_id = doc_store_status.id;
              var store_name = doc_store_status.data().Store_Name;
              var obj_order_status = {
                store_id: store_id,
                store_name: store_name,
              };
              arr_store_info.push(obj_order_status);
            });
            Operating_City_Master();
          })

          .catch((err) => {
            console.log("Error getting Stores_Master", err);
            // reject(err);
          });
      }
      function Operating_City_Master() {
        db.collection("Operating_City_Master")
          .get()
          .then((storedoc) => {
            storedoc.forEach((doc_store_status) => {
              var City_id = doc_store_status.id;
              var City_Name = doc_store_status.data().City_Name;
              var obj_order_status = {
                City_id: City_id,
                City_Name: City_Name,
              };
              arr_city_info.push(obj_order_status);
            });
            get_user_address();
          })
          .catch((err) => {
            console.log("Error getting documents", err);
            // reject(err);
          });
      }
      function get_user_address() {
        if (sdate && edate) {
          db.collection("Customer_Address")
            .where("Created_At", ">", start)
            .where("Created_At", "<", end)
            .get()
            .then((snap_country) => {
              snap_country.forEach((doc_country) => {
                var Ref_Customer_Id = doc_country.data().Ref_Customer_Id;
                var Full_Address = doc_country.data().Full_Address;
                var Post_Code = doc_country.data().Post_Code;
                var obj_country_code = {
                  Ref_Customer_Id: Ref_Customer_Id,
                  Full_Address: Full_Address,
                  Post_Code: Post_Code,
                };
                arr_customer_address.push(obj_country_code);
              });
              customer_information();
            })
            .catch((err) => {
              console.log("Error getting country code", err);
              reject(err);
            });
        } else {
          db.collection("Customer_Address")
            .get()
            .then((snap_country) => {
              snap_country.forEach((doc_country) => {
                var Ref_Customer_Id = doc_country.data().Ref_Customer_Id;
                var Full_Address = doc_country.data().Full_Address;
                var Post_Code = doc_country.data().Post_Code;
                var obj_country_code = {
                  Ref_Customer_Id: Ref_Customer_Id,
                  Full_Address: Full_Address,
                  Post_Code: Post_Code,
                };
                arr_customer_address.push(obj_country_code);
              });
              customer_information();
            })
            .catch((err) => {
              console.log("Error getting country code", err);
              reject(err);
            });
        }
      }
      function customer_information() {
        if (sdate && edate) {
          db.collection("Customer_Shared_Information")
            .where("Created_At", ">", start)
            .where("Created_At", "<", end)
            .orderBy("Created_At", "desc")
            .get()
            .then((snap_customer_status) => {
              var snap_size = snap_customer_status.size;
              console.log(snap_size);
              if (snap_size !== 0) {
                snap_customer_status.forEach((doc_driver_status) => {
                  var dri_doc_id = doc_driver_status.id;
                  console.log(dri_doc_id);
                  var Customer_Email_Address =
                    doc_driver_status.data().Customer_Email_Address;
                  var Customer_First_Name =
                    doc_driver_status.data().Customer_First_Name;
                  var Customer_Last_Name =
                    doc_driver_status.data().Customer_Last_Name;
                  var Customer_Mobile_Number =
                    doc_driver_status.data().Customer_Mobile_Number;
                  var Created_At = moment(
                    doc_driver_status.data().Created_At.toDate()
                  ).format("MMMM Do YYYY, h:mm:ss a");
                  var Ref_Customer_Country_Code_Id =
                    doc_driver_status.data().Ref_Customer_Country_Code_Id;
                  var order_count =
                    doc_driver_status.data().Customer_Number_Of_Trips ===
                    undefined
                      ? "-"
                      : doc_driver_status.data().Customer_Number_Of_Trips;
                  var Referral_Code = doc_driver_status.data().Referral_Code;
                  var Ref_Customer_City_Id =
                    doc_driver_status.data().Ref_Customer_City_Id;
                  var Ref_Customer_Store_Id =
                    doc_driver_status.data().Ref_Customer_Store_Id;

                  // Customer Country Code
                  if (Ref_Customer_Country_Code_Id) {
                    var selectecountry_code =
                      arr_country_code[
                        arr_country_code
                          .map(function (item) {
                            return item.country_autoId;
                          })
                          .indexOf(Ref_Customer_Country_Code_Id)
                      ];
                    var Country_code = selectecountry_code.country_code;
                  } else {
                    Country_code = "";
                  }

                  // Customer City Master
                  if (Ref_Customer_City_Id) {
                    var selectcity =
                      arr_city_info[
                        arr_city_info
                          .map(function (item) {
                            return item.City_id;
                          })
                          .indexOf(Ref_Customer_City_Id)
                      ];
                    var cityname = selectcity.City_Name;
                  } else {
                    cityname = "-";
                  }

                  // Customer Store Master
                  if (Ref_Customer_Store_Id) {
                    var selectstore =
                      arr_store_info[
                        arr_store_info
                          .map(function (item) {
                            return item.store_id;
                          })
                          .indexOf(Ref_Customer_Store_Id)
                      ];
                    if (selectstore !== undefined) {
                      var storename = selectstore.store_name;
                    } else {
                      storename = "-";
                    }
                  } else {
                    storename = "-";
                  }

                  if (dri_doc_id) {
                    // console.log(dri_doc_id)
                    var selected_address =
                      arr_customer_address[
                        arr_customer_address
                          .map(function (item) {
                            return item.Ref_Customer_Id;
                          })
                          .indexOf(dri_doc_id)
                      ];
                    if (selected_address !== undefined) {
                      var Full_Address = selected_address.Full_Address;
                      var Post_Code = selected_address.Post_Code;
                    } else {
                      Full_Address = "-";
                      Post_Code = "-";
                    }
                  } else {
                    Full_Address = "-";
                    Post_Code = "-";
                  }

                  var dri_phone_num =
                    "+" + Country_code + Customer_Mobile_Number;
                  var obj_customer = {
                    key: dri_doc_id,
                    Customer_Name:
                      Customer_First_Name + " " + Customer_Last_Name,
                    Customer_Email_Address: Customer_Email_Address,
                    Customer_Mobile_Number: dri_phone_num,
                    create_at: Created_At,
                    order_count: order_count.toString(),
                    Referral_Code: Referral_Code,
                    cityname,
                    storename,
                    Full_Address,
                    Post_Code,
                  };
                  arr_customer_status.push(obj_customer);
                  var arr_length = arr_customer_status.length;
                  console.log(arr_length, snap_size);
                  if (arr_length === snap_size) {
                    dispatch({
                      type: GET_DRIVER,
                      payload: arr_customer_status,
                    });
                    resolve(arr_customer_status);
                  }
                });
              } else {
                reject("No Data Found");
              }
            })
            .catch((err) => {
              console.log("Error getting documents", err);
              reject(err);
            });
        } else {
          db.collection("Customer_Shared_Information")
            .orderBy("Created_At", "desc")
            .get()
            .then((snap_customer_status) => {
              var snap_size = snap_customer_status.size;
              console.log(snap_size);
              snap_customer_status.forEach((doc_driver_status) => {
                var dri_doc_id = doc_driver_status.id;
                console.log(dri_doc_id);
                var Customer_Email_Address =
                  doc_driver_status.data().Customer_Email_Address;
                var Customer_First_Name =
                  doc_driver_status.data().Customer_First_Name;
                var Customer_Last_Name =
                  doc_driver_status.data().Customer_Last_Name;
                var Customer_Mobile_Number =
                  doc_driver_status.data().Customer_Mobile_Number;
                var Created_At = moment(
                  doc_driver_status.data().Created_At.toDate()
                ).format("MMMM Do YYYY, h:mm:ss a");
                var Ref_Customer_Country_Code_Id =
                  doc_driver_status.data().Ref_Customer_Country_Code_Id;
                var order_count =
                  doc_driver_status.data().Customer_Number_Of_Trips ===
                  undefined
                    ? "-"
                    : doc_driver_status.data().Customer_Number_Of_Trips;
                var Referral_Code = doc_driver_status.data().Referral_Code;
                var Ref_Customer_City_Id =
                  doc_driver_status.data().Ref_Customer_City_Id;
                var Ref_Customer_Store_Id =
                  doc_driver_status.data().Ref_Customer_Store_Id;

                // Customer Country Code
                if (Ref_Customer_Country_Code_Id) {
                  var selectecountry_code =
                    arr_country_code[
                      arr_country_code
                        .map(function (item) {
                          return item.country_autoId;
                        })
                        .indexOf(Ref_Customer_Country_Code_Id)
                    ];
                  var Country_code = selectecountry_code.country_code;
                } else {
                  Country_code = "";
                }

                // Customer City Master
                if (Ref_Customer_City_Id) {
                  var selectcity =
                    arr_city_info[
                      arr_city_info
                        .map(function (item) {
                          return item.City_id;
                        })
                        .indexOf(Ref_Customer_City_Id)
                    ];
                  var cityname = selectcity.City_Name;
                } else {
                  cityname = "-";
                }

                // Customer Store Master
                if (Ref_Customer_Store_Id) {
                  var selectstore =
                    arr_store_info[
                      arr_store_info
                        .map(function (item) {
                          return item.store_id;
                        })
                        .indexOf(Ref_Customer_Store_Id)
                    ];
                  if (selectstore !== undefined) {
                    var storename = selectstore.store_name;
                  } else {
                    storename = "-";
                  }
                } else {
                  storename = "-";
                }

                if (dri_doc_id) {
                  // console.log(dri_doc_id)
                  var selected_address =
                    arr_customer_address[
                      arr_customer_address
                        .map(function (item) {
                          return item.Ref_Customer_Id;
                        })
                        .indexOf(dri_doc_id)
                    ];
                  if (selected_address !== undefined) {
                    var Full_Address = selected_address.Full_Address;
                    var Post_Code = selected_address.Post_Code;
                  } else {
                    Full_Address = "-";
                    Post_Code = "-";
                  }
                } else {
                  Full_Address = "-";
                  Post_Code = "-";
                }

                var dri_phone_num = "+" + Country_code + Customer_Mobile_Number;
                var obj_customer = {
                  key: dri_doc_id,
                  Customer_Name: Customer_First_Name + " " + Customer_Last_Name,
                  Customer_Email_Address: Customer_Email_Address,
                  Customer_Mobile_Number: dri_phone_num,
                  create_at: Created_At,
                  order_count: order_count.toString(),
                  Referral_Code: Referral_Code,
                  cityname,
                  storename,
                  Full_Address,
                  Post_Code,
                };
                arr_customer_status.push(obj_customer);
                var arr_length = arr_customer_status.length;
                console.log(arr_length, snap_size);
                if (arr_length === snap_size) {
                  dispatch({
                    type: GET_DRIVER,
                    payload: arr_customer_status,
                  });
                  resolve(arr_customer_status);
                }
              });
            })
            .catch((err) => {
              console.log("Error getting documents", err);
              reject(err);
            });
        }

        //country code
      }
    });
  };
}

export function customerbyphone(phonenumber) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let Orderquery;

      var arr_country_code = [];
      var arr_store_info = [];
      var arr_city_info = [];
      var arr_customer_status = [];
      db.collection("MD_Country_Code")
        .get()
        .then((snap_country) => {
          snap_country.forEach((doc_country) => {
            var country_autoId = doc_country.id,
              country_code = doc_country.data().Country_Code;
            var obj_country_code = {
              country_autoId: country_autoId,
              country_code: country_code,
            };
            arr_country_code.push(obj_country_code);
          });
          store_info(arr_country_code);
        })
        .catch((err) => {
          console.log("Error getting country code", err);
          reject(err);
        });
      function store_info() {
        db.collection("Stores_Master")
          .get()
          .then((storedoc) => {
            storedoc.forEach((doc_store_status) => {
              var store_id = doc_store_status.id;
              var store_name = doc_store_status.data().Store_Name;
              var obj_order_status = {
                store_id: store_id,
                store_name: store_name,
              };
              arr_store_info.push(obj_order_status);
            });
            Operating_City_Master();
          })

          .catch((err) => {
            console.log("Error getting Stores_Master", err);
            // reject(err);
          });
      }
      function Operating_City_Master() {
        db.collection("Operating_City_Master")
          .get()
          .then((storedoc) => {
            storedoc.forEach((doc_store_status) => {
              var City_id = doc_store_status.id;
              var City_Name = doc_store_status.data().City_Name;
              var obj_order_status = {
                City_id: City_id,
                City_Name: City_Name,
              };
              arr_city_info.push(obj_order_status);
            });
            customer_information();
          })
          .catch((err) => {
            console.log("Error getting documents", err);
            // reject(err);
          });
      }

      async function customer_information() {
        Orderquery = await db
          .collection("Customer_Shared_Information")
          .where("Customer_Mobile_Number", "==", parseInt(phonenumber))
          .get();

        try {
          var snap_size = Orderquery.size;
          console.log(snap_size);
          if (snap_size !== 0) {
            Orderquery.forEach((doc_driver_status) => {
              var dri_doc_id = doc_driver_status.id;
              // console.log(dri_doc_id);
              var Customer_Email_Address =
                doc_driver_status.data().Customer_Email_Address;
              var Customer_First_Name =
                doc_driver_status.data().Customer_First_Name;
              var Customer_Last_Name =
                doc_driver_status.data().Customer_Last_Name;
              var Customer_Mobile_Number =
                doc_driver_status.data().Customer_Mobile_Number;
              var Created_At = moment(
                doc_driver_status.data().Created_At.toDate()
              ).format("MMMM Do YYYY, h:mm:ss a");
              var Ref_Customer_Country_Code_Id =
                doc_driver_status.data().Ref_Customer_Country_Code_Id;
              var order_count =
                doc_driver_status.data().Customer_Number_Of_Trips === undefined
                  ? "-"
                  : doc_driver_status.data().Customer_Number_Of_Trips;
              var Referral_Code = doc_driver_status.data().Referral_Code;
              var Ref_Customer_City_Id =
                doc_driver_status.data().Ref_Customer_City_Id;
              var Ref_Customer_Store_Id =
                doc_driver_status.data().Ref_Customer_Store_Id;

              // Customer Country Code
              if (Ref_Customer_Country_Code_Id) {
                var selectecountry_code =
                  arr_country_code[
                    arr_country_code
                      .map(function (item) {
                        return item.country_autoId;
                      })
                      .indexOf(Ref_Customer_Country_Code_Id)
                  ];
                var Country_code = selectecountry_code.country_code;
              } else {
                Country_code = "";
              }

              // Customer City Master
              if (Ref_Customer_City_Id) {
                var selectcity =
                  arr_city_info[
                    arr_city_info
                      .map(function (item) {
                        return item.City_id;
                      })
                      .indexOf(Ref_Customer_City_Id)
                  ];
                var cityname = selectcity.City_Name;
              } else {
                cityname = "-";
              }

              // Customer Store Master
              if (Ref_Customer_Store_Id) {
                var selectstore =
                  arr_store_info[
                    arr_store_info
                      .map(function (item) {
                        return item.store_id;
                      })
                      .indexOf(Ref_Customer_Store_Id)
                  ];
                var storename = selectstore.store_name;
              } else {
                storename = "-";
              }

              db.collection("Customer_Address")
                .doc(dri_doc_id)
                .get()
                .then((cust_doc) => {
                  if (cust_doc.exists) {
                    var Full_Address = cust_doc.data().Full_Address;
                    var Post_Code = cust_doc.data().Post_Code;
                  } else {
                    Full_Address = "-";
                    Post_Code = "-";
                  }

                  var dri_phone_num =
                    "+" + Country_code + Customer_Mobile_Number;
                  var obj_customer = {
                    key: dri_doc_id,
                    Customer_Name:
                      Customer_First_Name + " " + Customer_Last_Name,
                    Customer_Email_Address: Customer_Email_Address,
                    Customer_Mobile_Number: dri_phone_num,
                    create_at: Created_At,
                    order_count: order_count.toString(),
                    Referral_Code: Referral_Code,
                    cityname,
                    storename,
                    Full_Address,
                    Post_Code,
                  };
                  arr_customer_status.push(obj_customer);
                  var arr_length = arr_customer_status.length;
                  console.log(arr_length, snap_size);
                  if (arr_length === snap_size) {
                    dispatch({
                      type: GET_DRIVER,
                      payload: arr_customer_status,
                    });
                    resolve(arr_customer_status);
                  }
                })
                .catch((error) => {
                  console.log(error.message);
                  reject(error.message);
                });
            });
          } else {
            reject("No Data Found");
          }
        } catch (err) {
          console.log("Error getting documents", err);
          reject(err.message);
        }
        //country code
      }
    });
  };
}

export function authuser(email) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(email);
      db.collection("EmployeeMaster")
        .where("Email", "==", email)
        .where("IsAvailable", "==", true)
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.size !== 0) {
            resolve("success");
          } else {
            reject("usernotfound");
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

export function user_device_details(userid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Customer_Device_Details")
        .doc(userid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Document data:", doc.data());
            var App_Version = doc.data().App_Version;
            var Device_Make = doc.data().Device_Make;
            var Device_Model = doc.data().Device_Model;
            var Device_OS = doc.data().Device_OS;
            var obj = {
              App_Version,
              Device_Make,
              Device_Model,
              Device_OS,
            };
            resolve(obj);
          } else {
            // doc.data() will be undefined in this case
            reject("No such document!");
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    });
  };
}

// getdesignation
export function getdesignation(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("DesignationMaster")
        .where("desgId", "==", id)
        .onSnapshot(function (doc) {
          // console.log("Current data:", doc);
          if (doc.empty) {
            reject("Not found");
          } else {
            doc.forEach((s) => {
              // console.log(s.data())
              resolve(s.data());
            });
          }
        });
    });
  };
}
