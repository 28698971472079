import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Row,
  Button,
  message,
  Spin,
  Select,
  Empty,
  Avatar,
} from "antd";
import { allstorename, Add_Promo_Type } from "../actions/assetAction";
import _ from "lodash";
import { db } from "../firebase";
import request from "superagent";

const { TextArea } = Input;
const { Option } = Select;
let bucket_baseurl;

class Homebanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList1: "",
      data: [],
      products: [],
      fetching: false,
      StoreID: "",
      loading: false,
      callouttype: "",
    };
  }
  componentWillMount() {
    this.props.allstorename();
    db.collection("Constants")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          bucket_baseurl = doc.data().Bucket_Base_Url;
        });
      });
  }
  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          {storeall.store_name}
        </Option>
      );
    });
  }
  handleStoreID = (value) => {
    this.setState({ StoreID: value });
  };

  fetchUser = async (value) => {
    //console.log('fetching user', value.length);
    if (value.length > 3) {
      this.setState({ data: [], fetching: true });
      try {
        const res = await request
          .post(
            "https://europe-west2-shopezy-a4bc4.cloudfunctions.net/algoliasearch"
          )
          .send({
            searchkey: value,
            storeid: this.state.StoreID,
          });

        // res.body, res.headers, res.status
        console.log(res.body);
        if (res.body.ReturnCode === 201) {
          this.setState({ fetching: false, loading: false });
        } else {
          const data = res.body.data.map((user) => ({
            text: user.Product_Id + "-" + user.Product_Name,
            value: user.objectID,
            proimg: bucket_baseurl + user.Product_Images[0],
          }));
          this.setState({
            data,
            fetching: false,
          });
        }
      } catch (err) {
        return err;
      }
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        console.log(values, this.state.products);
        this.props
          .Add_Promo_Type(values, this.state.products)
          .then((res) => {
            message.success("Data Saved Sucessfully!");
            this.setState({ loading: false });
          })
          .catch((err) => {
            message.err("Something went wrong!");
          });
      }
    });
  };
  handleChange = (value) => {
    const results = value.map(function (obj) {
      return obj.key;
    });
    console.log(results);
    this.setState({ products: results });
  };
  callouttype = (val) => {
    this.setState({ callouttype: val });
  };
  //==================== Render Method
  render() {
    const { fetching, data } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Add Homebanner</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid text-left addproduct">
          <Spin spinning={this.state.loading} delay={500}>
            <Row>
              <Form onSubmit={this.handleSubmit}>
                <Form.Item {...formItemLayout} label="Store">
                  {getFieldDecorator("Ref_Store_Id_List", {
                    rules: [
                      { required: true, message: "Select any one store" },
                    ],
                  })(
                    <Select
                      style={{
                        width: 340,
                      }}
                      onChange={this.handleStoreID}
                      placeholder="Select Store"
                    >
                      {this.renderStorename()}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Callout Type">
                  {getFieldDecorator("Callout_Type", {
                    rules: [{ required: true, message: "Select callout type" }],
                  })(
                    <Select
                      onChange={this.callouttype}
                      style={{ width: 340 }}
                      placeholder="Select Type"
                    >
                      <Option value="1">Products</Option>
                      <Option value="2">Detail</Option>
                      <Option value="3">General</Option>
                      <Option value="4">Referral</Option>
                    </Select>
                  )}
                </Form.Item>
                {this.state.callouttype === "1" ||
                this.state.callouttype === "4" ? (
                  <Form.Item
                    {...formItemLayout}
                    label="Offer Title / Coupon Code"
                  >
                    {getFieldDecorator("Offer_title", {
                      rules: [
                        {
                          required: false,
                          message: "Enter offer title",
                          whitespace: false,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                ) : (
                  <Form.Item
                    {...formItemLayout}
                    label="Offer Title / Coupon Code"
                  >
                    {getFieldDecorator("Offer_title", {
                      rules: [
                        {
                          required: true,
                          message: "Enter offer title",
                          whitespace: false,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                )}

                <Form.Item {...formItemLayout} label="Offer Name">
                  {getFieldDecorator("Offer_Name", {
                    rules: [
                      {
                        required: true,
                        message: "Enter offer name",
                        whitespace: false,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>

                <Form.Item {...formItemLayout} label="Offer Banner Image URL">
                  {getFieldDecorator("Offer_Banner_Image", {
                    rules: [
                      {
                        required: true,
                        message: "Enter image URL",
                        whitespace: false,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>

                <Form.Item {...formItemLayout} label="Display Order">
                  {getFieldDecorator("Banner_Display_Order", {
                    rules: [
                      {
                        required: true,
                        pattern: new RegExp("^[0-9]*$"),
                        message: "Display order format wrong!",
                      },
                    ],
                  })(<Input placeholder="0" />)}
                </Form.Item>
                {this.state.callouttype === "1" ||
                this.state.callouttype === "4" ? (
                  <Form.Item {...formItemLayout} label="Offer Description">
                    {getFieldDecorator("Offer_Description", {
                      rules: [
                        {
                          required: false,
                          message: "Enter Category Description",
                          whitespace: false,
                        },
                      ],
                    })(<TextArea rows={5} />)}
                  </Form.Item>
                ) : (
                  <Form.Item {...formItemLayout} label="Offer Description">
                    {getFieldDecorator("Offer_Description", {
                      rules: [
                        {
                          required: true,
                          message: "Enter Category Description",
                          whitespace: false,
                        },
                      ],
                    })(<TextArea rows={5} />)}
                  </Form.Item>
                )}
                {this.state.callouttype === "1" ||
                this.state.callouttype !== "4" ? (
                  <Form.Item {...formItemLayout} label="Products">
                    {getFieldDecorator("Products", {
                      rules: [{ required: true, message: "Select products" }],
                    })(
                      <Select
                        showSearch
                        labelInValue
                        placeholder="Type 4 characters to search..!"
                        notFoundContent={
                          fetching ? <Spin size="small" /> : <Empty />
                        }
                        filterOption={false}
                        onSearch={this.fetchUser}
                        defaultActiveFirstOption={false}
                        onChange={this.handleChange}
                        mode="multiple"
                        style={{
                          width: "100%",
                        }}
                        disabled={this.state.StoreID === "" ? true : false}
                      >
                        {data.map((d) => (
                          <Option key={d.value}>
                            <Avatar shape="square" src={d.proimg} /> {d.text}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : null}

                {/* =============================== Submit Button ======================================== */}

                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={this.state.iconLoading}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ marginLeft: 15 }}
                    size="large"
                    onClick={this.handleReset}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            </Row>
          </Spin>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    storeall: state.storeall,
  };
}

const FormCompoent = Form.create()(Homebanner);
export default connect(mapStateToProps, { allstorename, Add_Promo_Type })(
  FormCompoent
);
