import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../actions/userAction";
import { Layout, Icon, Avatar, Typography, Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
const { Header } = Layout;
const { Text } = Typography;

class TopHeader extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      current: "1",
      volume: 0,
    };
  }

  render() {
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Link to="/logout" onClick={() => this.props.logout()}>
            <Icon type="logout" />
            <span className="nav-text"> Logout</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="1">
          <Link to="/changepassword">
            <Icon type="logout" />
            <span className="nav-text"> Change Password</span>
          </Link>
        </Menu.Item> */}
      </Menu>
    );
    return (
      <Header className="text-right">
        <Dropdown overlay={menu} trigger={["click"]}>
          <span>
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              icon="user"
            />

            <Text style={{ marginLeft: 5 }}>
              Welcome! {this.props.userinfo.Name}
            </Text>
          </span>
        </Dropdown>
      </Header>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, userinfo: state.userinfo };
}

export default connect(mapStateToProps, { logout })(TopHeader);
