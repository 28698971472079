import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { emailLogin, logout } from "../actions/userAction";
import {
  saveAsset,
  productList1,
  catList1,
  subcatList1,
  filterProduct1,
  filtersubCat,
  allstorename,
} from "../actions/assetAction";
import { db } from "../firebase";
import {
  Icon,
  message,
  Popconfirm,
  Select,
  Avatar,
  Button,
  Input,
  Table,
  Modal,
  Tag,
  Switch,
  Divider,
  Row,
  Form,
  Col,
  Tooltip,
} from "antd";
//import 'antd/dist/antd.css';
import "../styles/css/App.css";
import Highlighter from "react-highlight-words";
import firebase from "firebase/app";
import "firebase/firestore";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Option = Select.Option;

class Products extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      disabled: true,
      searchText: "",
      tdata: null,
      loading: false,
      btnloading: false,
      mimage: "",
      showfilter: false,
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  confirmdelete = (key) => {
    console.log(key);
    db.collection("Product_Details1")
      .doc(key)
      .update({
        Is_Active: false,
        Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function () {
        message.success("Product successfully deleted!");
      })
      .catch(function (error) {
        message.error("Error removing document: ", error);
      });
    const tdata = [...this.state.tdata];
    this.setState({ tdata: tdata.filter((item) => item.key !== key) });
  };

  cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }

  handleCategory = (value) => {
    this.setState({
      category: value,
      subcategory: "",
      disabled: false,
    });
    this.props.filtersubCat(value);
  };

  handlesubCategory = (value) => {
    this.setState({
      subcategory: value,
      loading: true,
    });
    var cat = this.state.category;
    var subcat = value;
    var StoreID = this.state.StoreID;
    this.props
      .filterProduct1(cat, subcat, StoreID)
      .then((res) => {
        console.log(res);
        this.setState({
          tdata: this.props.products,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.props.catList1();
    this.props.subcatList1();
    this.props.allstorename().then((res) => {
      var ref_store_id_promo = sessionStorage.getItem("ref_store_id_promo");
      if (ref_store_id_promo) {
        this.props.form.setFieldsValue({
          store_name: ref_store_id_promo,
        });
        this.setState({ btnloading: true, StoreID: ref_store_id_promo });
        this.props
          .productList1(ref_store_id_promo)
          .then((res) => {
            console.log(res);
            this.setState({
              tdata: this.props.products,
              showfilter: true,
              btnloading: false,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              showfilter: false,
              btnloading: false,
            });
          });
      }
    });
  }

  // ======================================================

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        sessionStorage.setItem("ref_store_id_promo", values.store_name);
        this.setState({ btnloading: true });
        this.props
          .productList1(values.store_name)
          .then((res) => {
            console.log(res);
            this.setState({
              tdata: this.props.products,
              btnloading: false,
              loading: false,
              showfilter: true,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              btnloading: false,
              loading: false,
              showfilter: false,
            });
          });
      }
    });
  };
  handleClearbtn = () => {
    this.props.form.resetFields();
    this.setState({ tdata: [], showfilter: false });
    sessionStorage.removeItem("ref_store_id_promo");
  };

  handleResetbtn = () => {
    this.setState({
      category: "",
      disabled: true,
      subcategory: "",
      loading: true,
    });
    this.props
      .productList1(this.state.StoreID)
      .then((res) => {
        console.log(res);
        this.setState({
          tdata: this.props.products,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };
  renderCategory() {
    return _.map(this.props.categories, (categories, key) => {
      return (
        <Option value={categories.id} key={key}>
          <Avatar src={categories.imgUrl} />
          {categories.cname}
          {/* ({categories.cid})  */}
        </Option>
      );
    });
  }
  renderSubCategory() {
    return _.map(this.props.subcategories, (subcategories, key) => {
      return (
        <Option value={subcategories.id} key={key}>
          <Avatar src={subcategories.imgUrl} />
          {subcategories.sname}
          {/* ({subcategories.sid})  */}
        </Option>
      );
    });
  }
  renderNotes() {
    return _.map(this.state.tdata, (products, key) => {
      return (
        <tr key={key}>
          <td>{products.imgUrl}</td>
          <td>{products.pid}</td>
          <td>{products.Product_Discounted_Price}</td>
          <td>{products.Product_Price}</td>
          <td>{products.pname}</td>
          <td>{products.catname}</td>
          <td>{products.subcatname}</td>
          <td>{products.isstock.toString()}</td>
          <td>{products.updated_at}</td>
        </tr>
      );
    });
  }
  handleMenuClick = (e) => {
    //message.info('Click on menu item.');
    console.log("click", e.key);
    this.props.history.push(e.key);
  };

  showModal = (e, data) => {
    console.log(data);

    this.setState({
      visible: true,
      mimage: data,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  onChange = (checked, key) => {
    console.log(`switch to ${checked}`);
    console.log(key);
    this.setState({ loading: true });
    if (checked === false) {
      db.collection("Product_Out_Stock1")
        .doc(key)
        .set({
          Ref_Product_Id: key,
          Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
          Created_At: firebase.firestore.FieldValue.serverTimestamp(),
          Ref_Store_Id: this.state.StoreID,
        })
        .then((res) => {
          console.log("Document successfully written!");
          db.collection("Product_Details1")
            .doc(key)
            .update({
              Is_Stock_Available: checked,
              Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((res) => {
              this.props
                .productList1(this.state.StoreID)
                .then((res) => {
                  this.setState({ tdata: this.props.products, loading: false });
                  message.success("Product successfully Updated!");
                })
                .catch((err) => {
                  console.log("Product_List1", err);
                });
            })
            .catch((err) => {
              console.log("Product_Details1", err);
            });
        })
        .catch((err) => {
          console.log("Product out stock", err);
        });
    } else {
      db.collection("Product_Out_Stock1")
        .doc(key)
        .delete()
        .then((res) => {
          console.log("Document successfully written!");
          db.collection("Product_Details1")
            .doc(key)
            .update({
              Is_Stock_Available: checked,
              Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((res) => {
              this.props
                .productList1(this.state.StoreID)
                .then((res) => {
                  this.setState({ tdata: this.props.products, loading: false });
                  message.success("Product successfully Updated!");
                })
                .catch((err) => {
                  console.log("Product_List1", err);
                });
            })
            .catch((err) => {
              console.log("Product_Details1", err);
            });
        })
        .catch((err) => {
          console.log("Product out stock", err);
        });
    }
    // db.collection("Product_Details1")
    //   .doc(key)
    //   .update({
    //     Is_Stock_Available: checked,
    //     Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
    //   })
    //   .then((res) => {
    //     if (checked === false) {
    //       // Add a new document in collection "cities"

    //         })
    //         .catch((error) => {
    //           console.error("Error writing document: ", error);
    //         });
    //     }
    //   else {
    //     db.collection("Product_Out_Stock1")
    //       .doc(key)
    //       .delete()
    //       .then((res) => {
    //         console.log("Document successfully deleted!");
    //         this.props
    //           .productList1(this.state.StoreID)
    //           .then((res) => {
    //             //console.log(res);
    //             this.setState({
    //               tdata: this.props.products,
    //               loading: false,
    //             });
    //             message.success("Product successfully Updated!");
    //           })
    //           .catch((error) => {
    //             console.log(error);
    //             this.setState({
    //               loading: false,
    //             });
    //           });
    //       })
    //       .catch((error) => {
    //         console.error("Error removing document: ", error);
    //       });
    //   }
    // })
    // .catch((error) => {
    //   message.error("Error removing document: ", error);
    // });
  };

  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          <Avatar src={storeall.imgUrl} />
          {storeall.store_name}
        </Option>
      );
    });
  }
  handleStoreID = (value) => {
    this.setState({ StoreID: value });
  };
  //===============================================

  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "Product Image",
        dataIndex: "imgUrl",
        key: "imgUrl",
        width: "10%",
        render: (text, record) => (
          <span>
            <Avatar
              shape="square"
              size={64}
              src={record.imgUrl}
              onClick={(e) => this.showModal(e, record.imgUrl)}
            />
          </span>
        ),
      },
      {
        title: "ID",
        dataIndex: "pid",
        key: "pid",
        ...this.getColumnSearchProps("pid"),
      },
      {
        title: "Display Order",
        dataIndex: "Product_Display_Order",
        key: "Product_Display_Order",
        ...this.getColumnSearchProps("Product_Display_Order"),
      },
      {
        title: "Price",
        dataIndex: "Product_Price",
        key: "Product_Price",
        width: "8%",
      },
      {
        title: "Discount Price",
        dataIndex: "Product_Discounted_Price",
        key: "Product_Discounted_Price",
        width: "8%",
      },
      {
        title: "Marked Price",
        dataIndex: "markedup_price",
        key: "markedup_price",
        width: "8%",
      },
      {
        title: "Name",
        dataIndex: "pname",
        key: "pname",
        ...this.getColumnSearchProps("pname"),
      },
      {
        title: "Category",
        dataIndex: "catname",
        key: "catname",
        ...this.getColumnSearchProps("catname"),
      },
      {
        title: "Sub-Category",
        dataIndex: "subcatname",
        key: "subcatname",
        ...this.getColumnSearchProps("subcatname"),
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        render: (text, record) => (
          <span>
            {record.Status === "true" ? (
              <Tag color="green">Active</Tag>
            ) : (
              <Tag color="volcano">In Active</Tag>
            )}
          </span>
        ),
      },
      {
        title: "Stock Status",
        dataIndex: "stockstatus",
        key: "stockstatus",
        render: (text, record) => (
          <span>
            {record.Status === "true" ? (
              <Switch
                defaultChecked={record.isstock}
                onChange={(e) => this.onChange(e, record.key)}
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
              />
            ) : (
              <Switch
                disabled
                defaultChecked={record.isstock}
                onChange={(e) => this.onChange(e, record.key)}
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
              />
            )}
          </span>
        ),
      },
      {
        title: "Updated At",
        dataIndex: "updated_at",
        key: "updated_at",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "12%",
        render: (text, record) => (
          <span>
            <Link to={{ pathname: "/editproduct", productid: record.key }}>
              <Tooltip title="Edit Product">
                <Button type="primary" shape="circle" icon="edit" />
              </Tooltip>
            </Link>

            <Divider type="vertical" />
            {record.Status === "true" && (
              <Popconfirm
                title="Are you sure delete this Product?"
                onConfirm={() => this.confirmdelete(record.key)}
                onCancel={this.cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger" shape="circle" icon="delete" />
              </Popconfirm>
            )}
          </span>
        ),
      },
    ];

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Premier Products</h4>
              </div>
              <div className="col-sm-8 text-right">
                <Link to="/addproduct">
                  <Button className="pull-right" type="primary" icon="shopping">
                    Add Product
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid allproduct">
          <Row className="m-b-30">
            <Col>
              <Form layout="inline" onSubmit={this.handleSubmit}>
                <Form.Item label="Store Name">
                  {getFieldDecorator("store_name", {
                    rules: [
                      {
                        required: true,
                        message: "Please Select Store",
                        whitespace: false,
                      },
                    ],
                  })(
                    <Select
                      style={{
                        width: 340,
                      }}
                      onChange={this.handleStoreID}
                      placeholder="Select Store"
                    >
                      {this.renderStorename()}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.btnloading}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ marginLeft: 15 }}
                    type="default"
                    onClick={this.handleClearbtn}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Divider dashed />
          {this.state.showfilter === true && (
            <div className="row">
              <div className="text-left filterdiv col-md-4">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="text-left download-table-xls-button btn ant-btn-primary"
                  table="table-to-xls"
                  filename="Shopezy-Product-Report"
                  sheet="tablexls"
                  buttonText="Export Product Report"
                />
              </div>
              <div className="text-right filterdiv col-md-8">
                <span style={{ marginRight: 10 }}>Filter:</span>
                <Select
                  showSearch
                  placeholder="Category"
                  optionFilterProp="children"
                  onChange={this.handleCategory}
                  value={this.state.category}
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) >= 0
                  }
                  style={{ width: 200 }}
                >
                  {this.renderCategory()}
                </Select>
                <Select
                  showSearch
                  placeholder="Sub-Category"
                  optionFilterProp="children"
                  onChange={this.handlesubCategory}
                  value={this.state.subcategory}
                  disabled={this.state.disabled}
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) >= 0
                  }
                  style={{ width: 200, marginLeft: 10 }}
                >
                  {this.renderSubCategory()}
                </Select>
                <Button
                  style={{ marginLeft: 15 }}
                  onClick={this.handleResetbtn}
                >
                  Clear
                </Button>
              </div>
            </div>
          )}
          <div>
            <table className="hidden" id="table-to-xls">
              <thead>
                <tr>
                  <th>img_url</th>
                  <th>product_Id</th>
                  <th>price</th>
                  <th>name</th>
                  <th>category</th>
                  <th>subcategory</th>
                  <th>stockstatus</th>
                  <th>updated_at</th>
                </tr>
              </thead>
              <tbody>{this.renderNotes()}</tbody>
            </table>
          </div>
          <Table
            size="middle"
            columns={columns}
            dataSource={this.state.tdata}
            loading={this.state.loading}
            bordered
          />
        </div>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Close
            </Button>,
          ]}
        >
          <div className="center-item">
            <img src={this.state.mimage} className="img-responsive" alt="" />
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    products: state.products,
    categories: state.categories,
    subcategories: state.subcategories,
    storeall: state.storeall,
  };
}
const WrappedRegistrationForm = Form.create()(Products);
export default connect(mapStateToProps, {
  emailLogin,
  logout,
  saveAsset,
  productList1,
  catList1,
  subcatList1,
  filterProduct1,
  filtersubCat,
  allstorename,
})(WrappedRegistrationForm);
