import React from "react";
import ReactDOM from "react-dom";
import Login from "./component/Login";
import Dashboard from "./component/dashboard";
import Customer from "./component/Customer";
import verifyuser from "./component/verifyuser";
import Home from "./component/Home";
import Products from "./component/allproducts";
import AllCat from "./component/Categories";
import Categories from "./component/AddCategories";
import EditCategories from "./component/EditCategories";
import EditSubcategory from "./component/EditSubcategory";
import AllSubCat from "./component/Subcategory";
import Subcategory from "./component/AddSubcategory";
import AddCoupon from "./component/Discount";
import Coupon from "./component/AllCoupon";
import AddPromo from "./component/addpromo";
import EditCoupon from "./component/editcoupon";
import LoadingComponent from "./component/LoadingComponent";
import AuthenticatedCompoment from "./component/AuthenticatedComponent";
import EditProduct from "./component/EditProducts";
import EditProduct1 from "./component/EditProduct1";
import EditDriver from "./component/EditDriver";
import Chat from "./component/chat";
import TrackMap from "./component/map";
import UserCreation from "./component/UserCreation";
import AddStore from "./component/AddStore";
import EditStore from "./component/EditStore";
import Order from "./component/Order";
import Order1 from "./component/Order1";
import ViewStore from "./component/AllStore";
import ViewStoreRequest from "./component/StoreRequest";
import tracking from "./component/tracking";
import massUpload from "./component/massUpload";
import Confirmation from "./component/Confirmation";
import failure from "./component/failure";
import Sidemenu from "./routes/Header";
import TopHeader from "./routes/topheader";
import premierproducts from "./component/premierproducts";
import premierpromo from "./component/premierpromo";
import ViewMealDeal from "./component/ViewMealDeal";
import EditMealDeal from "./component/EditMealDeal";
import AddtoHome from "./component/Addtohomescreen";
import vieworder from "./component/vieworderURL";
import orderview from "./component/vieworder";
import allnotification from "./component/Allnotification";
import ProductStock from "./component/ProductStock";
import RoleList from "./component/RoleList";
import CreateRole from "./component/CreateRole";
import EditRole from "./component/EditRole";
import * as serviceWorker from "./serviceWorker";
// import promiseMiddleware from 'redux-promise';
//ant
import { Layout } from "antd";

//Redux
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import history from "./history";
import EditPromo from "./component/EditPromo";
import ViewPromo from "./component/ViewPromo";
import DailyOrder from "./component/DailyOrder";
import MostOrder from "./component/MostOrderProduct";
import bulkSMS from "./component/bulkSMS";
import Pusher from "./component/PushNotification";
import OrderReport from "./component/Order-Report";
import TaxReport from "./component/taxreport";
import Homebanner from "./component/Homebanner";
import OrderHistory from "./component/OrderHistory";
import SortProducts from "./component/SortProducts";
import StoreNearOrder from "./component/StoreNearOrder";
import ChangePassword from "./component/ChangePassword";

// create redux store -> reducers -> 'actions - actionType' | applyMiddleware()
// const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(thunk))
// );

// Hide redux dev tool extension in production

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk)
    : composeWithDevTools(applyMiddleware(thunk));

// create redux store -> reducers -> 'actions - actionType' | applyMiddleware()
const store = createStore(rootReducer, devTools);

// Hide all console in production

// console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

const { Content, Footer } = Layout;

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <BrowserRouter>
        <LoadingComponent>
          <div>
            <Switch>
              <Route path="/signup" component={verifyuser} exact={true} />
              <Route path="/login" component={Login} exact={true} />
              <Route path="/confirm" component={Confirmation} exact={true} />
              <Route path="/failure" component={failure} exact={true} />
              <AuthenticatedCompoment>
                <Layout>
                  <Sidemenu />
                  <Layout>
                    <TopHeader />
                    <AddtoHome />
                    <Content style={{ margin: "24px 16px 0" }}>
                      <div
                        style={{
                          background: "#fff",
                          minHeight: 360,
                        }}
                      >
                        <Route
                          path="/editproductpremier"
                          component={EditProduct}
                          exact={true}
                        />
                        <Route
                          path="/productstatus"
                          component={ProductStock}
                          exact={true}
                        />
                        <Route
                          path="/allmessages"
                          component={allnotification}
                          exact={true}
                        />
                        <Route
                          path="/orderreport"
                          component={OrderReport}
                          exact={true}
                        />
                        <Route
                          path="/homebanner"
                          component={Homebanner}
                          exact={true}
                        />
                        <Route
                          path="/editproduct"
                          component={EditProduct1}
                          exact={true}
                        />
                        <Route
                          path="/bulksms"
                          component={bulkSMS}
                          exact={true}
                        />
                        <Route
                          path="/notification"
                          component={Pusher}
                          exact={true}
                        />
                        <Route
                          path="/editdriver"
                          component={EditDriver}
                          exact={true}
                        />
                        <Route
                          path="/mostorder"
                          component={MostOrder}
                          exact={true}
                        />
                        <Route
                          path="/addproduct"
                          component={Home}
                          exact={true}
                        />
                        <Route path="/" component={Dashboard} exact={true} />
                        <Route
                          path="/product"
                          component={Products}
                          exact={true}
                        />
                        <Route
                          path="/sortproducts"
                          component={SortProducts}
                          exact={true}
                        />
                        <Route
                          path="/premierproducts"
                          component={premierproducts}
                          exact={true}
                        />
                        <Route
                          path="/category"
                          component={AllCat}
                          exact={true}
                        />
                        <Route
                          path="/addcategory"
                          component={Categories}
                          exact={true}
                        />
                        <Route
                          path="/editcategory"
                          component={EditCategories}
                          exact={true}
                        />
                        <Route
                          path="/editsubcategory"
                          component={EditSubcategory}
                          exact={true}
                        />
                        <Route
                          path="/subcategory"
                          component={AllSubCat}
                          exact={true}
                        />
                        <Route
                          path="/addsubcategory"
                          component={Subcategory}
                          exact={true}
                        />

                        <Route
                          path="/addcoupon"
                          component={AddCoupon}
                          exact={true}
                        />
                        <Route
                          path="/editcoupon"
                          component={EditCoupon}
                          exact={true}
                        />
                        <Route
                          path="/editpromo"
                          component={EditPromo}
                          exact={true}
                        />
                        <Route
                          path="/viewpromo"
                          component={ViewPromo}
                          exact={true}
                        />
                        <Route
                          path="/vieworder"
                          component={vieworder}
                          exact={true}
                        />
                        <Route
                          path="/orderview"
                          component={orderview}
                          exact={true}
                        />
                        <Route path="/coupon" component={Coupon} exact={true} />
                        <Route
                          path="/dailyorderreport"
                          component={DailyOrder}
                          exact={true}
                        />
                        <Route
                          path="/premierpromo"
                          component={premierpromo}
                          exact={true}
                        />
                        <Route
                          path="/addpromo"
                          component={AddPromo}
                          exact={true}
                        />
                        <Route
                          path="/trackorder"
                          component={TrackMap}
                          exact={true}
                        />
                        <Route
                          path="/user"
                          component={UserCreation}
                          exact={true}
                        />
                        <Route
                          path="/customer"
                          component={Customer}
                          exact={true}
                        />
                        <Route
                          path="/store"
                          component={AddStore}
                          exact={true}
                        />
                        <Route
                          path="/editstore"
                          component={EditStore}
                          exact={true}
                        />
                        <Route
                          path="/orderhistory"
                          component={OrderHistory}
                          exact={true}
                        />
                        <Route path="/order" component={Order} exact={true} />
                        <Route
                          path="/superorder"
                          component={Order1}
                          exact={true}
                        />
                        <Route
                          path="/viewstore"
                          component={ViewStore}
                          exact={true}
                        />
                        <Route
                          path="/tracking"
                          component={tracking}
                          exact={true}
                        />
                        <Route
                          path="/massupload"
                          component={massUpload}
                          exact={true}
                        />
                        <Route
                          path="/storerequest"
                          component={ViewStoreRequest}
                          exact={true}
                        />
                        <Route
                          path="/viewdeal"
                          component={ViewMealDeal}
                          exact={true}
                        />
                        <Route
                          path="/editdeal"
                          component={EditMealDeal}
                          exact={true}
                        />
                        <Route
                          path="/taxreport"
                          component={TaxReport}
                          exact={true}
                        />
                        <Route path="/chat" component={Chat} exact={true} />
                        <Route
                          path="/userrole"
                          component={RoleList}
                          exact={true}
                        />
                        <Route
                          path="/createrole"
                          component={CreateRole}
                          exact={true}
                        />
                        <Route
                          path="/editrole"
                          component={EditRole}
                          exact={true}
                        />
                        <Route
                          path="/storenearorder"
                          component={StoreNearOrder}
                          exact={true}
                        />
                        <Route
                          path="/changepassword"
                          component={ChangePassword}
                          exact={true}
                        />
                      </div>
                    </Content>
                    <Footer style={{ textAlign: "center" }}>
                      Shopezy ©2022 Copyrights All Rights Reserved.- V-3.0.0
                    </Footer>
                  </Layout>
                </Layout>
              </AuthenticatedCompoment>
            </Switch>
          </div>
        </LoadingComponent>
      </BrowserRouter>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
