import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Skeleton } from "antd";
import StatCard from "./templates/StatCard";
import { ShoppingBag, ShoppingCart, Target, User } from "react-feather";
import { analyticsdata, dashreport } from "../actions/dashboardAction";
import Chart from "react-apexcharts";
import { userstats } from "../actions/chartAction";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading1: false,
      today: "",
      week: "",
      month: "",
      year: "",
      dashstats: "",
      series: [],
      options: {
        legend: {
          position: "right",
        },
        labels: [],
      },
      series1: [],
      options1: {
        legend: {
          position: "right",
        },
        labels: [],
      },
      series2: [],
      options2: {
        legend: {
          position: "right",
        },
        labels: [],
      },
      series3: [],
      options3: {
        legend: {
          position: "right",
        },
        labels: [],
      },
      series4: [],
      options4: {
        legend: {
          position: "right",
        },
        labels: [],
      },
    };
  }

  componentDidMount() {
    this.setState({ loading: true, loading1: true, loadingchart: true });
    this.props.analyticsdata().then((result) => {
      //  console.log('resolved',result)
      this.setState({ loading: false });
    });

    this.props
      .userstats()
      .then((result) => {
        // console.log('resolved',result)
        this.setState({
          series: result.modelNumberArrAndroid,
          options: {
            ...this.state.options,
            labels: result.modelNameArrAndroid,
          },
          series1: result.modelNumberArrIos,
          options1: { ...this.state.options, labels: result.modelNameArrIos },
          series2: result.versionNumberArrAndroid,
          options2: {
            ...this.state.options,
            labels: result.versionNameArrAndroid,
          },
          series3: result.versionNumberArrIos,
          options3: { ...this.state.options, labels: result.versionNameArrIos },
          series4: result.deviceOSNumberArr,
          options4: { ...this.state.options, labels: result.deviceOSNameArr },
          loadingchart: false,
        });
      })
      .catch((err) => {});
  }

  render() {
    // const { today, week, year, month } = this.state;
    const { dashdata } = this.props;
    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h4>Dashboard</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid allproduct">
          <Row gutter={16}>
            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Total Orders"
                  value={dashdata.total_order}
                  icon={<ShoppingCart size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Total Customers"
                  value={dashdata.total_customer}
                  icon={<User size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Total Shops"
                  value={dashdata.total_shop}
                  icon={<ShoppingBag size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Active Drivers"
                  value={dashdata.total_cost}
                  icon={<Target size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>
          </Row>

          <div className="m-t-30">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12}>
                <Skeleton loading={this.state.loadingchart}>
                  <div className="panel panel-default">
                    <div className="panel-heading">Android Device Used</div>
                    <div className="panel-body">
                      <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="pie"
                        width={500}
                        height={320}
                      />
                    </div>
                  </div>
                </Skeleton>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Skeleton loading={this.state.loadingchart}>
                  <div className="panel panel-default">
                    <div className="panel-heading">iOS Device Used</div>
                    <div className="panel-body">
                      <Chart
                        options={this.state.options1}
                        series={this.state.series1}
                        type="pie"
                        width={500}
                        height={320}
                      />
                    </div>
                  </div>
                </Skeleton>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Skeleton loading={this.state.loadingchart}>
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      Android App Version Used
                    </div>
                    <div className="panel-body">
                      <Chart
                        options={this.state.options2}
                        series={this.state.series2}
                        type="pie"
                        width={500}
                        height={320}
                      />
                    </div>
                  </div>
                </Skeleton>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Skeleton loading={this.state.loadingchart}>
                  <div className="panel panel-default">
                    <div className="panel-heading">iOS App Version Used</div>
                    <div className="panel-body">
                      <Chart
                        options={this.state.options3}
                        series={this.state.series3}
                        type="pie"
                        width={500}
                        height={320}
                      />
                    </div>
                  </div>
                </Skeleton>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Skeleton loading={this.state.loadingchart}>
                  <div className="panel panel-default">
                    <div className="panel-heading">Device OS Used</div>
                    <div className="panel-body">
                      <Chart
                        options={this.state.options4}
                        series={this.state.series4}
                        type="pie"
                        width={500}
                        height={320}
                      />
                    </div>
                  </div>
                </Skeleton>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    dashdata: state.dashdata,
    common: state.common,
  };
}
export default connect(mapStateToProps, {
  analyticsdata,
  dashreport,
  userstats,
})(Dashboard);
