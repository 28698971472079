import React, { Component } from "react";
import { connect } from "react-redux";
import { allstorename } from "../actions/assetAction";
import { NearOrder } from "../actions/chartAction";
import {
  Button,
  Table,
  Input,
  Icon,
  Row,
  Col,
  Form,
  Select,
  DatePicker,
  Divider,
  Avatar,
  Modal,
} from "antd";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import "../styles/css/App.css";

const { Option } = Select;

class StoreNearOrder extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      order: "",
      tdata: [],
      startValue: null,
      endValue: null,
      endOpen: false,
      mimage: "",
    };
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    console.log(this.state.searchText);
  };
  componentDidMount() {
    this.props.allstorename();
  }
  disabledStartDate = (startValue) => {
    // const { endValue } = this.state;
    // if (!startValue || !endValue)
    // {   return false; }
    return startValue && startValue > moment().endOf("day");
  };

  disabledEndDate = (endValue) => {
    // const {startValue} = this.state;
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    return endValue && endValue > moment().endOf("day");
  };

  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values);
        this.setState({ loading: true });
        this.props
          .NearOrder(values)
          .then((result) => {
            console.log(result);
            //this.setState({ loading: false, tdata: result });
          })
          .catch((err) => {
            //this.setState({ loading: false, tdata: err });
            console.log(err);
          });
      }
    });
  };

  renderNotes() {
    return _.map(this.state.tdata, (order, key) => {
      return (
        <tr key={key}>
          <td>{order.Product_Id}</td>
          <td>{order.Product_Name}</td>
          <td>{order.Product_Thumb_Image_Url}</td>
          <td>{order.Product_Description}</td>
          <td>{order.Updated_At}</td>
        </tr>
      );
    });
  }
  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          {storeall.store_name}
        </Option>
      );
    });
  }
  handleStoreID = (value) => {
    this.setState({ StoreID: value });
  };
  showModal = (e, data) => {
    console.log(data);

    this.setState({
      visible: true,
      mimage: data,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  handleResett = () => {
    this.props.form.resetFields();
    this.setState({ tdata: [] });
  };
  // ======================================================

  render() {
    const { getFieldDecorator } = this.props.form;
    const { endOpen } = this.state;
    // ==============================================================================
    const columns = [
      {
        title: "Product_Id",
        dataIndex: "Product_Id",
        key: "Product_Id",
        ...this.getColumnSearchProps("Product_Id"),
      },
      {
        title: "Product_Name",
        dataIndex: "Product_Name",
        key: "Product_Name",
        ...this.getColumnSearchProps("Product_Name"),
      },
      {
        title: "Product_Description",
        dataIndex: "Product_Description",
        key: "Product_Description",
        ...this.getColumnSearchProps("Product_Description"),
      },
      {
        title: "Product image",
        dataIndex: "Product_Thumb_Image_Url",
        key: "Product_Thumb_Image_Url",
        render: (text, record) => (
          <span>
            <Avatar
              shape="square"
              size={64}
              src={record.Product_Thumb_Image_Url}
              onClick={(e) => this.showModal(e, record.Product_Thumb_Image_Url)}
            />
          </span>
        ),
      },
      {
        title: "Updated_At",
        dataIndex: "Updated_At",
        key: "Updated_At",
      },
    ];
    // ================================//

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Store Near Order Report</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid m-t-30 text-left invoice-pad">
          <Row className="m-b-30">
            <Col>
              <Form layout="inline" onSubmit={this.handleSubmit}>
                <Form.Item label="Store">
                  {getFieldDecorator("store_id", {
                    rules: [
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ],
                  })(
                    <Select
                      style={{
                        width: 340,
                      }}
                      onChange={this.handleStoreID}
                      placeholder="Select Store"
                    >
                      {this.renderStorename()}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="From Date">
                  {getFieldDecorator("start_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "This field is required",
                      },
                    ],
                  })(
                    <DatePicker
                      style={{
                        width: 150,
                      }}
                      disabledDate={this.disabledStartDate}
                      format="DD-MM-YYYY"
                      placeholder="Select Date"
                      onChange={this.onStartChange}
                      onOpenChange={this.handleStartOpenChange}
                    />
                  )}
                </Form.Item>
                <Form.Item label="To Date">
                  {getFieldDecorator("end_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "This field is required",
                      },
                    ],
                  })(
                    <DatePicker
                      style={{
                        width: 150,
                      }}
                      disabledDate={this.disabledEndDate}
                      format="DD-MM-YYYY"
                      placeholder="Select Date"
                      onChange={this.onEndChange}
                      open={endOpen}
                      onOpenChange={this.handleEndOpenChange}
                    />
                  )}
                </Form.Item>
                {/* <Form.Item label="Radius">
                  {getFieldDecorator("radius", {
                    rules: [
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ],
                  })(
                    <Select
                      style={{
                        width: 100,
                      }}
                    >
                      <Option value="0.5">0.5</Option>
                      <Option value="1.0">1.0</Option>
                      <Option value="1.5">1.5</Option>
                    </Select>
                  )}
                </Form.Item> */}

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                  <Button
                    style={{ marginLeft: 15 }}
                    type="default"
                    onClick={this.handleResett}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Divider dashed />

          <div className="row">
            <div className="text-left filterdiv col-md-6">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="text-left download-table-xls-button btn ant-btn-primary"
                table="table-to-xls"
                filename="Shopezy-Product-Report"
                sheet="Product"
                buttonText="Export Report"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div>
                <table className="hidden" id="table-to-xls">
                  <thead>
                    <tr>
                      <th>Product_Id</th>
                      <th>Product_Name</th>
                      <th>Product_Thumb_Image_Url</th>
                      <th>Product_Description</th>
                      <th>Updated_At</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderNotes()}</tbody>
                </table>
              </div>
              <Table
                columns={columns}
                dataSource={this.state.tdata}
                loading={this.state.loading}
                size="middle"
                bordered
              />
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Close
            </Button>,
          ]}
        >
          <div className="center-item">
            <img src={this.state.mimage} className="img-responsive" alt="" />
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    common: state.common,
    storeall: state.storeall,
  };
}
const WrappedRegistrationForm = Form.create()(StoreNearOrder);
export default connect(mapStateToProps, {
  allstorename,
  NearOrder,
})(WrappedRegistrationForm);
