import { db, firedb } from "../firebase";
import {
  GET_DASHBOARD,
  GET_COMMON_SLOT,
  GET_DASHBOARD_TODAY,
  GET_ORDER_LIVE,
  GET_CHAT_LIVE,
} from "../actionTypes";
import moment from "moment";
import "moment-timezone";

function sum(input) {
  //console.log(input)

  if (toString.call(input) !== "[object Array]") return false;

  var total = 0;
  for (var i = 0; i < input.length; i++) {
    if (isNaN(input[i])) {
      continue;
    } //console.log(input[i])
    total += Number(input[i]);
  }
  return total;
}

export function analyticsdata() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      db.collection("Dashboard_Count")
        .doc("Order_Doc")
        .onSnapshot(function (querySnapshot) {
          let total_order = querySnapshot.data().Count;
          console.log(total_order);
          total_customer(total_order);
        });
      function total_customer(total_order) {
        db.collection("Dashboard_Count")
          .doc("Customer_Master_Doc")
          .get()
          .then(function (querySnapshot) {
            let total_customer = querySnapshot.data().Count;
            console.log(total_customer);
            total_shop(total_order, total_customer);
          });
      }
      function total_shop(total_order, total_customer) {
        db.collection("Dashboard_Count")
          .doc("Stores_Master_Doc")
          .get()
          .then(function (querySnapshot) {
            let total_shop = querySnapshot.data().Count;
            console.log(total_shop);
            total_drivers(total_order, total_customer, total_shop);
          });
      }
      function total_drivers(total_order, total_customer, total_shop) {
        db.collection("Driver_Master")
          .where("Driver_Ride_Status_Id", "==", 2)
          .onSnapshot(function (querySnapshot) {
            let total_cost = querySnapshot.size;
            var arr = {
              total_order: total_order.toString(),
              total_customer: total_customer.toString(),
              total_shop: total_shop.toString(),
              total_cost: total_cost.toString(),
            };
            dispatch({ type: GET_DASHBOARD, payload: arr });
            resolve(arr);
          });
      }
    });
  };
}

export function dashreport() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      firedb
        .ref()
        .child("/Admin_Dashboard_Report")
        .on("value", (snapshot) => {
          dispatch({
            type: GET_COMMON_SLOT,
            payload: snapshot.val(),
          });
          resolve(snapshot.val());
          console.log(snapshot.val());
        });
    });
  };
}

export function dashreporttoday() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var salearray = [];
      var m = moment.tz("Europe/London");
      var m1 = m.startOf("day").format();
      var start = new Date(m1);
      var n = moment.tz("Europe/London").format();
      var end = new Date(n);
      db.collection("Order")
        .where("Created_At", ">", start)
        .where("Created_At", "<", end)
        .where("Ref_Order_Status_Id", "==", 12)
        .onSnapshot(function (querySnapshot) {
          let total_order = querySnapshot.size;
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data().Total_Price);
            var Total_Price;
            var Price = doc.data().Total_Price.toFixed(2);
            var Order_Modify_Fare = doc.data().Order_Modify_Fare;
            if (Order_Modify_Fare > 0) {
              Total_Price = Order_Modify_Fare;
            } else {
              Total_Price = Price;
            }
            salearray.push(Total_Price);
          });
          if (querySnapshot.size === salearray.length) {
            var total_cost = sum(salearray);
            // console.log(salearray)

            var arr = {
              total_order: total_order.toString(),
              total_cost: "£ " + total_cost.toFixed(2),
            };
            dispatch({ type: GET_DASHBOARD_TODAY, payload: arr });
            resolve(arr);
            //console.log(arr)
          }
        });
    });
  };
}

export function customdashreport(sdate, edate) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var salearray = [];
      var m = moment(sdate);
      var m1 = m.startOf("day").format();
      var start = new Date(m1);
      var n = moment(edate);
      var n1 = n.endOf("day").format();
      var end = new Date(n1);
      console.log(start);
      console.log(end);

      db.collection("Order")
        .where("Created_At", ">", start)
        .where("Created_At", "<", end)
        .get()
        .then(function (querySnapshot) {
          var today_total_order = querySnapshot.size;
          // console.log(today_total_order);
          total_pending_order(today_total_order);
        });

      let total_pending_order = (today_total_order) => {
        db.collection("Order")
          .where("Created_At", ">", start)
          .where("Created_At", "<", end)
          .where("Ref_Order_Status_Id", "==", 2)
          .get()
          .then(function (querySnapshot) {
            var total_pending_order = querySnapshot.size;
            //  console.log(total_pending_order);
            total_sales_order(today_total_order, total_pending_order);
          });
      };
      let total_sales_order = (today_total_order, total_pending_order) => {
        db.collection("Order")
          .where("Created_At", ">", start)
          .where("Created_At", "<", end)
          .where("Ref_Order_Status_Id", "==", 12)
          .get()
          .then(function (querySnapshot) {
            let total_order = querySnapshot.size;
            // console.log(total_order);
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              //console.log(doc.id, " => ", doc.data().Total_Price);
              var Total_Price;
              var Price = doc.data().Total_Price;
              var Order_Discount_Fare = doc.data().Order_Discount_Fare;
              if (doc.data().Ref_Service_Fee !== undefined) {
                var Ref_Service_Fee = doc.data().Ref_Service_Fee;
              } else {
                Ref_Service_Fee = 0;
              }
              if (doc.data().Ref_Delivery_Fee !== undefined) {
                var Delivery_Fee = doc.data().Ref_Delivery_Fee;
              } else {
                Delivery_Fee = 0;
              }
              if (doc.data().Ref_Bag_Fee !== undefined) {
                var Bag_Fee = doc.data().Ref_Bag_Fee;
              } else {
                Bag_Fee = 0;
              }

              var Order_Modify_Fare = doc.data().Order_Modify_Fare;
              if (Order_Modify_Fare > 0) {
                Total_Price =
                  Order_Modify_Fare + Delivery_Fee + Bag_Fee + Ref_Service_Fee;
              } else {
                Total_Price = Price + Delivery_Fee + Bag_Fee + Ref_Service_Fee;
              }
              var New_Price = Total_Price - Order_Discount_Fare;
              // console.log(New_Price);
              salearray.push(New_Price);
            });
            // console.log(salearray.length, querySnapshot.size);
            if (querySnapshot.size === salearray.length) {
              var total_cost = sum(salearray);
              // console.log(salearray)

              var arr = {
                total_order: total_order.toString(),
                total_cost: "£ " + total_cost.toFixed(2),
                today_total_order: today_total_order.toString(),
                total_pending_order: total_pending_order.toString(),
              };
              dispatch({ type: GET_DASHBOARD_TODAY, payload: arr });
              resolve(arr);
              salearray = [];
            }
          });
      };
    });
  };
}
export function storedashreport(storeid, sdate, edate) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var salearray = [];
      var m = moment(sdate);
      var m1 = m.startOf("day").format();
      var start = new Date(m1);
      var n = moment(edate);
      var n1 = n.endOf("day").format();
      var end = new Date(n1);
      console.log(start);
      console.log(end);

      db.collection("Order")
        .where("Ref_Order_Store_Id", "==", storeid)
        .where("Created_At", ">", start)
        .where("Created_At", "<", end)
        .get()
        .then(function (querySnapshot) {
          var today_total_order = querySnapshot.size;
          // console.log(today_total_order);
          total_pending_order(today_total_order);
        });

      let total_pending_order = (today_total_order) => {
        db.collection("Order")
          .where("Ref_Order_Store_Id", "==", storeid)
          .where("Created_At", ">", start)
          .where("Created_At", "<", end)
          .where("Ref_Order_Status_Id", "==", 2)
          .get()
          .then(function (querySnapshot) {
            var total_pending_order = querySnapshot.size;
            //  console.log(total_pending_order);
            total_sales_order(today_total_order, total_pending_order);
          });
      };
      let total_sales_order = (today_total_order, total_pending_order) => {
        db.collection("Order")
          .where("Ref_Order_Store_Id", "==", storeid)
          .where("Created_At", ">", start)
          .where("Created_At", "<", end)
          .where("Ref_Order_Status_Id", "==", 12)
          .get()
          .then(function (querySnapshot) {
            let total_order = querySnapshot.size;
            // console.log(total_order);
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              //console.log(doc.id, " => ", doc.data().Total_Price);
              var Total_Price;
              var Price = doc.data().Total_Price;
              var Order_Discount_Fare = doc.data().Order_Discount_Fare;
              if (doc.data().Ref_Service_Fee !== undefined) {
                var Ref_Service_Fee = doc.data().Ref_Service_Fee;
              } else {
                Ref_Service_Fee = 0;
              }
              if (doc.data().Ref_Delivery_Fee !== undefined) {
                var Delivery_Fee = doc.data().Ref_Delivery_Fee;
              } else {
                Delivery_Fee = 0;
              }
              if (doc.data().Ref_Bag_Fee !== undefined) {
                var Bag_Fee = doc.data().Ref_Bag_Fee;
              } else {
                Bag_Fee = 0;
              }

              var Order_Modify_Fare = doc.data().Order_Modify_Fare;
              if (Order_Modify_Fare > 0) {
                Total_Price =
                  Order_Modify_Fare + Delivery_Fee + Bag_Fee + Ref_Service_Fee;
              } else {
                Total_Price = Price + Delivery_Fee + Bag_Fee + Ref_Service_Fee;
              }
              var New_Price = Total_Price - Order_Discount_Fare;
              // console.log(New_Price);
              salearray.push(New_Price);
            });
            // console.log(salearray.length, querySnapshot.size);
            if (querySnapshot.size === salearray.length) {
              var total_cost = sum(salearray);
              // console.log(salearray)

              var arr = {
                total_order: total_order.toString(),
                total_cost: "£ " + total_cost.toFixed(2),
                today_total_order: today_total_order.toString(),
                total_pending_order: total_pending_order.toString(),
              };
              dispatch({ type: GET_DASHBOARD_TODAY, payload: arr });
              resolve(arr);
              salearray = [];
            }
          });
      };
    });
  };
}
export function orderlive(sdate, edate) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var m = moment(sdate);
      var m1 = m.startOf("day").format();
      var start = new Date(m1);
      var n = moment(edate);
      var n1 = n.endOf("day").format();
      var end = new Date(n1);
      db.collection("Order")
        .where("Created_At", ">", start)
        .where("Created_At", "<", end)
        .where("Ref_Order_Status_Id", "==", 2)
        .onSnapshot(function (querySnapshot) {
          let total_order = querySnapshot.size;
          console.log("totalorder", total_order);
          dispatch({ type: GET_ORDER_LIVE, payload: total_order });
        });
    });
  };
}

export function chatunreadcount() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var starCountRef = firedb
        .ref("Messages_Master/")
        .orderByChild("Chat_Type")
        .equalTo(1);
      starCountRef.on("value", function (snapshot) {
        var arr = [];
        snapshot.forEach(function (msgmaster2) {
          var doc_id = msgmaster2.key;
          firedb
            .ref("/Messages/" + doc_id)
            .orderByChild("Is_Read")
            .equalTo("False")
            .on("value", function (dataSnapshot) {
              var snap_update_child = dataSnapshot.numChildren();
              arr.push(snap_update_child);
              if (snapshot.numChildren() === arr.length) {
                var totalcount = arr.reduce(function (a, b) {
                  return a + b;
                }, 0);

                console.log(totalcount);
                dispatch({
                  type: GET_CHAT_LIVE,
                  payload: totalcount,
                });
                //arr = [];
                resolve(totalcount);
              }
            });
        });
      });
    });
  };
}
