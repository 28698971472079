import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { emailLogin, logout } from "../actions/userAction";
import {
  subcatList1,
  allstorename,
  SortProduct,
  updateProductList,
} from "../actions/assetAction";
import {
  Select,
  Avatar,
  Button,
  Divider,
  Tag,
  Row,
  Form,
  Col,
  message,
  Spin,
} from "antd";
//import 'antd/dist/antd.css';
import "../styles/css/App.css";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const SortableItem = sortableElement(({ value }) => (
  <tbody>
    <tr>
      <td>
        <Avatar src={value.imgUrl} />
      </td>
      <td>
        {value.pname}{" "}
        {value.isstock === false ? (
          <Tag color="red">Out of Stock</Tag>
        ) : (
          <Tag color="green">Stock</Tag>
        )}
      </td>
      <td>{value.catname}</td>
      <td>{value.subcatname}</td>
      <td>
        {value.Status === false ? (
          <Tag color="red">In-Active</Tag>
        ) : (
          <Tag color="green">Active</Tag>
        )}
      </td>
      <td>{value.Product_Display_Order}</td>
    </tr>
  </tbody>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <table className="table table-condensed">{children}</table>;
});

const Option = Select.Option;

class SortProducts extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      disabled: true,
      searchText: "",
      tdata: [],
      loading: false,
      btnloading: false,
      mimage: "",
      showfilter: false,
      StoreID: "",
      SubcatID: "",
      showButton: false,
    };
  }

  componentDidMount() {
    this.props.subcatList1().then((res) => {
      this.props.allstorename();
    });
  }

  // ======================================================
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(values);
        this.setState({ btnloading: true });
        this.props
          .SortProduct(values)
          .then((res) => {
            console.log(res);
            this.setState({
              tdata: this.props.products.reverse(),
              btnloading: false,
              loading: false,
              showfilter: true,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              btnloading: false,
              loading: false,
              showfilter: false,
            });
          });
      }
    });
  };
  handleClearbtn = () => {
    this.props.form.resetFields();
    this.setState({ tdata: [], showfilter: false, showButton: false });
  };

  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          <Avatar src={storeall.imgUrl} />
          {storeall.store_name}
        </Option>
      );
    });
  }
  renderSubcategory() {
    return _.map(this.props.subcategories, (subcategories, key) => {
      return (
        <Option value={subcategories.id} key={key}>
          <Avatar src={subcategories.imgUrl} />
          {subcategories.sname}
        </Option>
      );
    });
  }
  handleStoreID = (value) => {
    this.setState({ StoreID: value });
  };
  handleSubcategory = (value) => {
    this.setState({ SubcatID: value });
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ tdata }) => ({
      tdata: arrayMove(tdata, oldIndex, newIndex),
      showButton: true,
    }));
  };
  SaveUpdatedList = () => {
    const reversed = this.state.tdata.reverse();
    //console.log(reversed);
    this.setState({ loading: true });
    this.props
      .updateProductList(reversed)
      .then((res) => {
        console.log(res);
        message.success("Product updated successfully!");
        this.setState({
          btnloading: false,
          loading: false,
          showButton: false,
          tdata: [],
        });
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong!");
        this.setState({
          btnloading: false,
          loading: false,
          showfilter: false,
        });
      });
  };
  //===============================================

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Sort Products</h4>
              </div>
            </div>
          </div>
        </div>
        <Spin spinning={this.state.loading}>
          <div className="container-fluid allproduct">
            <Row className="m-b-30">
              <Col>
                <Form layout="inline" onSubmit={this.handleSubmit}>
                  <Form.Item label="Store Name">
                    {getFieldDecorator("store_name", {
                      rules: [
                        {
                          required: true,
                          message: "Please Select Store",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                        }
                        style={{
                          width: 340,
                        }}
                        onChange={this.handleStoreID}
                        placeholder="Select Store"
                      >
                        {this.renderStorename()}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="Select Subcategory">
                    {getFieldDecorator("subcategory", {
                      rules: [
                        {
                          required: true,
                          message: "Please Select Option",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                        }
                        style={{
                          width: 340,
                        }}
                        onChange={this.handleSubcategory}
                        placeholder="Select Subcategory"
                      >
                        {this.renderSubcategory()}
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.btnloading}
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ marginLeft: 15 }}
                      type="default"
                      onClick={this.handleClearbtn}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Divider dashed />

            <div>
              <SortableContainer onSortEnd={this.onSortEnd}>
                {this.state.tdata.map((value, index) => (
                  <SortableItem key={value.key} index={index} value={value} />
                ))}
              </SortableContainer>
            </div>
            {this.state.showButton === true && (
              <Button
                type="primary"
                size="large"
                onClick={this.SaveUpdatedList}
              >
                Save Products
              </Button>
            )}
          </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    products: state.products,
    categories: state.categories,
    subcategories: state.subcategories,
    storeall: state.storeall,
  };
}

const WrappedRegistrationForm = Form.create()(SortProducts);
export default connect(mapStateToProps, {
  emailLogin,
  logout,
  subcatList1,
  allstorename,
  SortProduct,
  updateProductList,
})(WrappedRegistrationForm);
