import { db } from "../firebase";
import _ from "lodash";
import moment from "moment";
let bucket_baseurl =
  "https://storage.googleapis.com/shopezy-a4bc4.appspot.com/";

export function userstats() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var DeviceModelAnroid = [];
      var DeviceModelIos = [];
      var ClientVersionandroid = [];
      var ClientVersionios = [];
      var DeviceOs = [];

      db.collection("Customer_Device_Details")
        .get()
        .then(function (querySnapshot) {
          // console.log(querySnapshot.size)
          querySnapshot.forEach(function (doc) {
            if (doc.data().Device_OS === "Android") {
              var deviceModelsandroid = doc.data().Device_Model;
              DeviceModelAnroid.push(deviceModelsandroid);
            }
            if (doc.data().Device_OS === "iOS") {
              var deviceModelsios = doc.data().Device_Model;
              DeviceModelIos.push(deviceModelsios);
            }
            if (doc.data().Device_OS === "Android") {
              var clientVersionsandroid = doc.data().App_Version;
              ClientVersionandroid.push(clientVersionsandroid);
            }
            if (doc.data().Device_OS === "iOS") {
              var clientVersionsios = doc.data().App_Version;
              ClientVersionios.push(clientVersionsios);
            }

            var deviceOSs = doc.data().Device_OS;
            DeviceOs.push(deviceOSs);
          });
          console.log(ClientVersionandroid);
          //console.log(ClientVersion);
          //console.log(DeviceOs);

          var uSet = new Set(DeviceModelAnroid);
          var uniqueModelandroid = [...uSet];
          var uSet4 = new Set(DeviceModelIos);
          var uniqueModelios = [...uSet4];
          var uSet1 = new Set(ClientVersionandroid);
          var uniqueVersionandroid = [...uSet1];
          var uSet2 = new Set(ClientVersionios);
          var uniqueVersionios = [...uSet2];
          var uSet3 = new Set(DeviceOs);
          var uniqueDeviceOs = [...uSet3];
          let modelNameArrAndroid = [];
          let modelNumberArrAndroid = [];
          let modelNameArrIos = [];
          let modelNumberArrIos = [];
          let versionNameArrAndroid = [];
          let versionNumberArrAndroid = [];
          let versionNameArrIos = [];
          let versionNumberArrIos = [];
          let deviceOSNameArr = [];
          let deviceOSNumberArr = [];
          //console.log(uniqueVersionandroid);
          uniqueModelandroid.forEach((modelName) => {
            // filter using modal name
            var filteredArray = DeviceModelAnroid.filter(
              (modelItem) => modelItem === modelName
            );
            // pushing the total length modalname
            modelNameArrAndroid.push([modelName]);
            modelNumberArrAndroid.push(parseInt(filteredArray.length));
          });
          uniqueModelios.forEach((modelName) => {
            // filter using modal name
            var filteredArray = DeviceModelIos.filter(
              (modelItem) => modelItem === modelName
            );
            // console.log(modelName,filteredArray.length);
            modelNameArrIos.push([modelName]);
            modelNumberArrIos.push(parseInt(filteredArray.length));
          });
          uniqueVersionandroid.forEach((versionName) => {
            var filteredArray = ClientVersionandroid.filter(
              (versionItem) => versionItem === versionName
            );

            if (versionName === undefined) {
              versionName = "Unknown";
            }
            //console.log(versionName,filteredArray.length);
            versionNameArrAndroid.push([versionName]);
            versionNumberArrAndroid.push(parseInt(filteredArray.length));
          });
          uniqueVersionios.forEach((versionName) => {
            var filteredArray1 = ClientVersionios.filter(
              (versionItem) => versionItem === versionName
            );
            // console.log(versionName,filteredArray1.length);
            if (versionName === undefined) {
              versionName = "Unknown";
            }
            versionNameArrIos.push([versionName]);
            versionNumberArrIos.push(parseInt(filteredArray1.length));
          });
          uniqueDeviceOs.forEach((deviceOsName) => {
            var filteredArray2 = DeviceOs.filter(
              (deviceOsItem) => deviceOsItem === deviceOsName
            );
            // console.log(deviceOsName,filteredArray2.length);
            deviceOSNameArr.push([deviceOsName]);
            deviceOSNumberArr.push(parseInt(filteredArray2.length));
          });
          //console.log(modelNameArrIos);
          //console.log(modelNumberArrIos);
          //console.log(deviceOSArr);
          var obj_arr = {
            modelNameArrAndroid,
            modelNumberArrAndroid,
            modelNameArrIos,
            modelNumberArrIos,
            versionNameArrAndroid,
            versionNumberArrAndroid,
            versionNameArrIos,
            versionNumberArrIos,
            deviceOSNameArr,
            deviceOSNumberArr,
          };
          //console.log(obj_arr);
          resolve(obj_arr);
        });
    });
  };
}

export function mostorder(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var productidArr = [];
      var allproduct = [];
      //console.log(storeid, smonth)
      var m = moment(values.start_date);
      var m1 = m.startOf("day").format();
      var start = new Date(m1);
      var n = moment(values.end_date);
      var n1 = n.endOf("day").format();
      var end = new Date(n1);
      console.log(start);
      console.log(end);

      db.collection("Order")
        .where("Ref_Order_Store_Id", "==", values.store_id)
        .where("Created_At", ">", start)
        .where("Created_At", "<", end)
        .where("Ref_Order_Status_Id", "==", 12)
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot.size);
          if (querySnapshot.size !== 0) {
            var index = 0;
            var totalcount = 0;
            querySnapshot.forEach((doc) => {
              // console.log(doc.id);

              db.collection("Order_Details")
                .where("Ref_Order_Id", "==", doc.id)
                .onSnapshot(function (querySnapshot1) {
                  totalcount =
                    parseInt(totalcount) + parseInt(querySnapshot1.size);
                  querySnapshot1.forEach((doc1) => {
                    //console.log(doc1.data());
                    var Ref_Products_Id = doc1.data().Ref_Products_Id;
                    var order_obj = {
                      Ref_Product_Brand_Id: doc1.data().Ref_Product_Brand_Id,
                      Ref_Products_Display_Name:
                        doc1.data().Ref_Products_Display_Name,
                      Ref_Products_Id: doc1.data().Ref_Products_Id,
                      Ref_Product_Thumb_Image_Url:
                        doc1.data().Ref_Product_Thumb_Image_Url,
                      No_Of_Order: doc1.data().No_Of_Order,
                    };
                    allproduct.push(order_obj);
                    productidArr.push(Ref_Products_Id);
                  });
                  index++;
                  if (querySnapshot.size === index) {
                    console.log(totalcount);
                    //console.log(order_detail_arr);
                    //resolve(order_detail_arr);
                    unique_count();
                  }
                });
            });
          } else {
            reject("No Data");
          }
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      function unique_count() {
        var slab_details = [];
        var uSet = new Set(productidArr);
        var uniquetax_slab = [...uSet];
        console.log(uniquetax_slab);
        uniquetax_slab.forEach((modelName) => {
          var filteredArray = allproduct.filter(
            (modelItem) => modelItem.Ref_Products_Id === modelName
          );
          console.log(filteredArray);
          const sum = filteredArray.reduce(
            (n, { No_Of_Order }) => n + No_Of_Order,
            0
          );
          var obj_arr = {
            key: modelName,
            Ref_Product_Brand_Id: filteredArray[0].Ref_Product_Brand_Id,
            Ref_Product_Thumb_Image_Url:
              bucket_baseurl + filteredArray[0].Ref_Product_Thumb_Image_Url,
            Ref_Products_Display_Name:
              filteredArray[0].Ref_Products_Display_Name,
            Ordercount: sum,
          };

          slab_details.push(obj_arr);
        });
        var slab_detail = _.orderBy(slab_details, ["Ordercount"], ["desc"]);
        console.log(slab_detail);
        resolve(slab_detail);
      }
    });
  };
}

export function productstatus(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var allproduct = [];
      //console.log(storeid, smonth)
      var m = moment(values.start_date);
      var m1 = m.startOf("day").format();
      var start = new Date(m1);
      var n = moment(values.end_date);
      var n1 = n.endOf("day").format();
      var end = new Date(n1);
      // console.log(start);
      //console.log(end);

      db.collection("Product_Details1")
        .where("Ref_Store_Id", "==", values.store_id)
        .where("Updated_At", ">", start)
        .where("Updated_At", "<", end)
        .where("Is_Stock_Available", "==", false)
        .where("Is_Active", "==", true)
        .onSnapshot(function (querySnapshot) {
          console.log(querySnapshot.size);
          if (querySnapshot.size !== 0) {
            querySnapshot.forEach((doc) => {
              var obj_arr = {
                key: doc.id,
                Product_Id: doc.data().Product_Id,
                Product_Name: doc.data().Product_Name,
                Product_Thumb_Image_Url:
                  bucket_baseurl + doc.data().Product_Thumb_Image_Url,
                Updated_At: moment(doc.data().Updated_At.toDate()).format(
                  "MM/DD/YYYY hh:mm:ss A"
                ),
                Product_Description: doc.data().Product_Description,
              };
              allproduct.push(obj_arr);
              resolve(allproduct);
            });
          } else {
            reject(allproduct);
          }
        });
    });
  };
}

export function NearOrder(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // console.log(values);
      var arr_order = [];
      var m = moment(values.start_date);
      var m1 = m.startOf("day").format();
      var start = new Date(m1);
      var n = moment(values.end_date);
      var n1 = n.endOf("day").format();
      var end = new Date(n1);
      db.collection("Order")
        .where("Ref_Order_Store_Id", "==", values.store_id)
        .where("Updated_At", ">", start)
        .where("Updated_At", "<", end)
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot.size);
          querySnapshot.forEach((doc) => {
            arr_order.push(doc.data());
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
          });
          // const type1 = arr_order.filter(
          //   ({ Order_Mileage }) => Order_Mileage <= 0.5
          // );
          // const type1 = arr_order.filter(
          //   ({ Order_Mileage }) => Order_Mileage > 1.0 && Order_Mileage < 1.5
          // );
          const type1 = arr_order.filter(
            ({ Order_Mileage }) => Order_Mileage > 1.0 && Order_Mileage < 1.5
          );

          console.log(type1);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    });
  };
}
