import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {UpdateCat, checkcat, SinglecatTable,allstorename} from '../actions/assetAction';
import {Form, Input, Icon, Row,Radio, Upload, Button, message, Spin ,Col, Popconfirm,Select} from 'antd';
import _ from 'lodash';
import '../styles/css/App.css';

const { TextArea } = Input;
const { Option } = Select;

class Editcategory extends Component {

    constructor(props) {
        super(props);
        // state
        this.state = {
          fileList1: "",
          iconLoading: false ,
          valstatus:'',
          loading:true,
          filetag:1
        };

    }

    componentWillMount()
    {
      
       // console.log(this.props.location.catid);
       this.props.allstorename();
        var drvrid;
        if (this.props.location.catid === undefined)
        {
            drvrid = localStorage.getItem('catid');
            this.props.SinglecatTable(drvrid).then((result) => {
                this.setState({ loading: false,});
                this.props.form.setFieldsValue({
                    Category_Id: this.props.common.cid,
                    Category_Name:this.props.common.cname,
                    Category_Description:this.props.common.description,
                    Category_Status:this.props.common.tags,
                    Category_Display_Order:this.props.common.Category_Display_Order,
                    Ref_Store_Id_List:this.props.common.Ref_Store_Id_List
                  })
            })
            .catch((err) => {
                message.error(err);
            });
            console.log("if Block ", drvrid);
        }
        else
        {
            localStorage.setItem('catid', this.props.location.catid);
            drvrid = localStorage.getItem('catid');
            this.props.SinglecatTable(drvrid).then((result) => {
              this.setState({ loading: false});
              this.props.form.setFieldsValue({
                Category_Id: this.props.common.cid,
                Category_Name:this.props.common.cname,
                Category_Description:this.props.common.description,
                Category_Status:this.props.common.tags,
                Category_Display_Order:this.props.common.Category_Display_Order,
                Ref_Store_Id_List:this.props.common.Ref_Store_Id_List
              })
            }).catch((err) => {
                message.error(err);
            });
            console.log(drvrid);
        }
    }  
    
    checkPrice = (rule, value, callback) => { 
      // console.log('changed', value);
       const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
       if ((!Number.isNaN(value) && reg.test(value)) || value === '')
        {
         callback();
         return;
       }
       callback('Input only a numeric value...!');
     }

    handleReset = () => {
      this.setState({ loading: true});
      var drvrid = localStorage.getItem('catid');
      this.props.SinglecatTable(drvrid).then((result) => {
          this.setState({ loading: false,});
          this.props.form.setFieldsValue({
              Category_Id: this.props.common.cid,
              Category_Name:this.props.common.cname,
              Category_Description:this.props.common.description,
              Category_Status:this.props.common.tags
            })
      })
      .catch((err) => {
          message.error(err);
      });
    }

    deleteImage = () => {
        this.setState({
            filetag:0
          });	
    }

    handleSubmit = (e) => {
      e.preventDefault();
     

      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
         
          this.setState({
            iconLoading: true
          });	
         var drvrid = localStorage.getItem('catid');
         console.log(values, this.state.fileList1);
              this.props.UpdateCat(values, this.state.fileList1, drvrid)
              .then((result) => {

                this.setState({
                  iconLoading: false,
                  fileList1: "",
                  filetag:0,
                });	
                this.props.form.resetFields();
               
                message.success("Category updated successfully!");
                
              }).catch((err) => {
                
              });

        }
      });
    }

    
    normFile = e => {
      console.log("Upload event:", e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };
   
    renderStorename() {
      return _.map(this.props.storeall, (storeall, key) => {
        return (
          <Option value={storeall.key} key={key}>
            {storeall.store_name}
          </Option>
        );
      });
    }
    handleStoreID = (value) => {
      this.setState({ StoreID: value });

    };
// ======================================================

    render() {
     
      const { fileList1 } = this.state;
     
    const propsthumb = {
      multiple: false,
      listType: "picture-card",
      accept: ".png,.jpg",
      showUploadList	: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList1.indexOf(file);
          const newFileList = state.fileList1.slice();
          newFileList.splice(index, 1);
          return {
            fileList1: newFileList,
          };
        });
        console.log(this.state.fileList1);
      },
      beforeUpload: (file) => {
        this.setState(state => ({
          fileList1: [...state.fileList1, file],
        }));
        return false;
      },
      fileList1,
    };
    
      const { getFieldDecorator } = this.props.form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
      };


//==============================================================================================================//
       
          return (
<div>
            <div className="container-fluid addp">
            <div className="container-fluid">
            <div className="row">
                <div className="col-sm-4">
                    <h4>Edit Category</h4>
                </div>
                <div className="col-sm-8 text-right">
                <Link to="/category"><Button className="pull-right" type="dark" icon="arrow-left">Back To Category</Button></Link>
                </div>
                </div>
            
            </div>
            </div>
          <div className="container-fluid text-left addproduct">
          <Spin spinning={this.state.loading}>
          <Row>
             <Form onSubmit={this.handleSubmit}>
             <Form.Item
                  {...formItemLayout}
                  label="Store"
                >
                  {getFieldDecorator('Ref_Store_Id_List', {
                    rules: [{ required: true, message: 'Select any one store'}],
                  })(
                    <Select
                      style={{
                        width: 340,
                      }}
                      mode="multiple"
                      onChange={this.handleStoreID}
                      placeholder="Select Store"
                    >
                      {this.renderStorename()}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Category ID"
                  hasFeedback 
                  validateStatus={this.state.valstatus}
                  
                >
                  {getFieldDecorator('Category_Id', {
                    rules: [{ required: true, message: 'Enter Category Id', whitespace: false }],
                  })(
                    <Input onBlur={this.categorycheck} style={{ width: 250 }} id={this.state.valstatus}/>
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  label="Category Name"
                >
                  {getFieldDecorator('Category_Name', {
                    rules: [{ required: true, message: 'Enter Category Name', whitespace: false }],
                  })(
                    <Input />
                  )}
                </Form.Item>

                <Form.Item
                              {...formItemLayout}
                              label="Display Order"
                            >
                             {getFieldDecorator("Category_Display_Order", {
                               
                                rules: [
                                  {
                                    required: true,
                                    pattern: new RegExp(
                                      "^[0-9]*$"
                                    ),
                                    message: "Display order format wrong!",
                                  },
                                ],
                              })(
                                <Input
                                  placeholder="0"
                                />
                              )}
                            </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Category Description"
                >
                  {getFieldDecorator('Category_Description', {
                    rules: [{ required: true, message: 'Enter Category Description', whitespace: false }],
                  })(
                    <TextArea rows ={5} />
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  label="Category Status"
                >
                  {getFieldDecorator('Category_Status', {
                    initialValue: "Active",
                    rules: [{ required: true, message: 'Select Category Status', whitespace: false }],
                  })(
                    <Radio.Group>
                      <Radio value="true">Active</Radio>
                      <Radio value="false">In-Active</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
                

                { this.state.filetag === 0 ? ( <Form.Item
                {...formItemLayout}
                label="Category Image"
                // extra="Category Image"
              >
            
                {getFieldDecorator('Category_Image_Url', {
                  valuePropName: 'fileList',
                  getValueFromEvent: this.normFile,
                    rules: [{
                        required: true, message: 'Please Upload Category Display Image!',
                    }],
                  })(
                  <Upload name="Category_Image_Url"  {...propsthumb} >
                    {fileList1.length === 1 ? 
                      null : 
                      <div>
                        <Icon type="plus" />
                        <div className="ant-upload-text">Choose</div>
                      </div>
                      }
                    
                  </Upload>
                )}
              </Form.Item>) : 
              (
                <Form.Item
                  {...formItemLayout}
                  label="Category Image"
                >
                <Row gutter={15}>
                <Col span={16}>
                 <img className="img-responsive" src={this.props.common.imgUrl} alt="" />
                 </Col>
                  <Col span={8}>
                  <Popconfirm title="Are you sure delete this Image, This will cause permanent loss of image ?" onConfirm={() => this.deleteImage()} onCancel={this.cancel} okText="Yes" cancelText="No">  <Button
                              type="danger"
                             
                            >
                              <Icon type="delete" /> 
                            </Button></Popconfirm>
                          </Col></Row>
                </Form.Item>)}
               

             

              
{/* =============================== Submit Button ======================================== */}

        <Form.Item
          wrapperCol={{ span: 12, offset: 6 }}
        >
          <Button type="primary" size="large" htmlType="submit" loading={this.state.iconLoading}>Submit</Button>
          <Button style={{ marginLeft: 15 }} size="large" onClick={this.handleReset}>
              Refresh
            </Button>
        </Form.Item>
          </Form>
        </Row>
        </Spin>
         </div>
        </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    user: state.user,
    common:state.common,
    storeall:state.storeall
  };
}

const WrappedRegistrationForm = Form.create()(Editcategory);

export default connect(mapStateToProps, {UpdateCat, checkcat,SinglecatTable,allstorename})(WrappedRegistrationForm);
