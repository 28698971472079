import React, { Component } from "react";
import { connect } from "react-redux";
import { emailLogin, logout } from "../actions/userAction";
import { Allnotification } from "../actions/assetAction";
import { Icon, Button, Input, Table, message } from "antd";
import { db } from "../firebase";
import "../styles/css/App.css";
import Highlighter from "react-highlight-words";
import { TimeAgo } from "@n1ru4l/react-time-ago";

class AllNotification extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      disabled: true,
      searchText: "",
      tdata: null,
      loading: true,
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    console.log(this.state.searchText);
  };

  componentWillMount() {
    //  componentWillMount

    this.props
      .Allnotification()
      .then((res) => {
        console.log(res);
        this.setState({
          tdata: this.props.common,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  }
  handleResett = () => {
    //  componentWillMount

    const initialDate = new Date();

    this.setState({
      loading: true,
    });

    this.props
      .Allnotification()
      .then((res) => {
        console.log(res);
        this.setState({
          tdata: this.props.common,
          loading: false,
          date: initialDate,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  confirm = (key) => {
    console.log(key);
    db.collection("Coupons_Master")
      .doc(key)
      .delete()
      .then(function () {
        message.success("Document successfully deleted!");
      })
      .catch(function (error) {
        message.error("Error removing document: ", error);
      });
    const tdata = [...this.state.tdata];
    this.setState({ tdata: tdata.filter((item) => item.key !== key) });
  };
  //===============================================

  render() {
    // console.log(this.props.products);
    const columns = [
      {
        title: "Store name",
        dataIndex: "topic",
        key: "topic",
        ...this.getColumnSearchProps("topic"),
      },

      {
        title: "type",
        dataIndex: "type",
        key: "type",
        ...this.getColumnSearchProps("type"),
      },
      {
        title: "image",
        dataIndex: "image",
        key: "image",
      },
      {
        title: "description",
        dataIndex: "description",
        key: "description",
        ...this.getColumnSearchProps("description"),
      },
      {
        title: "create_time",
        dataIndex: "create_time",
        key: "create_time",
        ...this.getColumnSearchProps("create_time"),
      },
    ];

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h4>Push Notification History</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid allproduct">
          <div className="text-right filterdiv">
            <span style={{ marginRight: 10 }}>
              Last Refreshed Time :{" "}
              <TimeAgo date={new Date()}>
                {({ value }) => (
                  <b>
                    <Icon type="clock-circle" theme="outlined" /> {value}
                  </b>
                )}
              </TimeAgo>
            </span>
            <Button
              type="primary"
              style={{ marginLeft: 15 }}
              onClick={this.handleResett}
            >
              Refresh
            </Button>
          </div>

          <Table
            columns={columns}
            dataSource={this.state.tdata}
            loading={this.state.loading}
            bordered
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    common: state.common,
  };
}

export default connect(mapStateToProps, {
  emailLogin,
  logout,
  Allnotification,
})(AllNotification);
