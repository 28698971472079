import React, { Component } from "react";
import { connect } from "react-redux";
import { emailLogin, logout } from "../actions/userAction";
import {
  customeallorder,
  MD_Order_Status,
  allstorename,
} from "../actions/assetAction";
import { Link } from "react-router-dom";
import {
  Icon,
  Tooltip,
  Button,
  Input,
  Tag,
  Skeleton,
  Col,
  Row,
  Table,
  Divider,
  message,
  DatePicker,
  Form,
  Modal,
  Select,
} from "antd";
import "../styles/css/App.css";
import Highlighter from "react-highlight-words";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import _ from "lodash";
import StatCard from "./templates/StatCard";
import { ShoppingCart, Target } from "react-feather";
import { customdashreport } from "../actions/dashboardAction";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { db } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";

const { Option } = Select;
const cryptLib = require("@skavinvarnan/cryptlib");

class Order extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      disabled: true,
      searchText: "",
      tdata: null,
      loading: true,
      dashstats: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      visible: false,
      volume: 0,
      mvisible: false,
      mdstatus: [],
      iconLoading: false,
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon
        type="search"
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    console.log(this.state.searchText);
  };

  componentDidMount() {
    this.props.allstorename();
    const today = moment();
    const from_date = today.startOf("day").format();
    const to_date = today.endOf("end").format();
    this.props.MD_Order_Status().then((res) => {
      this.setState({ mdstatus: res });
    });
    //  componentWillMount
    this.props
      .customeallorder(from_date, to_date)
      .then((res) => {
        console.log(res);
        this.setState({
          tdata: this.props.order,
          loading: false,
          visible: true,
        });
      })
      .catch((error) => {
        console.log("error");
        this.setState({ loading: false });
      });
    this.props
      .customdashreport(from_date, to_date)
      .then((result) => {
        console.log("resolved", this.props.dashtoday);
        this.setState({ dashstats: this.props.dashtoday });
      })
      .catch((error) => {
        console.log("error");
        this.setState({ loading: false });
      });
    this.timer = setInterval(
      () => this.props.customeallorder(from_date, to_date),
      60000
    );
    this.timer1 = setInterval(
      () => this.props.customdashreport(from_date, to_date),
      60000
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.order !== this.props.order) {
      console.log("did update");
      this.setState({
        tdata: this.props.order,
      });
    }
    if (prevProps.dashtoday !== this.props.dashtoday) {
      console.log("did update");
      this.setState({
        dashstats: this.props.dashtoday,
      });
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.timer1);
  }
  handleResett = () => {
    this.props.form.resetFields();
    const initialDate = new Date();
    const today = moment();
    const from_date = today.startOf("day").format();
    const to_date = today.endOf("end").format();

    this.setState({ loading: true });
    this.props
      .customdashreport(from_date, to_date)
      .then((result) => {
        console.log("resolved", this.props.dashtoday);
        this.setState({ dashstats: this.props.dashtoday });
      })
      .catch((error) => {
        console.log("error");
        this.setState({ loading: false });
      });
    this.props
      .customeallorder(from_date, to_date)
      .then((res) => {
        console.log(res);
        this.setState({
          tdata: this.props.order,
          loading: false,
          date: initialDate,
        });
        this.timer = setInterval(
          () => this.props.customeallorder(from_date, to_date),
          60000
        );
        this.timer1 = setInterval(
          () => this.props.customdashreport(from_date, to_date),
          60000
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };

  renderNotes() {
    return _.map(this.props.order, (order, key) => {
      return (
        <tr key={key}>
          <td>{order.cust_id}</td>
          <td>{order.order_id}</td>
          <td>{order.customer_name}</td>
          <td>{order.customer_phone}</td>
          <td>{order.delivery_address}</td>
          <td>{order.driver_name}</td>
          <td>{order.store_name}</td>
          <td>{order.order_status}</td>

          <td>
            {order.Ref_Delivery_Preferences === 2 ? "PICKUP" : "DELIVERY"}
          </td>
          <td>{order.Delivery_Fee}</td>
          <td>{order.Ref_Service_Fee}</td>

          <td>{order.Total_Price}</td>
          <td>{order.Ref_Cash_Back_Amount}</td>
          <td>{order.Payment_Type}</td>
          <td>{order.orderno}</td>
          <td>{order.Order_Coupon_Applicable === true ? "Yes" : "No"}</td>
          <td>{order.order_date}</td>
        </tr>
      );
    });
  }
  renderOrderStatus() {
    return _.map(this.state.mdstatus, (order, key) => {
      return (
        <Option key={key} value={order.Order_Status_Id}>
          {order.Order_Status}
        </Option>
      );
    });
  }

  confirmdelete = (key) => {
    const initialDate = new Date();

    this.setState({ loading: true });

    //console.log(key);
    db.collection("Order")
      .doc(key)
      .update({
        Ref_Driver_Id: "",
        Ref_Driver_Name: "",
        Ref_Order_Status_Id: 5,
        Driver_Accepted_Address: "",
        Driver_Accepted_Latitude: 0,
        Driver_Accepted_Longitude: 0,
        Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
        Updated_By: this.props.userinfo.Name,
      })
      .then((res) => {
        this.props
          .dashreporttoday()
          .then((result) => {
            console.log("resolved", this.props.dashtoday);
            this.setState({ dashstats: this.props.dashtoday });
          })
          .catch((error) => {
            console.log("error");
            this.setState({ loading: false });
          });
        this.props
          .allorder()
          .then((res) => {
            console.log(res);
            this.setState({
              tdata: this.props.order,
              loading: false,
              date: initialDate,
            });
            message.success("Order successfully updated!");
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        message.error("Error removing document: ", error);
        this.setState({ loading: false });
      });
  };
  disabledStartDate = (startValue) => {
    // const { endValue } = this.state;
    // if (!startValue || !endValue)
    // {   return false; }
    return startValue && startValue > moment().endOf("day");
  };

  disabledEndDate = (endValue) => {
    // const {startValue} = this.state;
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    return endValue && endValue > moment().endOf("day");
  };

  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      ["start_date", "end_date"],
      (err, values) => {
        if (!err) {
          this.setState({ loading: true });
          clearInterval(this.timer);
          clearInterval(this.timer1);
          console.log(values);
          this.props
            .customdashreport(values.start_date, values.end_date)
            .then((result) => {
              console.log("resolved", this.props.dashtoday);
              this.setState({ dashstats: this.props.dashtoday });
            })
            .catch((error) => {
              console.log("error");
              this.setState({ loading: false });
            });
          this.props
            .customeallorder(values.start_date, values.end_date)
            .then((res) => {
              console.log(res);
              this.setState({
                tdata: this.props.order,
                loading: false,
                visible: true,
              });
            })
            .catch((error) => {
              console.log("error");
              this.setState({ loading: false });
            });
        }
      }
    );
  };
  editorder = (e) => {
    console.log(e);
    this.props.form.setFieldsValue({
      order_id: e.order_id,
      Order_Status: e.orderstatus_id,
      otp: e.OTP_Num,
      order_key: e.key,
      store_id: e.store_id,
      cashback_otp: e.cashback_otp,
    });
    this.setState({
      mvisible: true,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      mvisible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);

    this.setState({
      mvisible: false,
    });
  };
  handleSubmit1 = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      [
        "order_id",
        "Order_Status",
        "otp",
        "order_key",
        "store_id",
        "cashback_otp",
      ],
      (err, values) => {
        if (!err) {
          this.setState({ iconLoading: true });
          const key = "bbC2H19lkVbQDfakxcrtNMQdd0FloLyw";
          const OTP_Num = cryptLib.encryptPlainTextWithRandomIV(
            values.otp,
            key
          );
          if (values.cashback_otp === "-") {
            var cashback_otp = "";
          } else {
            const key1 = "bbC2H19lkVbQDfakxcrtNMQdd0FloLyw";
            cashback_otp = cryptLib.encryptPlainTextWithRandomIV(
              values.cashback_otp,
              key1
            );
          }

          var obj = {
            Display_Order_Id: values.order_id,
            Order_OTP: OTP_Num,
            Cash_Back_Code: cashback_otp,
            Ref_Order_Store_Id: values.store_id,
            Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
            Updated_By: this.props.userinfo.Name,
          };

          db.collection("Order")
            .doc(values.order_key)
            .update(obj)
            .then((res) => {
              console.log(res);
              this.setState({
                loading: true,
                mvisible: false,
                iconLoading: false,
              });
              const today = moment();
              const from_date = today.startOf("day").format();
              const to_date = today.endOf("end").format();
              this.props
                .customeallorder(from_date, to_date)
                .then((res) => {
                  console.log(res);
                  this.setState({ tdata: this.props.order, loading: false });
                })
                .catch((error) => {
                  console.log("error");
                  this.setState({ loading: false });
                });
            });
        }
      }
    );
  };
  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          {storeall.store_name}
        </Option>
      );
    });
  }

  handleStoreID = (value) => {
    this.setState({ StoreID: value });
  };
  onCopy = (e) => {
    console.log(e);
    this.setState({ copytext: e });
    navigator.clipboard.writeText(e);
    // const el = this.textArea;
    // el.select();
    // document.execCommand("copy");
  };

  //===============================================
  //===============================================

  handleMenuClick = (key, storeid, order_id) => {
    console.log("click", key, storeid);
    this.setState({ loading: true });
    const docRef = db.collection("Stores_Master").doc(storeid);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          if (key === 1) {
            var obj = {
              Ref_Delivery_Fee: doc.data().Delivery_Fee,
              Ref_Delivery_Preferences: parseInt(key),
              Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
              Order_OTP: "DHvmxA66INgloQtqfOoQ94r5tFwHauNjGe3y26sOAkA=",
              Updated_By: this.props.userinfo.Name,
            };
          } else {
            obj = {
              Ref_Delivery_Fee: 0,
              Ref_Delivery_Preferences: parseInt(key),
              Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
              Delivery_Preferences_Code:
                "2COe86Ukx2trCRbHw3md9Hia1EhMH975RGjWsoO76A0=",
              Updated_By: this.props.userinfo.Name,
            };
          }
          db.collection("Order")
            .doc(order_id)
            .update(obj)
            .then((res) => {
              console.log(res);
              this.setState({
                mvisible: false,
                iconLoading: false,
              });
              const today = moment();
              const from_date = today.startOf("day").format();
              const to_date = today.endOf("end").format();
              this.props
                .customeallorder(from_date, to_date)
                .then((res) => {
                  console.log(res);
                  this.setState({ tdata: this.props.order, loading: false });
                })
                .catch((error) => {
                  console.log("error");
                  this.setState({ loading: false });
                });
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        this.setState({ loading: false });
      });
  };
  //===============================================
  //===============================================

  render() {
    // const menu = (key, storeid, order_id) => (
    //   <Menu>
    //     <Menu.Item
    //       key="2"
    //       onClick={() => this.handleMenuClick(key, storeid, order_id)}
    //     >
    //       Change To Pickup
    //     </Menu.Item>
    //   </Menu>
    // );
    // const menu1 = (key, storeid, order_id) => (
    //   <Menu>
    //     <Menu.Item
    //       key="1"
    //       onClick={() => this.handleMenuClick(key, storeid, order_id)}
    //     >
    //       Change To Delivery
    //     </Menu.Item>
    //   </Menu>
    // );
    const { dashstats, endOpen } = this.state;
    const { getFieldDecorator } = this.props.form;
    // console.log(this.props.products);
    const columns = [
      {
        title: "Order ID",
        dataIndex: "order_id",
        width: 120,
        key: "order_id",
        ...this.getColumnSearchProps("order_id"),
      },

      {
        title: "Order Time",
        dataIndex: "orderdifftime",
        width: 110,
        key: "orderdifftime",
        render: (text, record) => (
          <span>
            {record.orderstatus_id === 1 ||
            record.orderstatus_id === 12 ||
            record.orderstatus_id === 20 ||
            record.orderstatus_id === 4 ? (
              "-"
            ) : (
              <span>
                {record.actualdeliverytime < -1 ? (
                  <span>
                    {record.actualdeliverytime < -2 ? (
                      <Tag color="#ff5500">{record.orderdifftime}</Tag>
                    ) : (
                      <Tag color="#fa8c16">{record.orderdifftime}</Tag>
                    )}
                  </span>
                ) : (
                  <Tag color="#3498db">{record.orderdifftime}</Tag>
                )}
              </span>
            )}
          </span>
        ),
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        width: 120,
        key: "customer_name",
        ...this.getColumnSearchProps("customer_name"),
      },
      {
        title: "Customer Phone",
        dataIndex: "customer_phone",
        width: 120,
        key: "customer_phone",
        ...this.getColumnSearchProps("customer_phone"),
      },
      {
        title: "Delivery Address",
        dataIndex: "delivery_address",
        width: 150,
        key: "delivery_address",
        ...this.getColumnSearchProps("delivery_address"),
      },
      {
        title: "Store Name",
        dataIndex: "store_name",
        key: "store_name",
        width: 100,
        ...this.getColumnSearchProps("store_name"),
      },
      {
        title: "Driver Name",
        dataIndex: "driver_name",
        width: 80,
        key: "driver_name",
        ...this.getColumnSearchProps("driver_name"),
      },
      {
        title: "Order Status",
        dataIndex: "order_status",
        width: 130,
        key: "order_status",
        ...this.getColumnSearchProps("order_status"),
        render: (text, record) => (
          <span>
            {(() => {
              switch (record.orderstatus_id) {
                case 7:
                  return <Tag color="lime">{record.order_status}</Tag>;
                case 12:
                  return <Tag color="#87d068">{record.order_status}</Tag>;
                case 9:
                  return <Tag color="volcano">{record.order_status}</Tag>;

                default:
                  return <Tag color="orange">{record.order_status}</Tag>;
              }
            })()}
          </span>
        ),
      },
      {
        title: "Order Date",
        dataIndex: "order_date",
        width: 100,
        key: "order_date",
      },

      {
        title: "Price",
        dataIndex: "Total_Price",
        key: "Total_Price",
        ...this.getColumnSearchProps("Total_Price"),
      },
      {
        title: "OTP",
        dataIndex: "OTP_Num",
        key: "OTP_Num",
        render: (text, record) => (
          <span>
            {record.Ref_Delivery_Preferences === 2
              ? record.delivery_otp
              : record.OTP_Num}
          </span>
        ),
      },
      {
        title: "Cashback OTP",
        dataIndex: "cashback_otp",
        key: "cashback_otp",
      },
      {
        title: "Order Type",
        dataIndex: "Ref_Delivery_Preferences",
        width: 150,
        key: "Ref_Delivery_Preferences",
        render: (text, record) => (
          <span>
            {record.Ref_Delivery_Preferences === 2 ? (
              <div>
                <Button style={{ backgroundColor: "#3498db", color: "white" }}>
                  Pick up
                </Button>
              </div>
            ) : (
              <div>
                <Button type="primary">Delivery</Button>
              </div>
            )}
          </span>
        ),
        filters: [
          {
            text: "Pick up",
            value: 2,
          },
          {
            text: "Delivery",
            value: 1,
          },
        ],
        filterMultiple: false,
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value, record) => record.Ref_Delivery_Preferences === value,
      },
      // {
      //   title: "Payment Status",
      //   dataIndex: "Ref_Order_Transaction_Payment_Id",
      //   width: 100,
      //   key: "Ref_Order_Transaction_Payment_Id",
      //   render: (text, record) => (
      //     <span>
      //       {record.Ref_Order_Transaction_Payment_Id === 1 ? (
      //         <Tag color="green">DONE</Tag>
      //       ) : (
      //         <Tag color="volcano">NOT DONE</Tag>
      //       )}
      //     </span>
      //   ),
      // },
      {
        title: "Payment Type",
        dataIndex: "Payment_Type",
        key: "Payment_Type",
      },
      {
        title: "No. of Order",
        dataIndex: "orderno",
        width: 80,
        key: "orderno",
      },
      {
        title: "App Version",
        dataIndex: "App_version",
        key: "App_version",
        ...this.getColumnSearchProps("App_version"),
      },
      {
        title: "Coupon Applied",
        dataIndex: "Order_Coupon_Applicable",
        width: 150,
        key: "Order_Coupon_Applicable",
        render: (text, record) => (
          <span>
            {record.Order_Coupon_Applicable === true ? (
              <Tag color="green">Yes</Tag>
            ) : (
              <Tag color="volcano">No</Tag>
            )}
          </span>
        ),
        filters: [
          {
            text: "Yes",
            value: true,
          },
          {
            text: "No",
            value: false,
          },
        ],
        filterMultiple: false,
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value, record) => record.Order_Coupon_Applicable === value,
      },
      {
        title: "Order Device",
        dataIndex: "Order_Request_Device",
        key: "Order_Request_Device",
        ...this.getColumnSearchProps("Order_Request_Device"),
      },
      {
        title: "Customer Id",
        dataIndex: "cust_id",
        key: "cust_id",
        ...this.getColumnSearchProps("cust_id"),
      },
      {
        title: "updated_by",
        dataIndex: "updated_by",
        key: "updated_by",
        ...this.getColumnSearchProps("updated_by"),
      },

      {
        title: "Action",
        dataIndex: "action",
        fixed: "right",
        width: 250,
        key: "action",
        render: (text, record) => (
          <span>
            {record.orderstatus_id === 10 ? (
              <span>
                <Tooltip title="Copy Order">
                  <CopyToClipboard
                    onCopy={this.onCopy}
                    text={record.customerdetail}
                  >
                    <Button type="primary" shape="circle" icon="copy" />
                  </CopyToClipboard>
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Edit Order">
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => this.editorder(record)}
                    icon="edit"
                  />
                </Tooltip>
                <Divider type="vertical" />
                <Link
                  to={{
                    pathname: "/trackorder",
                    orderid: record.key,
                  }}
                >
                  <Tooltip title="Order Tracking">
                    <Button type="primary" shape="circle" icon="environment" />
                  </Tooltip>
                </Link>
                <Divider type="vertical" />
                <Link
                  target="_blank"
                  to={{
                    pathname: "/orderview?id=" + record.key,
                  }}
                >
                  <Tooltip title="View Order ">
                    <Button type="primary" shape="circle" icon="eye" />
                  </Tooltip>
                </Link>
                <Divider type="vertical" />
                <Tooltip title="Revoke Driver">
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => this.confirmdelete(record.key)}
                    disabled={record.orderstatus_id === 12 ? true : false}
                    icon="user"
                  />
                </Tooltip>
              </span>
            ) : (
              <span>
                <Tooltip title="Copy Order">
                  <CopyToClipboard
                    onCopy={this.onCopy}
                    text={record.customerdetail}
                  >
                    <Button type="primary" shape="circle" icon="copy" />
                  </CopyToClipboard>
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Edit Order">
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => this.editorder(record)}
                    icon="edit"
                  />
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Order Tracking">
                  <Button
                    disabled
                    type="primary"
                    shape="circle"
                    icon="environment"
                  />
                </Tooltip>
                <Divider type="vertical" />
                <Link
                  target="_blank"
                  to={{
                    pathname: "/orderview?id=" + record.key,
                  }}
                >
                  <Tooltip title="View Order ">
                    <Button type="primary" shape="circle" icon="eye" />
                  </Tooltip>
                </Link>
                <Divider type="vertical" />
                <Tooltip title="Revoke Driver">
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => this.confirmdelete(record.key)}
                    disabled={record.orderstatus_id === 12 ? true : false}
                    icon="user"
                  />
                </Tooltip>
              </span>
            )}
          </span>
        ),
      },
    ];

    return (
      <div>
        <Modal
          title="Order Info"
          visible={this.state.mvisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Form onSubmit={this.handleSubmit1} layout="vertical">
            <Form.Item label="Order Key" className="hidden">
              {getFieldDecorator("order_key", {
                rules: [
                  {
                    required: true,
                    message: "Order key should not empty",
                  },
                ],
              })(<Input disabled />)}
            </Form.Item>
            <Form.Item label="Order Id">
              {getFieldDecorator("order_id", {
                rules: [
                  {
                    required: false,
                    message: "Order Id should not empty",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            {/* <Form.Item label="Order Status">
              {getFieldDecorator("Order_Status", {
                rules: [
                  {
                    required: true,
                    message: "Order status should not empty",
                  },
                ],
              })(<Select>{this.renderOrderStatus()}</Select>)}
            </Form.Item> */}
            <Form.Item label="OTP">
              {getFieldDecorator("otp", {
                rules: [
                  {
                    required: true,
                    message: "OTP should not empty",
                  },
                ],
              })(<Input maxLength={4} />)}
            </Form.Item>
            <Form.Item label="Cashback OTP">
              {getFieldDecorator("cashback_otp", {
                rules: [
                  {
                    required: true,
                    message: "Cashback OTP should not empty",
                  },
                ],
              })(<Input maxLength={7} />)}
            </Form.Item>
            <Form.Item label="Store">
              {getFieldDecorator("store_id", {
                rules: [
                  {
                    required: true,
                    message: "This field is required",
                  },
                ],
              })(
                <Select
                  style={{
                    width: 340,
                  }}
                  onChange={this.handleStoreID}
                  placeholder="Select Store"
                >
                  {this.renderStorename()}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={this.state.iconLoading}
              >
                Submit
              </Button>
              <Button
                style={{
                  marginLeft: 15,
                }}
                size="large"
                onClick={this.handleCancel}
              >
                Clear
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h4>Order History</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid allproduct">
          <Row className="m-b-30">
            <Col>
              <Form layout="inline" onSubmit={this.handleSubmit}>
                <Form.Item label="From Date">
                  {getFieldDecorator("start_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "This field is required",
                      },
                    ],
                  })(
                    <DatePicker
                      style={{
                        width: 350,
                      }}
                      disabledDate={this.disabledStartDate}
                      format="DD-MM-YYYY"
                      placeholder="Select Date"
                      onChange={this.onStartChange}
                      onOpenChange={this.handleStartOpenChange}
                    />
                  )}
                </Form.Item>
                <Form.Item label="To Date">
                  {getFieldDecorator("end_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "This field is required",
                      },
                    ],
                  })(
                    <DatePicker
                      style={{
                        width: 350,
                      }}
                      disabledDate={this.disabledEndDate}
                      format="DD-MM-YYYY"
                      placeholder="Select Date"
                      onChange={this.onEndChange}
                      open={endOpen}
                      onOpenChange={this.handleEndOpenChange}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                  <Button
                    style={{ marginLeft: 15 }}
                    type="default"
                    onClick={this.handleResett}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Divider dashed />
          <Row
            gutter={16}
            className={this.state.visible === true ? "" : "hidden"}
          >
            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Total Orders"
                  value={dashstats.today_total_order}
                  icon={<ShoppingCart size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Pending Orders"
                  value={dashstats.total_pending_order}
                  icon={<ShoppingCart size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Delivered Orders"
                  value={dashstats.total_order}
                  icon={<ShoppingCart size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Turnover"
                  value={dashstats.total_cost}
                  icon={<Target size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>
          </Row>
          <div className="row m-t-30">
            <div className="text-left filterdiv col-md-6">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="text-left download-table-xls-button btn ant-btn-primary"
                table="table-to-xls"
                filename="Shopezy-Order-Report"
                sheet="tablexls"
                buttonText="Export Order Report"
              />
            </div>
            <div className="text-right filterdiv col-md-6">
              <span
                style={{
                  marginRight: 10,
                }}
              >
                Last Refreshed Time :
                <TimeAgo date={new Date()}>
                  {({ value }) => (
                    <b>
                      <Icon type="clock-circle" theme="outlined" /> {value}
                    </b>
                  )}
                </TimeAgo>
              </span>
              <Button
                type="primary"
                style={{
                  marginLeft: 15,
                }}
                onClick={this.handleResett}
              >
                Refresh
              </Button>
            </div>
          </div>
          <div>
            <table className="hidden" id="table-to-xls">
              <thead>
                <tr>
                  <th>Customer_ID</th>
                  <th>order_id</th>
                  <th>customer_name</th>
                  <th>customer_phone</th>
                  <th>delivery_address</th>
                  <th>driver_name</th>
                  <th>store_name</th>
                  <th>order_status</th>
                  <th>delivery_type</th>
                  <th>delivery_fee</th>
                  <th>service_fee</th>
                  <th>Total_Price</th>
                  <th>Ref_Cash_Back_Amount</th>
                  <th>Payment_Type</th>
                  <th>No_Order</th>
                  <th>Order_Coupon_Applicable</th>
                  <th>Order_date</th>
                </tr>
              </thead>
              <tbody>{this.renderNotes()}</tbody>
            </table>
          </div>

          <Table
            columns={columns}
            dataSource={this.state.tdata}
            loading={this.state.loading}
            scroll={{ x: 2450 }}
            size="middle"
            bordered
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    order: state.order,
    dashtoday: state.dashtoday,
    storeall: state.storeall,
    userinfo: state.userinfo,
  };
}

const WrappedRegistrationForm = Form.create()(Order);

export default connect(mapStateToProps, {
  emailLogin,
  logout,
  allstorename,
  customeallorder,
  customdashreport,
  MD_Order_Status,
})(WrappedRegistrationForm);
