import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {addDis, citytable,allstorename} from "../actions/assetAction";
import {
  Form,
  Input,
  Row,
  Radio,
  InputNumber,
  Select,
  Button,
  message,
  Icon,
  Col,
  DatePicker
} from "antd";
import voucher_codes from "voucher-code-generator";
import moment from "moment";
import _ from "lodash";
import "../styles/css/App.css";
const {Option} = Select;
const {TextArea} = Input;

class Adddiscount extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      iconLoading: false,
      coupon: "",
      ceiling_limit: "false",
      usage_limit_value: "false",
      startValue: null,
      endValue: null,
      endOpen: false,
      ceiling_limit_div: false
    };
  }

  componentWillMount() {
    this.props.allstorename();
    this
      .props
      .citytable();
    this
      .props
      .form
      .setFieldsValue({activation_date: this.state.startValue, expiration_date: this.state.endValue});
  }

  handleReset = () => {
    this
      .props
      .form
      .resetFields();
    this.setState({iconLoading: false});
  };

  generateCoupon = () => {
    var coupon = voucher_codes.generate({length: 8, count: 1});
    this
      .props
      .form
      .setFieldsValue({coupon_code: coupon});
  };

  checkPrice = (rule, value, callback) => {
    // console.log('changed', value);
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!Number.isNaN(value) && reg.test(value)) || value === "") {
      callback();
      return;
    }
    callback("Input only a numermic value...!");
  };

  handleSubmit = e => {
    e.preventDefault();

    this
      .props
      .form
      .validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.setState({iconLoading: true});

          console.log(values);

          this
            .props
            .addDis(values)
            .then((result) => {
              this.setState({iconLoading: false});
              this
                .props
                .form
                .resetFields();

              message.success("Coupon created successfully!");

              setTimeout(() => {
                this
                  .props
                  .history
                  .push("/coupon");
              }, 8000);
            })
            .catch((err) => {
              this.setState({iconLoading: false});
              message.error(err);
            });

        }
      });
  };

  // timepicker

  renderCategory1() {
    return _.map(this.props.common, (common, key) => {
      return (
        <Option value={common.id} key={key}>
          {common.cname}
          {/* ({categories.cid})  */}
        </Option>
      );
    });
  }

  handleTax = e => {
    console.log(e.target.value);

    this.setState({ceiling_limit: e.target.value});
  };

  handleTax1 = e => {
    this.setState({usage_limit_value: e.target.value});
  };

  disabledStartDate = startValue => {
    // const { endValue } = this.state; if (!startValue || !endValue) {   return
    // false; }
    return startValue && startValue < moment().startOf("day");
  };

  disabledEndDate = endValue => {
    const {startValue} = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({[field]: value});
  };

  onStartChange = value => {
    this.onChange("startValue", value);
  };

  onEndChange = value => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({endOpen: true});
    }
  };

  handleEndOpenChange = open => {
    this.setState({endOpen: open});
  };
  handleCoupon = (value) => {
    console.log(`selected ${value}`);
    if (value === "2") {
      this.setState({ceiling_limit_div: true});
    } else {
      this.setState({ceiling_limit_div: false});
    }
  }
  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          {storeall.store_name}
        </Option>
      );
    });
  }
  handleStoreID = (value) => {
    this.setState({ StoreID: value });
  };
  // ======================================================

  render() {
    const {endOpen} = this.state;

    const {getFieldDecorator} = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 24
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 20
        }
      }
    };

    //==============================================================================
    //================================//

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Add Coupon Code</h4>
              </div>
              <div className="col-sm-8 text-right">
                <Link to="/coupon">
                  <Button className="pull-right" type="dark" icon="arrow-left">
                    Back To Coupon
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid text-left addproduct">
          <Form onSubmit={this.handleSubmit}>
            <Row gutter={15}>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 10
              }}>
                <Form.Item {...formItemLayout} label="Coupon Code">
                  <Row gutter={15}>
                    <Col span={16}>
                      {getFieldDecorator("coupon_code", {
                        initialValue: this.state.coupon,

                        rules: [
                          {
                            required: true,
                            message: "Please select generate coupon"
                          }
                        ]
                      })(<Input/>)}{" "}
                    </Col>
                    <Col span={8}>
                      <Button type="primary" onClick={this.generateCoupon}>
                        <Icon type="tag"/>
                        Generate Coupon
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 8
              }}>
                <Form.Item {...formItemLayout} label="Discount Type">
                  {getFieldDecorator("discount_type", {
                    rules: [
                      {
                        required: true,
                        message: "Please Select Discount Type",
                        whitespace: false
                      }
                    ]
                  })(
                    <Select onChange={this.handleCoupon} placeholder="Select Discount Type">
                      <Option value="2">Value (£ GBP)</Option>
                      <Option value="1">Percent (%)</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 6
              }}>
                <Form.Item {...formItemLayout} label="Discount Value">
                  {getFieldDecorator("discount_value", {
                    rules: [
                      {
                        required: true,
                        validator: this.checkPrice
                      }
                    ]
                  })(<InputNumber style={{
                    width: 200
                  }}/>)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              {this.state.ceiling_limit_div === true
                ? (null)
                : (
                  <div>
                    <Col
                      xs={{
                      span: 24
                    }}
                      sm={{
                      span: 12
                    }}
                      lg={{
                      span: 12
                    }}>
                      <Form.Item {...formItemLayout} label="Ceiling Limit Applicable">
                        {getFieldDecorator("ceiling_limit", {
                          initialValue: "false",
                          rules: [
                            {
                              required: true,
                              message: "Select YES if Ceiling Limit Applicable",
                              whitespace: false
                            }
                          ]
                        })(
                          <Radio.Group onChange={this.handleTax}>
                            <Radio value="true">YES</Radio>
                            <Radio value="false">NO</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{
                      span: 24
                    }}
                      sm={{
                      span: 12
                    }}
                      lg={{
                      span: 12
                    }}>
                      <Form.Item {...formItemLayout} label="Ceiling Limit Value">
                        {getFieldDecorator("ceiling_limit_value", {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              validator: this.checkPrice
                            }
                          ]
                        })(<InputNumber
                          style={{
                          width: 470
                        }}
                          disabled={this.state.ceiling_limit === "true"
                          ? false
                          : true}/>)}
                      </Form.Item>
                    </Col>
                  </div>
                )}
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>

                <Form.Item {...formItemLayout} label="Activation Date">
                  {getFieldDecorator("activation_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "Enter Activation Date"
                      }
                    ]
                  })(<DatePicker
                    style={{
                    width: 350
                  }}
                    disabledDate={this.disabledStartDate}
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder="Start"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}/>)}
                </Form.Item>
              </Col>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>
                <Form.Item {...formItemLayout} label="Expiration Date">
                  {getFieldDecorator("expiration_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "Enter Expiration Date"
                      }
                    ]
                  })(<DatePicker
                    style={{
                    width: 350
                  }}
                    disabledDate={this.disabledEndDate}
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder="End"
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}/>)}
                </Form.Item>
              </Col>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>
                <Form.Item {...formItemLayout} label="Coupon Title">
                  {getFieldDecorator("coupon_Title", {
                    rules: [
                      {
                        required: true,
                        message: "Enter Coupon Title",
                        whitespace: false
                      }
                    ]
                  })(<Input/>)}
                </Form.Item>
              </Col>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>
                <Form.Item {...formItemLayout} label="Coupon Description">
                  {getFieldDecorator("coupon_description", {
                    rules: [
                      {
                        required: true,
                        message: "Enter Coupon Description",
                        whitespace: false
                      }
                    ]
                  })(<TextArea rows={5}/>)}
                </Form.Item>
              </Col>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>
                <Form.Item {...formItemLayout} label="Usage Limit Applicable">
                  {getFieldDecorator("usage_limit", {
                    initialValue: "false",
                    rules: [
                      {
                        required: true,
                        message: "Select YES if Usage Limit Applicable",
                        whitespace: false
                      }
                    ]
                  })(
                    <Radio.Group onChange={this.handleTax1}>
                      <Radio value="true">YES</Radio>
                      <Radio value="false">NO</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>

              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>
                <Form.Item {...formItemLayout} label="Usage Limit Value">
                  {getFieldDecorator("usage_limit_value", {
                    initialValue: 0,
                    rules: [
                      {
                        required: true,
                        validator: this.checkPrice
                      }
                    ]
                  })(<InputNumber
                    disabled={this.state.usage_limit_value === "true"
                    ? false
                    : true}
                    style={{
                    width: 470
                  }}/>)}
                </Form.Item>
              </Col>

              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>
                <Form.Item {...formItemLayout} label="Operating City">
                  {getFieldDecorator("operating_city", {
                    rules: [
                      {
                        required: true,
                        message: "Please Select Operating",
                        whitespace: false
                      }
                    ]
                  })(
                    <Select placeholder="Select Opearting City">
                      {this.renderCategory1()}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>
                <Form.Item {...formItemLayout} label="Coupon Type">
                  {getFieldDecorator("coupon_type", {
                    rules: [
                      {
                        required: true,
                        message: "Please Select Coupon Type",
                        whitespace: false
                      }
                    ]
                  })(
                    <Select placeholder="Select Coupon Type">
                      <Option value="3">Universal</Option>
                      {/* <Option value="4">Exclusive</Option> */}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>
                <Form.Item {...formItemLayout} label="Coupon Per User Limit">
                  {getFieldDecorator("Coupon_Per_User_Limit", {
                    initialValue: 0,
                    rules: [
                      {
                        required: true,
                        validator: this.checkPrice
                      }
                    ]
                  })(<InputNumber style={{
                    width: 470
                  }}/>)}
                </Form.Item>
              </Col>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>
              <Form.Item label="Store Name">
                  {getFieldDecorator("store_name", {
                    rules: [
                      {
                        required: true,
                        message: "Please Select Store",
                        whitespace: false,
                      },
                    ],
                  })(
                    <Select
                      style={{
                        width: 400,
                      }}
                      onChange={this.handleStoreID}
                      placeholder="Select Store"
                    >
                      {this.renderStorename()}
                    </Select>
                  )}
                </Form.Item></Col>
              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 12
              }}
                lg={{
                span: 12
              }}>
                <Form.Item {...formItemLayout} label="Status">
                  {getFieldDecorator("coupon_status", {
                    rules: [
                      {
                        required: true,
                        message: "Select YES if Coupon is Active",
                        whitespace: false
                      }
                    ]
                  })(
                    <Radio.Group>
                      <Radio value="5">Active</Radio>
                      <Radio value="6">In-Active</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>

              {/* =============================== Submit Button ======================================== */}

              <Col
                xs={{
                span: 24
              }}
                sm={{
                span: 24
              }}
                lg={{
                span: 24
              }}>
                <Form.Item wrapperCol={{
                  span: 12
                }}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={this.state.iconLoading}>
                    Submit
                  </Button>
                  <Button
                    style={{
                    marginLeft: 15
                  }}
                    size="large"
                    onClick={this.handleReset}>
                    Clear
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {user: state.user, common: state.common,storeall: state.storeall,};
}

const WrappedRegistrationForm = Form.create()(Adddiscount);

export default connect(mapStateToProps, {addDis, citytable,allstorename})(WrappedRegistrationForm);
