import React, { Component } from "react";
import { connect } from "react-redux";
import { vat_tax_report, allstorename } from "../actions/assetAction";
import {
  Icon,
  Button,
  Input,
  Col,
  Row,
  Divider,
  DatePicker,
  Form,
  Select,Skeleton
} from "antd";
import "../styles/css/App.css";
import Highlighter from "react-highlight-words";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import _ from "lodash";
import moment from "moment";


const { Option } = Select;

class VatTax extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      disabled: true,
      searchText: "",
      tdata: null,
      loading: false,
      dashstats: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      visible: false,
      iconLoading: false,
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon
        type="search"
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    console.log(this.state.searchText);
  };

  componentDidMount() {
    this.props.allstorename();
  }

  handleResett = () => {
    this.props.form.resetFields();
    this.setState({ tdata: null, visible: false  });
  };

  renderNotes() {
    return _.map(this.state.tdata, (order, key) => {
      return (
        <tr key={key}>
                  <td>{order.Slab_Name}</td>
                  <td>{order.Slab_Count}</td>
                  <td>{order.Slab_Total}</td>
        </tr>
      );
    });
  }
  renderOrderStatus() {
    return _.map(this.state.mdstatus, (order, key) => {
      return (
        <Option key={key} value={order.Order_Status_Id}>
          {order.Order_Status}
        </Option>
      );
    });
  }

  disabledStartDate = startValue => {
    // const { endValue } = this.state; 
    // if (!startValue || !endValue) 
    // {   return false; }
   return startValue && startValue > moment().endOf("day");
  };

  disabledEndDate = endValue => {
    // const {startValue} = this.state;
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    return endValue && endValue > moment().endOf("day");
  };

  onChange = (field, value) => {
    this.setState({[field]: value});
  };

  onStartChange = value => {
    this.onChange("startValue", value);
  };

  onEndChange = value => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({endOpen: true});
    }
  };

  handleEndOpenChange = open => {
    this.setState({endOpen: open});
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({loading: true});
        this.props.vat_tax_report(values.store_id, values.start_date, values.end_date).then(res=>{
            console.log(res);
            this.setState({tdata: res,loading: false});
        })
      }
    });
  };

  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          {storeall.store_name}
        </Option>
      );
    });
  }
  handleStoreID = (value) => {
    this.setState({ StoreID: value });
  };
  
  //===============================================
  //===============================================

  render() {
    
    const { getFieldDecorator } = this.props.form;
    const {endOpen} = this.state;

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h4>VAT Tax Report</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid allproduct">
          <Row className="m-b-30">
            <Col>
              <Form layout="inline" onSubmit={this.handleSubmit}>
                <Form.Item label="Store">
                  {getFieldDecorator("store_id", {
                    rules: [
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ],
                  })(
                    <Select
                      style={{
                        width: 340,
                      }}
                      onChange={this.handleStoreID}
                      placeholder="Select Store"
                    >
                      {this.renderStorename()}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="From Date">
                  {getFieldDecorator("start_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "This field is required"
                      }
                    ]
                  })(<DatePicker
                    style={{
                    width: 150
                  }}
                    disabledDate={this.disabledStartDate}
                    
                    format="DD-MM-YYYY"
                    placeholder="Select Date"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}/>)}
                </Form.Item>
            <Form.Item label="To Date">
                  {getFieldDecorator("end_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "This field is required"
                      }
                    ]
                  })(<DatePicker
                    style={{
                    width: 150
                  }}
                    disabledDate={this.disabledEndDate}
                    
                    format="DD-MM-YYYY"
                    placeholder="Select Date"
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}/>)}
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                  <Button
                    style={{ marginLeft: 15 }}
                    type="default"
                    onClick={this.handleResett}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Divider dashed />
         
         
          <div className="text-left filterdiv col-md-6">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="text-left download-table-xls-button btn ant-btn-primary"
                table="table-to-xls"
                filename="Shopezy-VAT-Report"
                sheet="tablexls"
                buttonText="Export VAT Report"/></div>
                <div className="col-md-12">
                  <Skeleton loading={this.state.loading}>
                  {this.state.tdata !== null &&
              <table  className="table table-bordered" id="table-to-xls">
              <thead>
                <tr>
                  <th>Slab_Name</th>
                  <th>Slab_Count</th>
                  <th>Slab_Total</th>
                </tr>
              </thead>
              <tbody>
                {this.renderNotes()}
              </tbody>
            </table>}
            </Skeleton>
            </div> 
        </div>
        <div>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    common: state.common,
    storeall: state.storeall,
   
  };
}

const WrappedRegistrationForm = Form.create()(VatTax);

export default connect(mapStateToProps, {
    vat_tax_report,
  allstorename,
})(WrappedRegistrationForm);