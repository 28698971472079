import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getDesignations } from "../actions/assetAction";
// import _ from "lodash";
import { Button, Table, Tag, Icon } from "antd";
import nProgress from "nprogress";

class RoleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      designations: [],
    };
  }

  componentWillMount() {
    console.log(this.props.userinfo, this.props.userinfo.Permission);
    console.log(this.props.userinfo.Permission[0].Read);
    var userp = this.props.userinfo.Permission[0];
    if (!userp.Read) {
      this.props.history.push("/userrole");
    } else {
      this.getdesignation();
    }
  }

  getdesignation() {
    nProgress.start();
    this.props.getDesignations().then((res) => {
      console.log(res);
      this.setState({
        designations: res,
      });
      nProgress.done();
    });
  }
  // ======================================================

  render() {
    const columns = [
      {
        title: "ID",
        dataIndex: "desgId",
        key: "desgId",
      },
      {
        title: "Role Name",
        dataIndex: "desgRole",
        key: "desgRole",
      },

      {
        title: "Description",
        dataIndex: "Description",
        key: "Description",
      },
      {
        title: "Status",
        dataIndex: "IsActive",
        key: "IsActive",
        render: (tag) => (
          <span>
            <Tag color={tag ? "green" : "volcano"}>
              {tag ? "Active" : "In-Active"}
            </Tag>
          </span>
        ),
      },
      {
        title: "Actions",
        dataIndex: "action",
        render: (text, record) => (
          <span>
            <Link
              className="padd5 mr5"
              style={{ marginRight: 10 }}
              to={{ pathname: "/editrole", userrole: record }}
            >
              <Icon type="edit" title="Edit role" />
            </Link>
            {/* <span
              className="padd5 mr5"
              style={{cursor : "pointer"}}
              onClick={() =>
                this.props.deleteDesignations(record.docid).then((res) => {
                  this.getdesignation();
                })
              }
            >
              <Icon type="delete" />
            </span> */}
          </span>
        ),
      },
    ];

    const data = this.state.designations;

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Role List</h4>
              </div>
              <div className="col-sm-8 text-right">
                <Link to="/createrole">
                  <Button type="primary">Create Role</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid allproduct">
          <Table columns={columns} dataSource={data} bordered />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
  };
}

export default connect(mapStateToProps, {
  getDesignations,
})(RoleList);
