import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  UpdatePromo,
  promotable,
  Singlepromo,
  currencyList,
  allstorename,
} from "../actions/assetAction";
import debounce from "lodash/debounce";
import _ from "lodash";
import { db } from "../firebase";
import request from "superagent";
import {
  Form,
  Row,
  Button,
  message,
  Col,
  Card,
  Select,
  Spin,
  Input,
  DatePicker,
  Upload,
  Icon,
  Empty,
  Avatar,
  Tag,
  Popconfirm,
  Drawer,
} from "antd";
import { Dev_URL } from "../actionTypes";
import moment from "moment";
import "../styles/css/App.css";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const SortableItem = sortableElement(({ value }) => (
  <li className="visibleall">
    <Avatar src={bucket_baseurl + value.Ref_Products_Image_Url} />{" "}
    {value.Ref_Products_Display_Name}{" "}
    {value.Is_Stock_Available === false ? (
      <Tag color="red">Out of Stock</Tag>
    ) : (
      <Tag color="green">Stock</Tag>
    )}
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

const { Option } = Select;

const { TextArea } = Input;

let bucket_baseurl;

class EditPromo extends Component {
  constructor(props) {
    super(props);
    // state
    // this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 800);
    this.state = {
      data: [],
      value: [],
      fetching: false,
      fileList1: "",
      symbol: "",
      fileList: [],
      prodList: [],
      singleprod: [],
      PromoTypeID: null,
      loading1: false,
      proddup: null,
      filetag: 1,
      promosid: "",
      StoreID: "",
    };
  }

  componentDidMount() {
    this.props.allstorename();
    db.collection("Constants")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          bucket_baseurl = doc.data().Bucket_Base_Url;
        });
      });
    this.setState({ loading1: true });
    this.props.promotable();
    this.props.currencyList();
    var reqid;
    var promosid;
    if (this.props.location.promoid === undefined) {
      reqid = localStorage.getItem("drid");
      promosid = localStorage.getItem("psid");
      this.props
        .Singlepromo(reqid)
        .then((result) => {
          console.log(result);
          this.setState({
            promosid: promosid,
            loading1: false,
            PromoTypeID: result.Ref_Promo_Type_Id,
            startValue: result.From_Date,
            singleprod: result.promo_group,
            symbol: result.symbol,
            fileList: result.Promo_Images,
            StoreID: result.Ref_Store_Id,
          });
          this.props.form.setFieldsValue({
            promo_type: result.Ref_Promo_Type_Id,
            Ref_Currency_Id: result.Ref_Currency_Id,
            dragger: result.Promo_Images,
            Promo_Display_Order: result.Promo_Display_Order,
          });
        })
        .catch((err) => {});
      console.log(reqid, promosid);
    } else {
      localStorage.setItem("drid", this.props.location.promoid);
      localStorage.setItem("psid", this.props.location.Promo_Status);
      reqid = localStorage.getItem("drid");
      promosid = localStorage.getItem("psid");
      this.props
        .Singlepromo(reqid)
        .then((result) => {
          this.setState({
            promosid: promosid,
            loading1: false,
            PromoTypeID: result.Ref_Promo_Type_Id,
            startValue: result.From_Date,
            singleprod: result.promo_group,
            symbol: result.symbol,
            fileList: result.Promo_Images,
            StoreID: result.Ref_Store_Id,
          });
          this.props.form.setFieldsValue({
            promo_type: result.Ref_Promo_Type_Id,
            Ref_Currency_Id: result.Ref_Currency_Id,
            dragger: result.Promo_Images,
            Promo_Display_Order: result.Promo_Display_Order,
          });
        })
        .catch((err) => {});
      console.log(reqid, promosid);
    }
  }

  fetchUser = async (value) => {
    //console.log('fetching user', value.length);
    if (value.length > 3) {
      this.setState({ data: [], fetching: true });
      try {
        const res = await request.post(Dev_URL + "/searchproductnew").send({
          PromoTypeID: this.state.PromoTypeID,
          ProductText: value,
          StoreID: this.state.StoreID,
        });

        // res.body, res.headers, res.status
        console.log(res.body);
        if (res.body.ReturnCode === 201) {
          this.setState({ fetching: false });
        } else {
          const data = res.body.Product_Details.map((user) => ({
            Ref_Products_Display_Name: `${user.Ref_Products_Display_Name}`,
            Ref_Products_Id: user.Product_Id,
            Ref_Products_Image_Url:
              bucket_baseurl + user.Ref_Products_Image_Url[0],
            Promo_Exists_Already: user.Promo_Exists_Already,
          }));
          this.setState({
            data,
            fetching: false,
            prodList: res.body.Product_Details,
          });
        }
      } catch (err) {
        return err;
      }
    }
  };

  handleCurrencyChange = (value) => {
    db.collection("MD_Currency")
      .doc(value)
      .onSnapshot((doc) => {
        console.log(doc.data().Currency_Symbol);
        this.setState({
          symbol: doc.data().Currency_Symbol,
        });
      });
  };

  handleChange = (value) => {
    console.log(value);
    const productlist = this.state.prodList;
    const productlist1 = this.state.singleprod;
    var selected_Product =
      productlist[
        productlist
          .map(function (item) {
            return item.Product_Id;
          })
          .indexOf(value.key)
      ];

    var dup_Product =
      productlist1[
        productlist1
          .map(function (item) {
            return item.Product_Id;
          })
          .indexOf(value.key)
      ];
    console.log(dup_Product);

    if (dup_Product !== undefined) {
      message.error("Product Already Selected..!");
    } else {
      var productarr = this.state.singleprod;
      productarr.push(selected_Product);

      this.setState({
        value,
        data: [],
        fetching: false,
        singleprod: productarr,
      });
    }
  };

  handleBuyOne = (value) => {
    //console.log(value);

    const productlist = this.state.prodList;
    // console.log(productlist);
    var selected_Product =
      productlist[
        productlist
          .map(function (item) {
            return item.Product_Id;
          })
          .indexOf(value.key)
      ];

    console.log(selected_Product);
    if (selected_Product.Product_Is_Discounted === true) {
      this.props.form.setFieldsValue({
        promo_price: parseFloat(
          Math.round(selected_Product.Product_Discounted_Price * 100) / 100
        ).toFixed(2),
      });
    } else {
      this.props.form.setFieldsValue({
        promo_price: parseFloat(
          Math.round(selected_Product.Product_Actual_Price * 100) / 100
        ).toFixed(2),
      });
    }
    var productarr = [];
    productarr.push(selected_Product);

    this.setState({
      value,
      data: [],
      fetching: false,
      singleprod: productarr,
    });
  };

  disabledStartDate = (startValue) => {
    return startValue && startValue < moment().startOf("day");
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  renderCategory1() {
    return _.map(this.props.common, (common, key) => {
      return (
        <Option value={common.Promotion_Id} key={key}>
          {common.promoname}
        </Option>
      );
    });
  }

  renderCurrency() {
    return _.map(this.props.currency, (currency, key) => {
      return (
        <Option value={currency.id} key={key}>
          {currency.symbol} - {currency.name} - ({currency.code}){" "}
        </Option>
      );
    });
  }

  handlePromo = (value) => {
    console.log(`selected ${value}`);
    this.setState({ PromoTypeID: value });
    this.props.form.resetFields();
    this.setState({
      data: [],
      value: [],
      fetching: false,
      fileList1: "",
      fileList: [],
      prodList: [],
      singleprod: [],
    });
  };

  handleDeleteProduct = (key) => {
    console.log(key);
    const singleprod = [...this.state.singleprod];
    this.setState({
      singleprod: singleprod.filter((item) => item.Key !== key),
    });
    //this.setState({ singleprod:[] });
    this.props.form.setFieldsValue({
      product: "",
    });
    console.log(this.state.singleprod);
  };

  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      data: [],
      value: [],
      fetching: false,
      fileList1: "",
      fileList: [],
      prodList: [],
      singleprod: [],
      PromoTypeID: null,
      symbol: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var promoid = localStorage.getItem("drid");
        // console.log(values)
        // console.log(this.state.fileList)
        // console.log(this.state.fileList1)
        // console.log(this.state.singleprod)
        this.setState({
          iconLoading: true,
          loading1: true,
        });
        this.props
          .UpdatePromo(
            values,
            this.state.fileList,
            this.state.fileList1,
            this.state.singleprod,
            this.props.promo,
            promoid,
            this.props.userinfo.Name
          )
          .then((result) => {
            this.setState({
              iconLoading: false,
            });

            this.props.form.resetFields();
            this.setState({
              data: [],
              value: [],
              fetching: false,
              fileList1: "",
              fileList: [],
              prodList: [],
              singleprod: [],
              PromoTypeID: null,
              loading1: false,
              symbol: "",
            });
            message.success("Promo updated successfully!");
            setTimeout(() => {
              this.props.history.push("/premierpromo");
            }, 3000);
          })
          .catch((err) => {
            this.setState({
              iconLoading: false,
              loading1: false,
            });
            message.error(err.message);
          });
      }
    });
  };
  deleteImage = () => {
    this.setState({
      filetag: 0,
    });
  };
  updatefilelist = (d) => {
    console.log(d);
    this.props.form.setFieldsValue({
      dragger: d,
    });
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ singleprod }) => ({
      singleprod: arrayMove(singleprod, oldIndex, newIndex),
    }));
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          <Avatar src={storeall.imgUrl} />
          {storeall.store_name}
        </Option>
      );
    });
  }
  handleStoreID = (value) => {
    this.setState({ StoreID: value });
  };
  // ======================================================

  render() {
    const { endOpen, fileList1, fileList } = this.state;
    const { fetching, data, singleprod } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const propsthumb = {
      multiple: false,
      listType: "picture-card",
      accept: ".png,.jpg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList1.indexOf(file);
          const newFileList = state.fileList1.slice();
          newFileList.splice(index, 1);
          return {
            fileList1: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList1: [...state.fileList1, file],
        }));
        return false;
      },
      fileList1,
    };

    const props = {
      multiple: true,
      accept: ".png,.jpg",
      listType: "picture",
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          this.updatefilelist(newFileList);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    //==============================================================================================================//

    return (
      <div>
        <Spin size="large" spinning={this.state.loading1}>
          <div className="container-fluid addp">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-4">
                  <h4>Edit Promo</h4>
                </div>
                <div className="col-sm-8 text-right">
                  <Link to="/premierpromo">
                    <Button
                      className="pull-right"
                      type="dark"
                      icon="arrow-left"
                    >
                      Back
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid text-left addproduct">
            <Drawer
              title="Product Order"
              width={520}
              height={1000}
              placement="right"
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <SortableContainer onSortEnd={this.onSortEnd}>
                {singleprod.map((value, index) => (
                  <SortableItem key={value.Key} index={index} value={value} />
                ))}
              </SortableContainer>
            </Drawer>
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={15}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Promo Type">
                    {getFieldDecorator("promo_type", {
                      rules: [
                        {
                          required: true,
                          message: "Please Select Promo Type",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Select
                        disabled
                        onChange={this.handlePromo}
                        placeholder="Select Promo Type"
                      >
                        {this.renderCategory1()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store Name">
                    {getFieldDecorator("Ref_Store_Id", {
                      initialValue: this.props.promo.Ref_Store_Id,
                      rules: [
                        {
                          required: true,
                          message: "Select Store",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Select
                        onChange={this.handleStoreID}
                        placeholder="Select Store"
                      >
                        {this.renderStorename()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={15}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 50 }}>
                    Promo Info
                  </h4>
                </Col>
                <Col
                  xs={{
                    span: 24,
                  }}
                  sm={{
                    span: 12,
                  }}
                  lg={{
                    span: 12,
                  }}
                >
                  <Form.Item {...formItemLayout} label="Offer Name">
                    {getFieldDecorator("promo_title", {
                      initialValue: this.props.promo.Promo_Title,
                      rules: [
                        {
                          required: true,
                          message: "Enter Offer Name Should not be empty",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Input
                        disabled={
                          this.props.promo.Ref_Promo_Type_Id === "501"
                            ? false
                            : true
                        }
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Currency">
                    {getFieldDecorator("Ref_Currency_Id", {
                      rules: [
                        {
                          required: true,
                          message: "Enter Currency",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select Currency type"
                        onChange={this.handleCurrencyChange}
                      >
                        {this.renderCurrency()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Promo Price">
                    {getFieldDecorator("promo_price", {
                      initialValue: this.props.promo.Promo_Price,

                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(
                            "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
                          ),
                          message: "Currency format wrong!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="00.00"
                        prefix={this.state.symbol}
                        disabled={
                          this.state.PromoTypeID === "201" ? false : true
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <div>
                  {this.state.PromoTypeID === "201" ? (
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                      <Form.Item {...formItemLayout} label="Buy Quantity">
                        {getFieldDecorator("buy_quantity", {
                          initialValue: this.props.promo.Buy_Promo_Qty,
                          rules: [
                            {
                              required: true,
                              message: "Buy Quantity Should not be empty",
                              whitespace: false,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  ) : null}
                </div>
                <Col
                  xs={{
                    span: 24,
                  }}
                  sm={{
                    span: 12,
                  }}
                  lg={{
                    span: 12,
                  }}
                >
                  <Form.Item {...formItemLayout} label="Display Order">
                    {getFieldDecorator("Promo_Display_Order", {
                      initialValue: this.props.promo.Promo_Display_Order,
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp("^[0-9]*$"),
                          message: "Display order format wrong!",
                        },
                      ],
                    })(<Input placeholder="0" />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Promo Description">
                    {getFieldDecorator("Promo_Description", {
                      initialValue: this.props.promo.Promo_Description,
                      rules: [
                        {
                          required: true,
                          message: "Enter Promo Description",
                          whitespace: false,
                        },
                      ],
                    })(<TextArea rows={5} />)}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Activation Date">
                    {getFieldDecorator("activation_date", {
                      initialValue: this.props.promo.From_Date,
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: "Enter Activation Date",
                        },
                      ],
                    })(
                      <DatePicker
                        style={{ width: 350 }}
                        disabledDate={this.disabledStartDate}
                        format="DD-MM-YYYY"
                        placeholder="Activation Date"
                        onChange={this.onStartChange}
                        onOpenChange={this.handleStartOpenChange}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Expiration Date">
                    {getFieldDecorator("expiration_date", {
                      initialValue: this.props.promo.To_Date,
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: "Enter Expiration Date",
                        },
                      ],
                    })(
                      <DatePicker
                        style={{ width: 350 }}
                        disabledDate={this.disabledEndDate}
                        format="DD-MM-YYYY"
                        placeholder="Expiration Date"
                        onChange={this.onEndChange}
                        open={endOpen}
                        onOpenChange={this.handleEndOpenChange}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Promo Images"
                    extra="Additional Promotion Images"
                  >
                    <div className="dropbox">
                      {getFieldDecorator("dragger", {
                        rules: [
                          {
                            required: true,
                            message: "Please upload promotion images",
                          },
                        ],
                      })(
                        <Upload.Dragger
                          name="Product_Images"
                          {...props}
                          listType="picture"
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload.
                          </p>
                        </Upload.Dragger>
                      )}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  {this.state.filetag === 0 ? (
                    <Form.Item
                      {...formItemLayout}
                      label="Banner Image"
                      // extra="Category Image"
                    >
                      {getFieldDecorator("banner_Url", {
                        valuePropName: "fileList",
                        getValueFromEvent: this.normFile,
                        rules: [
                          {
                            required: true,
                            message: "Please Upload Banner Display Image!",
                          },
                        ],
                      })(
                        <Upload name="Category_Image_Url" {...propsthumb}>
                          {fileList1.length === 1 ? null : (
                            <div>
                              <Icon type="plus" />
                              <div className="ant-upload-text">Choose</div>
                            </div>
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                  ) : (
                    <Form.Item {...formItemLayout} label="Banner Image">
                      <Row gutter={15}>
                        <Col span={24}>
                          <Card
                            style={{ width: 240 }}
                            cover={
                              <img
                                alt="example"
                                src={
                                  bucket_baseurl + this.props.promo.Banner_image
                                }
                              />
                            }
                            actions={[
                              <Popconfirm
                                title="Are you sure delete this Image, This will cause permanent loss of image ?"
                                onConfirm={() => this.deleteImage()}
                                onCancel={this.cancel}
                                okText="Yes"
                                cancelText="No"
                              >
                                {" "}
                                <Button type="danger">
                                  <Icon type="delete" />
                                </Button>
                              </Popconfirm>,
                            ]}
                          ></Card>
                        </Col>
                      </Row>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 50 }}>
                    Product Info
                  </h4>
                </Col>
              </Row>
              {(() => {
                switch (this.state.PromoTypeID) {
                  case "101":
                    return (
                      <div>
                        {singleprod.length === 0 ? (
                          <Row>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 12 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item
                                {...formItemLayout}
                                label="Search Product"
                              >
                                {getFieldDecorator("product", {
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "Select atleast one product to create promo",
                                    },
                                  ],
                                })(
                                  <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Type 4 characters to search..!"
                                    notFoundContent={
                                      fetching ? (
                                        <Spin size="small" />
                                      ) : (
                                        <Empty />
                                      )
                                    }
                                    filterOption={false}
                                    onSearch={this.fetchUser}
                                    defaultActiveFirstOption={false}
                                    onChange={this.handleBuyOne}
                                    style={{ width: "100%" }}
                                  >
                                    {data.map((d) => (
                                      <Option
                                        key={d.Ref_Products_Id}
                                        disabled={
                                          d.Promo_Exists_Already === true
                                            ? true
                                            : false
                                        }
                                      >
                                        <Avatar
                                          shape="square"
                                          src={d.Ref_Products_Image_Url}
                                        />
                                        {d.Ref_Products_Display_Name}
                                        <Tag
                                          style={{ float: "right" }}
                                          visible={
                                            d.Promo_Exists_Already === true
                                              ? true
                                              : false
                                          }
                                          color="volcano"
                                        >
                                          Promo Already Applied
                                        </Tag>
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        ) : null}
                        {singleprod.length !== 0 ? (
                          <Row gutter={15}>
                            <h5 className="resulttitle">Selected Product</h5>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 8 }}
                              lg={{ span: 24 }}
                            >
                              <Row gutter={15}>
                                {singleprod.map((d) => (
                                  <Col
                                    key={d.Key}
                                    xs={{ span: 24 }}
                                    sm={{ span: 8 }}
                                    lg={{ span: 6 }}
                                  >
                                    <Card
                                      cover={
                                        <div className="bbb_deals">
                                          {d.Is_Marked_Up_Price_Enabled ===
                                            true && (
                                            <div className="ribbon ribbon-top-right">
                                              <span>
                                                <small className="cross">
                                                  {d.Marked_Up_Quantity} +{" "}
                                                  {"For £"} +{d.Marked_Up_Price}
                                                </small>
                                              </span>
                                            </div>
                                          )}

                                          <div className="bbb_deals_slider_container">
                                            <div className=" bbb_deals_item">
                                              <div className="bbb_deals_image">
                                                <img
                                                  src={
                                                    bucket_baseurl +
                                                    d.Ref_Products_Image_Url[0]
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div className="bbb_deals_content">
                                                <div className="bbb_deals_info_line d-flex flex-row justify-content-start">
                                                  <div className="bbb_deals_item_price_a ml-auto">
                                                    {d.Product_Is_Discounted ===
                                                      true && (
                                                      <strike>
                                                        {"£" +
                                                          d.Product_Discounted_Price}
                                                      </strike>
                                                    )}
                                                  </div>
                                                  <div className="bbb_deals_item_price ml-auto">
                                                    {"£" +
                                                      d.Product_Actual_Price}
                                                  </div>
                                                </div>
                                                <div className="bbb_deals_info_line d-flex flex-row justify-content-start">
                                                  {d.Is_Premier_Promo ===
                                                    true && (
                                                    <span className="label label-warning">
                                                      {d.Display_Name}
                                                    </span>
                                                  )}

                                                  <div className="bbb_deals_item_name">
                                                    {
                                                      d.Ref_Products_Display_Name
                                                    }
                                                  </div>

                                                  {d.Is_Stock_Available ===
                                                  true ? (
                                                    <span className="label label-success">
                                                      Stock available
                                                    </span>
                                                  ) : (
                                                    <span className="label label-danger">
                                                      Out of stock
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                      actions={[
                                        <Icon
                                          type="delete"
                                          onClick={() =>
                                            this.handleDeleteProduct(d.Key)
                                          }
                                          key="delete"
                                        />,
                                      ]}
                                    ></Card>
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    );
                  case "201":
                    return (
                      <div>
                        <Row>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            lg={{ span: 12 }}
                          >
                            <Form.Item
                              {...formItemLayout}
                              label="Search Product"
                            >
                              {getFieldDecorator("product", {
                                rules: [
                                  {
                                    required: false,
                                    message:
                                      "Select atleast one product to create promo",
                                  },
                                ],
                              })(
                                <Select
                                  showSearch
                                  labelInValue
                                  placeholder="Type 4 characters to search..!"
                                  notFoundContent={
                                    fetching ? <Spin size="small" /> : <Empty />
                                  }
                                  filterOption={false}
                                  onSearch={this.fetchUser}
                                  defaultActiveFirstOption={false}
                                  onChange={this.handleChange}
                                  style={{ width: "100%" }}
                                >
                                  {data.map((d) => (
                                    <Option
                                      key={d.Ref_Products_Id}
                                      disabled={
                                        d.Promo_Exists_Already === true
                                          ? true
                                          : false
                                      }
                                    >
                                      <Avatar
                                        shape="square"
                                        src={
                                          bucket_baseurl +
                                          d.Ref_Products_Image_Url
                                        }
                                      />
                                      {d.Ref_Products_Display_Name}
                                      <Tag
                                        style={{ float: "right" }}
                                        visible={
                                          d.Promo_Exists_Already === true
                                            ? true
                                            : false
                                        }
                                        color="volcano"
                                      >
                                        Promo Already Applied
                                      </Tag>
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        {singleprod.length !== 0 ? (
                          <Row gutter={15}>
                            <Col
                              xs={{ span: 12 }}
                              sm={{ span: 8 }}
                              lg={{ span: 12 }}
                            >
                              <h5 className="resulttitle">Selected Product</h5>
                            </Col>
                            <Col
                              xs={{ span: 12 }}
                              sm={{ span: 8 }}
                              lg={{ span: 12 }}
                            >
                              <Button
                                className="pull-right"
                                type="primary"
                                onClick={this.showDrawer}
                              >
                                Sort Product Order
                              </Button>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 8 }}
                              lg={{ span: 24 }}
                            >
                              <Row gutter={15}>
                                {singleprod.map((d) => (
                                  <Col
                                    key={d.Key}
                                    xs={{ span: 24 }}
                                    sm={{ span: 8 }}
                                    lg={{ span: 6 }}
                                  >
                                    <Card
                                      cover={
                                        <div className="bbb_deals">
                                          {d.Is_Marked_Up_Price_Enabled ===
                                            true && (
                                            <div className="ribbon ribbon-top-right">
                                              <span>
                                                <small className="cross">
                                                  {d.Marked_Up_Quantity} +{" "}
                                                  {"For £"} +{d.Marked_Up_Price}
                                                </small>
                                              </span>
                                            </div>
                                          )}

                                          <div className="bbb_deals_slider_container">
                                            <div className=" bbb_deals_item">
                                              <div className="bbb_deals_image">
                                                <img
                                                  src={
                                                    bucket_baseurl +
                                                    d.Ref_Products_Image_Url[0]
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div className="bbb_deals_content">
                                                <div className="bbb_deals_info_line d-flex flex-row justify-content-start">
                                                  <div className="bbb_deals_item_price_a ml-auto">
                                                    {d.Product_Is_Discounted ===
                                                      true && (
                                                      <strike>
                                                        {"£" +
                                                          d.Product_Discounted_Price}
                                                      </strike>
                                                    )}
                                                  </div>
                                                  <div className="bbb_deals_item_price ml-auto">
                                                    {"£" +
                                                      d.Product_Actual_Price}
                                                  </div>
                                                </div>
                                                <div className="bbb_deals_info_line d-flex flex-row justify-content-start">
                                                  {d.Is_Premier_Promo ===
                                                    true && (
                                                    <span className="label label-warning">
                                                      {d.Display_Name}
                                                    </span>
                                                  )}

                                                  <div className="bbb_deals_item_name">
                                                    {
                                                      d.Ref_Products_Display_Name
                                                    }
                                                  </div>

                                                  {d.Is_Stock_Available ===
                                                  true ? (
                                                    <span className="label label-success">
                                                      Stock available
                                                    </span>
                                                  ) : (
                                                    <span className="label label-danger">
                                                      Out of stock
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                      actions={[
                                        <Icon
                                          type="delete"
                                          onClick={() =>
                                            this.handleDeleteProduct(d.Key)
                                          }
                                          key="delete"
                                        />,
                                      ]}
                                    ></Card>
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    );
                  case "501":
                    return (
                      <div>
                        <Row>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            lg={{ span: 12 }}
                          >
                            <Form.Item
                              {...formItemLayout}
                              label="Search Product"
                            >
                              {getFieldDecorator("product", {
                                rules: [
                                  {
                                    required: false,
                                    message:
                                      "Select atleast one product to create promo",
                                  },
                                ],
                              })(
                                <Select
                                  showSearch
                                  labelInValue
                                  placeholder="Type 4 characters to search..!"
                                  notFoundContent={
                                    fetching ? <Spin size="small" /> : <Empty />
                                  }
                                  filterOption={false}
                                  onSearch={this.fetchUser}
                                  defaultActiveFirstOption={false}
                                  onChange={this.handleChange}
                                  style={{ width: "100%" }}
                                >
                                  {data.map((d) => (
                                    <Option
                                      key={d.Ref_Products_Id}
                                      disabled={
                                        d.Promo_Exists_Already === true
                                          ? true
                                          : false
                                      }
                                    >
                                      <Avatar
                                        shape="square"
                                        src={
                                          bucket_baseurl +
                                          d.Ref_Products_Image_Url
                                        }
                                      />
                                      {d.Ref_Products_Display_Name}
                                      <Tag
                                        style={{ float: "right" }}
                                        visible={
                                          d.Promo_Exists_Already === true
                                            ? true
                                            : false
                                        }
                                        color="volcano"
                                      >
                                        Promo Already Applied
                                      </Tag>
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        {singleprod.length !== 0 ? (
                          <Row gutter={15}>
                            <Col
                              xs={{ span: 12 }}
                              sm={{ span: 8 }}
                              lg={{ span: 12 }}
                            >
                              <h5 className="resulttitle">Selected Product</h5>
                            </Col>
                            <Col
                              xs={{ span: 12 }}
                              sm={{ span: 8 }}
                              lg={{ span: 12 }}
                            >
                              <Button
                                className="pull-right"
                                type="primary"
                                onClick={this.showDrawer}
                              >
                                Sort Product Order
                              </Button>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 8 }}
                              lg={{ span: 24 }}
                            >
                              <Row gutter={15}>
                                {singleprod.map((d) => (
                                  <Col
                                    key={d.Key}
                                    xs={{ span: 24 }}
                                    sm={{ span: 8 }}
                                    lg={{ span: 6 }}
                                  >
                                    <Card
                                      cover={
                                        <div className="bbb_deals">
                                          {d.Is_Marked_Up_Price_Enabled ===
                                            true && (
                                            <div className="ribbon ribbon-top-right">
                                              <span>
                                                <small className="cross">
                                                  {d.Marked_Up_Quantity} +{" "}
                                                  {"For £"} +{d.Marked_Up_Price}
                                                </small>
                                              </span>
                                            </div>
                                          )}

                                          <div className="bbb_deals_slider_container">
                                            <div className=" bbb_deals_item">
                                              <div className="bbb_deals_image">
                                                <img
                                                  src={
                                                    bucket_baseurl +
                                                    d.Ref_Products_Image_Url[0]
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div className="bbb_deals_content">
                                                <div className="bbb_deals_info_line d-flex flex-row justify-content-start">
                                                  <div className="bbb_deals_item_price_a ml-auto">
                                                    {d.Product_Is_Discounted ===
                                                      true && (
                                                      <strike>
                                                        {"£" +
                                                          d.Product_Discounted_Price}
                                                      </strike>
                                                    )}
                                                  </div>
                                                  <div className="bbb_deals_item_price ml-auto">
                                                    {"£" +
                                                      d.Product_Actual_Price}
                                                  </div>
                                                </div>
                                                <div className="bbb_deals_info_line d-flex flex-row justify-content-start">
                                                  {d.Is_Premier_Promo ===
                                                    true && (
                                                    <span className="label label-warning">
                                                      {d.Display_Name}
                                                    </span>
                                                  )}

                                                  <div className="bbb_deals_item_name">
                                                    {
                                                      d.Ref_Products_Display_Name
                                                    }
                                                  </div>

                                                  {d.Is_Stock_Available ===
                                                  true ? (
                                                    <span className="label label-success">
                                                      Stock available
                                                    </span>
                                                  ) : (
                                                    <span className="label label-danger">
                                                      Out of stock
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                      actions={[
                                        <Icon
                                          type="delete"
                                          onClick={() =>
                                            this.handleDeleteProduct(d.Key)
                                          }
                                          key="delete"
                                        />,
                                      ]}
                                    ></Card>
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    );
                  case "301":
                    return <div></div>;
                  case "401":
                    return <div></div>;
                  default:
                    return null;
                }
              })()}

              {singleprod.length === 0 ? null : (
                <Row gutter={15} className="m-t-100">
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                    <Form.Item wrapperCol={{ span: 12 }}>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={this.state.iconLoading}
                      >
                        Submit
                      </Button>
                      <Button
                        style={{ marginLeft: 15 }}
                        size="large"
                        onClick={this.handleReset}
                      >
                        Clear
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    common: state.common,
    currency: state.currency,
    promo: state.promo,
    storeall: state.storeall,
    userinfo: state.userinfo,
  };
}

const WrappedRegistrationForm = Form.create()(EditPromo);

export default connect(mapStateToProps, {
  UpdatePromo,
  promotable,
  Singlepromo,
  currencyList,
  allstorename,
})(WrappedRegistrationForm);
