import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../actions/userAction";
import { Layout, Menu, Icon } from "antd";
import { getdesignation } from "../actions/userAction";
import { Link } from "react-router-dom";

import createHistory from "history/createBrowserHistory";
import Logo from "../styles/img/logo-main-white.svg";

const SubMenu = Menu.SubMenu;

const { Sider } = Layout;

class Navi extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      current: "1",
      collapsed: false,
      loading: true,
      permission: [],
    };
  }

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };
  componentWillMount() {
    const history = createHistory();
    // Get the current location.
    const location = history.location;
    // console.log(this.props.userinfo.Permission);
    this.setState({
      current: location.pathname,
      permission: this.props.userinfo.Permission,
    });
    this.props
      .getdesignation(this.props.userinfo.designation)
      .then((res) => {
        console.log(res.Permissions);
        this.setState({
          permission: res.Permissions,
          loading: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          permission: [],
          loading: true,
        });
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.userinfo !== this.props.userinfo) {
      this.props
        .getdesignation(this.props.userinfo.designation)
        .then((res) => {
          console.log(res.Permissions);
          this.setState({
            permission: res.Permissions,
            loading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            permission: [],
            loading: true,
          });
        });
    }
  }

  handleClick = (e) => {
    // console.log('click ', e);
    this.setState({ current: e.key });
  };

  render() {
    var { permission, loading } = this.state;

    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
      >
        <div className="logo">
          <img src={Logo} className="img-responsive mainlogo" alt="Logo" />
        </div>
        {loading === false ? (
          <Menu
            theme="dark"
            mode="inline"
            onClick={this.handleClick}
            selectedKeys={[this.state.current]}
            style={{
              lineHeight: "64px",
            }}
          >
            <Menu.Item key="/">
              <Link to="/">
                <Icon type="dashboard" />
                <span className="nav-text">Dashboard</span>
              </Link>
            </Menu.Item>
            {permission[0].Read ? (
              <Menu.Item key="/customer">
                <Link to="/customer">
                  <Icon type="user" />
                  <span className="nav-text">Customer</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[1].Read ? (
              <Menu.Item key="/category">
                <Link to="/category">
                  <Icon type="profile" />
                  <span className="nav-text">Category</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[2].Read ? (
              <Menu.Item key="/subcategory">
                <Link to="/subcategory">
                  <Icon type="profile" />
                  <span className="nav-text">Sub-Category</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[3].Read ? (
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    {" "}
                    <Icon type="gold" />{" "}
                    <span className="nav-text"> Products </span>{" "}
                  </span>
                }
              >
                <Menu.Item key="/premierproducts">
                  <Link to="/premierproducts">
                    <Icon type="gold" />
                    <span className="nav-text">Premier Products</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/product">
                  <Link to="/product">
                    <Icon type="gold" />
                    <span className="nav-text">Non-Premier Products</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/sortproducts">
                  <Link to="/sortproducts">
                    <Icon type="gold" />
                    <span className="nav-text">Sort Products</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : null}
            {permission[4].Read ? (
              <Menu.Item key="/user">
                <Link to="/user">
                  <Icon type="user" />
                  <span className="nav-text">Driver</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[5].Read ? (
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    {" "}
                    <Icon type="shop" />{" "}
                    <span className="nav-text"> Store </span>{" "}
                  </span>
                }
              >
                <Menu.Item key="/viewstore">
                  <Link to="/viewstore">
                    <Icon type="shop" />
                    <span className="nav-text">View Store</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/storerequest">
                  <Link to="/storerequest">
                    <Icon type="shop" />
                    <span className="nav-text">Store Request</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : null}
            {permission[7].Read ? (
              <Menu.Item key="/order">
                <Link to="/order">
                  <Icon type="shop" />
                  <span className="nav-text">Order</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[6].Read ? (
              <Menu.Item key="/orderhistory">
                <Link to="/orderhistory">
                  <Icon type="shop" />
                  <span className="nav-text">Order History</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[8].Read ? (
              <Menu.Item key="/coupon">
                <Link to="/coupon">
                  <Icon type="thunderbolt" />
                  <span className="nav-text">Coupon</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[9].Read ? (
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    {" "}
                    <Icon type="shop" />{" "}
                    <span className="nav-text"> Premier promo </span>{" "}
                  </span>
                }
              >
                <Menu.Item key="/addpromo">
                  <Link to="/addpromo">
                    <Icon type="shop" />
                    <span className="nav-text">Add promo</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/premierpromo">
                  <Link to="/premierpromo">
                    <Icon type="shop" />
                    <span className="nav-text">View promo</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : null}
            {permission[10].Read ? (
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    {" "}
                    <Icon type="shop" />{" "}
                    <span className="nav-text"> Report </span>{" "}
                  </span>
                }
              >
                <Menu.Item key="/dailyorderreport">
                  <Link to="/dailyorderreport">
                    <Icon type="shop" />
                    <span className="nav-text">Daily Order Report</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/productstatus">
                  <Link to="/productstatus">
                    <Icon type="shop" />
                    <span className="nav-text">Product Stock Report</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="/orderreport">
                  <Link to="/orderreport">
                    <Icon type="shop" />
                    <span className="nav-text">Store Order Report</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/taxreport">
                  <Link to="/taxreport">
                    <Icon type="shop" />
                    <span className="nav-text">Vat Tax Report</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/mostorder">
                  <Link to="/mostorder">
                    <Icon type="shop" />
                    <span className="nav-text">Product Sales Report</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/storenearorder">
                  <Link to="/storenearorder">
                    <Icon type="shop" />
                    <span className="nav-text">Store Near Order Report</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : null}
            {permission[11].Read ? (
              <Menu.Item key="/bulksms">
                <Link to="/bulksms">
                  <Icon type="message" />
                  <span className="nav-text">Bulk SMS Sender</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[12].Read ? (
              <Menu.Item key="/notification">
                <Link to="/notification">
                  <Icon type="message" />
                  <span className="nav-text">Push Notification</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[12].Read ? (
              <Menu.Item key="/allmessages">
                <Link to="/allmessages">
                  <Icon type="message" />
                  <span className="nav-text">Notification History</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[13].Read ? (
              <Menu.Item key="/homebanner">
                <Link to="/homebanner">
                  <Icon type="thunderbolt" />
                  <span className="nav-text">Home Banner</span>
                </Link>
              </Menu.Item>
            ) : null}
            {permission[14].Read ? (
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    {" "}
                    <Icon type="shop" />{" "}
                    <span className="nav-text"> Setting </span>{" "}
                  </span>
                }
              >
                <Menu.Item key="/userrole">
                  <Link to="/userrole">
                    <Icon type="shop" />
                    <span className="nav-text">User Role</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : null}
          </Menu>
        ) : null}
      </Sider>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, userinfo: state.userinfo };
}

export default connect(mapStateToProps, { logout, getdesignation })(Navi);
