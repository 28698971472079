import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  updatestore,
  slottable,
  citytable,
  checkemail,
  StoreEdit,
  cityCheck,
  catList1,
  MD_Promo_Type,
} from "../actions/assetAction";
import {
  Form,
  Input,
  Icon,
  Row,
  Radio,
  Drawer,
  Button,
  message,
  Col,
  Spin,
  Select,
  InputNumber,
  Popconfirm,
  Modal,
  Avatar,
  DatePicker,
} from "antd";
import Map from "./automap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../styles/css/App.css";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;
var id = 1;
var idd = 1;

class Addstore extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      fileList1: "",
      subtype: [],
      happy_hour_status: "true",
      filetag: 1,
      iconLoading: false,
      valstatus: "",
      place: [],
      visible: false,
      loading: true,
      Discounted: "",
      markedPrice: "",
      src: null,
      modal: false,
      croppedImage: "false",
      ImageUrl: "",
      crop: {
        unit: "px",
        width: 300,
        height: 300,
        aspect: 4 / 3,
      },
      startValue: null,
      endValue: null,
      Happy_Hour_Id: "",
      dynamicminorder: "true",
      dynamicdelivery: "true",
      productinformationcount: [],
      productinfo: [],
      productinformationcount1: [],
      productinfo1: [],
    };
  }

  componentDidMount() {
    this.props.catList1();
    this.props.slottable();
    this.props.citytable();
    var reqid;
    if (this.props.location.requestid === undefined) {
      reqid = sessionStorage.getItem("drid");
      console.log("if Block ", reqid);
      this.props.MD_Promo_Type(reqid).then((res) => {
        console.log(res);
        this.setState({ subtype: res });
        this.props
          .StoreEdit(reqid)
          .then((result) => {
            this.setState({
              loading: false,
              happy_hour_status:
                this.props.storerequest.Is_Happy_Hour_Enabled_Today,
              Discounted:
                this.props.storerequest.Stores_Master_Commission_Applicable,
              Happy_Hour_Id: this.props.storerequest.Happy_Hour_Id,
              dynamicminorder:
                this.props.storerequest.Is_Static_Min_Order_Amount_Enabled,
              dynamicdelivery:
                this.props.storerequest.Is_Static_Delivery_Price_Enabled,
            });

            if (
              this.props.storerequest.Dynamic_Min_Order_Amount !== undefined
            ) {
              var list = [];
              for (
                var i = 0;
                i < this.props.storerequest.Dynamic_Min_Order_Amount.length;
                i++
              ) {
                list.push(i);
              }
              id = this.props.storerequest.Dynamic_Min_Order_Amount.length;

              this.setState({
                productinformationcount: list,
                productinfo: this.props.storerequest.Dynamic_Min_Order_Amount,
              });
            }

            // Dynamic Delivery Fee
            if (this.props.storerequest.Dynamic_Delivery_Price !== undefined) {
              var list1 = [];
              for (
                var i1 = 0;
                i1 < this.props.storerequest.Dynamic_Delivery_Price.length;
                i1++
              ) {
                list1.push(i1);
              }
              idd = this.props.storerequest.Dynamic_Delivery_Price.length;

              this.setState({
                productinformationcount1: list1,
                productinfo1: this.props.storerequest.Dynamic_Delivery_Price,
              });
            }
          })
          .catch((err) => {
            message.err("Something went wrong!");
          })
          .catch((err) => {
            message.err("Something went wrong!");
          });
      });
    } else {
      sessionStorage.setItem("drid", this.props.location.requestid);
      reqid = sessionStorage.getItem("drid");
      this.props
        .MD_Promo_Type(reqid)
        .then((res) => {
          console.log(res);
          this.setState({ subtype: res });
          this.props
            .StoreEdit(reqid)
            .then((result) => {
              this.setState({
                loading: false,
                happy_hour_status:
                  this.props.storerequest.Is_Happy_Hour_Enabled_Today,
                Discounted:
                  this.props.storerequest.Stores_Master_Commission_Applicable,
                Happy_Hour_Id: this.props.storerequest.Happy_Hour_Id,
                dynamicminorder:
                  this.props.storerequest.Is_Static_Min_Order_Amount_Enabled,
                dynamicdelivery:
                  this.props.storerequest.Is_Static_Delivery_Price_Enabled,
              });
              if (
                this.props.storerequest.Dynamic_Min_Order_Amount !== undefined
              ) {
                var list = [];
                for (
                  var i = 0;
                  i < this.props.storerequest.Dynamic_Min_Order_Amount.length;
                  i++
                ) {
                  list.push(i);
                }
                id = this.props.storerequest.Dynamic_Min_Order_Amount.length;

                this.setState({
                  productinformationcount: list,
                  productinfo: this.props.storerequest.Dynamic_Min_Order_Amount,
                });
              }

              // Dynamic Delivery Fee
              if (
                this.props.storerequest.Dynamic_Delivery_Price !== undefined
              ) {
                var list1 = [];
                for (
                  var i1 = 0;
                  i1 < this.props.storerequest.Dynamic_Delivery_Price.length;
                  i1++
                ) {
                  list1.push(i1);
                }
                idd = this.props.storerequest.Dynamic_Delivery_Price.length;

                this.setState({
                  productinformationcount1: list1,
                  productinfo1: this.props.storerequest.Dynamic_Delivery_Price,
                });
              }
            })

            // Dynamic Form Data Update

            .catch((err) => {
              message.err("Something went wrong!");
            });
        })
        .catch((err) => {
          message.err("Something went wrong!");
        });
    }
  }

  renderCategory() {
    return _.map(this.props.slot, (slot, key) => {
      return (
        <Option value={slot.id} key={key}>
          {slot.sname}
          {/* ({categories.cid})  */}
        </Option>
      );
    });
  }
  renderCategory1() {
    return _.map(this.props.common, (common, key) => {
      return (
        <Option value={common.id} key={key}>
          {common.cname}
          {/* ({categories.cid})  */}
        </Option>
      );
    });
  }
  renderCategory2() {
    return _.map(this.props.categories, (categories, key) => {
      return (
        <Option value={categories.id} key={key}>
          <Avatar src={categories.imgUrl} />
          {categories.cname}
          {/* ({categories.cid})  */}
        </Option>
      );
    });
  }
  checkAddress = (rule, value, callback) => {
    console.log("changed", value);
    if (value === undefined) {
      callback("Input store address ...!");
      return;
    }
    callback();
  };

  handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      iconLoading: false,
      fileList1: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log(values);
      if (!err) {
        const { miles, rate, dmiles, drate } = values;
        var Dynamic_Min_Order_Amount = [];
        var Dynamic_Delivery_Fee = [];
        if (values.Is_Static_Min_Order_Amount_Enabled === "false") {
          var m1 = values.keys.map((key) => miles[key]);
          var r1 = values.keys.map((key) => rate[key]);
          Object.entries(m1).map((arr, index) => {
            var obj = { miles: parseInt(m1[index]), rate: parseInt(r1[index]) };
            Dynamic_Min_Order_Amount.push(obj);
            return false;
          });
        }
        var Blocked_Postal_code = values.Blocked_Postal_code.map(function (el) {
          return el.trim();
        });

        if (values.Is_Static_Delivery_Price_Enabled === "false") {
          var dm1 = values.keyss.map((key) => dmiles[key]);
          var dr1 = values.keyss.map((key) => drate[key]);
          Object.entries(dm1).map((arr, index) => {
            var obj1 = {
              miles: parseInt(dm1[index]),
              rate: parseInt(dr1[index]),
            };
            Dynamic_Delivery_Fee.push(obj1);
            return false;
          });
        }

        console.log(Dynamic_Min_Order_Amount, Dynamic_Delivery_Fee);

        var happpyid;
        if (values.Happy_Hour_Unix_End_Time === undefined) {
          happpyid = this.state.Happy_Hour_Id;
        } else {
          happpyid = values.Happy_Hour_Id;
        }
        this.setState({
          iconLoading: true,
        });
        var reqid = sessionStorage.getItem("drid");
        this.props
          .updatestore(
            values,
            this.state.NewCroppedFile,
            this.props.address,
            reqid,
            happpyid,
            Dynamic_Min_Order_Amount,
            Dynamic_Delivery_Fee,
            Blocked_Postal_code
          )
          .then((result) => {
            this.setState({
              iconLoading: false,
            });
            message.success("Store updated successfully!");
            setTimeout(() => {
              this.props.history.push("/viewstore");
            }, 2000);
          })
          .catch((err) => {});
      }
    });
  };

  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  updateData = (result) => {
    console.log(result);
    this.setState({ place: result });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props
      .cityCheck(this.props.address.city)
      .then((result) => {
        console.log(result);
        this.props.form.setFieldsValue({
          store_address: this.props.address.address,
          ref_city: result,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error(
          "Currently Service not available in this city, Please contact system Administrator",
          15
        );
      });
  };

  onTextChange = (val) => {
    var newVal = val;
    this.setState({ val: newVal });
  };
  checkPrice = (rule, value, callback) => {
    // console.log('changed', value);
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!Number.isNaN(value) && reg.test(value)) || value === "") {
      callback();
      return;
    }
    callback("Input only a numermic value...!");
  };

  //========================= image crop config=============

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result, modal: true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop, croppedImage: "false" });
  };
  cropImageAction = () => {
    this.setState({ src: null, modal: false, croppedImage: "true" });
  };
  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.png"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      const myFilename = "previewFile.png";
      const base64Image = canvas.toDataURL("image/png");
      const myNewCroppedFile = this.base64StringtoFile(base64Image, myFilename);
      console.log(myNewCroppedFile);
      this.setState({ NewCroppedFile: myNewCroppedFile });
      resolve(base64Image);
    });
  }
  base64StringtoFile = (base64String, filename) => {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  deleteImage = () => {
    this.setState({
      filetag: 0,
    });
  };
  handleCancel = () => {
    this.setState({ src: null, modal: false });
    this.props.form.setFieldsValue({
      Store_Image_Url: "",
    });
  };
  handleDiscounted = (e) => {
    this.setState({
      Discounted: e.target.value,
    });
    console.log(this.state.Discounted);
  };
  markedPrice = (e) => {
    this.setState({
      markedPrice: e.target.value,
    });
  };
  handleHappyHour = (e) => {
    this.setState({
      happy_hour_status: e.target.value,
    });
  };
  renderSubtype() {
    return _.map(this.state.subtype, (subtype, key) => {
      return (
        <Option value={subtype.Ref_Promo_Id} key={key}>
          {subtype.Offer_Name}
        </Option>
      );
    });
  }
  disabledStartDate = (startValue) => {
    // const { endValue } = this.state; if (!startValue || !endValue) {   return
    // false; }
    return startValue && startValue < moment().startOf("day");
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  distanceCalculator = (e) => {
    console.log(e);
    var static_km = e * 1.60934;
    var static_milli_km = static_km * 1000;
    this.props.form.setFieldsValue({
      Static_KM: static_km,
      Static_Milli_KM: static_milli_km,
    });
  };

  remove = (k) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  };
  remove1 = (k) => {
    const { form } = this.props;
    // can use data-binding to get
    const keyss = form.getFieldValue("keyss");
    // We need at least one passenger
    if (keyss.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keyss: keyss.filter((key) => key !== k),
    });
  };

  add1 = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keyss = form.getFieldValue("keyss");
    const nextKeys = keyss.concat(idd++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keyss: nextKeys,
    });
  };

  checkdynmicminorder = (e) => {
    this.setState({ dynamicminorder: e.target.value });
  };
  checkdynamicdelivery = (e) => {
    this.setState({ dynamicdelivery: e.target.value });
  };
  // ======================================================

  render() {
    const { crop, croppedImageUrl, ImageUrl, croppedImage, src } = this.state;
    const { endOpen } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    getFieldDecorator("keys", {
      initialValue: this.state.productinformationcount,
    });
    const keys = getFieldValue("keys");
    const formItems = _.map(keys, (k, index) => (
      <div key={k}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 11 }}>
          <Form.Item {...formItemLayout} label={"Miles"}>
            {getFieldDecorator(`miles[${k}]`, {
              initialValue:
                this.state.productinfo === undefined
                  ? ""
                  : index < this.state.productinfo.length
                  ? this.state.productinfo[index].miles
                  : "",
              rules: [
                {
                  required: true,
                  message: "This field is required",
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 11 }}>
          <Form.Item {...formItemLayout} label={"Rate"}>
            {getFieldDecorator(`rate[${k}]`, {
              initialValue:
                this.state.productinfo === undefined
                  ? ""
                  : index < this.state.productinfo.length
                  ? this.state.productinfo[index].rate
                  : "",
              rules: [
                {
                  required: true,
                  message: "This field is required",
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Col>
        {keys.length > 1 ? (
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 2 }}>
            <Button
              className="removebtn"
              type="danger"
              onClick={() => this.remove(k)}
            >
              <Icon className="dynamic-delete-button" type="minus-circle-o" />
            </Button>
          </Col>
        ) : null}
      </div>
    ));

    getFieldDecorator("keyss", {
      initialValue: this.state.productinformationcount1,
    });
    const keyss = getFieldValue("keyss");
    const formItemss = _.map(keyss, (k, index) => (
      <div key={k}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 11 }}>
          <Form.Item {...formItemLayout} label={"Miles"}>
            {getFieldDecorator(`dmiles[${k}]`, {
              initialValue:
                this.state.productinfo1 === undefined
                  ? ""
                  : index < this.state.productinfo1.length
                  ? this.state.productinfo1[index].miles
                  : "",
              rules: [
                {
                  required: true,
                  message: "This field is required",
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 11 }}>
          <Form.Item {...formItemLayout} label={"Rate"}>
            {getFieldDecorator(`drate[${k}]`, {
              initialValue:
                this.state.productinfo1 === undefined
                  ? ""
                  : index < this.state.productinfo1.length
                  ? this.state.productinfo1[index].rate
                  : "",
              rules: [
                {
                  required: true,
                  message: "This field is required",
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Col>
        {keyss.length > 1 ? (
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 2 }}>
            <Button
              className="removebtn"
              type="danger"
              onClick={() => this.remove1(k)}
            >
              <Icon className="dynamic-delete-button" type="minus-circle-o" />
            </Button>
          </Col>
        ) : null}
      </div>
    ));
    //==============================================================================================================//

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Edit Store</h4>
              </div>
              <div className="col-sm-8 text-right">
                <Link to="/viewstore">
                  <Button className="pull-right" type="dark" icon="arrow-left">
                    Back To Store
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid text-left addproduct">
          <Spin spinning={this.state.loading}>
            <Drawer
              width={720}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <Map
                google={this.props.google}
                center={{ lat: 18.5204, lng: 73.8567 }}
                height="300px"
                zoom={15}
              />
              <Button
                type="primary"
                className="m-t-100"
                size="large"
                htmlType="submit"
                onClick={this.onClose}
              >
                Submit
              </Button>
            </Drawer>

            <Row gutter={15}>
              <Form onSubmit={this.handleSubmit}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 0 }}>
                    Store Information
                  </h4>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store Name">
                    {getFieldDecorator("Store_Name", {
                      initialValue: this.props.storerequest.Store_Name,
                      rules: [
                        {
                          required: true,
                          message: "Enter store name",
                          whitespace: false,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store Short Code">
                    {getFieldDecorator("Store_Short_Code", {
                      initialValue: this.props.storerequest.Store_Short_Code,
                      rules: [
                        {
                          required: true,
                          message: "Enter store short code",
                          whitespace: false,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store address">
                    <Row gutter={8}>
                      <Col span={18}>
                        {getFieldDecorator("store_address", {
                          initialValue: this.props.storerequest.Store_Address,
                          rules: [
                            {
                              required: true,
                              validator: this.checkAddress,
                            },
                          ],
                        })(<TextArea rows={3} />)}
                      </Col>
                      <Col span={6}>
                        <Button type="primary" onClick={this.showDrawer}>
                          <Icon type="global" /> Select Address
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store Message">
                    {getFieldDecorator("Configuration_Message_One", {
                      initialValue:
                        this.props.storerequest.Configuration_Message_One,
                      rules: [
                        {
                          required: false,
                          message: "Enter Category Description",
                          whitespace: false,
                        },
                      ],
                    })(<TextArea rows={3} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Additional Store Message"
                  >
                    {getFieldDecorator("Configuration_Message_Two", {
                      initialValue:
                        this.props.storerequest.Configuration_Message_Two,
                      rules: [
                        {
                          required: false,
                          message: "Enter Category Description",
                          whitespace: false,
                        },
                      ],
                    })(<TextArea rows={3} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Operation Slot">
                    {getFieldDecorator("ref_slot", {
                      initialValue: this.props.storerequest.Ref_Slot_Time,
                      rules: [
                        {
                          required: true,
                          message: "select opeartion slot ",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select any one slot"
                        onChange={this.handleChange}
                      >
                        <Option value="1">1 Hours</Option>
                        <Option value="2">2 Hours</Option>
                        <Option value="3">3 Hours</Option>
                        <Option value="4">4 Hours</Option>
                        <Option value="5">5 Hours</Option>
                        <Option value="6">6 Hours</Option>
                        <Option value="7">7 Hours</Option>
                        <Option value="8">8 Hours</Option>
                        <Option value="9">9 Hours</Option>
                        <Option value="10">10 Hours</Option>
                        <Option value="11">11 Hours</Option>
                        <Option value="12">12 Hours</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Min Order Category List"
                  >
                    {getFieldDecorator("Exclude_Min_Order_Category_List", {
                      initialValue:
                        this.props.storerequest.Exclude_Min_Order_Category_List,
                      rules: [
                        {
                          required: true,
                          message: "Select Category",
                          type: "array",
                        },
                      ],
                    })(
                      <Select placeholder="Select Category" mode="multiple">
                        {this.renderCategory2()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Operating City">
                    {getFieldDecorator("ref_city", {
                      initialValue:
                        this.props.storerequest.Ref_Operating_City_Id,
                      rules: [
                        {
                          required: true,
                          message: "Select opearting city",
                        },
                      ],
                    })(
                      <Select disabled onChange={this.handleChange}>
                        {this.renderCategory1()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="18+ Category List">
                    {getFieldDecorator("Category_18_List", {
                      initialValue: this.props.storerequest.Category_18_List,
                      rules: [
                        {
                          required: true,
                          message: "Select Category",
                          type: "array",
                        },
                      ],
                    })(
                      <Select placeholder="Select Category" mode="multiple">
                        {this.renderCategory2()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Maximum Replaceable Amount"
                  >
                    {getFieldDecorator("replaceable_amount", {
                      initialValue:
                        this.props.storerequest.Maximum_Replaceable_Amount,
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<InputNumber style={{ width: 400 }} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Bag Fee">
                    {getFieldDecorator("Bag_Fee", {
                      initialValue: this.props.storerequest.Bag_Fee,

                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(
                            "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
                          ),
                          message: "Wrong format!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="00.00"
                        style={{ width: 400 }}
                        onChange={this.markedPrice}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Cash Back Fee">
                    {getFieldDecorator("Cash_Back_Fee", {
                      initialValue: this.props.storerequest.Cash_Back_Fee,

                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(
                            "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
                          ),
                          message: "Wrong format!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="00.00"
                        style={{ width: 400 }}
                        onChange={this.markedPrice}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Cashback Category List">
                    {getFieldDecorator("Cash_Back_Category", {
                      initialValue: this.props.storerequest.Cash_Back_Category,
                      rules: [
                        {
                          required: true,
                          message: "Select Category",
                        },
                      ],
                    })(
                      <Select placeholder="Select Category">
                        {this.renderCategory2()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Service Charge Type">
                    {getFieldDecorator("Service_Type", {
                      initialValue: this.props.storerequest.Service_Type,
                      rules: [
                        {
                          required: true,
                          message: "Please Select Service Charge Type",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Select
                        onChange={this.handleCoupon}
                        placeholder="Select Service Charge Type"
                      >
                        <Option value="1">Value </Option>
                        <Option value="0">Percent (%)</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Service Value">
                    {getFieldDecorator("Service_Value", {
                      initialValue: this.props.storerequest.Service_Value,
                      rules: [
                        {
                          required: true,
                          validator: this.checkPrice,
                        },
                      ],
                    })(
                      <InputNumber
                        style={{
                          width: 400,
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store Status">
                    {getFieldDecorator("Store_Status", {
                      initialValue: this.props.storerequest.Status,
                      rules: [
                        {
                          required: true,
                          message: "Select Store Status",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Radio.Group>
                        <Radio value="true">Active</Radio>
                        <Radio value="false">In-Active</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                {this.state.filetag === 0 ? (
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      {...formItemLayout}
                      label="Store Image"
                      // extra="Category Image"
                    >
                      {getFieldDecorator("Store_Image_Url", {
                        initialValue: ImageUrl,
                        rules: [
                          {
                            required: true,
                            message: "Please upload store display image!",
                          },
                        ],
                      })(<input type="file" onChange={this.onSelectFile} />)}
                    </Form.Item>

                    <Row gutter={8}>
                      <Col span={18}>
                        <div>
                          {croppedImage === "true" ? (
                            <img
                              alt="Crop"
                              style={{ maxWidth: "100%" }}
                              src={croppedImageUrl}
                            />
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item {...formItemLayout} label="Store Image">
                      <Row gutter={15}>
                        <Col span={16}>
                          <img
                            className="img-responsive"
                            src={this.props.storerequest.Store_Logo}
                            alt=""
                          />
                        </Col>
                        <Col span={8}>
                          <Popconfirm
                            title="Are you sure delete this Image, This will cause permanent loss of image ?"
                            onConfirm={() => this.deleteImage()}
                            onCancel={this.cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            {" "}
                            <Button type="danger">
                              <Icon type="delete" />
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                )}

                {/* Minimum order amount  */}

                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 80 }}>
                    Minimum Order Amount
                  </h4>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Is Static Min Order Amount Enabled"
                  >
                    {getFieldDecorator("Is_Static_Min_Order_Amount_Enabled", {
                      initialValue:
                        this.props.storerequest
                          .Is_Static_Min_Order_Amount_Enabled,
                      rules: [
                        {
                          required: true,
                          message: "This field is required",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Radio.Group onChange={this.checkdynmicminorder}>
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                  <Form.Item {...formItemLayout} label="Minimum Order Amount">
                    {getFieldDecorator("Min_Order_Amount", {
                      initialValue: this.props.storerequest.Min_Order_Amount,
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<InputNumber style={{ width: 400 }} disabled />)}
                  </Form.Item>
                </Col>
                {this.state.dynamicminorder === "true" && (
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                    <Form.Item
                      {...formItemLayout}
                      label="Static Minimum Order Amount"
                    >
                      {getFieldDecorator("Static_Min_Order_Amount", {
                        initialValue:
                          this.props.storerequest.Static_Min_Order_Amount,
                        rules: [{ required: true, validator: this.checkPrice }],
                      })(<InputNumber style={{ width: 400 }} />)}
                    </Form.Item>
                  </Col>
                )}
                {this.state.dynamicminorder === "false" && (
                  <span>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                      {formItems}
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                      <Button type="dashed" onClick={this.add}>
                        <Icon type="plus" /> Add Value
                      </Button>
                    </Col>
                  </span>
                )}

                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 80 }}>
                    Delivery Fee
                  </h4>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Is Static Delivery Price Enabled"
                  >
                    {getFieldDecorator("Is_Static_Delivery_Price_Enabled", {
                      initialValue:
                        this.props.storerequest
                          .Is_Static_Delivery_Price_Enabled,
                      rules: [
                        {
                          required: true,
                          message: "This field is required",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Radio.Group onChange={this.checkdynamicdelivery}>
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                  <Form.Item {...formItemLayout} label="Delivery Fee">
                    {getFieldDecorator("Delivery_Fee", {
                      initialValue: this.props.storerequest.Delivery_Fee,
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(
                            "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
                          ),
                          message: "Wrong format!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="00.00"
                        style={{ width: 400 }}
                        onChange={this.markedPrice}
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
                {this.state.dynamicdelivery === "true" && (
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                    <Form.Item {...formItemLayout} label="Static Delivery Fee">
                      {getFieldDecorator("Static_Delivery_Price", {
                        initialValue:
                          this.props.storerequest.Static_Delivery_Price,
                        rules: [
                          {
                            required: true,
                            pattern: new RegExp(
                              "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
                            ),
                            message: "Wrong format!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="00.00"
                          style={{ width: 400 }}
                          onChange={this.markedPrice}
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.dynamicdelivery === "false" && (
                  <span>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                      {formItemss}
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                      <Button type="dashed" onClick={this.add1}>
                        <Icon type="plus" /> Add Value
                      </Button>
                    </Col>
                  </span>
                )}

                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 80 }}>
                    Offer Information
                  </h4>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Happy hour today available"
                  >
                    {getFieldDecorator("Is_Happy_Hour_Enabled_Today", {
                      initialValue:
                        this.props.storerequest.Is_Happy_Hour_Enabled_Today,
                      rules: [
                        {
                          required: true,
                          message: "This field is required !",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Radio.Group onChange={this.handleHappyHour}>
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Is_Offer_Opened">
                    {getFieldDecorator("Is_Offer_Opened", {
                      initialValue: this.props.storerequest.Is_Offer_Opened,
                      rules: [
                        {
                          required: true,
                          message: "This field is required !",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Radio.Group disabled>
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Is_Offer_Closed">
                    {getFieldDecorator("Is_Offer_Closed", {
                      initialValue: this.props.storerequest.Is_Offer_Closed,
                      rules: [
                        {
                          required: true,
                          message: "This field is required !",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Radio.Group disabled>
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                {this.state.happy_hour_status === "true" ? (
                  <div>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                      <Form.Item {...formItemLayout} label="Start time">
                        {getFieldDecorator("Happy_Hour_Unix_Start_Time", {
                          initialValue:
                            this.props.storerequest.Happy_Hour_Unix_Start_Time,
                          rules: [
                            {
                              required: true,
                              message: "This field is required !",
                            },
                          ],
                        })(
                          <DatePicker
                            style={{
                              width: 350,
                            }}
                            disabledDate={this.disabledStartDate}
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder="Start"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                      <Form.Item {...formItemLayout} label="End time">
                        {getFieldDecorator("Happy_Hour_Unix_End_Time", {
                          initialValue:
                            this.props.storerequest.Happy_Hour_Unix_End_Time,
                          rules: [
                            {
                              required: true,
                              message: "This field is required !",
                            },
                          ],
                        })(
                          <DatePicker
                            style={{
                              width: 350,
                            }}
                            disabledDate={this.disabledEndDate}
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder="End"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                      <Form.Item {...formItemLayout} label="Promotion Name">
                        {getFieldDecorator("Happy_Hour_Id", {
                          initialValue: this.props.storerequest.Happy_Hour_Id,
                          rules: [
                            {
                              required: true,
                              message: "Select any one promotion",
                            },
                          ],
                        })(
                          <Select
                            style={{
                              width: 340,
                            }}
                            placeholder="Select promotion"
                          >
                            {this.renderSubtype()}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </div>
                ) : null}
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 80 }}>
                    Commission Information
                  </h4>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Commission Applicable">
                    {getFieldDecorator("Stores_Master_Commission_Applicable", {
                      initialValue: "true",
                      rules: [
                        {
                          required: true,
                          message: "Store Available Status",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Radio.Group onChange={this.handleDiscounted}>
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                {this.state.Discounted === "true" ? (
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item {...formItemLayout} label="Commission %:">
                      {getFieldDecorator("Stores_Commission_Percentage", {
                        initialValue:
                          this.props.storerequest.Stores_Commission_Percentage,

                        rules: [{ required: true, validator: this.checkPrice }],
                      })(<InputNumber style={{ width: 300 }} />)}
                    </Form.Item>
                  </Col>
                ) : null}
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 80 }}>
                    Additional Information
                  </h4>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store Servicable">
                    {getFieldDecorator("Store_Serviceable_Status", {
                      initialValue:
                        this.props.storerequest.Store_Serviceable_Status,
                      rules: [
                        {
                          required: true,
                          message: "Store Serviceable Status Required",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Radio.Group onChange={this.handleDiscounted}>
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Delivery Distance (mile)"
                  >
                    {getFieldDecorator("Delivery_Distance", {
                      initialValue: this.props.storerequest.Delivery_Distance,

                      rules: [{ required: true, validator: this.checkPrice }],
                    })(
                      <InputNumber
                        style={{ width: 300 }}
                        onChange={this.distanceCalculator}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Static KM">
                    {getFieldDecorator("Static_KM", {
                      initialValue: this.props.storerequest.Static_KM,
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<InputNumber style={{ width: 300 }} disabled />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Static Milli KM">
                    {getFieldDecorator("Static_Milli_KM", {
                      initialValue: this.props.storerequest.Static_Milli_KM,
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<InputNumber style={{ width: 300 }} disabled />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Block Post Codes">
                    {getFieldDecorator("Blocked_Postal_code", {
                      initialValue: this.props.storerequest.Blocked_Postal_code,
                      rules: [
                        { required: false, message: "This field is required!" },
                      ],
                    })(
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Type postal code"
                      ></Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Phone number">
                    {getFieldDecorator("Phone_number", {
                      initialValue: this.props.storerequest.Phone_number,
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<InputNumber style={{ width: 300 }} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store Alert Message">
                    {getFieldDecorator("Store_Alert_Message", {
                      initialValue: this.props.storerequest.Store_Alert_Message,
                      rules: [
                        {
                          required: true,
                          message: "This field is required!",
                          whitespace: false,
                        },
                      ],
                    })(<TextArea rows={3} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Order Start Time">
                    {getFieldDecorator("Order_Start_Time", {
                      initialValue: this.props.storerequest.Order_Start_Time,
                      rules: [
                        {
                          required: true,
                          message: "select order start time ",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select any option"
                        onChange={this.handleOrderStart}
                      >
                        <Option value="1">1 AM</Option>
                        <Option value="2">2 AM</Option>
                        <Option value="3">3 AM</Option>
                        <Option value="4">4 AM</Option>
                        <Option value="5">5 AM</Option>
                        <Option value="6">6 AM</Option>
                        <Option value="7">7 AM</Option>
                        <Option value="8">8 AM</Option>
                        <Option value="9">9 AM</Option>
                        <Option value="10">10 AM</Option>
                        <Option value="11">11 AM</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Order Time">
                    {getFieldDecorator("Order_Time", {
                      initialValue: this.props.storerequest.Order_Time,
                      rules: [
                        {
                          required: true,
                          message: "Enter Order Time",
                          whitespace: false,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 80 }}>
                    Store Manager Information
                  </h4>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Email ID"
                    hasFeedback
                    validateStatus={this.state.valstatus}
                  >
                    {getFieldDecorator("Email_ID", {
                      initialValue:
                        this.props.storerequest.Manager_Email_Address,
                      rules: [
                        {
                          required: true,
                          message: "Enter Email ID",
                          whitespace: false,
                        },
                      ],
                    })(<Input id={this.state.valstatus} disabled />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Name">
                    {getFieldDecorator("Name", {
                      initialValue: this.props.storerequest.Manager_Name,
                      rules: [
                        {
                          required: true,
                          message: "Enter name",
                          whitespace: false,
                        },
                      ],
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Phone Number">
                    {getFieldDecorator("phone", {
                      initialValue:
                        this.props.storerequest.Manager_Mobile_Number,
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>

                {/* =============================== Submit Button ======================================== */}

                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item wrapperCol={{ span: 12 }}>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={this.state.iconLoading}
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ marginLeft: 15 }}
                      size="large"
                      onClick={this.handleReset}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Row>
          </Spin>
          <Modal
            title="Crop Store Image"
            visible={this.state.modal}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.cropImageAction}
              >
                <Icon type="scissor" /> Crop Image
              </Button>,
            ]}
          >
            <Row gutter={8}>
              <Col span={24}>
                {src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
              </Col>
            </Row>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    address: state.address,
    slot: state.slot,
    common: state.common,
    storerequest: state.storerequest,
    categories: state.categories,
  };
}

const WrappedRegistrationForm = Form.create()(Addstore);

export default connect(mapStateToProps, {
  updatestore,
  slottable,
  citytable,
  checkemail,
  StoreEdit,
  cityCheck,
  catList1,
  MD_Promo_Type,
})(WrappedRegistrationForm);
