import React, { Component } from "react";
import { connect } from "react-redux";
import {
  pushmessage,
  allstorename,
  MD_Promo_Type,
} from "../actions/assetAction";
import _ from "lodash";
import { Form, Input, Row, Button, Select, message, Spin, Icon } from "antd";
import request from "superagent";
import { Dev_URL } from "../actionTypes";
import "../styles/css/App.css";
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const { TextArea } = Input;
const { Option } = Select;

class pusher extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      fileList1: "",
      iconLoading: false,
      loading: true,
      enable: true,
      phonenumber: [],
      type: "",
      subtype: [],
    };
  }

  componentWillMount() {
    this.props.allstorename();
    this.props.pushmessage().then((res) => {
      this.setState({ message: res, loading: false });
    });
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      iconLoading: false,
      fileList1: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        var dataobj = {
          customerid: "ALL",
          description: values.message,
          type: this.state.type,
          topic: values.store_id,
          image: values.image,
          subtype: values.subtype,
          htmlcode: values.htmlcode === undefined ? " " : values.htmlcode,
          productdetail: "",
        };

        request
          .post(Dev_URL + "/sendpushnotification")
          .send(dataobj)
          .then((res) => {
            console.log(res.body);
            if (res.body.returncode === 200) {
              console.log(res.body);
              this.setState({
                loading: false,
                enable: true,
                visible: false,
              });
              this.props.form.resetFields();
              message.success("Notification Sent Successfully!");
            } else {
              this.setState({
                loader: false,
                visible: true,
              });
              message.error("Something went wrong!");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  renderCategory1() {
    return _.map(this.props.common, (common, key) => {
      return (
        <Option value={common.key} key={key}>
          {common.type}
        </Option>
      );
    });
  }
  renderCategory2() {
    return _.map(this.state.template, (template, key) => {
      return (
        <Option value={template} key={key}>
          template - {key + 1}
        </Option>
      );
    });
  }

  notifychange = (e) => {
    console.log(e);
    this.props.form.resetFields("template");
    this.props.form.resetFields("message");
    var prodeal = this.state.message;
    var uniquename = _.find(prodeal, function (o) {
      return o.key === e;
    });
    console.log(uniquename);
    this.setState({
      template: uniquename.template,
      enable: false,
      type: uniquename.type,
    });
  };

  templatechange = (e) => {
    this.props.form.setFieldsValue({ message: e });
  };
  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          {storeall.store_name}
        </Option>
      );
    });
  }
  renderSubtype() {
    return _.map(this.state.subtype, (subtype, key) => {
      return (
        <Option value={subtype.Ref_Promo_Id} key={key}>
          {subtype.Promo_Title}
        </Option>
      );
    });
  }
  handleStoreID = (value) => {
    this.props.form.resetFields("subtype");
    this.setState({ StoreID: value });
    this.props
      .MD_Promo_Type(value)
      .then((res) => {
        console.log(res);
        this.setState({ subtype: res });
      })
      .catch((err) => {});
  };
  // ======================================================

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    //==============================================================================================================//

    return (
      <div>
        <Spin indicator={antIcon} spinning={this.state.loading}>
          <div className="container-fluid addp">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-4">
                  <h4>Push Notification</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid text-left addproduct">
            <Row>
              <Form onSubmit={this.handleSubmit}>
                <Form.Item {...formItemLayout} label="Type">
                  {getFieldDecorator("notificationtype", {
                    rules: [
                      { required: true, message: "Select any one option" },
                    ],
                  })(
                    <Select
                      onChange={this.notifychange}
                      placeholder="Select option"
                    >
                      {this.renderCategory1()}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Template">
                  {getFieldDecorator("template", {
                    rules: [
                      { required: true, message: "Select any one template" },
                    ],
                  })(
                    <Select
                      onChange={this.templatechange}
                      disabled={this.state.enable}
                      placeholder="Select template"
                    >
                      {this.renderCategory2()}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item {...formItemLayout} label="Store">
                  {getFieldDecorator("store_id", {
                    rules: [
                      { required: true, message: "Select any one store" },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                      }
                      style={{
                        width: 340,
                      }}
                      onChange={this.handleStoreID}
                      placeholder="Select Store"
                    >
                      {this.renderStorename()}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Promotion Name">
                  {getFieldDecorator("subtype", {
                    rules: [
                      { required: true, message: "Select any one promotion" },
                    ],
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                      }
                      style={{
                        width: 340,
                      }}
                      onChange={this.handlePromotype}
                      placeholder="Select promotion"
                    >
                      {this.renderSubtype()}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Image URL">
                  {getFieldDecorator("image", {
                    rules: [
                      {
                        required: true,
                        message: "please enter image url",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>

                <Form.Item {...formItemLayout} label="Message">
                  {getFieldDecorator("message", {
                    rules: [
                      {
                        required: true,
                        message: "please enter message",
                      },
                      {
                        max: 250,
                        message: "Message text maximum 250 characters only",
                      },
                    ],
                  })(<TextArea rows={5} />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="HTML Code">
                  {getFieldDecorator("htmlcode", {
                    rules: [
                      {
                        required: false,
                        message: "please enter message",
                      },
                    ],
                  })(<TextArea rows={8} />)}
                </Form.Item>

                {/* =============================== Submit Button ======================================== */}

                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={this.state.iconLoading}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ marginLeft: 15 }}
                    size="large"
                    onClick={this.handleReset}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    common: state.common,
    storeall: state.storeall,
  };
}

const WrappedRegistrationForm = Form.create()(pusher);

export default connect(mapStateToProps, {
  pushmessage,
  allstorename,
  MD_Promo_Type,
})(WrappedRegistrationForm);
