import { db } from "../firebase";
import moment from "moment";
import { GET_ORDER } from "../actionTypes";
const cryptLib = require("@skavinvarnan/cryptlib");

export function getorderdetails(sdate, edate, displayorderid, phonenumber) {
  const arr_order = [];
  const arr_orderstatus = [];
  const arr_paymenttype = [];
  const arr_storemaster = [];
  // let bucket_baseurl;
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let Orderquery;
      // console.log("sdate, edate, displayorderid, phonenumber", sdate, edate, displayorderid, phonenumber)
      if (sdate && edate) {
        const from_date = moment(sdate).startOf("day").format();
        const to_date = moment(edate).endOf("day").format();
        const start = new Date(from_date);
        const end = new Date(to_date);
        //console.log(start, end);
        Orderquery = await db
          .collection("Order")
          .where("Created_At", ">", start)
          .where("Created_At", "<", end)
          .orderBy("Created_At", "desc")
          .get();
      } else if (displayorderid) {
        Orderquery = await db
          .collection("Order")
          .where("Display_Order_Id", "==", displayorderid)
          .orderBy("Created_At", "desc")
          .get();
      } else if (phonenumber) {
        Orderquery = await db
          .collection("Order")
          .where("Ref_Customer_Mobile_No", "==", parseInt(phonenumber))
          .orderBy("Created_At", "desc")
          .get();
        //.orderBy("Created_At", "desc")
      } else {
        console.log("Invalid input");
        reject("Invalid input");
      }
      try {
        const Orderquerysize = Orderquery.size;
        //console.log("Orderquerysize", Orderquerysize)
        let order_index = 1;
        if (Orderquerysize !== 0) {
          //MD_Order_Status
          const Md_orderstatuspequery = await db
            .collection("MD_Order_Status")
            .get();
          Md_orderstatuspequery.forEach((doc_md_ord) => {
            arr_orderstatus.push(doc_md_ord.data());
          }); //end MD_Order_Status
          //MD_PaymentType
          const Md_paymenttypequery = await db
            .collection("MD_PaymentType")
            .get();
          Md_paymenttypequery.forEach((doc_md_pay) => {
            arr_paymenttype.push(doc_md_pay.data());
          });
          const storemaster_query = await db.collection("Stores_Master").get();
          storemaster_query.forEach((doc_strmst) => {
            let store_obj = {
              store_id: doc_strmst.id,
              store_name: doc_strmst.data().Store_Name,
              // store_imgUrl: bucket_baseurl + doc_strmst.data().Store_Logo,
            };
            arr_storemaster.push(store_obj);
          }); //end Stores_Master
          console.log(
            "arr_orderstatus",
            arr_orderstatus,
            "arr_paymenttype",
            arr_paymenttype,
            "arr_storemaster",
            arr_storemaster
          );
          Orderquery.forEach((doc_order) => {
            console.log(doc_order.data());
            var order_doc_id = doc_order.id;
            var order_id = doc_order.data().Display_Order_Id;
            let customer_id = doc_order.data().Ref_Customer_Id;

            if (doc_order.data().Ref_Customer_Mobile_No === undefined) {
              var customer_phonenumber = "-";
            } else {
              customer_phonenumber = doc_order.data().Ref_Customer_Mobile_No;
            }
            if (doc_order.data().Ref_Total_No_of_Order === undefined) {
              var Ref_Total_No_of_Order = "-";
            } else {
              Ref_Total_No_of_Order = doc_order.data().Ref_Total_No_of_Order;
            }

            var customer_name = doc_order.data().Ref_Customer_Name;
            var orderstatus_id = doc_order.data().Ref_Order_Status_Id;
            var address = doc_order.data().Order_Delivery_Address;
            var store_id = doc_order.data().Ref_Order_Store_Id;
            var order_date = doc_order.data().Created_At.toDate();
            var dateconvert = moment(order_date).format("LLL");
            var Order_Coupon_Applicable =
              doc_order.data().Order_Coupon_Applicable;
            var App_version =
              doc_order.data().App_Version === undefined
                ? "-"
                : doc_order.data().App_Version;
            const cipherText = doc_order.data().Order_OTP;
            var Ref_Payment_Type_Id = doc_order.data().Ref_Payment_Type_Id;
            var Order_Request_Device = doc_order.data().Order_Request_Device;
            var Ref_Driver_Name = doc_order.data().Ref_Driver_Name;
            if (cipherText !== "") {
              const key = "bbC2H19lkVbQDfakxcrtNMQdd0FloLyw";
              var OTP_Num = cryptLib.decryptCipherTextWithRandomIV(
                cipherText,
                key
              );
            } else {
              OTP_Num = "-";
            }
            //price logic
            var Order_Currency_Symbol = doc_order.data().Order_Currency_Symbol;
            var Price = doc_order.data().Total_Price;
            var Order_Modify_Fare = doc_order.data().Order_Modify_Fare;
            if (Order_Modify_Fare > 0) {
              var Total_Price1 = Order_Modify_Fare;
            } else {
              Total_Price1 = Price;
            }
            if (doc_order.data().Ref_Delivery_Fee !== undefined) {
              var Delivery_Fee = doc_order.data().Ref_Delivery_Fee;
            } else {
              Delivery_Fee = 0;
            }
            if (doc_order.data().Ref_Bag_Fee !== undefined) {
              var Bag_Fee = doc_order.data().Ref_Bag_Fee;
            } else {
              Bag_Fee = 0;
            }
            var Order_Discount_Fare = doc_order.data().Order_Discount_Fare;
            var total_amount = Total_Price1 + Delivery_Fee + Bag_Fee;
            console.log(total_amount);
            var Total_Price = total_amount - Order_Discount_Fare;
            //price logic end
            //in array Payment_Type
            var payment_status_obj =
              arr_paymenttype[
                arr_paymenttype
                  .map(function (item) {
                    return item.Payment_Type_Id;
                  })
                  .indexOf(Ref_Payment_Type_Id)
              ];
            console.log(payment_status_obj);
            let Payment_Type = "N/A";
            if (payment_status_obj !== undefined) {
              Payment_Type = payment_status_obj.Payment_Type;
            } // in array Payment_Type end
            //in array Order Status

            var order_status_obj =
              arr_orderstatus[
                arr_orderstatus
                  .map(function (item) {
                    return item.Order_Status_Id;
                  })
                  .indexOf(orderstatus_id)
              ];
            console.log(order_status_obj);
            let dir_order_status = "N/A";
            if (order_status_obj !== undefined) {
              dir_order_status = order_status_obj.Order_Status;
            } // in array Order Status end
            //in array Store Name

            var storemaster_obj =
              arr_storemaster[
                arr_storemaster
                  .map(function (item) {
                    return item.store_id;
                  })
                  .indexOf(store_id)
              ];
            console.log(storemaster_obj);
            let store_name = "N/A";
            if (storemaster_obj !== undefined) {
              store_name = storemaster_obj.store_name;
            } // in array Store Name end
            //Customer_Shared_Information

            db.collection("Customer_Shared_Information")
              .doc(customer_id)
              .get()
              .then((cust_doc) => {
                //customer details
                var customerdetail = "";
                customerdetail += order_id + "\n";
                customerdetail += customer_name + "\n";
                customerdetail += customer_phonenumber + "\n";
                customerdetail += address + "\n";
                customerdetail += store_name + "\n";
                customerdetail += Payment_Type + "\n";
                customerdetail +=
                  Order_Currency_Symbol + Total_Price.toFixed(2);
                //end customer details
                let obj_arr = {
                  key: order_doc_id,
                  order_id: order_id,
                  customer_name: customer_name,
                  customer_phone: customer_phonenumber,
                  cust_id: customer_id,
                  delivery_address: address,
                  store_name: store_name,
                  order_status: dir_order_status,
                  orderstatus_id: orderstatus_id,
                  order_date: dateconvert,
                  Total_Price:
                    Order_Currency_Symbol + " " + Total_Price.toFixed(2),
                  App_version: App_version,
                  driver_name: Ref_Driver_Name,
                  OTP_Num: OTP_Num,
                  orderno: Ref_Total_No_of_Order.toString(), //Customer_Number_Of_Trips
                  Payment_Type: Payment_Type,
                  Order_Request_Device: Order_Request_Device,
                  Order_Coupon_Applicable: Order_Coupon_Applicable,
                  store_id: store_id,
                  customerdetail: customerdetail,
                };
                arr_order.push(obj_arr); //push order with order details
                if (order_index === Orderquerysize) {
                  //final success
                  // console.log("inside Orderquerysize", Orderquerysize, "order_index", order_index)
                  //  console.log("arr_order final", arr_order)

                  dispatch({ type: GET_ORDER, payload: arr_order });
                  resolve("Success");
                }
                order_index++;
              })
              .catch((error) => {
                console.log(error.message);
                reject(error.message);
              }); //end Customer_Shared_Information
          }); //end get Order
        } else {
          dispatch({ type: GET_ORDER, payload: arr_order });
          resolve("Success");
        }
      } catch (err) {
        console.log("Error getting documents", err);
        reject(err.message);
      }
    });
  };
}
