import React, { Component } from "react";
import { connect } from "react-redux";
import {
  allcustomer,
  customerbyphone,
  user_device_details,
} from "../actions/userAction";
import { postmessage } from "../actions/chatAction";
import {
  Icon,
  Table,
  Tooltip,
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Spin,
  message,
  Select,
  Divider,
  DatePicker,
} from "antd";
import { auth } from "../firebase";
import "../styles/css/App.css";
import Highlighter from "react-highlight-words";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import _ from "lodash";
import request from "superagent";
import { Dev_URL } from "../actionTypes";
import moment from "moment";

const { Option } = Select;
class Drivers extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      // category: "", subcategory: "",
      disabled: true,
      searchText: "",
      tdata: null,
      loading: true,
      date: "",
      admin_user: "",
      visible: false,
      loader: false,
      msgtype: 0,
      startValue: null,
      endValue: null,
      endOpen: false,
      is_date: true,
      is_phonenumber: false,
      devicedetails: "",
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon
        type="search"
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text || ""}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    console.log(this.state.searchText);
  };

  handleCategory = (value) => {
    this.setState({ category: value, subcategory: "", disabled: false });
    this.props.filtersubCat(value);
  };

  handlesubCategory = (value) => {
    this.setState({ subcategory: value, loading: true });
    var cat = this.state.category;
    var subcat = value;
    this.props
      .filterProduct(cat, subcat)
      .then((res) => {
        console.log(res);
        this.setState({ tdata: this.props.products, loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleResett = () => {
    //  componentWillMount

    const initialDate = new Date();

    this.setState({ loading: true, is_date: true, is_phonenumber: false });

    const today = moment();
    const from_date = today.startOf("day").format();
    const to_date = today.endOf("end").format();
    const val = "Date";
    this.props
      .allcustomer(from_date, to_date, val)
      .then((res) => {
        //  console.log(res);
        this.setState({
          tdata: this.props.driver,
          loading: false,
          date: initialDate,
        });
        this.props.form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false, tdata: null });
      });
  };

  componentDidMount() {
    var user = auth.currentUser.uid;
    this.setState({
      admin_user: user,
      // chatall: this.props.chatall
    });
    const initialDate = new Date();
    const today = moment();
    const from_date = today.startOf("day").format();
    const to_date = today.endOf("end").format();
    this.props
      .allcustomer(from_date, to_date)
      .then((res) => {
        //  console.log(res);
        this.setState({
          tdata: this.props.driver,
          loading: false,
          date: initialDate,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false, tdata: null });
      });
  }

  renderNotes() {
    return _.map(this.props.driver, (driver, key) => {
      return (
        <tr key={key}>
          <td>{driver.Customer_Name}</td>
          <td>{driver.Customer_Mobile_Number}</td>
          <td>{driver.Customer_Email_Address}</td>
          <td>{driver.cityname}</td>
          <td>{driver.storename}</td>
          <td>{driver.Post_Code}</td>
          <td>{driver.Full_Address}</td>
          <td>{driver.order_count}</td>
          <td>{driver.create_at}</td>
        </tr>
      );
    });
  }
  confirm = (record, msgtype) => {
    console.log(record, msgtype);

    if (msgtype === 3) {
      this.setState({ loading: true, msgtype: msgtype });
      this.props
        .user_device_details(record.key)
        .then((res) => {
          this.setState({ loading: false, visible: true, devicedetails: res });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          message.error(err);
          this.setState({ loading: false });
        });
    } else {
      this.props.form.setFieldsValue({
        Customer_Name: record.Customer_Name,
        customer_id: record.key,
        phonenumber: record.Customer_Mobile_Number,
      });
      this.setState({
        visible: true,
        msgtype: msgtype,
      });
    }
  };

  cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["phonenumber", "image", "type", "customer_id", "message"],
      (err, values) => {
        if (!err) {
          console.log(values);
          this.setState({
            loader: true,
            visible: false,
          });
          if (this.state.msgtype === 1) {
            var dataobj = {
              phonenumber: values.phonenumber,
              message: values.message,
            };
            request
              .post(Dev_URL + "/sendsmsweb")
              .send(dataobj)
              .then((res) => {
                console.log(res.body);
                if (res.body.ReturnCode === 200) {
                  console.log(res.body);
                  this.setState({
                    loader: false,
                  });
                  this.props.form.resetFields();
                  message.success("SMS Sent Successfully!");
                } else {
                  this.setState({
                    loader: false,
                    visible: true,
                  });
                  message.error("Something went wrong!");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            var dataobj1 = {
              customerid: values.customer_id,
              description: values.message,
              type: values.type,
              topic: "",
              image: values.image,
              subtype: "",
              htmlcode: "",
              productdetail: "",
            };
            request
              .post(Dev_URL + "/sendpushnotification")
              .send(dataobj1)
              .then((res) => {
                console.log(res.body);
                if (res.body.returncode === 200) {
                  console.log(res.body);
                  this.setState({
                    loader: false,
                    visible: false,
                  });
                  this.props.form.resetFields();
                  message.success("Notification Sent Successfully!");
                } else {
                  this.setState({
                    loader: false,
                    visible: true,
                  });
                  message.error("Something went wrong!");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    );
  };
  disabledStartDate = (startValue) => {
    // const { endValue } = this.state;
    // if (!startValue || !endValue)
    // {   return false; }
    return startValue && startValue > moment().endOf("day");
  };

  disabledEndDate = (endValue) => {
    // const {startValue} = this.state;
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    return endValue && endValue > moment().endOf("day");
  };

  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  handlecustomer = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      ["start_date", "end_date", "filter_by", "phone_number"],
      (err, values) => {
        if (!err) {
          this.setState({ loading: true });
          console.log(
            values.start_date,
            values.end_date,
            values.filter_by,
            values.phone_number
          );
          const initialDate = new Date();
          if (values.filter_by === "Phone_Number") {
            this.props
              .customerbyphone(values.phone_number)
              .then((res) => {
                console.log(res);
                this.setState({
                  tdata: this.props.driver,
                  loading: false,
                  date: initialDate,
                });
              })
              .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
              });
          } else {
            this.props
              .allcustomer(values.start_date, values.end_date)
              .then((res) => {
                //  console.log(res);
                this.setState({
                  tdata: this.props.driver,
                  loading: false,
                  date: initialDate,
                });
              })
              .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
              });
          }
        }
      }
    );
  };
  handlefilterbyOnChange = (value) => {
    console.log(value);
    if (value === "Date") {
      this.setState({
        is_date: true,
        is_phonenumber: false,
      });
    } else if (value === "Phone_Number") {
      this.setState({
        is_date: false,
        is_phonenumber: true,
      });
    } else {
      this.setState({
        is_date: false,
        is_phonenumber: false,
      });
    }
  };
  // ======================================================
  // ===============================================

  render() {
    const { endOpen } = this.state;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "Customer id",
        dataIndex: "key",
        key: "key",
        width: 150,
        ...this.getColumnSearchProps("Customer_Name"),
      },
      {
        title: "Name",
        dataIndex: "Customer_Name",
        key: "Customer_Name",

        ...this.getColumnSearchProps("Customer_Name"),
      },
      {
        title: "Mobile Number",
        dataIndex: "Customer_Mobile_Number",
        key: "Customer_Mobile_Number",
        ...this.getColumnSearchProps("Customer_Mobile_Number"),
      },
      {
        title: "Email address",
        dataIndex: "Customer_Email_Address",
        key: "Customer_Email_Address",
        ...this.getColumnSearchProps("Customer_Email_Address"),
      },
      {
        title: "City Name",
        dataIndex: "cityname",
        key: "cityname",
        ...this.getColumnSearchProps("cityname"),
      },
      {
        title: "Store Name",
        dataIndex: "storename",
        key: "storename",
        ...this.getColumnSearchProps("storename"),
      },
      {
        title: "Full_Address",
        dataIndex: "Full_Address",
        key: "Full_Address",
        ...this.getColumnSearchProps("Full_Address"),
      },
      {
        title: "Post_Code",
        dataIndex: "Post_Code",
        key: "Post_Code",
        ...this.getColumnSearchProps("Post_Code"),
      },
      {
        title: "No. of Orders",
        dataIndex: "order_count",
        key: "order_count",
        ...this.getColumnSearchProps("order_count"),
      },
      {
        title: "Referral Code",
        dataIndex: "Referral_Code",
        key: "Referral_Code",
      },

      {
        title: "Created At",
        dataIndex: "create_at",
        key: "create_at",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: 150,
        render: (text, record) => (
          <span>
            <Tooltip title="SMS">
              <Button
                onClick={() => this.confirm(record, 1)}
                type="primary"
                icon="phone"
                shape="circle"
              />
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Notification">
              <Button
                onClick={() => this.confirm(record, 2)}
                type="primary"
                icon="bell"
                shape="circle"
              />
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Device Details">
              <Button
                onClick={() => this.confirm(record, 3)}
                type="primary"
                icon="mobile"
                shape="circle"
              />
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <div>
        <Spin
          style={{ zIndex: 9999 }}
          spinning={this.state.loader}
          tip="Sending ..."
        >
          <div className="container-fluid addp">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <h4>Customer</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <Row className="m-b-30 m-t-30">
              <Col>
                <Form layout="inline" onSubmit={this.handlecustomer}>
                  <Form.Item label="Filter By">
                    {getFieldDecorator("filter_by", {
                      initialValue: "Date",
                      rules: [
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ],
                    })(
                      <Select
                        //   mode="multiple"
                        allowClear
                        style={{ width: "320px" }}
                        placeholder="Please select"
                        onChange={this.handlefilterbyOnChange}
                      >
                        <Option value="Date">Date</Option>
                        <Option value="Phone_Number">Phone Number</Option>
                        <Option value="All">Show All Customer</Option>
                      </Select>
                    )}
                  </Form.Item>
                  {this.state.is_date === true ? (
                    <span>
                      <Form.Item label="From Date">
                        {getFieldDecorator("start_date", {
                          rules: [
                            {
                              type: "object",
                              required: true,
                              message: "This field is required",
                            },
                          ],
                        })(
                          <DatePicker
                            style={{
                              width: 350,
                            }}
                            disabledDate={this.disabledStartDate}
                            format="DD-MM-YYYY"
                            placeholder="Select Date"
                            onChange={this.onStartChange}
                            onOpenChange={this.handleStartOpenChange}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="To Date">
                        {getFieldDecorator("end_date", {
                          rules: [
                            {
                              type: "object",
                              required: true,
                              message: "This field is required",
                            },
                          ],
                        })(
                          <DatePicker
                            style={{
                              width: 350,
                            }}
                            disabledDate={this.disabledEndDate}
                            format="DD-MM-YYYY"
                            placeholder="Select Date"
                            onChange={this.onEndChange}
                            open={endOpen}
                            onOpenChange={this.handleEndOpenChange}
                          />
                        )}
                      </Form.Item>
                    </span>
                  ) : null}
                  {this.state.is_phonenumber === true ? (
                    <Form.Item label="Phone Number">
                      {getFieldDecorator("phone_number", {
                        rules: [
                          {
                            type: "string",
                            required: true,
                            message: "This field is required",
                          },
                        ],
                      })(
                        <Input
                          style={{ width: "320px" }}
                          placeholder="Phone Number"
                        />
                      )}
                    </Form.Item>
                  ) : null}

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                    <Button
                      style={{ marginLeft: 15 }}
                      type="default"
                      onClick={this.handleResett}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Divider dashed />
            <div className="row m-t-30">
              <div className="text-left filterdiv col-md-6">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="text-left download-table-xls-button btn ant-btn-primary"
                  table="table-to-xls"
                  filename="Shopezy-Customer-Report"
                  sheet="Customer"
                  buttonText="Export Customer Report"
                />
              </div>
              <div className="text-right filterdiv col-md-6">
                <span
                  style={{
                    marginRight: 10,
                  }}
                >
                  Last Refreshed Time :
                  <TimeAgo date={new Date()}>
                    {({ value }) => (
                      <b>
                        <Icon type="clock-circle" theme="outlined" /> {value}
                      </b>
                    )}
                  </TimeAgo>
                </span>
                <Button
                  type="primary"
                  style={{
                    marginLeft: 15,
                  }}
                  onClick={this.handleResett}
                >
                  Refresh
                </Button>
              </div>
            </div>
            <div>
              <table className="hidden" id="table-to-xls">
                <thead>
                  <tr>
                    <th>Customer_Name</th>
                    <th>Customer_Mobile_Number</th>
                    <th>Customer_Email_Address</th>
                    <th>City_Name</th>
                    <th>Store_Name</th>
                    <th>Postal_Code</th>
                    <th>Full_Address</th>
                    <th>order_count</th>
                    <th>create_at</th>
                  </tr>
                </thead>
                <tbody>{this.renderNotes()}</tbody>
              </table>
            </div>
            <Table
              columns={columns}
              dataSource={this.state.tdata}
              loading={this.state.loading}
              scroll={{ x: 2000 }}
              size="middle"
              bordered
            />
          </div>
          <Drawer
            title={
              this.state.msgtype === 1
                ? "Send Message to Customer"
                : this.state.msgtype === 2
                ? "Send Notification to Customer"
                : "Customer Device Details"
            }
            width={720}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form layout="vertical" onSubmit={this.handleSubmit}>
              {this.state.msgtype === 3 ? (
                <div>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item label="App Version">
                        {getFieldDecorator("App_Version", {
                          initialValue: this.state.devicedetails.App_Version,
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(<Input disabled />)}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Device Make">
                        {getFieldDecorator("Device_Make", {
                          initialValue: this.state.devicedetails.Device_Make,
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(<Input disabled />)}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Device Model">
                        {getFieldDecorator("Device_Model", {
                          initialValue: this.state.devicedetails.Device_Model,

                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(<Input disabled />)}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Device OS">
                        {getFieldDecorator("Device_OS", {
                          initialValue: this.state.devicedetails.Device_OS,

                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(<Input disabled />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Customer Name">
                        {getFieldDecorator("Customer_Name", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter customer name",
                            },
                          ],
                        })(<Input disabled />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Phone Number">
                        {getFieldDecorator("phonenumber", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter user name",
                            },
                          ],
                        })(<Input disabled />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  {this.state.msgtype === 2 ? (
                    <div>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="Image URL">
                            {getFieldDecorator("image", {
                              rules: [
                                {
                                  required: true,
                                  message: "please enter image url",
                                },
                              ],
                            })(<Input />)}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="Type">
                            {getFieldDecorator("type", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please select type",
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 120 }}
                                onChange={this.handleChange}
                              >
                                <Option value="Product">Product</Option>
                                <Option value="Common">Common</Option>
                                <Option value="Cart">Cart</Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ) : null}

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Customer id">
                        {getFieldDecorator("customer_id", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter user name",
                            },
                          ],
                        })(<Input disabled />)}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item label="Message">
                        {getFieldDecorator("message", {
                          initialValue:
                            "Shopezy! That stuff in your cart would look way better in your house. Come finish up your order! Happy Shopping!!",
                          rules: [
                            {
                              required: true,
                              message: "please enter message",
                            },
                            {
                              max: 250,
                              message:
                                "Message text maximum 250 characters only",
                            },
                          ],
                        })(
                          <Input.TextArea
                            rows={4}
                            placeholder="please enter message"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}

              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                {this.state.msgtype !== 3 ? (
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                ) : null}
              </div>
            </Form>
          </Drawer>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, driver: state.driver };
}

const App = Form.create()(Drivers);

export default connect(mapStateToProps, {
  allcustomer,
  postmessage,
  customerbyphone,
  user_device_details,
})(App);
