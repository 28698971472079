import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { addCat, checkcat, allstorename } from "../actions/assetAction";
import _ from "lodash";
import {
  Form,
  Input,
  Icon,
  Row,
  Radio,
  Upload,
  Button,
  message,
  Select,
} from "antd";
import "../styles/css/App.css";

const { TextArea } = Input;
const { Option } = Select;

class Addcategory extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      fileList1: "",
      iconLoading: false,
      valstatus: "",
    };
  }

  componentWillMount() {
    this.props.allstorename();
    var userp = this.props.userinfo.Permission[1];
    if (!userp.Read) {
      this.props.history.push("/");
    }
  }

  checkPrice = (rule, value, callback) => {
    // console.log('changed', value);
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!Number.isNaN(value) && reg.test(value)) || value === "") {
      callback();
      return;
    }
    callback("Input only a numeric value...!");
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      iconLoading: false,
      fileList1: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          iconLoading: true,
        });

        this.props
          .checkcat(values.Category_Id)
          .then((result) => {
            console.log(result);
            this.setState({
              valstatus: "success",
            });
            this.props
              .addCat(values, this.state.fileList1)
              .then((result) => {
                this.setState({
                  iconLoading: false,
                  fileList1: "",
                  valstatus: "",
                });
                this.props.form.resetFields();

                message.success("Category added successfully!");
              })
              .catch((err) => {});
          })
          .catch((err) => {
            if (err.exists) {
              console.log(err);
              this.setState({
                valstatus: "error",
                iconLoading: false,
              });
              message.error(
                "Category ID already exist, Kindly input unique ID"
              );
            } else {
              message.error(err);
            }
          });
      }
    });
  };
  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          {storeall.store_name}
        </Option>
      );
    });
  }
  handleStoreID = (value) => {
    this.setState({ StoreID: value });
  };

  // ======================================================

  render() {
    const { fileList1 } = this.state;

    const propsthumb = {
      multiple: false,
      listType: "picture-card",
      accept: ".png,.jpg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList1.indexOf(file);
          const newFileList = state.fileList1.slice();
          newFileList.splice(index, 1);
          return {
            fileList1: newFileList,
          };
        });
        console.log(this.state.fileList1);
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList1: [...state.fileList1, file],
        }));
        return false;
      },
      fileList1,
    };

    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };

    //==============================================================================================================//

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Add Category</h4>
              </div>
              <div className="col-sm-8 text-right">
                <Link to="/category">
                  <Button className="pull-right" type="dark" icon="arrow-left">
                    Back To Category
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid text-left addproduct">
          <Row>
            <Form onSubmit={this.handleSubmit}>
              <Form.Item {...formItemLayout} label="Store">
                {getFieldDecorator("Ref_Store_Id_List", {
                  rules: [{ required: true, message: "Select any one store" }],
                })(
                  <Select
                    style={{
                      width: 340,
                    }}
                    mode="multiple"
                    onChange={this.handleStoreID}
                    placeholder="Select Store"
                  >
                    {this.renderStorename()}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label="Category ID"
                hasFeedback
                validateStatus={this.state.valstatus}
              >
                {getFieldDecorator("Category_Id", {
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp("^[A-Za-z0-9]+$"),
                      message: "Category ID should be alphanumeric only",
                    },
                  ],
                })(<Input style={{ width: 250 }} id={this.state.valstatus} />)}
              </Form.Item>

              <Form.Item {...formItemLayout} label="Category Name">
                {getFieldDecorator("Category_Name", {
                  rules: [
                    {
                      required: true,
                      message: "Enter Category Name",
                      whitespace: false,
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item {...formItemLayout} label="Display Order">
                {getFieldDecorator("Category_Display_Order", {
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp("^[0-9]*$"),
                      message: "Display order format wrong!",
                    },
                  ],
                })(<Input placeholder="0" />)}
              </Form.Item>

              {/* <Form.Item
                  {...formItemLayout}
                  label="Category Display Order"
                >
                  {getFieldDecorator('Category_DisplayOrder', {
                    rules: [{ required: true, validator: this.checkPrice }],
                  })(
                    <InputNumber style={{ width: 250 }}/>
                  )}
                </Form.Item> */}

              <Form.Item {...formItemLayout} label="Category Description">
                {getFieldDecorator("Category_Description", {
                  rules: [
                    {
                      required: true,
                      message: "Enter Category Description",
                      whitespace: false,
                    },
                  ],
                })(<TextArea rows={5} />)}
              </Form.Item>

              <Form.Item {...formItemLayout} label="Category Status">
                {getFieldDecorator("Category_Status", {
                  initialValue: "true",
                  rules: [
                    {
                      required: true,
                      message: "Select Category Status",
                      whitespace: false,
                    },
                  ],
                })(
                  <Radio.Group>
                    <Radio value="true">Active</Radio>
                    <Radio value="false">In-Active</Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label="Category Image"
                // extra="Category Image"
              >
                {getFieldDecorator("Category_Image_Url", {
                  valuePropName: "fileList",
                  getValueFromEvent: this.normFile,
                  rules: [
                    {
                      required: true,
                      message: "Please Upload Category Display Image!",
                    },
                  ],
                })(
                  <Upload name="Category_Image_Url" {...propsthumb}>
                    {fileList1.length === 1 ? null : (
                      <div>
                        <Icon type="plus" />
                        <div className="ant-upload-text">Choose</div>
                      </div>
                    )}
                  </Upload>
                )}
              </Form.Item>

              {/* =============================== Submit Button ======================================== */}

              <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={this.state.iconLoading}
                >
                  Submit
                </Button>
                <Button
                  style={{ marginLeft: 15 }}
                  size="large"
                  onClick={this.handleReset}
                >
                  Clear
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    storeall: state.storeall,
    userinfo: state.userinfo,
  };
}

const WrappedRegistrationForm = Form.create()(Addcategory);

export default connect(mapStateToProps, { addCat, checkcat, allstorename })(
  WrappedRegistrationForm
);
