import React, { Component } from "react";
import { connect } from "react-redux";
import { storeallorder, allstorename } from "../actions/assetAction";
import {
  Icon,
  Button,
  Input,
  Tag,
  Col,
  Row,
  Table,
  Divider,
  DatePicker,
  Form,
  Select,
  Skeleton,
  message,
} from "antd";
import "../styles/css/App.css";
import Highlighter from "react-highlight-words";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import _ from "lodash";
import moment from "moment";
import StatCard from "./templates/StatCard";
import { ShoppingCart, Target } from "react-feather";
import { storedashreport } from "../actions/dashboardAction";

const { Option } = Select;

class Order extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      disabled: true,
      searchText: "",
      tdata: null,
      loading: false,
      dashstats: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      visible: false,
      iconLoading: false,
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon
        type="search"
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    console.log(this.state.searchText);
  };

  componentDidMount() {
    this.props.allstorename();
  }

  handleResett = () => {
    this.props.form.resetFields();
    this.setState({ tdata: [], visible: false });
  };

  renderNotes() {
    return _.map(this.state.tdata, (order, key) => {
      return (
        <tr key={key}>
          <td>{order.cust_id}</td>
          <td>{order.order_id}</td>
          <td>{order.customer_name}</td>
          <td>{order.customer_phone}</td>
          <td>{order.Order_Postal_Code}</td>
          <td>{order.delivery_address}</td>
          <td>{order.driver_name}</td>
          <td>{order.store_name}</td>
          <td>{order.order_status}</td>
          <td>{order.Total_Price}</td>
          <td>{order.Delivery_Fee}</td>
          <td>{order.Bag_Fee}</td>
          <td>{order.cash_back_fee}</td>
          <td>{order.Ref_Cash_Back_Amount}</td>
          <td>{order.Payment_Type}</td>
          <td>
            {order.Ref_Delivery_Preferences === 2 ? "Pick up" : "Delivery"}
          </td>
          <td>{order.orderno}</td>
          <td>{order.Order_Coupon_Applicable === true ? "Yes" : "No"}</td>
          <td>{order.order_date}</td>
        </tr>
      );
    });
  }
  renderOrderStatus() {
    return _.map(this.state.mdstatus, (order, key) => {
      return (
        <Option key={key} value={order.Order_Status_Id}>
          {order.Order_Status}
        </Option>
      );
    });
  }

  disabledStartDate = (startValue) => {
    // const { endValue } = this.state;
    // if (!startValue || !endValue)
    // {   return false; }
    return startValue && startValue > moment().endOf("day");
  };

  disabledEndDate = (endValue) => {
    // const {startValue} = this.state;
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    return endValue && endValue > moment().endOf("day");
  };

  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .storedashreport(values.store_id, values.start_date, values.end_date)
          .then((result) => {
            console.log("resolved", this.props.dashtoday);
            this.setState({ dashstats: this.props.dashtoday });
          })
          .catch((error) => {
            console.log("error");
            this.setState({ loading: false });
          });
        this.props
          .storeallorder(values.store_id, values.start_date, values.end_date)
          .then((res) => {
            console.log(res);
            this.setState({
              tdata: this.props.order,
              loading: false,
              visible: true,
            });
          })
          .catch((error) => {
            console.log("error");
            message.error("Something Went Wrong !");
            this.setState({ loading: false, tdata: [] });
          });
      }
    });
  };

  renderStorename() {
    return _.map(this.props.storeall, (storeall, key) => {
      return (
        <Option value={storeall.key} key={key}>
          {storeall.store_name}
        </Option>
      );
    });
  }
  handleStoreID = (value) => {
    this.setState({ StoreID: value });
  };
  //===============================================
  //===============================================

  render() {
    const { dashstats, endOpen } = this.state;
    const { getFieldDecorator } = this.props.form;
    // console.log(this.props.products);
    const columns = [
      {
        title: "Order ID",
        dataIndex: "order_id",
        width: 140,
        key: "order_id",
        ...this.getColumnSearchProps("order_id"),
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        key: "customer_name",
        ...this.getColumnSearchProps("customer_name"),
      },
      {
        title: "Customer Phone",
        dataIndex: "customer_phone",
        key: "customer_phone",
        ...this.getColumnSearchProps("customer_phone"),
      },
      {
        title: "Postal Code",
        dataIndex: "Order_Postal_Code",
        key: "Order_Postal_Code",
        ...this.getColumnSearchProps("Order_Postal_Code"),
      },
      {
        title: "No. of Order",
        dataIndex: "orderno",
        width: 80,
        key: "orderno",
      },
      {
        title: "Driver Name",
        dataIndex: "driver_name",
        key: "driver_name",
        ...this.getColumnSearchProps("driver_name"),
      },
      {
        title: "Order Status",
        dataIndex: "order_status",
        width: 150,
        key: "order_status",
        render: (text, record) => (
          <span>
            {(() => {
              switch (record.orderstatus_id) {
                case 7:
                  return <Tag color="lime">{record.order_status}</Tag>;
                case 12:
                  return <Tag color="#87d068">{record.order_status}</Tag>;
                case 9:
                  return <Tag color="volcano">{record.order_status}</Tag>;

                default:
                  return <Tag color="orange">{record.order_status}</Tag>;
              }
            })()}
          </span>
        ),
      },
      {
        title: "Coupon Applied",
        dataIndex: "Order_Coupon_Applicable",
        width: 150,
        key: "Order_Coupon_Applicable",
        render: (text, record) => (
          <span>
            {record.Order_Coupon_Applicable === true ? (
              <Tag color="green">Yes</Tag>
            ) : (
              <Tag color="volcano">No</Tag>
            )}
          </span>
        ),
      },
      {
        title: "Order Date",
        dataIndex: "order_date",
        width: 130,
        key: "order_date",
      },
      {
        title: "Store Name",
        dataIndex: "store_name",
        key: "store_name",
      },
      {
        title: "Payment Type",
        dataIndex: "Payment_Type",
        key: "Payment_Type",
      },
      {
        title: "Order Type",
        dataIndex: "Ref_Delivery_Preferences",
        width: 130,
        key: "Ref_Delivery_Preferences",
        render: (text, record) => (
          <span>
            {record.Ref_Delivery_Preferences === 2 ? (
              <Tag color="#fa8c16">Pick up</Tag>
            ) : (
              <Tag color="#3498db">Delivery</Tag>
            )}
          </span>
        ),
      },
      {
        title: "Price",
        dataIndex: "Total_Price",
        key: "Total_Price",
        ...this.getColumnSearchProps("Total_Price"),
      },
      {
        title: "Delivery_Fee",
        dataIndex: "Delivery_Fee",
        key: "Delivery_Fee",
        ...this.getColumnSearchProps("Delivery_Fee"),
      },
      {
        title: "Bag_Fee",
        dataIndex: "Bag_Fee",
        key: "Bag_Fee",
        ...this.getColumnSearchProps("Bag_Fee"),
      },
      {
        title: "Cash_Back_Fee",
        dataIndex: "cash_back_fee",
        key: "cash_back_fee",
        ...this.getColumnSearchProps("cash_back_fee"),
      },
      {
        title: "App Version",
        dataIndex: "App_version",
        key: "App_version",
        ...this.getColumnSearchProps("App_version"),
      },
      {
        title: "Order Device",
        dataIndex: "Order_Request_Device",
        key: "Order_Request_Device",
        ...this.getColumnSearchProps("Order_Request_Device"),
      },
      {
        title: "Order Time",
        dataIndex: "Order_Time",
        key: "Order_Time",
        ...this.getColumnSearchProps("Order_Time"),
      },
    ];

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h4>Store Order Report</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid allproduct">
          <Row className="m-b-30">
            <Col>
              <Form layout="inline" onSubmit={this.handleSubmit}>
                <Form.Item label="Store">
                  {getFieldDecorator("store_id", {
                    rules: [
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ],
                  })(
                    <Select
                      style={{
                        width: 340,
                      }}
                      onChange={this.handleStoreID}
                      placeholder="Select Store"
                    >
                      {this.renderStorename()}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="From Date">
                  {getFieldDecorator("start_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "This field is required",
                      },
                    ],
                  })(
                    <DatePicker
                      style={{
                        width: 150,
                      }}
                      disabledDate={this.disabledStartDate}
                      format="DD-MM-YYYY"
                      placeholder="Select Date"
                      onChange={this.onStartChange}
                      onOpenChange={this.handleStartOpenChange}
                    />
                  )}
                </Form.Item>
                <Form.Item label="To Date">
                  {getFieldDecorator("end_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "This field is required",
                      },
                    ],
                  })(
                    <DatePicker
                      style={{
                        width: 150,
                      }}
                      disabledDate={this.disabledEndDate}
                      format="DD-MM-YYYY"
                      placeholder="Select Date"
                      onChange={this.onEndChange}
                      open={endOpen}
                      onOpenChange={this.handleEndOpenChange}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                  <Button
                    style={{ marginLeft: 15 }}
                    type="default"
                    onClick={this.handleResett}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Divider dashed />
          <Row
            gutter={16}
            className={this.state.visible === true ? "" : "hidden"}
          >
            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Total Orders"
                  value={dashstats.today_total_order}
                  icon={<ShoppingCart size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Pending Orders"
                  value={dashstats.total_pending_order}
                  icon={<ShoppingCart size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Delivered Orders"
                  value={dashstats.total_order}
                  icon={<ShoppingCart size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Skeleton loading={this.state.loading}>
                <StatCard
                  type="fill"
                  title="Turnover"
                  value={dashstats.total_cost}
                  icon={<Target size={20} strokeWidth={2} />}
                  color={"primary"}
                />
              </Skeleton>
            </Col>
          </Row>
          <div className="row m-t-30">
            <div className="text-left filterdiv col-md-6">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="text-left download-table-xls-button btn ant-btn-primary"
                table="table-to-xls"
                filename="Shopezy-Order-Report"
                sheet="tablexls"
                buttonText="Export Order Report"
              />
            </div>
          </div>
          <div>
            <table className="hidden" id="table-to-xls">
              <thead>
                <tr>
                  <th>Customer_ID</th>
                  <th>order_id</th>
                  <th>customer_name</th>
                  <th>customer_phone</th>
                  <th>Order_Postal_Code</th>
                  <th>delivery_address</th>
                  <th>driver_name</th>
                  <th>store_name</th>
                  <th>order_status</th>
                  <th>Total_Price</th>
                  <th>Delivery_Fee</th>
                  <th>Bag_Fee</th>
                  <th>Cash_Back_Fee</th>
                  <th>Cash_Back_Amount</th>
                  <th>Payment_Type</th>
                  <th>Delivery_Type</th>
                  <th>No_Order</th>
                  <th>Order_Coupon_Applicable</th>
                  <th>Order_date</th>
                </tr>
              </thead>
              <tbody>{this.renderNotes()}</tbody>
            </table>
          </div>

          <Table
            columns={columns}
            dataSource={this.state.tdata}
            loading={this.state.loading}
            scroll={{ x: 2500 }}
            size="middle"
            bordered
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    order: state.order,
    storeall: state.storeall,
    dashtoday: state.dashtoday,
  };
}

const WrappedRegistrationForm = Form.create()(Order);

export default connect(mapStateToProps, {
  storeallorder,
  allstorename,
  storedashreport,
})(WrappedRegistrationForm);
