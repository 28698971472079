import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  AddStore,
  slottable,
  citytable,
  checkemail,
  StoreDetail,
  cityCheck,
  catList1,
} from "../actions/assetAction";
import {
  Form,
  Input,
  Modal,
  Icon,
  Row,
  Drawer,
  Button,
  message,
  Col,
  Spin,
  Select,
  InputNumber,
  Radio,
  Avatar,
} from "antd";
import Map from "./automap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../styles/css/App.css";

const { TextArea } = Input;
const { Option } = Select;

class Addstore extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      fileList1: "",
      iconLoading: false,
      valstatus: "",
      place: [],
      Discounted: "true",
      happy_hour_status: "false",
      visible: false,
      loading: true,
      markedPrice: "",
      addressfield: true,
      userLocation: { lat: 18.5204, lng: 73.8567 },
      src: null,
      modal: false,
      croppedImage: "false",
      ImageUrl: "",
      crop: {
        unit: "px",
        width: 300,
        height: 300,
        aspect: 4 / 3,
      },
    };
  }

  componentWillMount() {
    var userp = this.props.userinfo.Permission[5];
    if (!userp.Read) {
      this.props.history.push("/");
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        this.setState({
          userLocation: { lat: latitude, lng: longitude },
        });
      },
      () => {
        //this.setState({ loading: false });
      }
    );
    this.props.catList1();
    this.props.slottable();
    this.props.citytable();
    var reqid;
    if (this.props.location.requestid === undefined) {
      reqid = localStorage.getItem("drid");
      console.log("if Block ", reqid);
      this.props
        .StoreDetail(reqid)
        .then((result) => {
          this.setState({ loading: false });
        })
        .catch((err) => {});
    } else {
      localStorage.setItem("drid", this.props.location.requestid);
      reqid = localStorage.getItem("drid");
      this.props
        .StoreDetail(reqid)
        .then((result) => {
          this.setState({ loading: false });
        })
        .catch((err) => {});
      console.log(reqid);
    }
  }
  // componentDidMount(){
  //   this.props.form.setFieldsValue({
  //     store_address: this.props.address.address,
  //   })
  // }

  renderCategory() {
    return _.map(this.props.slot, (slot, key) => {
      return (
        <Option value={slot.id} key={key}>
          {slot.sname}
          {/* ({categories.cid})  */}
        </Option>
      );
    });
  }
  renderCategory1() {
    return _.map(this.props.common, (common, key) => {
      return (
        <Option value={common.id} key={key}>
          {common.cname}
          {/* ({categories.cid})  */}
        </Option>
      );
    });
  }
  renderCategory2() {
    return _.map(this.props.categories, (categories, key) => {
      return (
        <Option value={categories.id} key={key}>
          <Avatar src={categories.imgUrl} />
          {categories.cname}
          {/* ({categories.cid})  */}
        </Option>
      );
    });
  }

  checkAddress = (rule, value, callback) => {
    console.log("changed", value);
    if (value === undefined) {
      callback("Input store address ...!");
      return;
    }
    callback();
  };

  handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      iconLoading: false,
      fileList1: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      // console.log(this.state.croppedImageUrl);
      if (!err) {
        this.setState({
          iconLoading: true,
        });
        this.props
          .checkemail(values.Email_ID)
          .then((result) => {
            console.log(result);
            if (result === "NO") {
              this.setState({
                valstatus: "success",
              });
              var reqid = localStorage.getItem("drid");
              this.props
                .AddStore(
                  values,
                  this.state.NewCroppedFile,
                  this.props.address,
                  reqid
                )
                .then((result) => {
                  message.success("Store Created Successfully..!");
                  this.setState({
                    iconLoading: false,
                    fileList1: "",
                  });
                  this.props.form.resetFields();
                  this.props.history.push("/storerequest");
                })
                .catch((err) => {});
            } else {
              this.setState({
                valstatus: "error",
                iconLoading: false,
              });

              message.error(
                "Email ID already exist, Kindly use different email id for Manager"
              );
            }
          })
          .catch((err) => {});
      }
    });
  };
  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  updateData = (result) => {
    console.log(result);
    this.setState({ place: result });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props
      .cityCheck(this.props.address.city)
      .then((result) => {
        console.log(result);
        this.props.form.setFieldsValue({
          store_address: this.props.address.address,
          ref_city: result,
        });
        this.setState({
          addressfield: false,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error(
          "Currently Service not available in this city, Please contact system Administrator",
          15
        );
      });
  };

  onTextChange = (val) => {
    var newVal = val;
    this.setState({ val: newVal });
  };
  checkPrice = (rule, value, callback) => {
    // console.log('changed', value);
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!Number.isNaN(value) && reg.test(value)) || value === "") {
      callback();
      return;
    }
    callback("Input only a numermic value...!");
  };

  //========================= image crop config=============

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result, modal: true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop, croppedImage: "false" });
  };
  cropImageAction = () => {
    this.setState({ src: null, modal: false, croppedImage: "true" });
  };
  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.png"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      const myFilename = fileName;
      const base64Image = canvas.toDataURL("image/png");
      const myNewCroppedFile = this.base64StringtoFile(base64Image, myFilename);
      console.log(myNewCroppedFile);
      this.setState({ NewCroppedFile: myNewCroppedFile });
      resolve(base64Image);
    });
  }
  base64StringtoFile = (base64String, filename) => {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  handleCancel = () => {
    this.setState({ src: null, modal: false });
    this.props.form.setFieldsValue({
      Store_Image_Url: "",
    });
  };
  handleDiscounted = (e) => {
    this.setState({
      Discounted: e.target.value,
    });
    console.log(this.state.Discounted);
  };

  markedPrice = (e) => {
    this.setState({
      markedPrice: e.target.value,
    });
  };
  distanceCalculator = (e) => {
    console.log(e);
    var static_km = e * 1.60934;
    var static_milli_km = static_km * 1000;
    this.props.form.setFieldsValue({
      Static_KM: static_km,
      Static_Milli_KM: static_milli_km,
    });
  };
  handleOrderStart = (e) => {
    console.log(e);
  };
  // ======================================================

  render() {
    const { crop, croppedImageUrl, ImageUrl, croppedImage, src } = this.state;

    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    //==============================================================================================================//

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Add Store</h4>
              </div>
              <div className="col-sm-8 text-right">
                <Link to="/storerequest">
                  <Button className="pull-right" type="dark" icon="arrow-left">
                    Back To Store
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid text-left addproduct">
          <Spin spinning={this.state.loading}>
            <Drawer
              width={720}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <Map
                google={this.props.google}
                center={this.state.userLocation}
                height="300px"
                zoom={15}
              />
              <Button
                type="primary"
                className="m-t-100"
                size="large"
                htmlType="submit"
                onClick={this.onClose}
              >
                Submit
              </Button>
            </Drawer>

            <Row gutter={15}>
              <Form onSubmit={this.handleSubmit}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 0 }}>
                    Store Information
                  </h4>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store Name">
                    {getFieldDecorator("Store_Name", {
                      initialValue: this.props.storerequest.store_name,
                      rules: [
                        {
                          required: true,
                          message: "Enter store name",
                          whitespace: false,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store Short Code">
                    {getFieldDecorator("Store_Short_Code", {
                      rules: [
                        {
                          required: true,
                          message: "Enter store short code",
                          whitespace: false,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Store address"
                    extra={this.props.storerequest.Manager_Store_Address}
                  >
                    <Row gutter={8}>
                      <Col span={18}>
                        {getFieldDecorator("store_address", {
                          // initialValue:this.props.address.address,
                          rules: [
                            {
                              required: true,
                              validator: this.checkAddress,
                            },
                          ],
                        })(
                          <TextArea
                            rows={3}
                            disabled={this.state.addressfield}
                          />
                        )}
                      </Col>
                      <Col span={6}>
                        <Button type="primary" onClick={this.showDrawer}>
                          <Icon type="global" /> Select Address
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Store Message">
                    {getFieldDecorator("Configuration_Message_One", {
                      rules: [
                        {
                          required: false,
                          message: "Enter Category Description",
                          whitespace: false,
                        },
                      ],
                    })(<TextArea rows={3} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Additional Store Message"
                  >
                    {getFieldDecorator("Configuration_Message_Two", {
                      rules: [
                        {
                          required: false,
                          message: "Enter Category Description",
                          whitespace: false,
                        },
                      ],
                    })(<TextArea rows={3} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Operation Slot">
                    {getFieldDecorator("ref_slot", {
                      rules: [
                        {
                          required: true,
                          message: "select opeartion slot ",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select any one slot"
                        onChange={this.handleChange}
                      >
                        <Option value="1">1 Hours</Option>
                        <Option value="2">2 Hours</Option>
                        <Option value="3">3 Hours</Option>
                        <Option value="4">4 Hours</Option>
                        <Option value="5">5 Hours</Option>
                        <Option value="6">6 Hours</Option>
                        <Option value="7">7 Hours</Option>
                        <Option value="8">8 Hours</Option>
                        <Option value="9">9 Hours</Option>
                        <Option value="10">10 Hours</Option>
                        <Option value="11">11 Hours</Option>
                        <Option value="12">12 Hours</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Min Order Category List"
                  >
                    {getFieldDecorator("Exclude_Min_Order_Category_List", {
                      rules: [
                        {
                          required: true,
                          message: "Select Category",
                          type: "array",
                        },
                      ],
                    })(
                      <Select placeholder="Select Category" mode="multiple">
                        {this.renderCategory2()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Operating City">
                    {getFieldDecorator("ref_city", {
                      rules: [
                        {
                          required: true,
                          message: "Select opearting city",
                        },
                      ],
                    })(
                      <Select disabled onChange={this.handleChange}>
                        {this.renderCategory1()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="18+ Category List">
                    {getFieldDecorator("Category_18_List", {
                      rules: [
                        {
                          required: true,
                          message: "Select Category",
                          type: "array",
                        },
                      ],
                    })(
                      <Select placeholder="Select Category" mode="multiple">
                        {this.renderCategory2()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Minimum Order Amount">
                    {getFieldDecorator("Min_Order_Amount", {
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<InputNumber style={{ width: 400 }} />)}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                  <Form.Item {...formItemLayout} label="Delivery Fee">
                    {getFieldDecorator("Delivery_Fee", {
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(
                            "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
                          ),
                          message: "Wrong format!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="00.00"
                        style={{ width: 400 }}
                        onChange={this.markedPrice}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Maximum Replaceable Amount"
                  >
                    {getFieldDecorator("replaceable_amount", {
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<InputNumber style={{ width: 400 }} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                  <Form.Item {...formItemLayout} label="Bag Fee">
                    {getFieldDecorator("Bag_Fee", {
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(
                            "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
                          ),
                          message: "Wrong format!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="00.00"
                        style={{ width: 400 }}
                        onChange={this.markedPrice}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Cash Back Fee">
                    {getFieldDecorator("Cash_Back_Fee", {
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(
                            "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
                          ),
                          message: "Wrong format!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="00.00"
                        style={{ width: 400 }}
                        onChange={this.markedPrice}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Cashback Category List">
                    {getFieldDecorator("Cash_Back_Category", {
                      rules: [
                        {
                          required: true,
                          message: "Select Category",
                        },
                      ],
                    })(
                      <Select placeholder="Select Category">
                        {this.renderCategory2()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  xs={{
                    span: 24,
                  }}
                  sm={{
                    span: 12,
                  }}
                  lg={{
                    span: 8,
                  }}
                >
                  <Form.Item {...formItemLayout} label="Discount Type">
                    {getFieldDecorator("discount_type", {
                      rules: [
                        {
                          required: true,
                          message: "Please Select Discount Type",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Select
                        onChange={this.handleCoupon}
                        placeholder="Select Discount Type"
                      >
                        <Option value="2">Value (� GBP)</Option>
                        <Option value="1">Percent (%)</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  xs={{
                    span: 24,
                  }}
                  sm={{
                    span: 12,
                  }}
                  lg={{
                    span: 6,
                  }}
                >
                  <Form.Item {...formItemLayout} label="Discount Value">
                    {getFieldDecorator("discount_value", {
                      rules: [
                        {
                          required: true,
                          validator: this.checkPrice,
                        },
                      ],
                    })(
                      <InputNumber
                        style={{
                          width: 200,
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Store Image"
                    // extra="Category Image"
                  >
                    {getFieldDecorator("Store_Image_Url", {
                      initialValue: ImageUrl,
                      rules: [
                        {
                          required: true,
                          message: "Please upload store display image!",
                        },
                      ],
                    })(<input type="file" onChange={this.onSelectFile} />)}
                  </Form.Item>

                  <Row gutter={8}>
                    <Col span={18}>
                      <div>
                        {croppedImage === "true" ? (
                          <img
                            alt="Crop"
                            style={{ maxWidth: "100%" }}
                            src={croppedImageUrl}
                          />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 80 }}>
                    Commission Information
                  </h4>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Commission Applicable">
                    {getFieldDecorator("Stores_Master_Commission_Applicable", {
                      initialValue: "true",
                      rules: [
                        {
                          required: true,
                          message: "Store Available Status",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Radio.Group onChange={this.handleDiscounted}>
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                {this.state.Discounted === "true" ? (
                  <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item {...formItemLayout} label="Commission %:">
                      {getFieldDecorator("Stores_Commission_Percentage", {
                        initialValue: 0,

                        rules: [{ required: true, validator: this.checkPrice }],
                      })(<InputNumber style={{ width: 300 }} />)}
                    </Form.Item>
                  </Col>
                ) : null}
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 80 }}>
                    Additional Information
                  </h4>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Delivery Distance (mile)"
                  >
                    {getFieldDecorator("Delivery_Distance", {
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(
                      <InputNumber
                        style={{ width: 300 }}
                        onChange={this.distanceCalculator}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Static KM">
                    {getFieldDecorator("Static_KM", {
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<InputNumber style={{ width: 300 }} disabled />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Static Milli KM">
                    {getFieldDecorator("Static_Milli_KM", {
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<InputNumber style={{ width: 300 }} disabled />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Block Post Codes">
                    {getFieldDecorator("Blocked_Postal_code", {
                      rules: [
                        { required: false, message: "This field is required!" },
                      ],
                    })(
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Type postal code"
                      ></Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Phone number">
                    {getFieldDecorator("Phone_number", {
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<InputNumber style={{ width: 300 }} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Order Start Time">
                    {getFieldDecorator("Order_Start_Time", {
                      rules: [
                        {
                          required: true,
                          message: "select order start time ",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select any option"
                        onChange={this.handleOrderStart}
                      >
                        <Option value="1">1 AM</Option>
                        <Option value="2">2 AM</Option>
                        <Option value="3">3 AM</Option>
                        <Option value="4">4 AM</Option>
                        <Option value="5">5 AM</Option>
                        <Option value="6">6 AM</Option>
                        <Option value="7">7 AM</Option>
                        <Option value="8">8 AM</Option>
                        <Option value="9">9 AM</Option>
                        <Option value="10">10 AM</Option>
                        <Option value="11">11 AM</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Order Time">
                    {getFieldDecorator("Order_Time", {
                      rules: [
                        {
                          required: true,
                          message: "Enter Order Time",
                          whitespace: false,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 80 }}>
                    Store Manager Information
                  </h4>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Email ID"
                    hasFeedback
                    validateStatus={this.state.valstatus}
                  >
                    {getFieldDecorator("Email_ID", {
                      initialValue: this.props.storerequest.manager_email,
                      rules: [
                        {
                          required: true,
                          message: "Enter Email ID",
                          whitespace: false,
                        },
                      ],
                    })(<Input id={this.state.valstatus} />)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Name">
                    {getFieldDecorator("Manager_Name", {
                      initialValue: this.props.storerequest.manager_name,
                      rules: [
                        {
                          required: true,
                          message: "Enter name",
                          whitespace: false,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Phone Number">
                    {getFieldDecorator("phone", {
                      initialValue: this.props.storerequest.manager_phone,
                      rules: [{ required: true, validator: this.checkPrice }],
                    })(<Input />)}
                  </Form.Item>
                </Col>

                {/* =============================== Submit Button ======================================== */}

                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item wrapperCol={{ span: 12 }}>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={this.state.iconLoading}
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ marginLeft: 15 }}
                      size="large"
                      onClick={this.handleReset}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Row>
          </Spin>
          <Modal
            title="Crop Store Image"
            visible={this.state.modal}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.cropImageAction}
              >
                <Icon type="scissor" /> Crop Image
              </Button>,
            ]}
          >
            <Row gutter={8}>
              <Col span={24}>
                {src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
              </Col>
            </Row>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    address: state.address,
    slot: state.slot,
    common: state.common,
    storerequest: state.storerequest,
    categories: state.categories,
    userinfo: state.userinfo,
  };
}

const WrappedRegistrationForm = Form.create()(Addstore);

export default connect(mapStateToProps, {
  AddStore,
  slottable,
  citytable,
  checkemail,
  StoreDetail,
  cityCheck,
  catList1,
})(WrappedRegistrationForm);
