import { firedb,storage,db } from "../firebase";
import { GET_CHAT, GET_CHAT_ALL } from "../actionTypes";
import moment from "moment";
import _ from "lodash";
import random from "random-string-generator";
const newMetadata = {
  cacheControl: 'public,max-age=0',
}
const message_arr = [];

export function allchat() {
  return dispatch => {
    return new Promise((resolve, reject) => {
    
      db.collection("Stores_Master").get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
           var obj_arr = {
             key:doc.id,
             store_name:doc.data().Store_Name,
           }
            message_arr.push(obj_arr)
        });
        firedb
        .ref("/Messages_Master/")
        .orderByChild("Chat_Type").equalTo(1)
        .on("value", function(snapshot) {
          var snap_size = snapshot.numChildren();
          //console.log(snap_size);
          var arr = [];
          snapshot.forEach(function(msgmaster2) {
            //console.log(msgmaster2.val());
            var Ref_Customer_Name = msgmaster2.val().Ref_Customer_Name;
            var Ref_Customer_Profile_Pic = msgmaster2.val().Ref_Customer_Profile_Pic;
            var Updated_At = msgmaster2.val().Updated_At;
            var doc_id = msgmaster2.key;
            var Ref_Store_Id = msgmaster2.val().Ref_Store_Id;
             //arr_order_status
             if (Ref_Store_Id) 
             {
              var selectedorder_status =
              message_arr[message_arr.map(function (item1) {return item1.key}).indexOf(Ref_Store_Id)];

              if(selectedorder_status !== undefined)
              {
                var ref_store_name = selectedorder_status.store_name
              }
              else
              {
                ref_store_name = "-"
              }
              
            } 
            else 
            {
              ref_store_name = "-";
            }
            // console.log(doc_id);
            firedb
              .ref("/Messages/" + doc_id)
              .orderByChild("Is_Read")
              .equalTo("False")
              .once("value", function(dataSnapshot) {
                var snap_update_child = dataSnapshot.numChildren();
                var obj = {
                  id: doc_id,
                  Ref_Customer_Name: Ref_Customer_Name,
                  Ref_Customer_Profile_Pic: Ref_Customer_Profile_Pic,
                  Ref_Store_Name : ref_store_name,
                  Updated_At: Updated_At,
                  Count: snap_update_child,
                  sortdate: Updated_At
                };
                //console.log(obj);
                arr.push(obj);
                //console.log(snap_size, arr.length);
                if (snap_size === arr.length) {
                 var sortedarr = _.orderBy(arr, ['sortdate'],['desc']);   
                 var newarr = sortedarr.slice(0, 25);

                 console.log(newarr)     
                  dispatch({
                    type: GET_CHAT_ALL,
                    payload: newarr
                  });
                  resolve(newarr);
                }
              });
          });
        });
    });

    });
  };
}

export function singlechat(chatid, prechatid) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      console.log(chatid, prechatid);
      firedb.ref("/Messages/" + prechatid).off("value");
      firedb.ref("/Messages/" + chatid).on("value", function(dataSnapshot) {
        var snap_update_child = dataSnapshot.numChildren();
        console.log(snap_update_child);
        dataSnapshot.forEach(function(childsnap_update) {
          // console.log(childsnap_update)
          var Trip_autoid = childsnap_update.key;
          var IsRead = childsnap_update.val().Is_Read;
          // alert(Trip_autoid+'/ '+IsRead)
          if (IsRead === "False") {
            var msgref_update = firedb
              .ref()
              .child("Messages/" + chatid + "/" + Trip_autoid);
             
            msgref_update.update({
              Is_Read: "True"
            });
          }
        });
        dispatch({
          type: GET_CHAT,
          payload: dataSnapshot.val()
        });
        resolve(dataSnapshot.val());
      });
    });
  };
}

export function postchat(admin_user, active_user, msg) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      var postData = {
        Admin_Id: admin_user,
        Updated_At: parseInt(moment().format('X'))
      };
      firedb
        .ref("Messages_Master/" + active_user)
        .update(postData)
        .then(result => {
          firedb
            .ref("Messages_Master/" + active_user)
            .once("value")
            .then(function(snapshot) {
              console.log(snapshot.val().Customer_Id);
              var ref_newapp = firedb.ref("Messages").child(active_user);
              var auto_id = ref_newapp.push();
              auto_id.set({
                From_Id: admin_user,
                Is_Read: "False",
                Message_Date: moment().format("DD-MM-YYYY"),
                Message_Time: moment().format("H:mm:ss"),
                Text: msg,
                Time_Stamp: parseInt(moment().format("X")),
                To_Id: snapshot.val().Customer_Id
              });

              resolve(snapshot);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };
}

export function postmessage(cust_user, admin_user, msg,Customer_Name) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      console.log(cust_user, admin_user, msg)
      var starCountRef = firedb.ref('Messages_Master/').orderByChild("Customer_Id").equalTo(cust_user);
      starCountRef.once("value", function(querySnapshot) {
        if (querySnapshot.numChildren() === 0) 
        {
          var postsRef = firedb.ref("Messages_Master/")
          var newRef = postsRef.push({
            Admin_Id: admin_user,
            Chat_Type:1,
            Customer_Id:cust_user,
            Manager_Id:"",
            Ref_Chat_Status_Id:1,
            Ref_City_Id:"",
            Ref_Customer_Name:Customer_Name,
            Ref_Customer_Profile_Pic:"",
            Ref_Display_Order_Id:"",
            Ref_Order_Id:"",
            Ref_Store_Id:"",
            Created_At:parseInt(moment().format('X')),
            Updated_At: parseInt(moment().format('X'))
          })
          var postId = newRef.key;
          console.log(postId);
          var ref_newapp = firedb.ref("Messages/"+postId);
              var auto_id = ref_newapp.push();
              auto_id.set({
                From_Id: admin_user,
                Is_Read: "True",
                Message_Date: moment().format("DD-MM-YYYY"),
                Message_Time: moment().format("H:mm:ss"),
                Text: msg,
                Time_Stamp: parseInt(moment().format("X")),
                To_Id: cust_user
              });
              resolve("chat done")
        }
        else 
        {
        reject("User Already Exist");
        }
      })

    });
  };
}

export function postchatimg(admin_user, active_user, msg) {
  return dispatch => {
    
    return new Promise((resolve, reject) => {
      let id2 = random("lowernumeric");
      var postData = {
        Admin_Id: admin_user,
        Updated_At: parseInt(moment().format('X'))
      };
      msg.forEach((f) => {
        storage
          .ref("Chat_Images/"+active_user+"/"+active_user+"_"+id2+f.name)
          .put(f,newMetadata)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              //console.log(snapshot.ref.fullPath);
              let img = new Image();
              img.src = downloadURL;
              img.onload = () =>
              {
               // console.log(img.height);
                firedb
                .ref("Messages_Master/" + active_user)
                .update(postData)
                .then(result => {
                  firedb
                    .ref("Messages_Master/" + active_user)
                    .once("value")
                    .then(function(snapshot) {
                      console.log(snapshot.val().Customer_Id);
                      var ref_newapp = firedb.ref("Messages").child(active_user);
                      var auto_id = ref_newapp.push();
                      auto_id.set({
                        From_Id: admin_user,
                        Is_Read: "False",
                        Image_Url:downloadURL,
                        Image_Height:img.height,
                        Image_Width:img.width,
                        Message_Date: moment().format("DD-MM-YYYY"),
                        Message_Time: moment().format("H:mm:ss"),
                        Text: "",
                        Time_Stamp: parseInt(moment().format("X")),
                        To_Id: snapshot.val().Customer_Id
                      });
        
                      resolve(snapshot);
                    })
                    .catch(err => {
                      reject(err);
                    });
                })
                .catch(err => {
                  console.log(err);
                  reject(err);
                });
              }  
         
            })
          })
      })
      
    });
  };
}
