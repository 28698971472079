import React, { Component } from "react";
import { connect } from "react-redux";
import { OrderDetailNew, orderlog } from "../actions/assetAction";
import { Link } from "react-router-dom";
import { Skeleton, Button, Steps } from "antd";
import _ from "lodash";
import "../styles/css/App.css";
const referencevalue = require("../env");
const { Step } = Steps;

class vieworder extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: true,
      order: "",
      finalarray: [],
      orderlog: [],
    };
  }

  componentDidMount() {
    let location = new URLSearchParams(window.location.search);
    let orderid = location.get("id");
    console.log(orderid);

    this.props
      .OrderDetailNew(orderid)
      .then((result) => {
        console.log(result);
        this.setState({
          loading: false,
          order: result,
          finalarray: result.finalarray,
        });
        this.props
          .orderlog(result.Display_Order_Id)
          .then((res) => {
            console.log(res);
            this.setState({ orderlog: res });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderCategory() {
    return _.map(this.state.finalarray, (finalarray, key) => {
      if (
        finalarray.Ref_Order_Detail_Status_Id ===
          parseInt(referencevalue.ProductEdited) ||
        finalarray.Ref_Order_Detail_Status_Id ===
          parseInt(referencevalue.ProductReplaced) ||
        finalarray.Ref_Order_Detail_Status_Id ===
          parseInt(referencevalue.ProductCancelled)
      ) {
        return (
          <tr key={finalarray.Display_Order_Detail_Id}>
            <td className="strike">{finalarray.No_Of_Order} x</td>
            <td className="text-left">
              <span className="strike">
                {finalarray.Ref_Products_Display_Name}
              </span>
              <br />
              <span className="strike">{finalarray.Promo_Display_Name}</span>
            </td>
            <td className="text-right strike">
              {referencevalue.currencysymbol}{" "}
              {finalarray.Ref_Order_Total_Fare.toFixed(2)}
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={finalarray.Display_Order_Detail_Id}>
            <td>{finalarray.No_Of_Order} x</td>
            <td className="text-left">
              <span>{finalarray.Ref_Products_Display_Name}</span>
              <br />
              <span>{finalarray.Promo_Display_Name}</span>
            </td>
            <td className="text-right">
              {referencevalue.currencysymbol}{" "}
              {finalarray.Ref_Order_Total_Fare.toFixed(2)}
            </td>
          </tr>
        );
      }
    });
  }

  // ======================================================

  render() {
    const { order, orderlog } = this.state;

    // ==============================================================================
    // ================================//

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Order Details</h4>
              </div>
              <div className="col-sm-8 text-right">
                <Link to="/order">
                  <Button className="pull-right" type="dark" icon="arrow-left">
                    Back To Order
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid m-t-30 text-left invoice-pad">
          <Skeleton active loading={this.state.loading}>
            <div className="row">
              <div className="col-xs-12">
                <div className="invoice-title">
                  <h2>Invoice</h2>
                  <h3 className="pull-right">
                    Order # {order.Display_Order_Id}
                  </h3>
                </div>
                <hr />
                <div className="row">
                  <div className="col-xs-6">
                    <address>
                      <strong>Billed To:</strong>
                      <br />
                      {order.Customer_Name}
                      <br />
                      {order.Order_Delivery_Address}
                      <br />
                    </address>
                  </div>
                  <div className="col-xs-6 text-right">
                    <address>
                      <strong>Store Details:</strong>
                      <br />
                      {order.Store_Name}
                      <br />
                      {order.Store_Address}
                      <br />
                      Ph: {order.Store_Mobile_Number}
                      <br />
                      <br />
                      <strong>Store Manager:</strong>
                      <br />
                      {order.Manager_Name}
                      <br />
                      {order.Manager_Email_Address}
                    </address>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6">
                    <address>
                      <strong>Payment Method:</strong>
                      <br />
                      Pay By {order.Payment_Type}
                      <br />
                      {order.Customer_Email_Address}
                    </address>
                  </div>
                  <div className="col-xs-6 text-right">
                    <address>
                      <strong>Order Date:</strong>
                      <br />
                      {order.Created_At}
                      <br />
                      <br />
                    </address>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-b-30 m-t-30">
              <div className="col-md-12">
                <Steps>
                  {orderlog.map((d, index) => (
                    <Step
                      key={index}
                      status={
                        orderlog.length - 1 === index ? "process" : "finish"
                      }
                      title={d.dir_order_status}
                      description={d.Updated_at}
                    />
                  ))}
                </Steps>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h3 className="panel-title">
                      <strong>Order summary</strong>
                    </h3>
                  </div>
                  <div className="panel-body">
                    <div className="table-responsive">
                      <table className="table table-condensed">
                        <thead>
                          <tr>
                            <td>
                              <strong>Quantity</strong>
                            </td>
                            <td>
                              <strong>Item</strong>
                            </td>
                            <td className="text-right">
                              <strong>Price</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderCategory()}

                          <tr>
                            <td className="thick-line"></td>

                            <td className="thick-line text-right">
                              <strong>
                                Subtotal ({order.TotalOrderCount})
                              </strong>
                            </td>
                            <td className="thick-line text-right">
                              {order.Order_Currency_Symbol}{" "}
                              {order.TotalOrderPrice}
                            </td>
                          </tr>
                          <tr>
                            <td className="no-line"></td>

                            <td className="no-line text-right text-success">
                              <strong>Saving Price</strong>
                            </td>
                            <td className="no-line text-right text-success">
                              {order.Order_Currency_Symbol} {order.Saving_Price}
                            </td>
                          </tr>

                          <tr>
                            <td className="no-line"></td>

                            <td className="no-line text-right">
                              <strong>Discount</strong>
                            </td>
                            <td className="no-line text-right">
                              {order.Order_Currency_Symbol}{" "}
                              {order.Order_Discount_Fare}
                            </td>
                          </tr>
                          <tr>
                            <td className="no-line"></td>

                            <td className="no-line text-right">
                              <strong>Delivery Fee</strong>
                            </td>
                            <td className="no-line text-right">
                              {order.Order_Currency_Symbol} {order.Delivery_Fee}
                            </td>
                          </tr>
                          <tr>
                            <td className="no-line"></td>

                            <td className="no-line text-right">
                              <strong>Bag Fee</strong>
                            </td>
                            <td className="no-line text-right">
                              {order.Order_Currency_Symbol} {order.Bag_Fee}
                            </td>
                          </tr>
                          <tr>
                            <td className="no-line"></td>

                            <td className="no-line text-right">
                              <strong>Service Fee</strong>
                            </td>
                            <td className="no-line text-right">
                              {order.Order_Currency_Symbol}{" "}
                              {order.Ref_Service_Fee}
                            </td>
                          </tr>
                          {order.Ref_Cash_Back_Fee !== "0.00" && (
                            <tr>
                              <td className="no-line"></td>

                              <td className="no-line text-right">
                                <strong>Cashback Fee</strong>
                              </td>
                              <td className="no-line text-right">
                                {order.Order_Currency_Symbol}{" "}
                                {order.Ref_Cash_Back_Fee}
                              </td>
                            </tr>
                          )}

                          <tr>
                            <td className="no-line"></td>
                            <td className="no-line text-right">
                              <strong>Total</strong>
                            </td>
                            <td className="no-line text-right">
                              {order.Order_Currency_Symbol} {order.Total_Price}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Skeleton>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, common: state.common };
}

export default connect(mapStateToProps, { OrderDetailNew, orderlog })(
  vieworder
);
