import { combineReducers } from "redux";
import userReducer from "./userReducer";
import loadingReducer from "./loadingReducer";
import proReducer from "./proReducer";
import catReducer from "./catReducer";
import subcatReducer from "./subcatReducer";
import currencyReducer from "./curReducer";
import appReducers from "./appReducers";
import locReducers from "./locReducers";
import couponReducer from "./couponReducer";
import driverReducer from "./driverReducer";
import addressReducer from "./getaddReducer";
import orderReducer from "./orderReducer";
import slotReducer from "./slotReducer";
import commonReducer from "./commonReducer";
import StoreReqReducer from "./StoreReqReducer";
import chatReducer from "./chatReducer";
import promoReducer from "./promoReducer";
import chatallReducer from "./chatallReducer";
import promoallReducer from "./premierpromoReducer";
import dashboardReducers from "./dashboardReducers";
import dashboardtodayReducers from "./dashboardTodayReducer";
import storeallReducers from "./storeallReducers";
import chatliveReducer from "./chatliveReducer";
import userinfoReducer from "./userinfoReducer";

const rootReducer = combineReducers({
  user: userReducer,
  loading: loadingReducer,
  products: proReducer,
  categories: catReducer,
  subcategories: subcatReducer,
  currency: currencyReducer,
  status: appReducers,
  currentLatLng: locReducers,
  coupon: couponReducer,
  driver: driverReducer,
  address: addressReducer,
  order: orderReducer,
  slot: slotReducer,
  common: commonReducer,
  storerequest: StoreReqReducer,
  chat: chatReducer,
  promo: promoReducer,
  chatall: chatallReducer,
  pormoall: promoallReducer,
  dashdata: dashboardReducers,
  dashtoday: dashboardtodayReducers,
  storeall: storeallReducers,
  chatlive: chatliveReducer,
  userinfo: userinfoReducer,
});

export default rootReducer;
