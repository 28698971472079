import React, { Component } from "react";
import { connect } from "react-redux";
import { updatePassword } from "../actions/userAction";
import { Button, message, Form, Icon, Input } from "antd";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      confirmDirty: false,
      loading: false,
    };
    // bind
  }

  // handle change
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // handle submit
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.props
          .updatePassword(
            this.props.userinfo.Email,
            values.currentpassword,
            values.password
          )
          .then((res) => {
            message.success(res);
          })
          .catch((err) => {
            message.success(err);
          });
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Change Your Password</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid allproduct">
          <div className="row">
            <div className="col-md-4">
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item label="Current Password" hasFeedback>
                  {getFieldDecorator("currentpassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your current password!",
                      },
                    ],
                  })(<Input.Password />)}
                </Form.Item>
                <Form.Item label="New Password" hasFeedback>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(<Input.Password />)}
                </Form.Item>
                <Form.Item label="Confirm Password" hasFeedback>
                  {getFieldDecorator("confirm", {
                    rules: [
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, userinfo: state.userinfo };
}

const WrappedNormalLoginForm = Form.create({ name: "Change_Password" })(
  ChangePassword
);

export default connect(mapStateToProps, { updatePassword })(
  WrappedNormalLoginForm
);
