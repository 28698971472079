import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/messaging';

// Initialize Firebase
var config = {
apiKey: "AIzaSyDQJ1oFTe57_D3kzTFo0YY20Q-j70_Xrr0",
    authDomain: "pickmelocals.firebaseapp.com",
    databaseURL: "https://pickmelocals.firebaseio.com",
    projectId: "pickmelocals",
    storageBucket: "pickmelocals.appspot.com",
    messagingSenderId: "792329169017"
}

  firebase.initializeApp(config);
  

  
  export const db = firebase.firestore();
  export const storage = firebase.storage();
  export const auth = firebase.auth();
  export const firedb = firebase.database();
  
  
  