import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getModules, createDesignations } from "../actions/assetAction";
import _ from "lodash";
import {
  Button,
  Table,
  Select,
  Form,
  Input,
  Switch,
  message,
  Col,
  Row,
} from "antd";
const { Option } = Select;

class CreateRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Modules: [],
      loading: false,
    };
  }

  componentWillMount() {
    this.getModules();
  }

  getModules() {
    this.props
      .getModules()
      .then((res) => {
        // console.log(res);
        res.forEach((element) => {
          element.fullAccess = false;
          element.Read = false;
          element.Create = false;
          element.Update = false;
          element.Delete = false;
        });

        this.setState({
          Modules: res,
        });
      })
      .catch((err) => {
        this.setState({
          Modules: [],
        });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var premissions = [];
        this.state.Modules.forEach((element) => {
          premissions.push({
            fullAccess: element.fullAccess,
            Read: element.Read,
            Create: element.Create,
            Update: element.Update,
            Delete: element.Delete,
            id: element.id,
          });
        });
        console.log("Received values of form: ", values, premissions);
        this.setState({
          loading: true,
        });
        this.props
          .createDesignations(values, premissions)
          .then((res) => {
            message.success("Added Successfully!");
            this.props.history.push("/userrole");
            this.setState({
              loading: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  handleChange = (record, text, p) => {
    // console.log(record, text, p);
    var modules = this.state.Modules;
    var index = _.findIndex(modules, { id: record.id });
    modules[index].fullAccess = p === "fullAccess" ? !text : false;
    modules[index].Read =
      p === "fullAccess" ? !text : p === "Read" ? !record.Read : record.Read;
    modules[index].Create =
      p === "fullAccess"
        ? !text
        : p === "Create"
        ? !record.Create
        : record.Create;
    modules[index].Update =
      p === "fullAccess"
        ? !text
        : p === "Update"
        ? !record.Update
        : record.Update;
    modules[index].Delete =
      p === "fullAccess"
        ? !text
        : p === "Delete"
        ? !record.Delete
        : record.Delete;
    // console.log(index, modules);
    this.setState({
      Modules: modules,
    });
  };

  // ======================================================

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Modules, loading } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const columns = [
      {
        title: "Module",
        dataIndex: "Name",
        key: "Name",
      },
      {
        title: "Full Access",
        dataIndex: "fullAccess",
        key: "fullAccess",
        render: (text, record) => {
          return (
            <Switch
              checked={text}
              onChange={() => this.handleChange(record, text, "fullAccess")}
            />
          );
        },
      },
      {
        title: "Read",
        dataIndex: "Read",
        key: "Read",
        render: (text, record) => {
          return (
            <Switch
              checked={text}
              onChange={() => this.handleChange(record, text, "Read")}
            />
          );
        },
      },
      {
        title: "Create",
        dataIndex: "Create",
        key: "Create",
        render: (text, record) => {
          return (
            <Switch
              checked={text}
              onChange={() => this.handleChange(record, text, "Create")}
            />
          );
        },
      },
      {
        title: "Update",
        dataIndex: "Update",
        key: "Update",
        render: (text, record) => {
          return (
            <Switch
              checked={text}
              onChange={() => this.handleChange(record, text, "Update")}
            />
          );
        },
      },
      {
        title: "Delete",
        dataIndex: "Delete",
        key: "Delete",
        render: (text, record) => {
          return (
            <Switch
              checked={text}
              onChange={() => this.handleChange(record, text, "Delete")}
            />
          );
        },
      },
    ];

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Create Role</h4>
              </div>
              <div className="col-sm-8 text-right">
                <Link to="/userrole">
                  <Button type="default">Back</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid allproduct">
          <Form onSubmit={this.handleSubmit} className="row">
            <Form.Item {...formItemLayout}>
              <h5 className="padd15">METADATA</h5>
            </Form.Item>
            <Row gutter={15}>
              <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 8 }}>
                <Form.Item label="Role Name" {...formItemLayout}>
                  {getFieldDecorator("desgRole", {
                    rules: [
                      { required: true, message: "Please input role name!" },
                    ],
                  })(<Input placeholder="Enter role name" />)}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 8 }}>
                <Form.Item label="Description" {...formItemLayout}>
                  {getFieldDecorator("Description", {
                    rules: [
                      { required: true, message: "Please input description!" },
                    ],
                  })(<Input placeholder="Enter description" />)}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 8 }}>
                <Form.Item label="Status" {...formItemLayout}>
                  {getFieldDecorator("IsActive", {
                    rules: [
                      { required: true, message: "Please input description!" },
                    ],
                  })(
                    <Select
                      mode="single"
                      style={{ width: "100%" }}
                      placeholder="Select Status"
                    >
                      <Option value="true">Active</Option>
                      <Option value="false">In-Active</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item {...formItemLayout}>
              <h5 className="padd15">PERMISSIONS</h5>
            </Form.Item>
            <Form.Item className="col-sm-12 col-xs-12">
              <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={Modules}
              />
              <Button
                className="mr5"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Submit
              </Button>
              <Button
                className="mr5"
                type="default"
                onClick={() => this.getModules()}
              >
                Reset
              </Button>
              <Link to="/userrole">
                <Button className="mr5" type="link">
                  Cancel
                </Button>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
  };
}
const WrappedComponent = Form.create({})(CreateRole);
export default connect(mapStateToProps, {
  getModules,
  createDesignations,
})(WrappedComponent);
