export const GET_USER = "GET_USER";
export const GET_USERINFO = "GET_USERINFO";
export const USER_STATUS = "USER_STATUS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_SUBCATEGORIES = "GET_SUBCATEGORIES";
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_SUCCESS = "GET_SUCCESS";
export const GET_ERROR = "GET_ERROR";
export const GET_LOCATION = "GET_LOCATION";
export const GET_COUPON = "GET_COUPON";
export const GET_DRIVER = "GET_DRIVER";
export const GET_CITY = "GET_CITY";
export const GET_COUNTRY_CODE = "GET_COUNTRY_CODE";
export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ORDER = "GET_ORDER";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const GET_SLOT = "GET_SLOT";
export const GET_STORE = "GET_STORE";
export const GET_STORE_ALL = "GET_STORE_ALL";
export const GET_COMMON_SLOT = "GET_COMMON_SLOT";
export const GET_STORE_DETAIL = "GET_STORE_DETAIL";
export const GET_CHAT = "GET_CHAT";
export const GET_CHAT_ALL = "GET_CHAT_ALL";
export const GET_SINGLE_PROMO = "GET_SINGLE_PROMO";
export const GET_ALL_PROMO = "GET_ALL_PROMO";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_TODAY = "GET_DASHBOARD_TODAY";
export const GET_ORDER_LIVE = "GET_ORDER_LIVE";
export const GET_CHAT_LIVE = "GET_CHAT_LIVE";
export const Dev_URL = "https://europe-west2-pickmelocals.cloudfunctions.net";
//export const Live_URL ='https://us-central1-pickmelocals-b857b.cloudfunctions.net';

//export const Dev_URL ='https://us-central1-pickmelocals.cloudfunctions.net';
