import { GET_USERINFO } from "../actionTypes";

const userinfoReducer = function (state = {}, action) {
  switch (action.type) {
    case GET_USERINFO:
      return action.payload;
    default:
      return state;
  }
};

export default userinfoReducer;
